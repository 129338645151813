import api from "./api";
const insuranceMediaAddAPI = (data) => {
    return api({
        method: "POST",
        url: "/patient/insurance/media/add",
        data,
    }).then(res => res.data)
}

const insuranceMediaUploadToS3API = (url, type, file) => {
    return api({
        method: "PUT",
        url: url,
        headers: { "content-type": type },
        data: file,
    })
}

const insuranceMediaConfirmAPI = (data) => {
    return api({
        method: "POST",
        url: "/patient/insurance/media/confirm",
        data,
    }).then(res => res.data)
}

const insuranceGetAllMediaAPI = (patientId) => {
    return api({
        method: "GET",
        url: `/patient/insurance/media/all/${patientId}`
    }).then(res => res.data)
}

export {
    insuranceMediaAddAPI,
    insuranceMediaConfirmAPI,
    insuranceGetAllMediaAPI,
    insuranceMediaUploadToS3API,
}