import { useState } from "react";
import { Modal, Typography, Spin } from "antd";

import ResourceDetail from "./ResourceDetail";

const { Title } = Typography;

const ResourceViewEditModal = ({
  showDetailModal,
  setShowDetailModal,
  resource,
}) => {
  const [loading, setLoading] = useState(false);

  const handleAddResourceCancel = () => {
    setShowDetailModal(false);
  };

  return (
    <div>
      <Modal
        className="addResourceModal"
        title={
          <div className="titleSpan">
            <Title level={5} style={{ margin: "0px" }}>
              Resource Detail
            </Title>
          </div>
        }
        width={800}
        visible={showDetailModal}
        onCancel={handleAddResourceCancel}
        footer={null}
      >
        <Spin spinning={loading}>
          <ResourceDetail resource={resource} />
        </Spin>
      </Modal>
    </div>
  );
};

export default ResourceViewEditModal;
