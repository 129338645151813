import api from "./api";

const getClinicIntegrationsListAPI = (clinicId) => {
    return api({
        method: "GET",
        url: `/clinic/integrations/getAll/${clinicId}`,
    }).then((res) => res.data);
};

const generateHubspotInstallUrlAPI = (clinicId) => {
    return api({
        method: "POST",
        url: `/clinic/integrations/hubspot/createInstallUrl`,
        data:{clinicId}
    }).then((res) => res.data);
};



export {
    getClinicIntegrationsListAPI,
    generateHubspotInstallUrlAPI
};
