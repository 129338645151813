import { useState, useEffect } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";

const LogoutConfirmationModal = ({
  title,
  onConfirmation,
  showModal,
  setShow,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const clinic = useSelector((state) => state.clinic);
  const [warningText , setWarningText] = useState("");

  useEffect(() => {
    if(clinic && clinic?.firstTimeOnboard ){
      if(clinic?.firstTimeOnboardStep ==="ADD_PATIENT" || clinic?.firstTimeOnboardStep ==="ADD_PRESCRIPTION"){
        setWarningText("You are in-between the process and should complete it before logging out.");
      }
    }
    
  }, [clinic])
  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
    onConfirmation();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {warningText ? <p style={{marginBottom:"-13px"}}>{warningText}</p> : ""}
        <p>Are you sure you want to Logout?</p>
      </Modal>
    </>
  );
};

export default LogoutConfirmationModal;
