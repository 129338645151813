import React, { useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Form,
  Input,
  ColorPicker,
  Button,
  Tooltip
} from "antd";
import ImageUpload from "./ImageUpload";
import { QuestionCircleOutlined } from '@ant-design/icons';
const { Title } = Typography;

const WhiteLabelForm = ({
  whiteLabelForm,
  onWhiteLabelSubmit,
  whiteLabelData,
  loginImageFile,
  setLoginImageFile,
  splashImageFile,
  setSplashImageFile,
  treatmentImageFile,
  setTreatmentImageFile,
}) => {
  useEffect(() => {
    //  console.log("whiteLabelData", whiteLabelData)
    let formData = {};
    if (whiteLabelData) {
      formData = whiteLabelData;
    }
    whiteLabelForm.setFieldsValue(formData);
  }, [whiteLabelData, whiteLabelForm]);

  const onChange = (info) => {
    // setIprFile(info.file);
  };

  const beforeUpload = (file) => {
    return false;
  };

  return (
    <div>
      <Form
        style={{
          width: "100%",
        }}
        form={whiteLabelForm}
        name="complex-form"
        layout={"vertical"}
        onFinish={onWhiteLabelSubmit}
      >
        <Row gutter={[16, 16]}>
          {/* <Col lg={24}>
            <Form.Item
              label="App Name"
              name="appName"
              rules={[
                {
                  required: true,
                  message: "App Name is required",
                },
              ]}
            >
              <Input placeholder="App Name" size="large" />
            </Form.Item>
          </Col> */}
          <Col lg={24}>
              <Form.Item
              label={
                <span>
                  Terms & Condition
                  <Tooltip className="whitelabel-tooltip" title="this link will show on app login, more tabs">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="termsUrl"
                rules={[
                  {
                    required: true,
                    message: "Terms & Condition url is required",
                  },
                  { type: 'url'}
                ]}>
                <Input placeholder="Terms & Condition Url"
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item 
              label={
                <span>
                  Privacy Policy
                  <Tooltip className="whitelabel-tooltip" title="this link will show on app login, more tabs">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="privacyPolicyUrl"
                rules={[
                  {
                    required: true,
                    message: "Privacy Policy url is required",
                  },
                  { type: 'url'}
                ]}>
                <Input placeholder="Privacy Policy Url"
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item 
              label={
                <span>
                 Cancelation Policy Url
                  <Tooltip className="whitelabel-tooltip" title="this link will show on booking cancelation policy ">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="cancelationUrl"
                rules={[
                  {
                    required: true,
                    message: "Cancelation Policy url is required",
                  },
                  { type: 'url'}
                ]}>
                <Input placeholder="Cancelation Policy Url"
                />
              </Form.Item>
            </Col>
        </Row>
        <Row>
          <Title className="whiteLabel-title" level={5}>
            App Color Settings
          </Title>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label={
                <span>
                  Main Color
                  <Tooltip className="whitelabel-tooltip" title="This is the app's main color, it will be visible on all buttons, tabs etc.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="primaryColor"
              rules={[
                {
                  required: true,
                  message: "Main color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span> Main Color ({color.toHexString()})</span>
                )}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
               label={
                <span>
                  Progress Chart Color
                  <Tooltip className="whitelabel-tooltip" title="This is mobile app's dashboard processing chart color.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="dashboardProgressChart"
              rules={[
                {
                  required: true,
                  message: "Progress Chart Color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>
                    Progress Chart Color ({color.toHexString()})
                  </span>
                )}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label="Bottom Tab Active Color"
              name="bottomTabActive"
              rules={[
                {
                  required: true,
                  message: "Bottom Tab Active Color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>
                    App Bottom Tab Active Color ({color.toHexString()})
                  </span>
                )}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Bottom Tab Inactive Color"
              name="bottomTabInactive"
              rules={[
                {
                  required: true,
                  message: "Bottom Tab Inactive Color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>
                    {" "}
                    App Bottom Tab Inactive Color ({color.toHexString()})
                  </span>
                )}
              />
            </Form.Item>
          </Col>
        </Row> */}

        {/* <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label="Clickable Text Color"
              name="clickableTextColor"
              rules={[
                {
                  required: true,
                  message: "clickableTextcolor is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span> App Clickable Text Color ({color.toHexString()})</span>
                )}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="ScrollTabView Color"
              name="ScrollTabView"
              rules={[
                {
                  required: true,
                  message: "ScrollTabView Color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>App ScrollTabView Color ({color.toHexString()})</span>
                )}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              />
            </Form.Item>
          </Col>
        </Row> */}
        {/* <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label="textFieldBottomFocused"
              name="textFieldBottomFocused"
              rules={[
                {
                  required: true,
                  message: "textFieldBottomFocused is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>
                    {" "}
                    App Text Field Bottom Focused Color ({color.toHexString()})
                  </span>
                )}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="textFieldBottomUnfocused Color"
              name="textFieldBottomUnfocused"
              rules={[
                {
                  required: true,
                  message: "textFieldBottomUnfocused Color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>
                    App Text Field Bottom Unfocussed Color (
                    {color.toHexString()})
                  </span>
                )}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              />
            </Form.Item>
          </Col>
        </Row> */}

        {/* <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label="Input place holder color"
              name="inputPlaceholder"
              rules={[
                {
                  required: true,
                  message: "Input place holder color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>
                    {" "}
                    App Input Placeholder Color ({color.toHexString()})
                  </span>
                )}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Forgot password text color Color"
              name="forgot"
              rules={[
                {
                  required: true,
                  message: "Forgot password text  Color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>Forgot password Color ({color.toHexString()})</span>
                )}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              />
            </Form.Item>
          </Col>
        </Row> */}

        <Row gutter={[16, 16]}>
          {/* <Col lg={12}>
            <Form.Item
              label="background of change aligner button view background etc"
              name="bg"
              rules={[
                {
                  required: true,
                  message:
                    "Background of change aligner button color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>
                    {" "}
                    App Change Aligner Background Color ({color.toHexString()})
                  </span>
                )}
              />
            </Form.Item>
          </Col> */}
          {/* <Col lg={12}>
            <Form.Item
              label="treatment plan heading color"
              name="treatmentHeadingColor"
              rules={[
                {
                  required: true,
                  message: "treatmentHeadingColor  Color is required",
                },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker
                format="hex"
                size="large"
                showText={(color) => (
                  <span>
                    App Treatment plan heading Color ({color.toHexString()})
                  </span>
                )}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              />
            </Form.Item>
          </Col> */}
          <Col lg={12}>
              <Form.Item
                label={
                  <span>
                    Dark text color
                    <Tooltip className="whitelabel-tooltip" title="This color is visible on app's home page for chat, change aligner section and heading section">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="darkBlueTextColor"
                rules={[
                  {
                    required: true,
                    message: "Dark text color is required",
                  },
                ]}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              >
                <ColorPicker
                  format="hex"
                  size="large"
                  showText={(color) => (
                    <span>
                      Dark text color ({color.toHexString()})
                    </span>
                  )}
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <span>
                    Lite shade of primary color
                    <Tooltip className="whitelabel-tooltip" title="this is the color that we use on home page for chat and change aligner section">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="blueColor"
                rules={[
                  {
                    required: true,
                    message: "Lite shade of primary color is required",
                  },
                ]}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              >
                <ColorPicker
                  format="hex"
                  size="large"
                  showText={(color) => (
                    <span>
                      Lite shade of primary color ({color.toHexString()})
                    </span>
                  )}
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col lg={12}>
              <Form.Item
                label="Cyan color"
                name="cyanColor"
                rules={[
                  {
                    required: true,
                    message: "Cyan Color is required",
                  },
                ]}
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
              >
                <ColorPicker
                  format="hex"
                  size="large"
                  showText={(color) => (
                    <span>
                      Cyan Color ({color.toHexString()})
                    </span>
                  )}
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                />
              </Form.Item>
            </Col> */}
        </Row>
        <Row>
          <Title className="whiteLabel-title" level={5}>
            App Images
          </Title>
        </Row>
        <Row>
          <Col lg={12}>
          <label>Login Screen Image (Ratio 1:1)
              <Tooltip className="whitelabel-tooltip" title="this will show on login screen">
                <QuestionCircleOutlined />
              </Tooltip>
            </label>
            <ImageUpload
              multiple={false}
              beforeUpload={beforeUpload}
              onChange={onChange}
              uploadedList={loginImageFile}
              setUploadedList={setLoginImageFile}
              fileType="loginImage"
            />
            {whiteLabelData?.loginImage ? (
              <img
                src={whiteLabelData?.loginImage?.uploadedFile}
                alt=""
                style={{ width: "180px" }}
              />
            ) : null}
          </Col>
          <Col lg={12}>
          <label>App Logo (Ratio 1:1)
              <Tooltip className="whitelabel-tooltip" title="This will show on app splash screen, help and support screen">
                <QuestionCircleOutlined />
              </Tooltip>

            </label>
            <ImageUpload
              multiple={false}
              beforeUpload={beforeUpload}
              onChange={onChange}
              uploadedList={splashImageFile}
              setUploadedList={setSplashImageFile}
              fileType="splashImage"
            />
            {whiteLabelData?.splashImage ? (
              <img
                src={whiteLabelData?.splashImage?.uploadedFile}
                alt=""
                style={{ width: "180px" }}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
          <label>Treatment not Started image (Ratio 1:1)
              <Tooltip className="whitelabel-tooltip" title="this image show on app home screen when treatment is not started and on progress image section">
                <QuestionCircleOutlined />
              </Tooltip>
            </label>
            <ImageUpload
              multiple={false}
              beforeUpload={beforeUpload}
              onChange={onChange}
              uploadedList={treatmentImageFile}
              setUploadedList={setTreatmentImageFile}
              fileType="treatmentImage"
            />
            {whiteLabelData?.treatmentImage ? (
              <img
                src={whiteLabelData?.treatmentImage?.uploadedFile}
                alt=""
                style={{ width: "180px" }}
              />
            ) : null}
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            // block
            style={{ height: "42px", marginTop: "15px" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WhiteLabelForm;
