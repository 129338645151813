import { Row, Col, Divider } from "antd";
import AttachmentRow from "components/IPRDetail/AttachmentRow";
import AttachmentsInfo from "components/IPRDetail/IPRInfo/AttachmentsInfo";
import { getOrdinal } from "utilities/IPR";
import { showTeethName } from "components/shared/TeethChart/data";

const AttachmentData = ({
  attachmentsLabel,
  upperAttachments,
  lowerAttachments,
  chartValue,
  lowerAligners,
  upperAligners,
  onAttachmentsLabelClicked,
  alignersLabel,
}) => {
  const checkSessionNo = (aligner_count) => {
    const findSessionNo = attachmentsLabel.find(
      (obj) => obj.aligner_count == aligner_count
    );
    if (findSessionNo) {
      return findSessionNo.session;
    }
  };
  const checkSessionListName = (aligner_count) =>{
    const findSessionNo = attachmentsLabel.find(
      (obj) => obj.aligner_count === aligner_count
    );
    if (findSessionNo) {
      return findSessionNo.sessionListName;
    }
  }

  const totalAttachments = [...upperAttachments, ...lowerAttachments].sort(
    (a, b) => a.aligner_count - b.aligner_count
  );
  return (
    <div>
      <Row align="top" justify="space-between">
        <Col lg={4}>
          <div className="aligner-component" style={{ marginTop: "1rem" }}>
            <Row>
              <p className="patient-response" style={{ marginTop: "0px" }}>
                No. of Attachments
              </p>
            </Row>
            <Row>
              <p className="patient-question">{attachmentsLabel.length}</p>
            </Row>
          </div>
        </Col>
      </Row>
      <div
        className="aligner-component"
        style={{
          background: "#fff",
          border: "none",
          padding: "0px",
        }}
      >
        <AttachmentsInfo
          lowerAligners={lowerAligners}
          upperAligners={upperAligners}
          chartValue={chartValue}
          upperAttachments={upperAttachments}
          lowerAttachments={lowerAttachments}
        />
        <div className="treatment-attachmentInfo-container attachmentInfo-container">
          {totalAttachments.map((attachment, i) => {
            return (
              <div key={i}>
                {attachment.alignerValues.map((attachmentValue, index) => {
                  if (attachmentValue.value) {
                    return (
                      <div key={index}>
                        <Row gutter={[16, 16]} align="middle">
                          <Col lg={7} span={7}>
                            <Row>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  gap: "5px",
                                }}
                              >
                                {/* <div
                                  className="ipr-check-row"
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    className={`ipr-circle ipr-circle-${attachment.aligner_count}`}
                                  />
                                </div> */}
                                <p
                                  className="patient-question"
                                  style={{ marginTop: "0px" }}
                                >

                                  {getOrdinal(
                                    checkSessionNo(attachment.aligner_count)
                                  )}
                                  Attachment Session
                                  <br />
                                  (Before Aligner {attachment.aligner_count})
                                </p>
                              </div>
                            </Row>
                          </Col>
                          <Col lg={4} span={4} className="border-right">
                            <Row>
                              <p
                                className="patient-question"
                                style={{ marginTop: "0px" }}
                              >
                                Tooth Number
                              </p>
                            </Row>
                            <Row>
                              <p className="patient-response ">
                                {chartValue === "fdi"
                                  ? attachmentValue.teeth
                                  : showTeethName(
                                      attachmentValue.teeth,
                                      chartValue
                                    )}
                              </p>
                            </Row>
                          </Col>
                          <Col lg={5} span={5} className="border-right">
                            <Row>
                              <p
                                className="patient-question"
                                style={{ marginTop: "0px" }}
                              >
                                Attachment Type
                              </p>
                            </Row>
                            <Row>
                              <p className="patient-response ">
                                {" "}
                                {attachmentValue.attachmentType}
                              </p>
                            </Row>
                          </Col>
                          <Col lg={8} span={8}>
                            <Row>
                              <p
                                className="patient-question"
                                style={{ marginTop: "0px" }}
                              >
                                Detail
                              </p>
                            </Row>
                            <Row>
                              <p className="patient-response ">
                                {" "}
                                {attachmentValue.detail
                                  ? attachmentValue?.detail
                                  : "--"}
                              </p>
                            </Row>
                          </Col>
                        </Row>
                        {i < totalAttachments.length - 1 ? <Divider /> : ""}
                      </div>
                    );
                  } else {
                    return <div key={index}></div>;
                  }
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AttachmentData;
