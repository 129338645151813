import { companyType } from "colors-config";
import { Helmet } from "react-helmet";
const SEO = () => {
    if (companyType === 4) {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>Clinic Portal - Clearx Classic</title>
                <link rel="icon" href="/clearx_favicon/favicon.ico" />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/clearx_favicon/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/clearx_favicon/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/clearx_favicon/favicon-16x16.png"
                />
                <link rel="manifest" href="/clearx_favicon/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/clearx_favicon/safari-pinned-tab.svg"
                    color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
        )
    }
    else if (companyType === 5) {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>Clinic Portal - Smilelign</title>
                <link rel="icon" href="/smilelign_favicon/favicon.ico" />
                <link rel="apple-touch-icon" sizes="180x180" href="/smilelign_favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/smilelign_favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/smilelign_favicon/favicon-16x16.png" />
                <link rel="manifest" href="/smilelign_favicon/site.webmanifest" />
                <link rel="mask-icon" href="/smilelign_favicon/safari-pinned-tab.svg" color="#da532c" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
        )
    }
    else if (companyType === 6) {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>Clinic Portal - IAS</title>

                <link rel="apple-touch-icon" sizes="180x180" href="/ias_favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/ias_favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/ias_favicon/favicon-16x16.png" />
                <link rel="manifest" href="/ias_favicon/site.webmanifest" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
        )
    }
    else if (companyType === 7) {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>Clinic Portal - Aqua Aligner</title>

                <link rel="apple-touch-icon" sizes="180x180" href="/aqua_favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/aqua_favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/aqua_favicon/favicon-16x16.png" />
                <link rel="manifest" href="/aqua_favicon/site.webmanifest" />
                <link rel="mask-icon" href="/aqua_favicon/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
        )
    }
    else {
        return "";
    }

};

export default SEO;