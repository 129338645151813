import React from "react";

import { Menu } from "antd";

import "../../../assets/styles/components/patientJourneyMenubarComponent.scss";

class PatientJourneyMenubarComponent extends React.Component {
  state = {
    current: "0",
  };

  handleClick = (e) => {
    this.setState({ current: e.key });
    this.props.setCurrentSelection(+e.key);
  };

  render() {
    const { current } = this.state;
    return (
      <Menu
        className="patient-journey-menubar"
        onClick={this.handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        overflowedIndicator={false}
      >
        <Menu.Item onClick={()=> window.scrollTo(0, 500)} className="journey-item" key={0}>
          Progress Images
        </Menu.Item>
        <Menu.Item onClick={()=> window.scrollTo(0, 500)} className="journey-item" key={1}>
          Treatment Plan
        </Menu.Item>
        <Menu.Item className="journey-item"  key={2}>
          Smile Assessment & Consent
        </Menu.Item>
        <Menu.Item  className="journey-item" key={3}>
          Timeline
        </Menu.Item>
      </Menu>
    );
  }
}

export default PatientJourneyMenubarComponent;
