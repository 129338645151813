import { Button } from "antd";

const MultiStepButton = ({
  content,
  align,
  type,
  color,
  children,
  extraStyle,
  ...props
}) => {
  return (
    <Button
      {...props}
      type={type}
      style={{
        borderRadius: "20px",
        backgroundColor: color,
        float: align,
        width: "10rem",
        height: "2.5rem",
        ...extraStyle,
      }}
    >
      {children}
    </Button>
  );
};

export default MultiStepButton;
