import { useEffect, useState } from "react";
import { Modal, Divider } from "antd";

import { getFormatDate } from "utilities/commonMethod";
import { downloadS3File } from "api/patient";
import AppFileList from "components/AppFileList/AppFileList";
import { scansArray } from "utilities/Patient.js";
import { stlFilesOrder } from "utilities/treatment";

const RefinementViewModal = ({ refinementModalOpen, setRefinementModalOpen, refinementData }) => {
  const [fileList, setFileList] = useState({
    clinicalFiles: [],
    scanFiles: [],
  });

  useEffect(() => {
    if (refinementData?.patient_media?.length) {
      const scanFiles = [];
      const clinicalFiles = [];
      refinementData.patient_media.forEach((media) => {
        const extension = media.meta.extension;

        if (extension === "stl") {
          const name = media.name.split(".")[0];

          const scanItem = scansArray.find((scan) => scan.name === name);
          if (scanItem) {
            scanFiles.push(media);
          } else {
            clinicalFiles.push(media);
          }
        } else {
          clinicalFiles.push(media);
        }
      });
      const sortedScanFiles = scanFiles?.sort((a, b) => stlFilesOrder(a) - stlFilesOrder(b)) || [];
      setFileList({
        scanFiles: sortedScanFiles,
        clinicalFiles,
      });
    }
  }, [refinementData]);

  const handleOk = () => {
    handleCancel();
  };
  const handleCancel = () => {
    setRefinementModalOpen(false);
  };

  const onDownloadAllClick = () => {
    refinementData.patient_media.map((image) => {
      try {
        const imageName = image.hash + "." + image.meta.extension;
        window.open(downloadS3File(imageName));
      } catch (error) {}
    });
  };

  return (
    <>
      <Modal
        visible={refinementModalOpen}
        width={750}
        title="Refinement Details"
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Close"
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        <div className="refinement-detail-modal">
          <div>
            <div className="treatment-row">
              <label>Scan Files</label>
              <span className="detail">
                Updated:
                {refinementData?.updatedAt ? ` ${getFormatDate(refinementData?.updatedAt)}` : "--"}
              </span>
            </div>
            {fileList.scanFiles?.length ? <AppFileList files={fileList.scanFiles} /> : null}
          </div>
          <Divider styles={{ margin: "5px 0" }} />
          <div>
            <label>Xray / Clinical Files</label>
            {fileList?.clinicalFiles.length ? <AppFileList files={fileList.clinicalFiles} /> : null}
          </div>
          <Divider />
          <label>Additional Detail</label>
          <p className="detail">
            <div
              dangerouslySetInnerHTML={{
                __html: refinementData?.details ? refinementData?.details : "--",
              }}
            />
          </p>
        </div>
      </Modal>
    </>
  );
};

export default RefinementViewModal;
