import { companyType } from "colors-config";
import { FORM_CONSTANTS } from "components/private/newPrescriptionForm/constants";

export const prescriptionDataMapper = (formData, isDraft = false) => {
  return {
    treatmentTypes: formData?.treatmentTypes?.map((el) => {
      const aligner = {
        aligner: el.aligner,
        arches: el.arch_treated,
        whitening: el.retainer_and_whitening,
        whitening_arches: el.retainer_and_whitening_type,
        comment: el.retainer_and_whitening_comment,
      };
      return aligner;
    }),
    phasing: formData?.phasing,
    prescriptionPhasing: formData?.prescription_phasing,
    smilelignTreatmentTypeId: formData?.smilelignTreatmentTypeId || null,
    smilelignArches: formData?.smilelignArches || "",
    smilelignWhitening: formData?.smilelignWhitening || null,
    labProductId: formData?.labProductId || null,
    productPrice: formData?.productPrice || "",
    teethChart: formData?.teethChart || [],
    ipr: formData?.prescription_is_ipr,
    overjet: formData?.overjet || "",
    overbite: formData?.overbite || "",
    alignment: formData?.incisal_edge_alignment,
    bioType: formData?.periodontium_biotype,
    recession: formData?.periodontium_recession_miller,
    dentalClass: formData?.angle_dental_class || null,
    skeletalClass: formData?.angle_skeleton_class || null,
    skeletalDivision: formData?.angle_skeleton_division || null,
    skeletalSide: formData?.angle_skeleton_side,
    dentalDivision: formData?.angle_dental_division || null,
    dentalSide: formData?.angle_dental_side,
    parafunctionDetails: formData?.occlusion_angle_deviation,
    midline: formData?.prescription_is_midline_correction || "",
    duration: formData?.prescription_aligner_duration,
    prescriptionDetails: formData?.prescription_details || "",
    labId: formData?.prescription_lab_id,
    upperArchUrl: formData?.prescription_upper_arch_file_url || "",
    lowerArchUrl: formData?.prescription_lower_arch_file_url || "",
    biteScanUrl: formData?.prescription_bite_scans_file_url || "",
    crowding: formData?.prescription_complaint_crowding_upper || "",
    spacing: formData?.prescription_complaint_spacing_upper || "",
    openbite: formData?.prescription_complaint_open_bite_anterior || "",
    crossbite: formData?.prescription_complaint_cross_bite_anterior || "",
    others: formData?.prescription_complaint_others || "",
    attachments: formData?.prescription_attachments_upper_lower || "",
    version: formData?.prescription_version,
    caseId: formData?.case_id,
    openBiteValue: formData?.openBiteValue,
    crossBiteValue: formData?.crossBiteValue,
    editedDate: formData?.prescription_edited_date,
    data_consent: formData?.prescription_lab_id && !isDraft ? true : false,
    terms_conditions: formData?.prescription_lab_id && !isDraft ? true : false,
  };
};

export const findMaxPrescriptionVersion = (versions) => {
  if (versions && versions.length) {
    let maxVersion = Number(versions[0].value);
    versions.forEach((el) => {
      if (Number(el.value) > maxVersion) {
        maxVersion = Number(el.value);
      }
    });
    return maxVersion;
  }
  return 1;
};

export const prescriptionFormInitialValues = (labId, productName) => {
  return {
    treatmentTypes: [
      { aligner: productName, arches: "Upper and Lower", whitening: "no" },
    ],
    labId: labId,
    duration: "2 weeks",
    aligner: productName,
  };
};

export const getScanOptions = () => {
  const defaultOptions = [
    {
      value: "Pro Impressions",
      label: "Pro Impressions",
    },
    {
      value: "Scans",
      label: "Scans",
    }
  ];

  if (companyType === 5) {
    return defaultOptions;
  }

  // Insert "HIK Impressions" option in the middle
  const middleIndex = Math.floor(defaultOptions.length / 2);
  return [
    ...defaultOptions.slice(0, middleIndex),
    {
      value: "HIK Impressions",
      label: "HIK Impressions",
    },
    ...defaultOptions.slice(middleIndex)
  ];
};

export const getScanFiles = (id) => {
  if (id) {
    let finalClass = "scan-subheader required";
    if (companyType === 5 && id === 3) {
      finalClass = "scan-subheader required";
    } else if ((companyType !== 5 && id === 1) || id === 2) {
      finalClass = "scan-subheader";
    } 
    return finalClass;

  }
};

export const getBiteScanClass = () => {
  let finalClass = "scan-subheader required";
  if (companyType === 5) {
    finalClass = "scan-subheader";
  }
  return finalClass;
};


export const getBiteClass = (id) =>{
  if (companyType === 5) {
    return "scan-subheader"
  }
 return getScanFiles(id)
}


export const getRequiredFilesLength = () =>{
 return companyType === 5 ? 2 : 3
}


export const phasingPrescriptionCheck = (value) =>{
  return value === FORM_CONSTANTS.YES
}

export const  SCAN_COLOR = "#77888b"
export const PRO_IMPRESSIONS = "Pro Impressions"
export const HIK_IMPRESSIONS = "HIK Impressions"


export const getTreatmentButtonText = () =>{
  let text = "Approve Treatment Plan"
  if (companyType === 5) {
    return text = "Send Treatment Plan to Patient"
  }
  return text
}


export const getMissingFilesMessage  = (clinicalFilesArray, scanUrls) =>{
  let msg = "Are you sure you want to submit prescription without scan files?";
  const requiredFiles = ["UpperArch", "LowerArch", "BiteScan", "BiteScan2"];
  const scanUrlAvailableFiles = scanUrls.map(scan => scan.patient_image_view);
  const clinicalAvailableFiles = clinicalFilesArray.map(scan => scan.filename);
  const allFiles = [...scanUrlAvailableFiles, ...clinicalAvailableFiles]
  const missingFiles = requiredFiles.filter(file => !allFiles.includes(file));
  if (missingFiles.length) {
    msg = `Are you sure you want to submit prescription without ${missingFiles.join(", ")} scan files?`;
  }

  return msg;
 }

const scanFilesNames = (arch) =>{
  let files = []
if (arch === "upper") {
  files = ["UpperArch"]
}
else if(arch === "lower") {
files = ["LowerArch"]
}
else{
  files = ["UpperArch", "LowerArch"]
}
return files
}

 export const handleScanFilesModal = (clinicalFilesArray, scanUrls, arches) =>{
  const scanUrlAvailableFiles = scanUrls.map(scan => scan.patient_image_view);
  const clinicalAvailableFiles = clinicalFilesArray.map(scan => scan.filename);
  const allFiles = [...scanUrlAvailableFiles, ...clinicalAvailableFiles]
  const requiredFiles = scanFilesNames(arches)
  const missingFiles = requiredFiles.filter(file => !allFiles.includes(file));
  const attachedFilesMessage = allFiles.length ? `attached only ${allFiles.join(", ")} Scan File` : "attached no Scan Files";
  return {
    test : missingFiles.length ? true : false,
    message: `It looks like you have asked for ${arches} arches in your prescription but you have ${attachedFilesMessage}. Consider attaching the ${missingFiles.join(", ")} scan before proceeding.`
  }

 }