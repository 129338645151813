import { useState, useEffect } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";

export default function IframeModel({
  title,
  showModal,
  setShow,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const state = useSelector(i => i?.clinic)
  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };
  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      okButtonProps={{
        style: { width: "7rem", borderRadius: "30px" },
      }}
      cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      width={1020}
    >
      <iframe frameborder="0"
        style={{ width: "100%", minHeight: '28rem', border: 'none', overflow: 'hidden' }}
        src={`${process.env.REACT_APP_BASE_PATIENT}/#/booking/${state?.clinicId}`}>
      </iframe>
    </Modal>
  );
}