import { useEffect } from "react";

import { Card, Typography, Row, Col } from "antd";
import logo from "../../assets/images/Smile-Genius-Logo-copy-2.png";

const { Title } = Typography;

export default function StripeRefreshPage() {
  useEffect(() => {
    document.body.style = "background: #f3f6fa !important;";

    return () => {};
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          height: "4.7rem",
          position: "relative",

          boxShadow: "3px 6px 8px rgba(0, 0, 0, 0.03)",
        }}
      >
        <Row justify="start">
          <Col
            xxl={{ span: 6, offset: 1 }}
            xl={{ span: 6, offset: 1 }}
            lg={{ span: 6, offset: 1 }}
            md={24}
          >
            <Row justify="start">
              <img
                style={{
                  width: "110px",
                  height: "110px",

                  marginTop: "-15px",
                  marginRight: "2.1rem",
                }}
                src={logo}
                alt="logo"
              />
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Card
          style={{
            width: "40%",
            margin: "5rem auto 0 auto",
            borderRadius: "12px",
          }}
        >
          <Title level={2} style={{ fontFamily: "'Poppins', sans-serif" }}>
            Please go back to your profile to complete stripe setup
          </Title>
          {/* <p style={{ fontWeight: "525", fontSize: "20px", color: "#595959" }}>
        Awaiting confirmation by the Dentist
      </p> */}
          {/* <img
        style={{
          // backgroundColor: "red",
          marginBottom: "-50px",
          marginTop: "-30px",

          marginRight: "2rem",
        }}
        src={image}
        alt="email logo"
      /> */}

          {/* <p style={{ color: "#77849E", fontSize: "18px" }}>
            You will be sent an email confirmation link soon
          </p> */}
        </Card>
      </div>
    </div>
  );
}
