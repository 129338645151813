
import { Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const ResourceUpload = ({
  resourceFileList,
  setResourceFileList,
  multiple = true,
  maxCount = 5,
  beforeUpload,
  onChange,
}) => {
  const props = {
    className: "custom-file-dragger",
    name: "file",
    multiple: true,
    maxCount: maxCount,
    // customRequest: uploadFiles,
    // onDrop(e) {
    //   console.log("Dropped files", e.dataTransfer.files);
    // },
    // customRequest: uploadFiles,
    // onChange: onChange,
    // beforeUpload: beforeUpload,
  };
  async function uploadFiles(options) {

  }
  const onChangeHandler = (info) => {
    const { status } = info.file;
    console.log("onChangeHandler", info);
    console.log("info.file", info.file);
    const allFiles = [...resourceFileList];
    allFiles.push(info.file);
    setResourceFileList(allFiles);
    // setSelectedFle(info.file);
    // setScanType(event.target.id);
    console.log("statusstatusstatus", status);
    info.file.status = "done"
    message.success(`${info.file.name} file uploaded successfully.`);
  //   if (status !== "uploading") {
  //     console.log(info.file, info.fileList);
  //   }
  //   else if (status === "done") {
  //     message.success(`${info.file.name} file uploaded successfully.`);
  //   }
  //   else if (status === "error") {
  //     message.error(`${info.file.name} file upload failed.`);
  // };
}

  const onRemove = (delFile) => {
    const allFiles = [...resourceFileList];
    const filtered = allFiles.filter((file) => file.uid !== delFile.uid);
    setResourceFileList(filtered);
    message.success(`${delFile.name} removed successfully`);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="ipr-upload"
        multiple={true}
        accept=".pdf"
        {...props}
        fileList={resourceFileList}
        beforeUpload={(file) => {
        const allFiles = [...resourceFileList];
          if(allFiles.length){
            return message.error("Please upload only one file at a time")
          }        
          allFiles.push(file);
          setResourceFileList(allFiles);
          return false;
        }}
        maxCount={1}
        onRemove={onRemove}
        // onChange={(info) => onChangeHandler(info)}
      >
        <div style={{ textAlign: "center" }}>
          <p className="ant-upload-drag-icon">
            <PlusOutlined />
          </p>
          <p className="ant-upload-text">Upload</p>
        </div>
      </Upload>
    </div>
  );
};

export default ResourceUpload;
