import { useState, useEffect } from "react";
import {
  Table,
  Radio,
  Dropdown,
  Menu,
  Button,
  message,
  Spin,
  Tooltip,
  Typography
} from "antd";
import { connect } from "react-redux";
import { manageLabApiTasks } from "../../../models/mangeLab";
import DeleteModal from "../../shared/modals/DeleteModal";
import { ReactComponent as MenuIcon } from "../../../assets/images/menu_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete_icon.svg";
import AddLab from "../../shared/modals/AddLab";
import TitleComponent from "../../shared/titleComponent";
import "../../../assets/styles/components/labPartners.scss";
import { companyType } from "colors-config";
const { Column } = Table;

const { Title } = Typography;

const LabPartners = (props) => {
  const [modalPlaceholder, setModalPlaceholder] = useState(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [allowToAdd, setAllowToAdd] = useState(false);
  const [labPartners, setLabPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const menu = (obj) => {
    return (
      <Menu disabled={props.mode === "view" || companyType === 5 || companyType === 6} style={{ width: "8vw" }}>
        <Menu.Item onClick={() => openDeleteModal(obj)}>
          <div className="menuItem">
            <DeleteIcon alt="delete" />
            <p>Delete</p>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    if (props.clinicId) getLabPartners();
  }, [props.clinicId]);

  function getLabPartners() {
    setIsLoading(true);
    manageLabApiTasks
      .getAll("clinic/getPartnerLabsByClinic?clinicID=" + props.clinicId)
      .then((res) => {
        setIsLoading(false);
        setLabPartners(res.body.partnerLabDetails);
        setAllowToAdd(res.body.allowAddlabs);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }

  function makeDefault(id, rec, i) {
    message.loading("Please Wait", 2);
    manageLabApiTasks
      .post("clinic/setDefaultLab", {
        clinic_id: props.clinicId,
        lab_id: rec.lab_id,
      })
      .then((res) => {
        message.success(res.body.success);
        getLabPartners();
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data?.err?.msg) {
          message.error(error.response.data.err.msg);
        } else {
          message.error("Something went wrong. Please try again.");
        }
      });
  }

  function openDeleteModal(obj) {
    setModalPlaceholder(
      <DeleteModal
        deleteFor="lab partner"
        data={{
          clinicId: props.clinicId,
          lab_id: obj.lab_id,
          userName: obj.lab_name,
        }}
        handleAfterClose={() => setModalPlaceholder(null)}
        success={getLabPartners}
      />
    );
  }

  return (
    <div>
      {/* {props.mode === "partnerHandling" && (
        <Row>
          <Title level={2}>Labs list</Title>
        </Row>
      )} */}
      <div
        id={props.mode !== "partnerHandling" ? "container" : ""}
        style={{ width: "100%" }}
      >
        <div id="top">
          {/* <p style={{ margin: "0%", fontSize: "" }}>
            {props.mode !== "partnerHandling"
              ? "Lab Partner Lists"
              : "Labs List"}
          </p> */}

          {props.mode !== "partnerHandling" && (
            <Title style={{ fontFamily:"'Poppins', sans-serif", marginTop:"5px",  fontWeight: "bold" }} level={5}>
           Lab Partner Lists
          </Title>
          )}
          {props.mode === "partnerHandling" && (
            <TitleComponent titleText={"Labs List"}></TitleComponent>
          )}
          {companyType === 1 ?
            <Button
              // disabled={props.mode === "view"}
              type="primary"
              // style={{width:"8rem"}}
              id="button"
              onClick={() => setIsAddModalVisible(true)}
              disabled={!allowToAdd}
            >
              Add New Lab
            </Button> : null
          }
        </div>
        <Spin spinning={isLoading}>
          {labPartners.length === 0 ? (
            <p className="noPartners">Added Labs list will be displayed here</p>
          ) : (
            <Table dataSource={labPartners} pagination={false} id="table">
              <Column title="Lab Name" dataIndex="lab_name" key="lab_name" />
              <Column
                title="Services"
                dataIndex="lab_service"
                key="lab_service"
              />
              {props.mode === "partnerHandling" && (
                <Column
                  title="Partnership Status"
                  dataIndex="lab_partnership_status"
                  key="lab_partnership_status"
                />
              )}
              {props.mode === "partnerHandling" && (
                <Column
                  title="Active Orders"
                  dataIndex="lab_order_count"
                  key="lab_order_count"
                />
              )}
              <Column
                title="Email"
                dataIndex="lab_email"
                key="lab_email"
                ellipsis={"true"}
                width="200px"
                render={(val, record, index) => (
                  <Tooltip placement="topLeft" title={val}>
                    {val}
                  </Tooltip>
                )}
              />
              <Column
                //style={{marginLeft: "25px"}}
                title="Set as default"
                dataIndex="default"
                key="default"
                render={(val, record, index) => (
                  <Radio
                    style={{ marginLeft: "25px" }}
                    disabled={props.mode === "view"}
                    checked={record.lab_set_as_default === 1}
                    onClick={
                      record.lab_set_as_default !== 1
                        ? () => makeDefault(val, record, index)
                        : null
                    }
                  />
                )}
              />
                {companyType === 1 ?
                  <Column
                    render={(val) => (
                      <Dropdown
                        disabled={props.mode === "view"}
                        placement="bottomRight"
                        overlay={menu(val)}
                        trigger={["click"]}
                      >
                        <div id="menuIcon">
                          <MenuIcon alt="Actions" />
                        </div>
                      </Dropdown>
                    )}
                  /> : null
                }
            </Table>
          )}
        </Spin>
        <AddLab
          clinicId={props.clinicId}
          isVisible={isAddModalVisible}
          onCancel={() => setIsAddModalVisible(false)}
          success={getLabPartners}
        />
        {modalPlaceholder}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userEmail: state.clinic.clinicEmail,
  clinicId: state.clinic.clinicId,
});

export default connect(mapStateToProps)(LabPartners);
