import { ReactComponent as CalendarOff } from "assets/images/CalendarOff.svg";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

import styles from "./off.module.scss";
import AppButton from "../AppButton/AppButton";

const OffAvailability = ({ info, showCallback,callback }) => {
  const history = useHistory();
  return (
    <div className={styles["empty-container"]}>
      <CalendarOff />

      <h2>{info}</h2>
      {showCallback ?
        <AppButton
          type="primary"
          shape="round"
          size="large"

          onClick={() => {
            history.push("/clinic/profile");
            callback();
          }}
        >Turn on from settings</AppButton> : null
      }

    </div>
  );
};

export default OffAvailability;
