import  { useState, useEffect } from "react";
import BigCalendar from "react-big-calendar-like-google";
import CustomToolbar from "./customToolbar";
import {useLocation } from "react-router-dom";
import moment from "moment";
import AppointmentDetailsComponent from "./appointmentDetailsComponent";
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
import "../../../assets/styles/components/appointmentCalendarComponent.scss";
import EventComponent from "./calendarEventsComponent";
import { connect } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';


import {
  pickStatusColor,
  createDateTime,
} from "../../../utilities/commonMethod";

import { Drawer } from "antd";
import {
  isItTime,
  isToday,
  isConfirmButtonDisable,
} from "../../../utilities/commonMethod";
import { APPOINTMENT_STATUS_CODE } from "../../../helper/constants/const.index";
import { companyType, primaryColor } from "colors-config";
import { getAppointmentDetailsApi } from "api/patient";

dayjs.extend(customParseFormat)
BigCalendar.momentLocalizer(moment);

const createEvents = (appointments) => {
  if (appointments.length) {
    return appointments.map((appointment) => {
      return {
        title: `${appointment.patient_first_name} ${appointment.patient_last_name}`,
        start: createDateTime(
          appointment.appointment_date,
          appointment.appointment_start_time
        ),
        end: createDateTime(
          appointment.appointment_date,
          appointment.appointment_end_time
        ),
        bgColor: pickStatusColor(appointment.appointment_status, "slot"),
        resource: appointment,
      };
    });
  }
  return appointments;
};

function onSelectSlot(event) {}

const AppointmentCalendarComponent = ({
  startVideoCall,
  appointments,
  clinicians,
  confirmAppointment,
  confirmAppointmentReschedule,
  cancelAppointment,
  drawerVisible,
  setDrawerVisible,
  defaultClinicianId,
  confirmAppointmentStatus,
  updateClinician,
  clinic,
}) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [isDeleteEvent, setIsDeleteEvent] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [calendarStartTime,setCalendarStartTime] = useState(dayjs().startOf('day').toDate())
  const [calendarEndTime,setCalendarEndTime] = useState(dayjs().endOf('day').toDate())

  const [selectedCalendarView, setSelectedCalendarView] = useState("week");
  useEffect(()=>{

    if (queryParams && queryParams.get("appointment_id") ) {
      const appointmentId= queryParams.get("appointment_id");
      getAppointmentDetails(appointmentId)
     }
  },[]);


  function timeToMinutes(time) {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }

// Function to get the lowest and highest time
  function getMinMaxTime(workingHours) {
    let minTime = Number.MAX_SAFE_INTEGER;
    let maxTime = Number.MIN_SAFE_INTEGER;

    workingHours.forEach(day => {
      if (day.workDay && day.workHours.length > 0) {
        day.workHours.forEach(hours => {
          if(hours.length>=2){
            const startTime = dayjs(hours[0],"HH:mm");
            const endTime = dayjs(hours[1],"HH:mm");
            const startMinutes = timeToMinutes(startTime.format('HH:mm'));
            const endMinutes = timeToMinutes(endTime.format('HH:mm'));
            minTime = Math.min(minTime, startMinutes);
            maxTime = Math.max(maxTime, endMinutes);
          }
         
        });
      }
    });

    return {
      minTime: dayjs().startOf('day').add(minTime, 'minute').toDate(),
      maxTime: dayjs().startOf('day').add(maxTime, 'minute').toDate()
    };
  }

  useEffect(()=>{
    if(clinic?.workingHourData?.workingDays?.workingHours){
      const workingHours = clinic?.workingHourData?.workingDays?.workingHours;
      const timeData = getMinMaxTime(workingHours);
      if(timeData.minTime && timeData.maxTime){
        setCalendarStartTime(timeData.minTime);
        setCalendarEndTime(timeData.maxTime)
      }

    }
  },[clinic])

  const getAppointmentDetails=async(appointmentId)=>{
    try {
      const appointmentDetails = await getAppointmentDetailsApi({
        clinicId:clinic.clinicId,
        appointmentId
      });
      if(appointmentDetails?.data?.data){
        onSelectEvent({
          resource:appointmentDetails.data.data
        });
      }
      console.log("appointmentDetails",appointmentDetails);
    } catch (error) {
      console.log("error",error);
    }
  
  }
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };
  const checkAttendEnableState = (selectedAppointment) => {
    if (!selectedAppointment.appointment_start_time) {
      return false;
    }
    const currentTime = dayjs();

    // Parse the input time string and set the date to the current date
    const appointmentTime = dayjs(dayjs(selectedAppointment.appointment_date, 'YYYY-MM-DD').format("YYYY-MM-DD") + " " + selectedAppointment.appointment_start_time, "YYYY-MM-DD HH:mm:ss");

    // const timeDifference = currentTime.diff(appointmentTime, "minute");
    return currentTime.isSame(appointmentTime, 'd');
    // try {
    //   const AppUTCDate = new Date(selectedAppointment.appointment_date);
    //   const UTCTimeStamp = new Date().getTime();
    //   const appStartTime =
    //     new Date(AppUTCDate).getTime() +
    //     parseInt(
    //       selectedAppointment.appointment_start_time.split(":")[0] *
    //         60 *
    //         60 *
    //         1000
    //     ) +
    //     parseInt(selectedAppointment.appointment_start_time.split(":")[1]) *
    //       60000;
    //   if (
    //     isToday(selectedAppointment.appointment_date) &&
    //     UTCTimeStamp >= appStartTime
    //   ) {
    //     return true;
    //   }
    //   return false;
    // } catch (e) {
    //   return false;
    // }
  };

  function onSelectEvent(eventData) {
    let tempEventData = eventData?.resource;
    tempEventData.isAttendedEnable = checkAttendEnableState(tempEventData);
    if (
      (tempEventData.appointment_status === APPOINTMENT_STATUS_CODE.booked ||
        tempEventData.appointment_status ===
          APPOINTMENT_STATUS_CODE.clinicRescheduled) &&
      tempEventData.appointment_type_id === 2
    ) {
      tempEventData.isVideoEnabled = isItTime(tempEventData);
    } else {
      tempEventData.isVideoEnabled = false;
    }
    //console.log("thisno",isConfirmButtonDisable(tempEventData))
    tempEventData.isConfirmButtonDisable = true;
    // tempEventData.isConfirmButtonDisable = isConfirmButtonDisable(tempEventData);
    eventData.resource = { ...tempEventData };
    console.log("eventData",eventData);
    setSelectedEvent(eventData.resource);
    showDrawer();
  }

  useEffect(() => {
    if (deleteEvent) {
      let newEvents = events.filter(
        (event) => event.resource["id"] !== isDeleteEvent["resource"]["id"]
      );

      setEvents(newEvents);
      setDeleteEvent(false);
    }
  });

  useEffect(() => {
    if (appointments.length) {
      setEvents(createEvents(appointments));
    } else {
      setEvents([]);
    }
  }, [appointments]);

  return (
    <div className="container" style={{ marginTop: "10px" }}>
      <div style={{ padding: "10px", background: "white" }}>
        {clinic.clinicId && (
          <BigCalendar
            // min={moment(clinic.clinicStartTime, "HH:mm:ss").toDate()}
            // max={moment(clinic.clinicEndTime, "HH:mm:ss").toDate()}
            min={calendarStartTime}
            max={calendarEndTime}
            selectable
            view={selectedCalendarView}
            onView={(view) => setSelectedCalendarView(view)}
            events={events}
            startAccessor="start"
            endAccessor="end"
            className={`react-big-calendar ${companyType==5?"smilelign-calendar":""}`}
            step={15}
            timeslots={1}
            components={{
              // event: EventComponent,
              agenda: {
                event: EventComponent,
              },
              day: {
                event: EventComponent,
              },
              toolbar: (props) => (
                <CustomToolbar {...props} selectedView={selectedCalendarView} />
              ),
              week: {
                // onclick: setSelectedCalendarView("day"),
                event: (e) => <EventComponent isWeekly={true} {...e} />,
                header: ({ date, localizer }) => {
                  const today =
                    localizer.format(date, "DD-MM") ===
                    localizer.format(new Date(), "DD-MM");
                  return (
                    <div
                      className="week"
                      align="center"
                      style={
                        today
                          ? {
                              color: primaryColor, //Primary color logic
                              lineHeight: "20px",
                              marginTop: "10px",
                              marginRight: "10px",
                              fontFamily: "Mulish",
                            }
                          : {}
                      }
                    >
                      <span>{localizer.format(date, "dddd")}</span> <br />
                      <span>{localizer.format(date, "DD-MM")}</span>
                    </div>
                  );
                },
              },
            }}
            onSelectEvent={onSelectEvent}
            onSelectSlot={onSelectSlot}
          />
        )}

        {selectedEvent && (
          <Drawer
            title={
              <div>
                <p style={{ fontWeight: "bolder", fontSize: "1.2rem" }}>
                  Appointment Details
                </p>
                <p
                  style={{
                    color: pickStatusColor(
                      selectedEvent.appointment_status,
                      "drawer"
                    ),
                    fontWeight: "bolder",
                    marginTop: "-5px",
                  }}
                >
                  {selectedEvent.status_name}
                </p>
              </div>
            }
            placement="right"
            closable={true}
            onClose={onClose}
            visible={drawerVisible}
            width={550}
          >
            <AppointmentDetailsComponent
              startVideoCall={startVideoCall}
              selectedAppointment={selectedEvent}
              clinicians={clinicians}
              confirmAppointment={confirmAppointment}
              cancelAppointment={cancelAppointment}
              defaultClinicianId={defaultClinicianId}
              confirmAppointmentReschedule={confirmAppointmentReschedule}
              confirmAppointmentStatus={confirmAppointmentStatus}
              updateClinician={updateClinician}
            ></AppointmentDetailsComponent>
          </Drawer>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(AppointmentCalendarComponent);
