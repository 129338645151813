import { Row, Col } from "antd";

const PrescriptionPeri = ({prescriptionPDFObj}) => {
  return (
    <div className="prescriptionPDF-card">
    <h2>Periodontium</h2>
    <div className="prescriptionPDF-peri">
      <div className="prescriptionPDF-col">
        <div className="border-right">
          <label className="treatment-label">Biotype</label>
          <p className="treatment-info">{prescriptionPDFObj.bioType}</p>
        </div>
      </div>
      <div className="prescriptionPDF-col">
        <div>
          <label className="treatment-label">Recession (Miller s Class)</label>
          <p className="treatment-info">{prescriptionPDFObj.recession}</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PrescriptionPeri