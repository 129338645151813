import { useState, useEffect } from "react";
import { Modal, Row, Col, message } from "antd";
import dayjs from "dayjs";
import { connect } from "react-redux";
import moment from "moment";
//import CalendarComponent from "../../private/scheduling/calendarComponent";

import SlotsComponent from "../../private/scheduling/slotsComponent";
import Slots from "components/shared/modals/Appointment/Components/Slots/Slots";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import {
  getClinicScheduleApi,
  getTimeSlotApi,
} from "components/shared/modals/Appointment/api";
import { getClinicNewWorkingDays, getClinicWorkingDays, getHolidays, mapTimeSlotData } from "components/shared/modals/Appointment/utils";
import AppointmentCalendar from "../../shared/modals/Appointment/Components/Calendar/Calendar";

const AppointmentRescheduleModal = ({
  title,
  content,
  onConfirmation,
  showModal,
  setShow,
  clinic,
  selectedAppointment,
}) => {
  // console.log("selectedAppointment", selectedAppointment);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isComponentSpinning, setIsComponentSpinning] = useState(true);
  const [slotLoading, setSlotLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState( moment().format("YYYY-MM-DD"));
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDateObject, setSelectedDateObject] = useState(null);
  const [slots, setSlots] = useState([]);

  const [clinicWorkingSchedule, setClinicSchedule] = useState(null);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  useEffect(() => {
    _getOffDays();
    _getSlots();
  }, []);

  // const parseWorkDays = (workDays) => {
  //   return workDays.map((workday) => {
  //     return workday.workday_number;
  //   });
  // };

  const _getOffDays = async () => {
    try {
      const res = await getClinicScheduleApi(clinic.clinicId)

      // const {  workdays, working_period } = res.body;
      // const parsedWorkDays = parseWorkDays(workdays);
      const holidays = getHolidays(res?.data?.body?.holidays);
      // const workingDays = getClinicWorkingDays(res?.body.workdays);
      
      let newWorkingDays = {}
      if (res?.data?.body?.workingDays?.workingHours) {
        newWorkingDays = getClinicNewWorkingDays(res?.data?.body?.workingDays?.workingHours)
      } 
      setClinicSchedule(
        {
          workingDays:newWorkingDays,
          holidays: holidays || {},
          newWorkingDays,
          wokringPeriod: res?.body?.working_period || [],
        } || null
       );
    } catch (err) {
      console.log(err);
    }
  };

  const _getSlots = async (date) => {
    const dateStr = dayjs(date).format("YYYY-MM-DD");
    setIsComponentSpinning(true);
    try {

      setSlotLoading(true);
      const response = await getTimeSlotApi({
        clinicId: clinic.clinicId,
        date: dateStr,
        appointmentTypeId: selectedAppointment.appointment_type_id,
        appointmentNameId: selectedAppointment.appointment_name_id,
        clinicianId: selectedAppointment.clinician_id,
        time: dayjs().format("HH:mm:ss"),
      });
      setSlots(mapTimeSlotData(response.data?.body?.timeSlots));

      setIsComponentSpinning(false);
    } catch (error) {
      console.log(error);
      setIsComponentSpinning(false);
    } finally {
      setSlotLoading(false);
    }
  };


  const onDateChange = (dateStr) => {
    setSelectedDate(dateStr.format("YYYY-MM-DD"));
    setIsComponentSpinning(true);
    setSelectedDateObject(dateStr);
    _getSlots(dateStr);
  };

  useEffect(()=>{
    if(selectedDate){
      setIsComponentSpinning(true);
      setSelectedDateObject(selectedDate);
      _getSlots(selectedDate);
    }
  },[selectedDate]);

  const handleOk = () => {
    // console.log(selectedSlot, selectedDateObject, selectedAppointment, "theo");
    if (selectedSlot) {
      const selectedSlotData = slots.find(
        (el) => el.appointmentSlotId === selectedSlot
      );
      if (!selectedSlotData) {
        return message.error("Please select a slot.");
      }
      setIsModalVisible(false);
      setShow(false);
      onConfirmation(selectedSlotData, selectedDateObject, selectedAppointment);
    } else {
      message.error("Please select a slot.");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  // const onSlotSelectChange = (slot) => {
  //   setSelectedSlot(slot);
  // };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        okText="Confirm"
        onCancel={handleCancel}
        width={800}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        <Row>
          <Col md={10}>
          <AppointmentCalendar
            setSelectedDate={setSelectedDate}
            clinicWorkingSchedule={clinicWorkingSchedule}
              />
            {/* <CalendarComponent
              onDateChange={onDateChange}
              isSpinning={isComponentSpinning}
              nonWorkingDays={offDays}
            /> */}
          </Col>
          <Col md={14}>
            <div
              className="custom-slots"
              style={{
                marginLeft: "1rem",
                height: "327px",
                overflowY: "auto",
              }}
            >
              <Slots
                slotLoading={slotLoading}
                setSelectedSlot={setSelectedSlot}
                selectedSlot={selectedSlot}
                timeSlots={slots}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(AppointmentRescheduleModal);
