import { useEffect, useState } from "react";
import { Modal, Button, Input, Select, message } from "antd";
import { manageLabApiTasks } from "../../../models/mangeLab";
import "../../../assets/styles/modals/AddLab.scss";
import { companyName, companyType, primaryColor } from "colors-config";

const AddLab = ({ clinicId, isVisible, ...props }) => {
  const [labId, setLabId] = useState("");
  const [labServiceId, setLabServiceId] = useState("");
  const [labEmail, setLabEmail] = useState("");
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConsent, setTermsAndConsent] = useState(null);
  // const [pagination, setPagination] = useState(false);


  useEffect(() => {
    if (companyType != 5) {
      getLabCountries();
    }
    else{
      getLabTermsAndConditions("LAB69933501");
    }

  }, []);

  useEffect(() => {
    if (companyType != 5) {
      if (isVisible && country !== "")
        getLabs("");
    }
  }, [isVisible]);



  useEffect(() => {
    if (country !== "") getLabs("");
  }, [country]);

  function getLabCountries() {
    manageLabApiTasks
      .getAll("clinic/getLabCountries")
      .then((res) => {
        const arr = res.body.labCountries?.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
        setCountryList(arr);
        setCountry(res.body.labCountries[0]);
        getLabs("");
      })
      .catch((error) => console.error(error));
  }

  function getLabs(value) {
    manageLabApiTasks
      .getAll(
        "clinic/getLabsByCountry?clinicID=" +
        clinicId +
        "&country=" +
        country +
        "&searchKey=" +
        value
      )
      .then((res) => {
        const arr = res.body.labDetails.map((data) => ({
          value: data.lab_name,
          lab_id: data.lab_id,
          lab_service_id: data.lab_service_id,
          lab_email: data.lab_email,
        }));
        setAutoCompleteData(arr);
      })
      .catch((error) => console.error(error));
  }

  function onLabSelect(value) {
    autoCompleteData.filter((obj) => {
      if (obj.value === value) {
        getLabTermsAndConditions(obj.lab_id);
        setLabId(obj.lab_id);
        setLabEmail(obj.lab_email);
        setLabServiceId(String(obj.lab_service_id));
      }
    });
  }

  function getLabTermsAndConditions(id) {
    manageLabApiTasks
      .getAll("clinic/labPartner/getResource?labId=" + id)
      .then((res) => {
        setTermsAndConsent(res.body);
      })
      .catch((error) => {
        setTermsAndConsent(null)
        console.error(error)
      });
  }

  function openPdf(type) {
    if (termsAndConsent) {
      if (type === "termsAndConditions") {
        window.open(termsAndConsent.terms_and_conditions.url, "_blank");
      } else {
        window.open(termsAndConsent.data_acquisition_form.url, "_blank");
      }
    } else {
      message.error("Not found");
    }
  }

  function addLab() {
    if (companyType !== 5) {
      if (labId) {
        setIsLoading(true);
        const data = {
          clinicID: clinicId,
          labID: labId,
          serviceID: labServiceId,
          labEmail: labEmail,
          labSetAsDefault: "1",
        };
        manageLabApiTasks
          .post("clinic/addLabPartner", data)
          .then((res) => {
            setIsLoading(false);
            message.success(res.body.msg);
            props.success();
            props.onCancel();
            setLabId("");
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(error.response.data.err.error);
          });
      } else {
        message.error("Please Select Lab")
      }
    }
    else {
     
      setIsLoading(true);
      const data = {
        clinicID: clinicId,
        labID: "LAB69933501",
        serviceID: "3",
        labEmail: "admin@smilelign.com",
        labSetAsDefault: "1",
      };
      manageLabApiTasks
        .post("clinic/addLabPartner", data)
        .then((res) => {
          setIsLoading(false);
          message.success(res.body.msg);
          props.success();
          props.onCancel();
          setLabId("");
        })
        .catch((error) => {
          setIsLoading(false);
          message.error(error.response.data.err.error);
        });

    }

  }

  function onScroll({ target }) {
    if (target.scrollTop + target.offsetHeight === target.scrollHeight)
      console.log("load");
  }
  return (
    <Modal
      title="Add Lab"
      visible={isVisible}
      destroyOnClose
      onCancel={props.onCancel}
      {...props}
      footer={[
        <Button className="modalButton cancelBtn" style={{
          borderColor: primaryColor,
          color: primaryColor
        }} onClick={() => {
          props.onCancel()
          setLabId("")
        }
        }>
          Cancel
        </Button>,
        <Button
          className="modalButton request-partner"
          loading={isLoading}
          type="primary"
          onClick={addLab}
        >
          Request partnership
        </Button>,
      ]}
      bodyStyle={{ paddingBottom: "0" }}
      afterClose={() => setTermsAndConsent(null)}
    >
      {companyType!=5 ?
      <>
        <p>
        Search for the list of already registered Labs with {companyName}. Add
        Labs here for partnership with your Clinic.
      </p>
      <label>Search lab</label>
      <Input.Group compact>

        <Select
          size="large"
          //title={{Search Lab}}
          style={{ width: "25%" }}
          value={country}
          onSelect={(value) => setCountry(value)}
        >
          {countryList.map((data) => (
            <Select.Option value={data}>{data}</Select.Option>
          ))}
        </Select>
        <Select
          size="large"
          showSearch
          onSearch={(value) => getLabs(value)}
          style={{ width: "75%" }}
          onSelect={onLabSelect}
          listHeight={150}
          onPopupScroll={onScroll}
          virtual
        >
          {autoCompleteData.map((data) => (
            <Select.Option key={data.lab_id} value={data.value}>
              {data.value}
            </Select.Option>
          ))}
        </Select>
      </Input.Group>
      <p className="labTermsAndConditions"
      style={{
        marginTop:companyType==5?"0":"10%"
      }}
      >
        By clicking on the ‘Request partnership’ button you agree with the Lab&nbsp;
        <span
          style={labId ? { color: primaryColor } : { cursor: "no-drop", color: primaryColor }}
          className="termsAndConsent"
          onClick={() => labId && openPdf("termsAndConditions")}
        >
          Terms and Conditions
        </span>{" "}
        and Lab Declaration of&nbsp;
        <span className="termsAndConsent" style={{ color: primaryColor }} onClick={() => labId && openPdf("")}>
          Consent Data Acquisition and Processing
        </span>
      </p>
      </>:
          <p className="labTermsAndConditions"
          style={{
            marginTop:companyType==5?"0":"10%"
          }}
          >
            By clicking on the ‘Request partnership’ button you agree with the Lab&nbsp;
            <span
              style={{ color: primaryColor } }
              className="termsAndConsent"
              onClick={() =>  openPdf("termsAndConditions")}
            >
              Terms and Conditions
            </span>{" "}
            and Lab Declaration of&nbsp;
            <span className="termsAndConsent" style={{ color: primaryColor }} onClick={() => openPdf("")}>
              Consent Data Acquisition and Processing
            </span>
          </p>
      }
     
    
    </Modal>
  );
};

export default AddLab;
