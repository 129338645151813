import { useState, useEffect,  useMemo } from "react";
import {
    Table,
    Radio,
    Dropdown,
    Menu,
    Button,
    message,
    Spin,
    Tooltip,
    Avatar, Card, Skeleton, Switch
} from "antd";
import {useLocation} from "react-router-dom"
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import HubspotImage from "../../../assets/images/hubspot-logo.png"
import TitleComponent from "../../shared/titleComponent";
import { useSelector } from "react-redux";
import "../../../assets/styles/components/labPartners.scss";
import { generateHubspotInstallUrlAPI, getClinicIntegrationsListAPI } from "api/integrations";
const { Meta } = Card;

const ClinicIntegrationList = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [checkHubspot, setCheckHubspot] = useState(false);
    const [integrationList, setIntegrationList] = useState([]);
    const [hubspotLoading, setHubspotLoading] = useState(false);
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const integrationStatus = searchParams.get("status");

    const clinic = useSelector(state => state.clinic);

    const fetchClinicIntegrations = async (clinicId) => {
        try {
            setIsLoading(true);
            const getList = await getClinicIntegrationsListAPI(clinicId);
            setIntegrationList(getList.data);
            if (getList?.data?.length) {
                const checkHubspot = getList.data.find(obj => obj.type == "hubspot");
                setCheckHubspot(checkHubspot ? checkHubspot.isActive : false);
            }
            else {
                setCheckHubspot(false);
            }
        }
        catch (err) {
            console.log("err", err);
        }
        finally {
            setIsLoading(false)
        }


    }

    useEffect(()=>{
        console.log("checkParams",integrationStatus);
        if(integrationStatus){
            if(integrationStatus=="success"){
                message.success("Hubspot integration successfully done!")
            }
            else{
                message.error(integrationStatus)
            }
        }
       
    },[])

    useEffect(() => {
        if (clinic && clinic.clinicId) {
            fetchClinicIntegrations(clinic.clinicId)
        }
       
    }, [clinic])

    const onHubspotChange = async (value) => {
        try {
            setHubspotLoading(true);
            const urlData = await generateHubspotInstallUrlAPI(clinic.clinicId);
            window.open(urlData.data.url, '_parent', 'noreferrer');
            // setCheckHubspot(value)
        } catch (error) {

        }
        finally {
            setHubspotLoading(false)
        }

    }

    return (
        <div>
            <div
                style={{ width: "100%" }}
            >
                <div id="top">

                    <TitleComponent titleText={"Integration List"}></TitleComponent>

                </div>
                <div>
                    <Spin spinning={isLoading}>
                        <Card
                            className="integration-container"
                            style={{
                                width: 300,
                                marginTop: 16,
                            }}
                            cover={
                                <img
                                    alt="Hubspot logo"
                                    src={HubspotImage}
                                />
                            }
                            actions={[
                                checkHubspot ? "Active" : "Inactive",
                                <Switch
                                    className="button-toggle"
                                    checked={checkHubspot}
                                    loading={hubspotLoading}
                                    onChange={onHubspotChange}
                                />,
                            ]}
                        >

                        </Card>


                    </Spin>
                </div>

            </div>
        </div>
    );
};



export default ClinicIntegrationList;
