import { useState, useEffect } from "react";
import InvoiceListComponent from "../../components/private/invoiceListComponent";
import TitleComponent from "../../components/shared/titleComponent";
import { Row, Radio, message, Spin,Input,Col } from "antd";

import { connect } from "react-redux";
import { getPatientOrders } from "api/billing";
import LabInvoiceList from "components/private/LabInvoiceList/LabInvoiceList";
import { LAB_INVOICING, PATIENT_INVOICING } from "utilities/constants";

// const { Title } = Typography;

const ClinicInvoiceList = ({ clinic }) => {
  const [currentMenuSelected, setCurrentMenuSelected] = useState(PATIENT_INVOICING);
  const [patientInvoices, setPatientInvoices] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [currentInvoiceList, setCurrentInvoiceList] = useState([]);
  const [totalPatientsOrders, setTotalPatientsOrders] = useState(0);
  const [totalLabOrders, setTotalLabOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (clinic.clinicId) _getinvoiceList();
  }, [clinic.clinicId]);

  useEffect(() => {
    setCurrentInvoiceList(patientInvoices);
  }, [patientInvoices]);

  useEffect(() => {
    if (clinic.clinicId && (searchKey.length >= 3 || searchKey === "")) {
      _getPatientOrders();
    }
  }, [searchKey, paginationPage, paginationLimit, fromDate, endDate]);

  const _getinvoiceList = () => {
    _getPatientOrders();
  };

  const _getPatientOrders = async () => {
    setIsLoading(true);
    try {
      const patientOrderRes = await getPatientOrders(
        paginationLimit,
        paginationPage,
        clinic.clinicId,
        searchKey,
        fromDate,
        endDate
      );
      const { body } = patientOrderRes.data;
      setPatientInvoices(body.data);
      setTotalPatientsOrders(body.total_invoices);
    } catch (error) {
      message.error("Failed to load patients list");
    } finally {
      setIsLoading(false);
    }
  };

  const onMenuChanged = (event) => {
    const selectedMenu = event.target.value;
    // setSearchKey("");
    setCurrentMenuSelected(selectedMenu);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationPage(pagination.current);
    setPaginationLimit(pagination.pageSize);
    // if (filters && filters.journeyStatus.length > 0)
    //   setJourneyStatus(filters.journeyStatus);
  };

  const handleSearch = (value) => {
    setSearchKey(value);
    setPaginationPage(1);
  };

  const handleDateFilter = (confirm, start = "", end = "") => {
    setFromDate(start);
    setEndDate(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };

  return (
    <div style={{ width: "100%", marginLeft: "1rem" }}>
      <Row>
        <TitleComponent titleText={"Invoices List"}/>
      </Row>
      <Row justify="space-between" align="middle">
        <Col
          lg={12}
          style={{
            display: "flex"
          }}
        >
        <Radio.Group
          defaultValue={currentMenuSelected}
          buttonStyle="solid"
          style={{ height: "100%" }}
          onChange={onMenuChanged}
        >
          <Radio.Button
            className="patient-details-radio-buttons"
            value={PATIENT_INVOICING}
          >
            {`Patients (${totalPatientsOrders})`}
          </Radio.Button>
          <Radio.Button className="patient-details-radio-buttons" value={LAB_INVOICING}>
            {`Labs (${totalLabOrders})`}
          </Radio.Button>
        </Radio.Group>
        </Col>
        <Col lg={12}>
        <div
            style={{
              display: "flex",
              justifyContent: " flex-end"
            }}
        >
          <Input.Search
            placeholder="Search by Patient ID or Patient Name"
            allowClear={true}
            enterButton={true}
            size='large'
            onSearch={(e) => handleSearch(e.trim())}
            style={{
              width: 331,
              // right: 0,
              // marginTop: "-4rem",
              // marginBottom: "1rem",
              // position: "absolute",
              border: "none",
            }}
            // prefix={
            //   <SearchOutlined
            //     style={{ color: "#B0B0B0", marginRight: "4px" }}
            //   />
            // }
          />
        </div>
        </Col>
      </Row>
      <Spin spinning={isLoading} style={{ width: "100%" }}>
        <div
          style={{
            display: currentMenuSelected === PATIENT_INVOICING ? "block" : "none",
          }}
        >
          <InvoiceListComponent
            paginationPage={paginationPage}
            paginationLimit={paginationLimit}
            invoiceList={currentInvoiceList}
            onPagination={handleTableChange}
            onSearch={handleSearch}
            handleDateFilter={handleDateFilter}
            currentMenuSelected={currentMenuSelected}
            fromDate={fromDate}
            endDate={endDate}
            totalLabOrders={totalLabOrders}
            totalPatientsOrders={totalPatientsOrders}
          />
        </div>
      </Spin>
      <div
        style={{
          display: currentMenuSelected !== PATIENT_INVOICING ? "block" : "none",
        }}
      >
        <LabInvoiceList
          searchKey={searchKey}
          setTotalLabOrders={setTotalLabOrders}
          totalLabOrders={totalLabOrders}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(ClinicInvoiceList);