
const PrescriptionOcclusion = ({prescriptionPDFObj}) => {
  return (
    <div className="prescriptionPDF-card">
    <h2>Occlusion</h2>
    <div className="prescriptionPDF-occ">
      <div>
        <div className="border-right">
          <label className="treatment-label">Skeletal</label>
          <p className="treatment-info">{prescriptionPDFObj.skeletalClass}</p>
        </div>
      </div>
      <div className="border-right">
        <div>
          <label className="treatment-label">Division</label>
          <p className="treatment-info">{prescriptionPDFObj.skeletalDivision}</p>
        </div>
      </div>
      <div>
        <div>
          <label className="treatment-label">Side</label>
          <p className="treatment-info">{prescriptionPDFObj.skeletalSide}</p>
        </div>
      </div>
    </div>

    <div className="prescriptionPDF-occ">
      <div>
        <div className="border-right">
          <label className="treatment-label">Dental</label>
          <p className="treatment-info">{prescriptionPDFObj.dentalClass}</p>
        </div>
      </div>
      <div className="border-right">
        <div>
          <label className="treatment-label">Division</label>
          <p className="treatment-info">{prescriptionPDFObj.dentalDivision}</p>
        </div>
      </div>
      <div>
        <div>
          <label className="treatment-label">Side</label>
          <p className="treatment-info">{prescriptionPDFObj.dentalSide}</p>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div>
          <label className="treatment-label">Parafunction/Deviatoin</label>
          <p className="treatment-info"> {prescriptionPDFObj.parafunctionDetails}</p>
        </div>
      </div>

    </div>
  </div>
  )
}

export default PrescriptionOcclusion