import React from "react";
import { Form, Row, Radio, Divider, Col } from "antd";
import {FORM_CONSTANTS} from "../constants"
const Periodontium = ({ onFormChange, disabled }) => {
  return (
    <div
      style={{
       background: "#fff",
        // margin: "10px 0",
        padding: "0px 0px 42px  0px",
        borderRadius: "8px",
        flex:"1"
        // height:"100%"
      }}
    >
      <h1
        className="form-heading"
        style={{ background: "#fff",padding: "24px 20px 0px 18px", margin: "0px",  borderRadius: "8px", }}
      >
        Periodontium
      </h1>
      <Divider />

      <div className="instruction-row bio-row" style={{paddingTop:"30px", }} >
        <Row>
          <Col lg={7}>
            <label>Biotype</label>
          </Col>
          <Col lg={17}>
            <Form.Item name="bioType" label="">
              <Radio.Group onChange={(e) => onFormChange("bioType", e.target.value)} disabled={disabled}>
              <Radio value={FORM_CONSTANTS.THIN}>Thin</Radio>
              <Radio value={FORM_CONSTANTS.NORMAL}>Normal</Radio>
              <Radio value={FORM_CONSTANTS.THICK}>Thick</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="instruction-row bio-row">
        <Row>
          <Col lg={7}>
            <label>Recession (Miller's Class)</label>
          </Col>
          <Col lg={17}>
            <Form.Item name="recession" label="">
              <Radio.Group onChange={(e) => onFormChange("recession", e.target.value)} disabled={disabled}>
              <Radio value="1">1</Radio>
              <Radio value="2">2</Radio>
              <Radio value="3">3</Radio>
              <Radio value="4">4</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Periodontium;
