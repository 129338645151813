export const secondaryFilterFilters = [
  {
    label: "Treatment setup",
    value: "treatment_setup",
  },
  {
    label: "Modification",
    value: "modification",
  },
  {
    label: "Refinement Request",
    value: "refinement_request",
  },
  {
    label: "Appliance Production",
    value: "appliance_production",
  },
  {
    label: "New Comment",
    value: "comment",
  },
  {
    label: "Partnership",
    value: "partner",
  },
  {
    label: "IPR/Attachment",
    value: "ipr_attachment",
  },
];
