import PropTypes from "prop-types";
import { Tooltip } from 'antd';
import { getAlignerBackground, getAlignerNumberBackground } from "utilities/IPR";

const Aligners = ({ alignersObj, alignerType }) => {
//  console.log("alignersObj", alignersObj);
  return (
    <div className={alignersObj.length > 6 ? "aligners-container aligner-scroll-container" :"aligners-container"}>
      {alignersObj.map((upperAligner, upperIndex) => {
        return (
          <div
            key={upperIndex}
            className={
              upperAligner.selected
                ? "selected-aligner aligner-rows"
                : "aligner-rows"
            }
          >
              <div className="aligner-info">
               <Tooltip placement="topRight" title={` Session
                  ${upperAligner.index ? upperAligner.index : upperIndex + 1}
                    Before Aligner ${upperAligner.name.split("-")[1]}`}>
               <div 
                  style={{
                    background: `${getAlignerNumberBackground(upperAligner.aligner_count)} `,
                  }}
                className={`aligner-num`}
               >
               S{upperAligner.name.split("-")[1]}
              </div>
               </Tooltip>
         
            </div>
            <div className={`aligner-row`}>
              {upperAligner.alignerValues.map((aligner, alignerIndex) => {
                return (
                  <div
                  style={{
                    background: `${getAlignerBackground(
                      aligner,
                      upperAligner
                    )} `,
                  }}
                    className={`aligner-circle aligner-${alignerType} 
                    ${
                      alignerType === "upper"
                        ? `${
                            aligner.value &&
                            upperIndex === alignersObj.length - 1
                              ? `aligner-0-upper`
                              : ""
                          }`
                        : aligner.value
                        ? `aligner-${upperIndex}-${alignerType} aligner-${alignerType}`
                        : ""
                    }
                    `}
                    key={alignerIndex}
                  >
                    {aligner.value}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {/* <div className={`aligner-row `} style={{alignItems: alignerType === "upper" ? "flex-end": "flex-start"}}>
        {Object.keys(alignersObj).map((key, index) => {
          const aligner = alignersObj[key];
          return (
            <div key={index}>
              {aligner.map((a, i) => {
                return (
                  <div
                    className={`${
                      a.value ? `aligner-${a.session}  ` : ""
                    } aligner-circle 
                    ${alignerType === "upper" ? `${a.value && i === aligner.length - 1 ? `aligner-0-${alignerType}`:"" }`: `aligner-${i}-${alignerType}`}
                    `}
                    key={i}
                  >
                    {a.value}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default Aligners;

Aligners.propTypes = {
  alignersObj: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        session: PropTypes.number.isRequired,
        // Add more PropTypes for other properties if needed
      })
    )
  ).isRequired,
};
