import { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import { getAllLabOrders } from "api/clinic";
import { connect } from "react-redux";
import {
  getLabInvoiceListColumns,
} from "./components/TableColumns";
import LabInvoiceModal from "../LabInvoiceModal/components/LabInvoiceModal";
import { createLabInvoiceTableData } from "utilities/invoice";
const LabInvoiceList = ({ clinic, searchKey , setTotalLabOrders, totalLabOrders, onSearch}) => {
  const [labInvoices, setLabInvoices] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paginationObj, setPaginationObj] = useState({});
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  

  useEffect(() => {
    if (clinic.clinicId) {
      (async () => {
        try {
          setIsLoading(true);
          const labOrderRes = await getAllLabOrders({
            limit: paginationLimit,
            page: paginationPage,
            clinicId: clinic.clinicId,
            search: searchKey,
            startDate: fromDate,
            endDate: endDate,
          });
          setLabInvoices(createLabInvoiceTableData(labOrderRes?.body?.data));
          setTotalLabOrders(labOrderRes.body.total_invoices);
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [searchKey, paginationPage, paginationLimit, fromDate, endDate, clinic]);

    useEffect(() => {
    const obj = {
      current: paginationPage,
      pageSize: paginationLimit,
      showSizeChanger: true,
      // defaultCurrent: 1,
      // defaultPageSize: 50,
      pageSizeOptions: [5, 10, 20, 50],
      // size: "small",
      total: totalLabOrders,
    };

    setPaginationObj(obj);
  }, [paginationPage, paginationLimit, totalLabOrders, onSearch]);

  const handleDateFilter = (confirm, start = "", end = "") => {
    setFromDate(start);
    setEndDate(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationPage(pagination.current);
    setPaginationLimit(pagination.pageSize);
  };
  const onDownloadAllClick = (shippingFiles) => {
    shippingFiles.forEach((file) => {
      downloadHandler(file);
    });
  };
  const downloadHandler = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onViewClick = (record) => {
    setOrderInfo(record);
    setShowInvoiceModal(true);
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Table
          className="patient-list-table"
          style={{ width: "100%", overflow: "hidden", marginTop: "0.5rem" }}
          columns={getLabInvoiceListColumns(
            handleDateFilter,
            fromDate,
            endDate,
            onDownloadAllClick,
            onViewClick
          )}
          onChange={handleTableChange}
          dataSource={labInvoices}
          pagination={paginationObj}
        />

        {showInvoiceModal && (
          <LabInvoiceModal
            showInvoiceModal={showInvoiceModal}
            setShowInvoiceModal={setShowInvoiceModal}
            orderInfo={orderInfo}
            setOrderInfo={setOrderInfo}
          />
        )}
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(LabInvoiceList);
