import { useState, useEffect } from "react";
import { Dropdown, message, Modal, Button, Tooltip, Form, Input } from "antd";
import { DownOutlined, CheckCircleOutlined } from "@ant-design/icons";
import GenericButton from "../../../shared/buttons/genericButton";
import RequestRefinementModal from "./requestRefinementModal";
import { getRefinementData } from "api/clinic";
import {
  checkPatientEmail,
  completeTreatment,
  resendRegistrationEmail,
  shareWithPatientApi,
} from "api/patient";
import { JOURNEY_STATUS, TAG_STATUS, TREATMENT_STATUS } from "../../../../constants";
// import TreatmentDetailsModal from "./treatmentDetailsModal";
import AppTag from "components/shared/Tag/Tag";
import RefinementViewModal from "./refinementViewModal";
import dayjs from "dayjs";
import { ReactComponent as BulbIcon } from "../../../../assets/images/BulbIcon.svg";
import SendAppLinkModal from "components/shared/modals/sendAppLinkModal";
import AppButton from "components/shared/AppButton/AppButton";

const EndTreatment = ({
  getRefinementDetails,
  refinementData,
  setRefinementData,
  showRefinementButton,
  setShowRefinementButton,
  setRefinementRequested,
  refinementRequested,
  treatment,
  patientInfo,
  setShowLabOrderModal,
  setOrderRetainerClick,
  status,
  getCurrentTreatmentStatus,
  alignerList,
  getPatientInformation,
  setIsModalModifyPlanVisible,
  labOrders,
  setTreatment,
  setIsModalVisible,
  labOrder,
  orderButtonRender,
  setIsModalApprovePlanVisible,
  sendAppStage,
  setSharePatientMessage,
  treatmentPlans,
  setTreatmentDetailModalOpen,
  setFromShareWithPatient,
  setPatientInfoState,
  setIsModalApproveSendPlanVisible,
  setRefinementViewModalOpen,
  refinementViewModalOpen,
  setViewStartRefinementModal,
  setFromPatientJourneyButton
}) => {
  // const [showRefinementButton, setShowRefinementButton] = useState(true);
  const [refinementModalOpen, setRefinementModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [completeTreatmentModalOpen, setCompleteTreatmentModalOpen] = useState(false);

  // const [refinementData, setRefinementData] = useState();
  // const [refinementViewModalOpen, setRefinementViewModalOpen] = useState(false);
  const [sharedPatientLoading, setSharedPatientLoading] = useState(false);
  const [isModalSendVisible, setIsModalSendVisible] = useState(false);
  const [sendAppLoading, setSendAppLoading] = useState(false);

  const [sendAppForm] = Form.useForm();
  const requestRefinementClick = () => {
    setRefinementModalOpen(true);
  };
  const orderRetainerModal = () => {
    setOrderRetainerClick(true);
    setShowLabOrderModal(true);
  };

  const completeTreatmentClick = async () => {
    try {
      setConfirmLoading(true);
      await completeTreatment({
        patientId: treatment.patient_id,
        lab_treatment_id: treatment.lab_treatment_id,
        clinic: true,
      });
      message.success("Treatment has been completed");
      await getPatientInformation();
      await getCurrentTreatmentStatus();
      setShowRefinementButton(false);
    } catch (err) {
      console.log("err",err);
      message.error("Some error occured");
    } finally {
      setConfirmLoading(false);
      setCompleteTreatmentModalOpen(false);
    }
  };

  // useEffect(() => {
  //   if (treatment?.patient_id) {
  //     getRefinementDetails();
  //   }
  // }, [treatment]);

  const handleCancel = () => {
    setCompleteTreatmentModalOpen(false);
  };

  // const getRefinementDetails = async () => {
  //   if (treatment.patient_id) {
  //     const refinementDetailsRes = await getRefinementData(treatment.patient_id);
  //     if (
  //       refinementDetailsRes?.data?.data ||
  //       status.clinic_patient_journey_status_name == JOURNEY_STATUS.TREATMENT_COMPLETED
  //     ) {
  //       setShowRefinementButton(false);
  //       if (status.clinic_patient_journey_status_name == JOURNEY_STATUS.REFINEMENT_REQUESTED) {
  //         setRefinementRequested(true);
  //       }
  //     } else {
  //       setShowRefinementButton(true);
  //     }
  //     setRefinementData(refinementDetailsRes.data.data);

  //     setIsRefinement(!!refinementDetailsRes.data.data)
  //   }
  // };

  const onShareWithPatientClick = async () => {
    if (sendAppStage == 0) {
      setSharePatientMessage(true);
      setIsModalSendVisible(true);
      setFromShareWithPatient(true);
      return;
    }
    // shareWithPatientCall();
    setIsModalApproveSendPlanVisible(true);
  };

  const items = [
    {
      key: "1",
      label: "Complete Treatment",
      onClick: () => setCompleteTreatmentModalOpen(true),
    },
    {
      key: "2",
      label: "Request Refinement",
      onClick: requestRefinementClick,
    },
    {
      key: "3",
      label: "Order Retainer",
      onClick: orderRetainerModal,
    },
  ];

  // const showStatuses = () => {
  //   let sentToPatientView;
  //   let refinementRequestedView;
  //   let modifyPlanView;
  //   let treatmentDetailView;

  //   if (treatment.approval_status == "approved") {
  //     const checkTreatmentOrder = labOrders.find(
  //       (obj) => obj.lab_treatment_id == treatment.lab_treatment_id
  //     );
  //     if (treatment.revision_plan_version && !checkTreatmentOrder) {
  //       modifyPlanView = (
  //         <GenericButton onClick={() => setIsModalModifyPlanVisible(true)}>
  //           Modify Plan
  //         </GenericButton>
  //       );
  //     }

  //     if (treatment.treatment_details) {
  //       treatmentDetailView = (
  //         <GenericButton onClick={() => setTreatmentDetailModalOpen(true)}>
  //           Treatment Details
  //         </GenericButton>
  //       );
  //     }

  //     if ((patientInfo.clinic_patient_journey_status_id = 20 && refinementRequested)) {
  //       // refinementRequestedView = (
  //       //   <p
  //       //     className="sent-patient-tag"
  //       //     style={{
  //       //       width: "12rem",
  //       //       color: "red",
  //       //       background: "rgba(254, 80, 80, 0.18)",
  //       //     }}
  //       //   >
  //       //     Refinement Requested
  //       //   </p>
  //       refinementRequestedView = (
  //         <AppTag color={TAG_STATUS.ERROR} text={JOURNEY_STATUS.REFINEMENT_REQUESTED} />
  //       );
  //     }

  //     if (patientInfo.clinic_patient_journey_status_id != 20 && !refinementRequested) {
  //       sentToPatientView = <AppTag color={TAG_STATUS.SUCCESS} text="Sent to Patient" />;
  //     }
  //   }
  //   return (
  //     <>
  //       {modifyPlanView}
  //       {treatmentDetailView}
  //       {status.clinic_patient_journey_status_name === JOURNEY_STATUS.ALIGNER_IN_PRODUCTION && (
  //         <AppTag color={TAG_STATUS.DEFAULT} text={JOURNEY_STATUS.ALIGNER_IN_PRODUCTION} />
  //       )}
  //       {sentToPatientView}
  //       {refinementRequestedView}
  //     </>
  //   );
  // };

  const handleAppLinkOk = () => {
    onSendAppConfirm();
  };

  const onSendAppConfirm = async () => {
    const obj = {
      patient_id: treatment.patient_id,
    };
    try {
      let email;
      setSendAppLoading(true);
      const sendAppFormData = await sendAppForm.validateFields();
      email = sendAppFormData?.email || "";
      if (email) {
        obj.email = email;
        const checkPatientDetails = {
          email: email,
        };
        if (patientInfo.patient_email !== email) {
          const checkPatientResponse = await checkPatientEmail(checkPatientDetails);
          if (checkPatientResponse?.data?.data?.emailExists) {
            throw new Error("Email already Exists. Assign new Email");
          }
        }
      }

      await resendRegistrationEmail(obj);
      message.success("Email has been sent successfully.");

      setIsModalSendVisible(false);
      if (email) {
        setPatientInfoState({
          ...patientInfo,
          patient_email: email,
        });
      }
      setSendAppLoading(false);
      // shareWithPatientCall();
      setIsModalApproveSendPlanVisible(true);
    } catch (error) {
      message.error(error?.values ? "Please enter patient email" : error.message);
      setSendAppLoading(false);
    }
  };

  const handleAppLinkCancel = () => {
    setIsModalSendVisible(false);
  };

  const setConfirmTreatmentComponent = () => {
    if (
      labOrder?.delivery_status === "Shipped" &&
      treatment?.approved &&
      status.clinic_patient_journey_status_name == JOURNEY_STATUS.ALIGNER_SHIPPED &&
      status.patient_treatment_plan_status != JOURNEY_STATUS.RETAINER_STAGE
    ) {
      return (
        <AppButton
          shape="round"
          className="app-button-class"
          type="primary"
          onClick={() => {
            refinementData ? setViewStartRefinementModal(true) : setIsModalVisible(true);
          }}
        >
          {refinementData ? "Start Refinement" : "Start Treatment"}
        </AppButton>
      );
    }
    return;
  };

  const leftSideStatus = () => {
    let refinementDetailView;
    let modifyPlanView;
    if (treatment.approval_status === TREATMENT_STATUS.APPROVED) {
      const checkTreatmentOrder = labOrders.find(
        (obj) => obj.lab_treatment_id == treatment.lab_treatment_id
      );
      if (treatment.revision_plan_version && !checkTreatmentOrder) {
        modifyPlanView = (
          <AppButton
            shape="round"
            className="app-button-class"
            onClick={() => setIsModalModifyPlanVisible(true)}
          >
            Modify Plan
          </AppButton>
        );
      }
    }

    if (refinementData) {
      refinementDetailView = (
        <AppButton
          className="app-button-class"
          shape="round"
          type="default"
          onClick={() => setRefinementViewModalOpen(true)}
        >
          View Refinement details
        </AppButton>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
      >
        {treatment?.approval_status == "approved" && !treatment?.approved && !labOrders?.length ? (
          <Button
            shape="round"
            className="app-button-class"
            type="primary"
            onClick={() => {
              setIsModalApprovePlanVisible(true);
            }}
          >
            Select plan
          </Button>
        ) : (
          ""
        )}

        {setConfirmTreatmentComponent()}
        {orderButtonRender}
        {!labOrders?.length ? (
          <AppButton
            onClick={() => setIsModalModifyPlanVisible(true)}
            shape="round"
            className="app-button-class"
          >
            Modify Plan
          </AppButton>
        ) : (
          ""
        )}

        {status.clinic_patient_journey_status_name == JOURNEY_STATUS.TREATMENT_COMPLETED ? (
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                setFromPatientJourneyButton(false);
                setShowLabOrderModal(true);
              }}

              className="app-button-class"
            >
              Order
            </Button>
            <AppTag color={TAG_STATUS.SUCCESS} text={JOURNEY_STATUS.TREATMENT_COMPLETED} />
          </div>
        ) : (
          ""
        )}

        {showRefinementButton &&
        alignerList?.body?.data?.length &&
        status.patient_treatment_plan_status != JOURNEY_STATUS.RETAINER_STAGE ? (
          <Dropdown
            menu={{
              items,
            }}
          >
            <GenericButton
              customStyle={{
                borderRadius: "5rem",
                fontFamily: "Mulish",
              }}
              type="primary"
            >
              End Treatment <DownOutlined />
            </GenericButton>
          </Dropdown>
        ) : (
          ""
        )}
        {modifyPlanView}
        {refinementDetailView}
        {status.clinic_patient_journey_status_name == JOURNEY_STATUS.RETAINER_ORDERED ? (
          <AppTag color={TAG_STATUS.SUCCESS} text={JOURNEY_STATUS.RETAINER_ORDERED} />
        ) : (
          ""
        )}
      </div>
    );
  };

  const rightSideStatus = () => {
    let sentToPatientView;
    let refinementRequestedView;
    let treatmentDetailView;
    let refinementDetailView;
    let shareWithPatient;
    if (treatment.treatment_details) {
      treatmentDetailView = (
        <GenericButton onClick={() => setTreatmentDetailModalOpen(true)}>
          Treatment Details
        </GenericButton>
      );
    }

    if (patientInfo.clinic_patient_journey_status_id === 20 && refinementRequested) {
      refinementRequestedView = (
        <AppTag color={TAG_STATUS.ERROR} text={JOURNEY_STATUS.REFINEMENT_REQUESTED} />
      );
    }
    if (patientInfo.clinic_patient_journey_status_id !== 20 && !refinementRequested) {
      if (treatment.sendToPatient == 0 && treatment.patientApproval == 0) {
        if (!treatment.sendPatientDate) {
          shareWithPatient = (
            <AppButton
              loading={sharedPatientLoading}
              className="app-button-class"
              shape="round"
              type="primary"
              onClick={onShareWithPatientClick}
            >
              Share with Patient
            </AppButton>
          );
        } else {
          let sharedDate = "Sent: " + dayjs(treatment.sendPatientDate).format("DD-MM-YYYY");
          shareWithPatient = (
            <Tooltip
              title={
                <span>
                  <BulbIcon style={{ height: "16px" }} /> {sharedDate}
                </span>
              }
            >
              <span>
                <AppTag color={TAG_STATUS.SUCCESS} text="Shared with Patient" />
              </span>
            </Tooltip>
          );
        }

        let orderedDate = "";
        if (treatment.orderedDate) {
          orderedDate = "Order on: " + dayjs(treatment.orderedDate).format("DD-MM-YYYY");
        }
        if (
          status.clinic_patient_journey_status_name != JOURNEY_STATUS.TREATMENT_PROGRESS &&
          status.clinic_patient_journey_status_name != JOURNEY_STATUS.TREATMENT_COMPLETED
        ) {
          sentToPatientView = (
            <Tooltip
              title={
                <span>
                  <BulbIcon style={{ height: "16px" }} /> {orderedDate}
                </span>
              }
            >
              <span>
                <AppTag
                  color={TAG_STATUS.SUCCESS}
                  text={
                    status.clinic_patient_journey_status_name == JOURNEY_STATUS.ALIGNER_SHIPPED
                      ? JOURNEY_STATUS.ALIGNER_SHIPPED
                      : "Aligner Production Stage"
                  }
                />
              </span>
            </Tooltip>
          );
        }
      } else {
        if (treatment.patientApproval == 0) {
          sentToPatientView = <AppTag color={TAG_STATUS.ERROR} text="Pending Patient Approval" />;
        } else {
          if (status.clinic_patient_journey_status_name != JOURNEY_STATUS.TREATMENT_PROGRESS) {
            sentToPatientView = (
              <AppTag
                color={TAG_STATUS.SUCCESS}
                text={
                  patientInfo?.clinic_patient_journey_status_id == 10
                    ? "Aligner Production Stage"
                    : status.clinic_patient_journey_status_name == JOURNEY_STATUS.ALIGNER_SHIPPED
                    ? JOURNEY_STATUS.ALIGNER_SHIPPED
                    : status.clinic_patient_journey_status_name ==
                      JOURNEY_STATUS.ALIGNER_IN_PRODUCTION
                    ? JOURNEY_STATUS.ALIGNER_IN_PRODUCTION
                    : "Patient Approved"
                }
              />
            );
          }
        }
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
      >
        {treatmentDetailView}
        {/* {refinementDetailView} */}

        {status.clinic_patient_journey_status_name ===
          JOURNEY_STATUS.TP_COMPLETION_UNDER_REVIEW && (
          <AppTag color={TAG_STATUS.DEFAULT} text={JOURNEY_STATUS.TP_COMPLETION_UNDER_REVIEW} />
        )}
        {refinementRequestedView}
        {shareWithPatient}
        {sentToPatientView}
        {/* {treatment?.approved ? (
          <div
            className={`selected-treatment-tick ${
              treatment?.approval_status === "rejected" ? "rejected" : ""
            }`}
          >
            <CheckCircleOutlined />
          </div>
        ) : (
          ""
        )} */}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {leftSideStatus()}
        {rightSideStatus()}
      </div>

      {refinementModalOpen && (
        <RequestRefinementModal
        refinementAdditionalDetailModalVisible={refinementModalOpen}
        setRefinementAdditionalDetailModalVisible={setRefinementModalOpen}
          treatment={treatment}
          getRefinementDetails={getRefinementDetails}
          setRefinementRequested={setRefinementRequested}
          getCurrentTreatmentStatus={getCurrentTreatmentStatus}
          setShowRefinementButton={setShowRefinementButton}
          getPatientInformation={getPatientInformation}
        />
      )}

      {completeTreatmentModalOpen && (
        <Modal
          open={completeTreatmentModalOpen}
          // width={750}
          title="Complete Treatment"
          onOk={completeTreatmentClick}
          onCancel={handleCancel}
          confirmLoading={confirmLoading}
          okText="Confrm"
        >
          <p>Are you sure you want to Complete Treatment?</p>
        </Modal>
      )}
      {/* <RefinementViewModal
        refinementModalOpen={refinementViewModalOpen}
        setRefinementModalOpen={setRefinementViewModalOpen}
        treatment={treatment}
        refinementData={refinementData}
      /> */}

      {isModalSendVisible && (
        <div className="send-app-confirmation-modal">
          <SendAppLinkModal
            title="Send App Registration Emailss"
            isModalVisible={isModalSendVisible}
            setIsModalSendVisible={setIsModalSendVisible}
            handleOk={handleAppLinkOk}
            loading={sendAppLoading}
            handleCancel={handleAppLinkCancel}
            content={
              !patientInfo.patient_registered ? (
                <>
                  <p style={{ marginTop: 0 }}>
                    The patient is not available on the app. If you haven't sent the patient
                    registration link, please enter the email to send it.
                  </p>

                  <Form
                    form={sendAppForm}
                    name="sendPatientEmail"
                    initialValues={{
                      email: patientInfo.patient_email,
                    }}
                    layout="vertical"
                  >
                    <Form.Item
                      rules={[
                        {
                          type: "email",
                          message: "Invalid email address",
                        },
                        {
                          required: true,
                          message: "Please enter patient email",
                        },
                      ]}
                      name="email"
                      label="Email"
                    >
                      <Input placeholder="Enter email" />
                    </Form.Item>
                  </Form>
                </>
              ) : (
                "Would you like to send the app registration link to the patient again?"
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default EndTreatment;
