import React, { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { Menu, Dropdown, Row, Col } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "../../../assets/styles/components/patientJourneyMenubarComponent.scss";
import { companyType, primaryColor } from "colors-config";
import AppButton from "components/shared/AppButton/AppButton";
import { placeOrderItems } from "utilities/order";
import { AWAITING_PAYMENT_STATUS } from "utilities/constants";

const PatientOrdersMenubarComponent = ({
  selectedCaseId,
  activeCaseId,
  paymentStatus,
  setIsModalUpdateOrderVisible,
  patientOrders,
  setShowLabOrderModal,
  setShowPatientOrderModal,
  setCurrentSelection,
  currentOrdersSelection,
  setOrderType
}) => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");
    if (redirect === "lab" && companyType !== 5) {
      setCurrentSelection(1);
    }
  }, [location.search, setCurrentSelection]);

  const handleClick = (e) => {
    if (e.key) {
      setCurrentSelection(+e.key);
    }
  };

  const handleMenuClick = (key) => {
     setOrderType(key)
        if (
      patientOrders.length &&
      (!paymentStatus ||
        paymentStatus === "-" ||
        paymentStatus === AWAITING_PAYMENT_STATUS)
    ) {
      setIsModalUpdateOrderVisible(true);
    } else {
      setShowLabOrderModal(true);
    }
  }

 
  return (
    <Row className="patient-orders-row" align="middle">
      <Col lg={12}>
        <Menu
          className="patient-journey-menubar"
          onClick={handleClick}
          selectedKeys={[currentOrdersSelection]}
          mode="horizontal"
        >
          {companyType !== 5 && (
            <Menu.Item
              className={
                currentOrdersSelection === 0
                  ? `patient-journey-menubar-items rest-order ant-menu-item-selected`
                  : `patient-journey-menubar-items rest-order`
              }
              key={0}
            >
              Patient
            </Menu.Item>
          )}
          <Menu.Item
            className={
              currentOrdersSelection === 1
                ? `patient-journey-menubar-items ant-menu-item-selected`
                : `patient-journey-menubar-items`
            }
            // style={{borderBottom: currentOrdersSelection  === 1 ? `3px solid ${primaryColor} `: "none" }}
            key={1}
          >
            Labs
          </Menu.Item>
        </Menu>
      </Col>
      <Col lg={12}>
        {currentOrdersSelection === 1 && (
          <>
            <Dropdown
              menu={{
                items: placeOrderItems(handleMenuClick),
              }}
            >
              <AppButton shape="round" type="primary">
                Place Order <DownOutlined />
              </AppButton>
            </Dropdown>
          </>
        )}

        {currentOrdersSelection === 0 && companyType !== 5 && (
          <AppButton
            disabled={selectedCaseId !== activeCaseId}
            onClick={() => setShowPatientOrderModal(true)}
            shape="round"
          >
            Request Order to Patient
          </AppButton>
        )}
      </Col>
    </Row>
  );
};

export default withRouter(PatientOrdersMenubarComponent);
