import { Card, Typography, Button } from "antd";
import {ReactComponent as Wave} from "../../assets/images/wave.svg";
import { useHistory } from "react-router-dom";
import { companyName, primaryColor } from "colors-config";
import AppButton from "components/shared/AppButton/AppButton";

const { Title } = Typography;

export default function WelcomePageComponent() {
  const history = useHistory();

  return (
    <Card
      style={{
        width: "80%",
        margin: "0 auto",
        backgroundColor: "#E9F0F4",

        borderRadius: "12px",
        minHeight: "85vh",
      }}
    >
      <Wave
        style={{
          marginTop: "7%",
          marginBottom: "1rem",
          width:'100px',
          height:'100px'
        }}
        fill={primaryColor}
        alt="email logo"
      />
      <Title
        level={2}
        style={{
          fontFamily: "Mulish",
          width: "100%",
          fontSize: "36px",
          margin: "0  auto 0 auto",
        }}
      >
       Welcome To {companyName}!

      </Title>
      <p style={{ fontWeight: "525", fontSize: "20px", color: "#77888B", fontFamily: "Mulish" }}>
        Let’s set your profile & availability first.
      </p>

      <AppButton
        type="primary"
        shape="round"
        style={{ width: "10rem", height: "2.5rem", }}
        onClick={() => history.push("/onboarding")}
      >
        {" "}
        Let's go!
      </AppButton>
    </Card>
  );
}
