import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./table.scss";
import { treatmentProductsTableColumn } from "./columns";
import { calculateProductsTotal, productsDataMapper } from "utilities/treatment";

const TreatmentProductsTable = ({ treatment }) => {
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const finalProducts = productsDataMapper(treatment?.labProductList);
    setProductData(finalProducts);
  }, [treatment]);

  return (
    <div>
      {productData.length ? (
        <Table
          className="send-to-patient-table-bottom"
          columns={treatmentProductsTableColumn}
          dataSource={productData}
          pagination={false}
          rowHoverable={false}
          size={"small"}
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div>
                     {calculateProductsTotal(treatment?.labProductList)}
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      ) : (
        <div>
          <p className="patient-question" style={{textAlign:"left"}}>Treatment Products</p>
          <p className="patient-response">No Treatment Products added</p>
        </div>
      )}
    </div>
  );
};

export default TreatmentProductsTable;
