import  { useState, useEffect, useRef } from "react";

const Participant = ({ participant, isLocal }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [isVideoActive, setIsVideoActive] = useState(true);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setIsVideoActive(true);
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        !isLocal && setIsVideoActive(false);
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    if (!isLocal) {
      participant.on("trackDisabled", trackUnsubscribed);
      participant.on("trackEnabled", trackSubscribed);
    }

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack && videoTrack.attach) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack?.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack && audioTrack.attach) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack?.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className="participant">
      {!isLocal ? isVideoActive && <video ref={videoRef} autoPlay={true} />:<video ref={videoRef} autoPlay={true} />}
      {}
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
};

export default Participant;
