
import api, { getToken } from "./api";
import { io } from "socket.io-client";

export const addClinicComment = (data) => {
  return api({
    method: "POST",
    url: "/refinement/comment/add",
    data,
  });
};

export const addCommentApi = (data) => {
  return api({
    method: "POST",
    url: "/comment/add",
    data,
  });
};

export const getCommentHistoryService = ({ senderId, receiverId, patientId }) => {
  return api({
    method: "GET",
    url: "comment/get",
    params: {
      senderId,
      receiverId,
      patientId,
    },
  });
};


const socketBaseUrl = process.env.REACT_APP_BASE_URL_SOCKET;

const idToken = localStorage.getItem("idToken");

export const socket = io(socketBaseUrl, {
  path: "/api/v1/chat/socket",
  transports:["polling"],
  autoConnect: false,
  auth: {
    token: idToken ? idToken : "",
  },
});

export const uploadChatFile = (data, config = {}) => {
  return api({
    method: "POST",
    url: socketBaseUrl + "/api/v1/chat/upload-chat-file",
    data,
    headers: { "content-type": "multipart/form-data" },
    ...config,
  });
};

export const sendMessage = (data, cb = () => {}) => {
  socket.emit("chatMessage", data, cb);
};
export const getCommentMediaTemplate = (baseString) => {
  return baseString.includes("data:application/pdf")
    ? `<p><object data="${baseString}" type="application/pdf" width="100%" height="200px"></object></p>`
    : `<p><img src="${baseString}" /></p>`;
};

export const getCommentTextTemplate = (text) => {
  return `<div><h5>Refinement Request Information</h5><p>${text}</p></div>`;
};
