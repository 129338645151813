import { primaryColor } from "colors-config";
import PatientDetails from "./components/PatientDetails";
import "./treatment.scss";
import Footer from "./components/Footer";
import TreatmentInfo from "./components/TreatmentInfo";
import IprData from "./components/IPRData";
import AttachmentData from "./components/AttachmentData";

const TreatmentPDF = ({patientInfo, clinic, treatment, lowerAligners, upperAligners, chartValue, lowerAttachments,upperAttachments, alignersLabel, attachmentsLabel}) => {
  return (
    <div className="treatmentPDF-container">
      <h1 style={{color: primaryColor}}>Treatment Guide</h1>
      <PatientDetails patientInfo={patientInfo} clinic={clinic} />
      <TreatmentInfo treatment={treatment}/>
      <IprData 
        lowerAligners={lowerAligners}
        upperAligners={upperAligners}
        chartValue={chartValue}
        upperAttachments={upperAttachments}
        lowerAttachments={lowerAttachments}
        alignersLabel={alignersLabel}
        />

        <AttachmentData
        chartValue={chartValue}
        upperAttachments={upperAttachments}
        lowerAttachments={lowerAttachments}
        attachmentsLabel={attachmentsLabel}
        alignersLabel={alignersLabel}
        />
      <Footer/>
    </div>
  )
}

export default TreatmentPDF
