import { columnTitleElement } from "utilities/commonMethod";

import FilterDataRangePicker from "components/shared/filterDataRangePicker";
import {Tooltip} from "antd";
import { companyType } from "colors-config";
import { downloadButton } from "../../../shared/downloadButton";
import FileCell from "./TableCells/File";
import InvoiceTableCell from "./TableCells/Invoice";
import PatientName from "./TableCells/PatientName";
import PaymentStatus from "./TableCells/PaymentStatus";

export const getLabInvoiceListColumns = (
  handleDateFilter,
  fromDate,
  endDate,
  onDownloadAllClick,
  onViewClick
 ) => {
  let columns =  [
    {
      title: columnTitleElement("INVOICE NO"),
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      width: 170,
      render: (invoiceNo) => {
        return (
         <FileCell invoiceNo={invoiceNo}/>
        );
      },
    },
    {
      title: columnTitleElement("PATIENT NAME & ID"),
      dataIndex: "patientName",
      key: "patientName",
      render: (patientName, record) => {
        return (
          <PatientName record={record}/>
        );
      },
    },
    {
      title: columnTitleElement("INVOICE DATE"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      filtered: (fromDate && endDate) ? true : false,
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDataRangePicker
          clearFilters={clearFilters}
          confirm={confirm}
          handleDateFilter={handleDateFilter}
        />
      ),
    },

    {
      title: columnTitleElement("PRODUCT/SERVICE"),
      dataIndex: "productServiceName",
      key: "productServiceName",
    },
    {
      title: columnTitleElement("AMOUNT"),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: columnTitleElement("PAYMENT STATUS"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      render: (paymentStatus) => (
        <PaymentStatus paymentStatus={paymentStatus}/>
      ),
    },
    {
      title: columnTitleElement("INVOICE"),
      dataIndex: "invoice",
      key: "invoice",
      render: () => downloadButton(),
    },
  ];
  if(companyType===5 || companyType=== 1){
    columns = [
      {
        title: columnTitleElement("INVOICE NO"),
        dataIndex: "invoiceNo",
        key: "invoiceNo",
        width:170,
        render: (invoiceNo) => {
          return (
            <FileCell invoiceNo={invoiceNo}/>
          );
        },
      },
      {
        title: columnTitleElement("PATIENT NAME & ID"),
        dataIndex: "patientName",
        key: "patientName",
        render: (patientName, record) => {
          return (
            <PatientName record={record}/>
          );
        },
      },
      {
        title: columnTitleElement("INVOICE DATE"),
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        filtered: (fromDate && endDate),
        filterDropdown: ({ confirm, clearFilters }) => (
          <FilterDataRangePicker
            clearFilters={clearFilters}
            confirm={confirm}
            handleDateFilter={handleDateFilter}
          />
        ),
      },

      {
        title: columnTitleElement("LAB PRODUCT / SERVICE NAME"),
        dataIndex: "productServiceName",
        key: "productServiceName",
        render: (productName, record) => {
          return (
            <Tooltip title={record.productServiceNames}>
            {record.productServiceNames}
          </Tooltip>
          );
        },
      },
      {
        title: columnTitleElement("QUANTITY"),  
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: columnTitleElement("LAB TOTAL PRICE"),
        dataIndex: "totalPrice",
        key: "totalPrice",
      },
      {
        title: columnTitleElement("PAYMENT STATUS"),
        key: "paymentStatus",
        dataIndex: "paymentStatus",
        render: (paymentStatus) => (
          <PaymentStatus paymentStatus={paymentStatus}/>
        ),
      },
      {
        title: columnTitleElement("INVOICE"),
        dataIndex: "invoice",
        key: "invoice",
        render: (invoice, record) => {
          return (
          <InvoiceTableCell record={record} onViewClick={onViewClick} onDownloadAllClick={onDownloadAllClick}/>
          );
        },
      },
    ];
  }
  return columns
};

