import buttonStyles from "./styles.module.scss";

export const createConfirmConfig = ({
  title,
  content,
  onOk,
  onCancel,
  okText,
  cancelText,
  width,
  icon,
  centered,
}) => {
  const okButtonProps = {
    className: buttonStyles["roundButton"],
  };

  const cancelButtonProps = {
    className: buttonStyles["roundButton"],
  };

  return {
    title,
    content,
    closable: true,
    okButtonProps,
    cancelButtonProps,
    onOk: onOk,
    onCancel: onCancel,
    okText,
    cancelText,
    width,
    icon,
    centered,
  };
};
