import { useEffect, useState } from "react";
import { Form, Modal, Input, Row, Col, Button, message, Select } from "antd";
import { manageStaffApiTasks } from "../../../models/mangeStaff";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import { connect, useSelector } from "react-redux";
import { loadClinic } from "../../../services/redux/actions/clinicActions";
import { getPatientCountryCodesList } from "services/redux/selectors/clinicSelectors";

import "../../../assets/styles/modals/AddClinician.scss";
import { updateClinicOnboardingProgressStatus } from "api/clinic";
import { ONBOARDING_PROGRESS } from "../../../constants";
import { companyType,prefix } from "colors-config";

const AddClinician = ({ clinicId, mode, data, loadClinic, ...props }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [h, setCountryCodes] = useState([]);

  const countryCodes = useSelector(getPatientCountryCodesList);
  const colSpan = 12;
  const [form] = Form.useForm();
  const formItemRule = { required: true };

  useEffect(() => {
    // getCountryCode();
    if (mode === "edit") {
      form.setFieldsValue({
        clinicianName: data.clinician_name,
        registrationNo: data.clinician_regno,
        email: data.clinician_email,
        phoneNo: {
          countryCode: data.clinician_country_code_name,
          number: data.clinician_contact.replace(
            data.clinician_country_code_name,
            ""
          ),
        },
      });
    }
  }, []);

  function getCountryCode() {
    manageStaffApiTasks
      .getAll("clinic/getClinicListOfValues")
      .then((res) => {
        // Sort the country codes by country_code_name
        const sortedCountryCodes = res?.body?.countryCodes?.sort((a, b) =>
          a.country_code_name < b.country_code_name
            ? -1
            : b.country_code_name < a.country_code_name
            ? 1
            : 0
        );
        // Remove duplicates
        const uniqueCountryCodes = sortedCountryCodes.filter(
          (code, index, array) => {
            if (index === 0) {
              return true;
            }
            return (
              code.country_code_name !== array[index - 1].country_code_name
            );
          }
        );
        setCountryCodes(uniqueCountryCodes);
      })
      .catch((error) => message.error("Failed to fetch country code"));
  }

  function submit() {
    form
      .validateFields()
      .then((value) => {
        setIsLoading(true);
        mode === "edit" ? editToDB(value) : addToDB(value);
      })
      .catch((error) => console.error(error));
  }

  async function isOnboarded() {
    try {
      const res = await dentistClinicApiTasks.post("clinic/edit/onboarded", {
        clinic_id: clinicId,
        status: "COMPLETED",
      });
      await updateClinicOnboardingProgressStatus({
        email:props.clinic.clinicEmail,
        status:ONBOARDING_PROGRESS.ONBOARDING_COMPLETED
      });
      loadClinic();
    } catch (error) {
      console.log(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  }

  function addToDB(obj) {
    manageStaffApiTasks
      .post("clinic/clinician/Add", {
        clinician_name: obj.clinicianName,
        clinician_regno: obj.registrationNo,
        clinician_email: obj.email,
        clinician_contact: obj.phoneNo?.number,
        clinician_country_code: obj.phoneNo?.countryCode,
        clinic_id: clinicId,
      })
      .then((res) => {
        if (res?.body?.err?.error === "User Email already exists") {
          message.error(res?.body?.err?.error);
          setIsLoading(false);
          return false;
        }
        setIsLoading(false);
        message.success(res.body.msg);
        props.success();
        isOnboarded();
        setIsVisible(false);
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(error.response.data.err.error);
      });
  }

  function editToDB(obj) {
    manageStaffApiTasks
      .post("clinic/clinician/edit", {
        clinician_name: obj.clinicianName,
        clinician_regno: obj.registrationNo,
        clinician_email: obj.email,
        clinician_contact: obj.phoneNo.number,
        clinician_country_code: obj.phoneNo.countryCode,
        clinic_id: clinicId,
        clinician_id: String(data.clinician_id),
      })
      .then((res) => {
        setIsLoading(false);
        message.success(res.body.msg);
        props.success();
        setIsVisible(false);
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(error.response.data.err.error);
      });
  }

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <Modal
      title={mode === "edit" ? "Edit Clinician" : "Add Clinician"}
      visible={isVisible}
      width={650}
      //style={{fontFamily: "Mulish"}}
      onCancel={() => setIsVisible(false)}
      afterClose={props.handleAfterClose}
      {...props}
      footer={[
        <Button className="modalButton" onClick={() => setIsVisible(false)}>
          Cancel
        </Button>,
        <Button
          className="modalButton"
          type="primary"
          onClick={submit}
          loading={isLoading}
        >
          Save & Next
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={{
          phoneNo: {
            countryCode: prefix,
            number: "",
          },
        }}
        layout="vertical"
      >
        <Row gutter={20}>
          <Col span={colSpan}>
            <Form.Item
              name="clinicianName"
              label="Clinician Name"
              rules={[
                { ...formItemRule, message: "Clinician Name is Required" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("clinicianName").length > 50) {
                      return Promise.reject(
                        "Length should not be more than 50 character"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              name="registrationNo"
              label="Registration No."
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  pattern: emailRegex,
                  message: "Enter valid email",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item name="phoneNo" label="Phone No.">
              <Input.Group compact>
                <Form.Item
                  name={["phoneNo", "countryCode"]}
                  noStyle
                  // rules={[
                  //   ({ getFieldValue }) => ({
                  //     validator(_, value) {
                  //       console.log(
                  //         getFieldValue("phoneNo")["number"],
                  //         value,
                  //         "phone"
                  //       );
                  //       if (
                  //         (value && getFieldValue("phoneNo")["number"]) ||
                  //         (!getFieldValue("phoneNo")["number"] && !value)
                  //       ) {
                  //         return Promise.resolve();
                  //       } else {
                  //         return Promise.reject(
                  //           "Please add a complete phone number."
                  //         );
                  //       }
                  //     },
                  //   }),
                  // ]}
                >
                  <Select size="large" 
                   showSearch
                   filterOption={(input, option) =>
                     option.children
                       .toLowerCase()
                       .indexOf(input.toLowerCase()) >= 0
                   }
                  style={{ width: "27%" }}>
                    {countryCodes?.map((data, index) => (
                      <Select.Option key={index} value={data.countryCallingCodes}>
                        {data.countryCallingCodes}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["phoneNo", "number"]}
                  noStyle
                  rules={[
                    {
                      message: "Invalid phone number",
                      pattern: /^\d{9,10}$/,
                    },
                  ]}
                >
                  <Input size="large" type="number" style={{ width: "73%" }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps, { loadClinic })(AddClinician);
