import { useRef, useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./textEditor.scss";
import PropTypes from "prop-types";

const TextEditor = ({
  editoInput,
  setEditorInput,
  hideToolbar,
  placeholder,
  disabled,
  imageCallback,
}) => {
  const quillRef = useRef(null);

  const editorKey = useMemo(
    () => `${hideToolbar ? "hidden-toolbar" : "shown-toolbar"}-${placeholder}`,
    [hideToolbar, placeholder]
  );

  useEffect(() => {
    const editor = quillRef?.current?.getEditor(); // Get the Quill editor instance
    const editorContainer = editor?.root; // Get the editor container (the DOM element)

    // Define the paste event handler
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData || window.clipboardData;

      if (clipboardData && clipboardData.items) {
        // Loop through clipboard items to find if any are an image
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i];

          if (item.type.indexOf("image") !== -1) {
            // If an image is found, prevent the default paste behavior
            event.preventDefault();

            const file = item.getAsFile();
            imageCallback(file);
          }
        }
      }
    };

    // Add the paste event listener to the editor container
    editorContainer?.addEventListener("paste", handlePaste);

    // Clean up the event listener on component unmount
    return () => {
      editorContainer?.removeEventListener("paste", handlePaste);
    };
  }, [editorKey]);
  let modules = {
    toolbar: hideToolbar
      ? false
      : [
          [{ header: [1, 2, false] }],
          [{ list: "bullet" }, { list: "ordered" }, "bold", "italic", "underline"],
        ],
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactQuill
        key={editorKey}
        ref={quillRef}
        readOnly={disabled}
        theme="snow"
        placeholder={placeholder || ""}
        preserveWhitespace
        value={editoInput}
        onChange={(value) => setEditorInput(value)}
        modules={modules}
        className={disabled ? "quill-readonly" : ""}
      />
    </div>
  );
};

TextEditor.defaultProps = {
  hideToolbar: false,
  placeholder: "",
  imageCallback: () => {},
};

TextEditor.propTypes = {
  editoInput: PropTypes.string.isRequired,
  setEditorInput: PropTypes.func.isRequired,
  image: PropTypes.bool,
  hideToolbar: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  imageCallback: PropTypes.func,
};

export default TextEditor;
