import { Steps } from "antd";

import "../../assets/styles/components/stepsComponent.scss";
import { companyType, primaryColor } from "colors-config";

const { Step } = Steps;

const StepsComponent = ({ steps, current }) => {
  const totalSteps = steps.length;
  const step = current + 1;
  const underlineWidth = (step / totalSteps) * 100;
  return (
    <div className="steps steps-container">
      <Steps
        className={`${companyType === 5 ? "steps-smilealign" : ""} ${
          companyType === 6 ? "steps-ias" : ""
        } ${companyType === 6 ? "steps-aqua" : ""}`}
        current={current}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 2rem",
        }}
      >
        <Step title="Profile" />
        <Step title="Availability" />
        <Step title="Treatments & Appointments" />
      </Steps>
      {/* <Steps
        labelPlacement="vertical"
        current={current}
        className={`${companyType === 5 ? "steps-smilealign" : ""} ${companyType === 6 ? "steps-ias" : ""} ${companyType === 6 ? "steps-aqua" : ""}`}
        style={{ backgroundColor: "white", borderRadius: "6px" }}
      >
        {steps.map((item) => (
          <Step
            style={{ margin: "2rem 2rem 0 0", padding: "0 0.5rem 0 0" }}
            key={item.title}
            title={item.title}
          />
        ))}
      </Steps> */}
      <div
        className="steps-line"
        style={{ width: `${underlineWidth}%`, background: primaryColor }}
      />
    </div>
  );
};

export default StepsComponent;
