import { useState, useEffect, useMemo } from "react";
import StepsComponent from "../../components/shared/stepsComponent";
import AccountSettingsFormComponent from "../../components/private/onboarding-profile/accountSettingsFormComponent";
import AvailabilitySettingsComponent from "../../components/private/onboarding-profile/availabilitySettingsComponent";
import ServicesAndResourcesComponent from "../../components/private/onboarding-profile/servicesAndResourcesComponent";
import ManageStaff from "../../components/private/onboarding-profile/manageStaff";
import LabPartners from "../../components/private/onboarding-profile/labPartners";
import { message, Typography, Row, Form } from "antd";
import MultiStepButton from "../../components/shared/buttons/multiStepButton";
import { connect, useSelector } from "react-redux";

import { dentistClinicApiTasks } from "../../models/dentistClinic";
import { useHistory, useLocation } from "react-router-dom";
import { loadClinic, openNewPatientDailog } from "../../services/redux/actions/clinicActions";
import moment from "moment";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import "../../assets/styles/routes/onboarding.scss";
import errorModal from "../../components/shared/modals/errorModal";
import { clinicSelector } from "services/redux/selectors/clinicSelectors";
import {
  addClinicAppointmentProducts,
  addClinicAvailability,
  getClinicResources,
  getProfileAndAvailability,
  getSingleOnBoardClinic,
  updateClinicOnboardingProgressStatus,
} from "api/clinic";
import { ONBOARDING_PROGRESS } from "constants";
import ClinicianForm from "components/private/onboarding-profile/ClinicianForm/ClinicianForm";
const { Title } = Typography;
const steps = [
  {
    title: "Profile",
  },
  {
    title: "Availability",
  },
  {
    title: "Treatments & Appointments",
  },
  // {
  //   title: "Manage Staff",
  // },
];
function Onboarding({ loadClinic, openNewPatientDailog }) {
  const clinic = useSelector(clinicSelector);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const page = searchParams.get("page");
  const [currentStep, setCurrentStep] = useState(0);
  const [appointmentTypes, setAppointmentTypes] = useState({});
  const [productOrServiceTypes, setProductOrServiceTypes] = useState({});
  const [shouldShowSaveAndNext, setShouldShowSaveAndNext] = useState(false);
  const [fromBackBtn, setFromBackBtn] = useState(false);
  const [onBoardClinic, setOnBoardClinic] = useState(null);
  const history = useHistory();

  const [isSpinningAvailability, setIsSpinningAvailability] = useState(false);
  const [prevUserAvailabilityForm, setPrevUserAvailabilityForm] = useState(null);
  const [resetClinicAvailabilityForm, setResetClinicAvailabilityForm] = useState(false);
  const [clinicAvailability, setClinicAvailability] = useState(false);
  const [clinicStaff, setClinicStaff] = useState([]);
  const [cliniciansForm] = Form.useForm();

  const [clinicDetails, setClinicDetails] = useState({
    clinicName: "",
    adminName: "",
    phone: "",
    email: "",
    businessRegistrationNo: "",
    bankAccountNo: "",
    address: "",
    city: "",
    county: "",
    postalCode: "",
    country: "",
    prefix: "",
    supportSamePhone: true,
    supportSameEmail: true,
    supportPhone: "",
    supportEmail: "",
  });

  const [userWeeklyAvailability, setUserWeeklyAvailability] = useState([]);
  const [timezone, setTimezone] = useState("");

  useEffect(() => {
    if (clinic?.clinicId) {
      onLoadClinic();
    }
  }, [clinic]);

  const onLoadClinic = async () => {
    await fetchClinicDetails();
  };

  useEffect(() => {
    if (page && clinic?.clinicId) {
      getClinicPreviousData();
    }
  }, [page, clinic]);

  const getClinicPreviousData = async () => {
    // setIsSpinningAvailability(true);
    await fetchClinicDetails();
    if (page === "availability") {
      // setIsSpinningAvailability(false);
      setCurrentStep(1);
    }
    if (page === "services") {
      setCurrentStep(2);
    }
    if (page === "staff") {
      setCurrentStep(3);
    }
    if (page === "lab") {
      setCurrentStep(4);
    }
  };

  useEffect(() => {
    if (clinic?.clinicEmail) {
      // getInitialData();
      setClinicStaff(clinic?.clinicians);
    }

  }, [clinic]);

  const getInitialData = async () => {
    // await getOnboardingData();
  };

  // const getOnboardingData = async () => {
  //   try {
  //     const { data } = await getSingleOnBoardClinic(clinic?.clinicEmail);
  //     if (data) {
  //       setOnBoardClinic(data);
  //     }
  //   } catch (error) {
  //     console.log("error: getSingleOnBoardClinic", error.response);
  //   }
  // };
  // useEffect(() => {
  //   if (onBoardClinic) {
  //     setClinicDetailsObject(onBoardClinic);
  //   }
  // }, [onBoardClinic]);

  // const setClinicDetailsObject = (data) => {
  //   setClinicDetails((prev) => ({
  //     ...prev,
  //     clinicName: data?.clinicName || "",
  //     country: data?.country || "",
  //   }));
  // };

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [shouldAvailabilitySubmit, setShouldAvailabilitySubmit] = useState(false);
  const [canAvailabilitySubmit, setCanAvailabilitySubmit] = useState(false);

  const [shouldProductsAndServicesSubmit, setShouldProductsAndServicesSubmit] = useState(false);
  const [shouldManageStaffSubmit, setShouldManageStaffSubmit] = useState(false);

  const [clinicOffDays, setClinicOffDays] = useState([]);
  const [clinicWorkingDaysRange, setClinicWorkingDaysRange] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [clinicWorkingDays, setClinicWorkingDays] = useState([]);
  const [clinicWorkingHours, setClinicWorkingHours] = useState([]);
  const [clinicBreakHours, setClinicBreakHours] = useState([]);

  const [fileList, setFileList] = useState([]);

  const [isMultiStepButtonDisabled, setIsMultiStepButtonDisabled] = useState(false);

  const [isButtonSpinning, setIsButtonSpinning] = useState(false);

  const [appointmentTypeOptions, setAppointmentTypeOptions] = useState([]);
  const [appointmentNameOptions, setAppointmentNameOptions] = useState([]);

  const [appointmentDurationOptions, setAppointmentDurationOptions] = useState([]);

  const [appointmentCurrencyOptions, setAppointmentCurrencyOptions] = useState([]);

  const [productOrServiceTypeOptions, setProductOrServiceTypeOptions] = useState([]);
  const [productOrServiceNameOptions, setProductOrServiceNameOptions] = useState([]);

  const [productOrServiceCurrencyOptions, setProductOrServiceCurrencyOptions] = useState([]);

  const [locationSearchResults, setLocationSearchResults] = useState([]);

  const onProfileFormSubmit = async (clinicDetails) => {
    const currentValues = cliniciansForm.getFieldsValue();

    setClinicDetails(clinicDetails);
    // setClinicStaff(clinicDetails?.clinicians)
    setIsButtonSpinning(true);

    const handleAddress = (addressDetail) => {
      try {
        const address = JSON.parse(addressDetail).description;
        return address;
      } catch (error) {
        return addressDetail;
      }
    };

    const model = {
      regno: clinicDetails.businessRegistrationNo,
      email: clinicDetails.email,
    };

    try {
      const res = await dentistClinicApiTasks.post("clinic/addRegNoBankAcc", model);

      setIsButtonSpinning(false);

      next();
    } catch (error) {
      errorModal(error.response.data.err);

      console.log(error);
      setIsButtonSpinning(false);
    }
  };
  const parseHolidaysForView = (holidays) => {
    return holidays.map((holiday) => {
      return moment(holiday.holiday).format("YYYY-MM-DD");
    });
  };
  const parseWorkingDaysRangeForView = (workingDaysRange) => {
    if (workingDaysRange) {
      const clinicEndDate = moment(workingDaysRange.clinic_end_date);
      if (clinicEndDate.year() === 1899) {
        return [
          moment().format("YYYY-MM-DD"),
          moment("2099-01-01T00:00:00.000Z").format("YYYY-MM-DD"),
        ];
      } else {
        return [
          moment(workingDaysRange.clinic_start_date),
          moment(workingDaysRange.clinic_end_date),
        ];
      }
    }
  };

  const parseWorkDaysForView = (workDays) => {
    return workDays.map((workDay) => {
      return workDay?.workday_number;
    });
  };

  const parseBreakAndWorkingHoursForView = (clinicTiming, type) => {
    const dummyDate = "2017-03-13";
    if (type === "working") {
      return [
        dayjs(dummyDate + " " + clinicTiming.clinic_start_time),
        dayjs(dummyDate + " " + clinicTiming.clinic_end_time),
      ];
    } else if (type === "break") {
      if (
        clinicTiming.clinic_break_start_time === "00:00:00" &&
        clinicTiming.clinic_break_end_time === "00:00:00"
      ) {
        return [];
      }
      return [
        moment(dummyDate + " " + clinicTiming.clinic_break_start_time),
        moment(dummyDate + " " + clinicTiming.clinic_break_end_time),
      ];
    }
  };

  const calculateTotalPrice = (price, discount) => {
    var numVal1 = Number(price);
    var numVal2 = Number(discount ? discount : 0);

    const totalValue = numVal1 * ((100 - numVal2) / 100);
    return Math.ceil(totalValue);
  };
  const calculatePercentage = (price, value) => {
    const total = price - value;
    const diffPercentage = (total / price) * 100;
    const discount = Number(100 - diffPercentage).toFixed(1);
    return discount;
  };

  const parseFiles = (files) => {
    return files.map((file) => {
      return {
        name: file.resource_title !== null ? file.title : file.file_name,
        status: "done",
        type: file.file_type,
        uid: uuidv4(),
        url: file.resource_url,
        originName: file.file_name,
      };
    });
  };
  const parseServicesForView = (services) => {
    let servicesObj = {};
    services.map((service) => {
      servicesObj[service.product_id] = {
        // productOrServiceType: service.product_type,
        productOrServiceType: JSON.stringify({
          product_type_id: service.product_type_id,
          product_type: service.product_type,
        }),

        // productOrServiceName: service.product_name,
        productOrServiceName: JSON.stringify({
          product_name_id: service.product_name_id,
          product_name: service.product_name,
        }),

        description: service.description,
        price: service.price,
        total: calculateTotalPrice(service.price, service.discountAmount),
        // discount:service.discount?service.discount:0,
        discount:
          service.discount && service.price
            ? calculatePercentage(service.price, service.discount)
            : 0,
        discountAmount: service.discount ? service.discount : 0,
        // currency: service.currency_name,
        currency: JSON.stringify({
          currency_id: service.product_currency_id,
          currency_name: service.currency_name,
        }),
        paymentIds: service.paymentIds,
        id: service.product_id,
        free: service.free ? true : false,
        hidePatientPrice: service.hidePatientPrice ? true : false,
      };
    });
    return servicesObj;
  };

  const fetchClinicDetails = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getProfileAndAvailability({ clinicId: clinic.clinicId });

        const holidays = res.body.holidays;
        const workingDays = res.body.workdays;
        const workingDaysRange = res.body.working_period[0];
        const breakHours = res.body.break_hours[0];
        const workingHours = res.body.working_hours[0];
        setClinicOffDays(parseHolidaysForView(holidays));
        setClinicWorkingDaysRange(parseWorkingDaysRangeForView(workingDaysRange));
        setClinicWorkingDays(parseWorkDaysForView(workingDays));
        setClinicBreakHours(parseBreakAndWorkingHoursForView(breakHours, "break"));
        setClinicWorkingHours(parseBreakAndWorkingHoursForView(workingHours, "working"));

        const servicesRes = await getClinicResources({
          clinicId: clinic.clinicId,
        });

        const appointmentTypes = servicesRes.body.clinic_appointments;
        const services = servicesRes.body.clinic_products;
        const resources = servicesRes.body.clinic_resources;
        if (appointmentTypes?.length) {
          setAppointmentTypes(parseAppointmentsForView(appointmentTypes));
        }

        setProductOrServiceTypes(parseServicesForView(services));
        setFileList(parseFiles(resources));

        setUserWeeklyAvailability(res?.body?.workingDays?.workingDays);
        setTimezone(res?.body?.profile?.timezone);
        setClinicAvailability(res?.body?.profile?.clinic_availability ? true : false);
        const { profile } = res.body;

        if (profile) {
          setClinicDetails({
            clinicName: profile.clinic_name,
            adminName: profile.clinic_admin,
            phone: profile.clinic_contact,
            email: profile.clinic_email,
            businessRegistrationNo: profile.clinic_regno,
            bankAccountNo: profile.clinic_bank_acc_no,
            address: "",
            city: "",
            county: "",
            postalCode: "",
            country: "",
            prefix: profile.country_code_name,
            supportPrefix: profile.supportPrefix,
            supportSamePhone: profile.supportSamePhone,
            supportSameEmail: profile.supportSameEmail,
            supportPhone: profile.supportNumber,
            supportEmail: profile.supportEmail,
            multipleAddresses: profile?.multipleAddresses || [],
            clinicians: res.body?.clinicians || [],
            consentDetails:res?.body?.consentDetails
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        return resolve();
      }
    });
  };

  const createWorkingDays = () => {
    return clinicWorkingDays.map((clinicWorkingDay) => {
      return [clinic.clinicId, clinicWorkingDay];
    });
  };

  const createHolidays = () => {
    return clinicOffDays.map((clinicOffDay) => {
      return [clinic.clinicId, clinicOffDay];
    });
  };

  const parseAppointmentsForView = (appointmentTypes) => {
    let appointmentTyepObject = {};
    appointmentTypes.map((appointmentType) => {
      // console.log("parseAppointmentsForView", appointmentType)
      appointmentTyepObject[appointmentType.appointment_id] = {
        appointmentType: JSON.stringify({
          clinic_appointment_type_id: appointmentType.appointment_type_id,
          clinic_appointment_type_name: appointmentType.appointment_type,
        }),
        // appointmentName: appointmentType.appointment_name,
        appointmentName: JSON.stringify({
          clinic_appointment_type_name_id: appointmentType.appointment_name_id,
          clinic_appointment_name: appointmentType.appointment_name,
        }),

        // durationOfMeeting: appointmentType.clinic_meeting_duration,
        durationOfMeeting: JSON.stringify({
          clinic_meeting_duration_id: appointmentType.slot_duration_id,
          clinic_meeting_duration: appointmentType.clinic_meeting_duration,
        }),

        description: appointmentType.description,
        price: appointmentType.price,
        // appointmentCurrency: appointmentType.currency_name,
        appointmentCurrency: JSON.stringify({
          currency_id: appointmentType.slot_currency_id,
          currency_name: appointmentType.currency_name,
        }),
        id: appointmentType.appointment_id,
        paymentIds: appointmentType.paymentIds,
        free: appointmentType.free,
      };
    });
    return appointmentTyepObject;
  };

  const onAvailabilitySubmit = async (values) => {
    let postClinicAvailability = {
      email: clinic.clinicEmail,
      clinicId: clinic.clinicId,
      clinicAvailability,
    };
    if (clinicAvailability) {
      postClinicAvailability = {
        ...postClinicAvailability,
        startDate: moment("2022-01-01T00:00:00.000Z").format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment("2099-01-01T00:00:00.000Z").format("YYYY-MM-DD HH:mm:ss"),
        startTime: clinicWorkingHours[0]?.format("HH:mm:ss") || "",
        endTime: clinicWorkingHours[1]?.format("HH:mm:ss") || "",
        breakTimeStart: clinicBreakHours[0]?.format("HH:mm:ss") || "",
        breakTimeEnd: clinicBreakHours[1]?.format("HH:mm:ss") || "",
      };

      if (values) {
        postClinicAvailability.workingDays = values;
        if (values?.timeZone) {
          postClinicAvailability.timezone = values.timeZone;
        }
      }
    }

    try {
      setIsButtonSpinning(true);

      const holidays = createHolidays();
      const postHolidays = { dates_array: holidays };

      const workingDays = createWorkingDays();
      const postWorkingDays = { workdays: workingDays };

      if (clinicAvailability) {
        const apiArray = [
          addClinicAvailability(postClinicAvailability),
          dentistClinicApiTasks.post(
            "clinic/AddHoliday?clinic_id=" + clinic.clinicId,
            postHolidays
          ),
          dentistClinicApiTasks.post("clinic/AddWorkday", postWorkingDays),
        ];
        await Promise.all(apiArray);
      } else {
        await addClinicAvailability(postClinicAvailability);
      }

      next();
    } catch (error) {
      errorModal(error?.response?.data?.err);
      console.log(error);
    } finally {
      setIsButtonSpinning(false);
    }
  };

  const onProductsAndServicesSubmit = async () => {
    setIsButtonSpinning(true);
    const appointments = Object.values(appointmentTypes).map((appointment) => {
      return {
        clinicId: clinic.clinicId,
        appointmentType: JSON.parse(appointment.appointmentType).clinic_appointment_type_name,
        appointmentName: JSON.parse(appointment.appointmentName).clinic_appointment_name,
        appointmentDuration: JSON.parse(appointment.durationOfMeeting).clinic_meeting_duration,
        appointmentDescription: appointment.description,
        appointmentPrice: appointment.price,
        appointmentTypeId: JSON.parse(appointment.appointmentType).clinic_appointment_type_id,
        appointmentNameId: JSON.parse(appointment.appointmentName).clinic_appointment_type_name_id,
        appointmentDurationId: JSON.parse(appointment.durationOfMeeting).clinic_meeting_duration_id,
        appointmentCurrencyId: JSON.parse(appointment.appointmentCurrency).currency_id,
        paymentIds: appointment?.paymentIds || [],
        free: appointment.free ? true : false,
      };
    });
    const productsAndServices = Object.values(productOrServiceTypes).map((productOrService) => {
      return {
        clinicId: clinic.clinicId,
        productType: JSON.parse(productOrService.productOrServiceType).product_type,
        productTypeId: JSON.parse(productOrService.productOrServiceType).product_type_id,
        productName: JSON.parse(productOrService.productOrServiceName).product_name,
        productNameId: JSON.parse(productOrService.productOrServiceName).product_name_id,
        productDescription: productOrService.description,
        productPrice: productOrService.price,
        productCurrencyId: JSON.parse(productOrService.currency).currency_id,
        paymentIds: productOrService?.paymentIds || [],
        discount: productOrService?.discount || 0,
        free: productOrService.free ? true : false,
        hidePatientPrice: productOrService?.hidePatientPrice ? true : false,
      };
    });
    const addAppointmentsAndProducts = {
      prodDetails: productsAndServices,
      appointmentDetails: appointments,
    };
    const hasDuplicates = (values) =>
      new Set(
        values.map(({ appointmentNameId, appointmentTypeId }) =>
          JSON.stringify([appointmentNameId, appointmentTypeId])
        )
      ).size < values.length;
    if (hasDuplicates(appointments)) {
      message.error("Appoinment is already exist!");
      setIsButtonSpinning(false);
      return false;
    }
    if (
      !appointments.every(
        (i) =>
          i.appointmentDescription && (i.appointmentPrice || i.free || i.appointmentPrice === 0)
      )
    ) {
      message.error("All fields are required");
      setIsButtonSpinning(false);
      return false;
    }
    if (
      !productsAndServices.every(
        (i) => i.productDescription && (i.productPrice || i.free || i.productPrice === 0)
      )
    ) {
      message.error("All fields are required");
      setIsButtonSpinning(false);
      return false;
    }

    try {
      await addClinicAppointmentProducts(addAppointmentsAndProducts);

      setIsButtonSpinning(false);

      // next();
    } catch (error) {
      // errorModal(error.response.data?.err?.message);
      if (error.response.data?.err?.msg) {
        errorModal(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }

      setIsButtonSpinning(false);
    }
  };

  const submitAvailabilityOff = async () => {
    const postClinicAvailability = {
      email: clinic.clinicEmail,
      clinicId: clinic.clinicId,
      clinicAvailability,
    };

    try {
      setIsButtonSpinning(true);
      await addClinicAvailability(postClinicAvailability);
      next();
    } catch (error) {
      console.log("submitAvailabilityOff", error);
    } finally {
      setIsButtonSpinning(false);
    }
  };

  const onNext = () => {
    if (currentStep == 0) {

      setShouldSubmit(true);
    } else if (currentStep === 1) {
      if (clinicAvailability) {
        setShouldAvailabilitySubmit(true);
      } else {
        submitAvailabilityOff();
      }
    } else if (currentStep === 2) {
      onProductsAndServicesSubmit();
    } else {
      if (shouldManageStaffSubmit) {
        next();
      } else {
        message.info("Add Atleast One Clinician");
      }
    }
  };

  const next = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    changeOnboardingStepLocation(nextStep)
  };

  const changeOnboardingStepLocation = (step) => {
    let newLocation = '#/onboarding';
    if (step == 1) {
      newLocation = '#/onboarding?page=availability'
    }
    if (step == 2) {
      newLocation = '#/onboarding?page=services'
    }
    window.location = newLocation;
    // window.location = '#/onboarding?page=availability'
  }

  const resetClinicAvaibility = () => {
    setClinicWorkingDaysRange(parseWorkingDaysRangeForView(clinicWorkingDaysRange));
    setClinicOffDays(parseHolidaysForView(clinicOffDays));
    setClinicWorkingDays(parseWorkDaysForView(clinicWorkingDays));
    // setClinicBreakHours(
    //   parseBreakAndWorkingHoursForView(breakHours, "break")
    // );
    // setClinicWorkingHours(
    //   parseBreakAndWorkingHoursForView(workingHours, "working")
    // );

    setResetClinicAvailabilityForm(true);
    setClinicOffDays(clinicOffDays);
  };

  const prev = () => {
    const prevStep = currentStep - 1;
   
    setFromBackBtn(true);
    setCurrentStep(prevStep);

    if (prevStep === 1) {
      resetClinicAvaibility();
    }
    changeOnboardingStepLocation(prevStep)

    // if(currentStep-1==0){
    //   setClinicDetailsObject()
    // }
  };

  async function isOnboarded() {
    try {
      const res = await dentistClinicApiTasks.post("clinic/edit/onboarded", {
        clinic_id: clinic?.clinicId,
        status: "COMPLETED",
      });
      await updateClinicOnboardingProgressStatus({
        email: clinic?.clinicEmail,
        status: ONBOARDING_PROGRESS.ONBOARDING_COMPLETED,
      });
      // loadClinic();
    } catch (error) {
      console.log(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  }

  const getShowBRN = (status) => {
    setShouldShowSaveAndNext(status);
  };
  const renderSwitch = () => {
    switch (currentStep) {
      case 0:
        return (
          <div>
            <AccountSettingsFormComponent
              onBoardClinic={onBoardClinic}
              onSubmit={onProfileFormSubmit}
              clinicDetails={clinicDetails}
              next={next}
              shouldSubmit={shouldSubmit}
              setShouldSubmit={setShouldSubmit}
              canSubmit={canAvailabilitySubmit}
              locationSearchResults={locationSearchResults}
              setLocationSearchResults={setLocationSearchResults}
              getShowBRN={getShowBRN}
              forPage="onBoarding"
              fromBackBtn={fromBackBtn}
              clinicStaff={clinicStaff}
              setClinicStaff={setClinicStaff}
              fetchClinicDetails={fetchClinicDetails}
            ></AccountSettingsFormComponent>
            {/* <ClinicianForm clinicStaff={clinicStaff} cliniciansForm={cliniciansForm}/> */}
          </div>
        );
      case 1:
        return (
          <div>
            <AvailabilitySettingsComponent
              resetClinicAvailabilityForm={resetClinicAvailabilityForm}
              prevUserAvailabilityForm={prevUserAvailabilityForm}
              setOffDays={setClinicOffDays}
              offDays={clinicOffDays}
              workingDaysRange={clinicWorkingDaysRange}
              setWorkingDaysRange={setClinicWorkingDaysRange}
              workingDays={clinicWorkingDays}
              setWorkingDays={setClinicWorkingDays}
              workingHours={clinicWorkingHours}
              setWorkingHours={setClinicWorkingHours}
              breakHours={clinicBreakHours}
              setBreakHours={setClinicBreakHours}
              shouldSubmit={shouldAvailabilitySubmit}
              setShouldSubmit={setShouldAvailabilitySubmit}
              next={next}
              onSubmit={onAvailabilitySubmit}
              isSpinningAvailability={isSpinningAvailability}
              userWeeklyAvailability={userWeeklyAvailability}
              timezone={timezone}
              setPrevUserAvailabilityForm={setPrevUserAvailabilityForm}
              setClinicDetails={setClinicDetails}
              clinicAvailability={clinicAvailability}
              setClinicAvailability={setClinicAvailability}
              clinicDetails={clinicDetails}
              type="onboarding"
            />
          </div>
        );

      case 2:
        return (
          <ServicesAndResourcesComponent
            onSubmit={onProductsAndServicesSubmit}
            shouldSubmit={shouldProductsAndServicesSubmit}
            setFileList={setFileList}
            appointmentTypes={appointmentTypes}
            setAppointmentTypes={setAppointmentTypes}
            productOrServiceTypes={productOrServiceTypes}
            setProductOrServiceTypes={setProductOrServiceTypes}
            setIsMultiStepButtonDisabled={setIsMultiStepButtonDisabled}
            fileList={fileList}
            appointmentTypeOptions={appointmentTypeOptions}
            setAppointmentTypeOptions={setAppointmentTypeOptions}
            appointmentNameOptions={appointmentNameOptions}
            setAppointmentNameOptions={setAppointmentNameOptions}
            appointmentDurationOptions={appointmentDurationOptions}
            setAppointmentDurationOptions={setAppointmentDurationOptions}
            appointmentCurrencyOptions={appointmentCurrencyOptions}
            setAppointmentCurrencyOptions={setAppointmentCurrencyOptions}
            productOrServiceTypeOptions={productOrServiceTypeOptions}
            setProductOrServiceTypeOptions={setProductOrServiceTypeOptions}
            productOrServiceNameOptions={productOrServiceNameOptions}
            setProductOrServiceNameOptions={setProductOrServiceNameOptions}
            productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
            setProductOrServiceCurrencyOptions={setProductOrServiceCurrencyOptions}
            clinicAvailability={clinicAvailability}
            setClinicAvailability={setClinicAvailability}
          ></ServicesAndResourcesComponent>
        );
      // case 3:
      //   return (
      //     <div>
      //       <ManageStaff shoudSubmit={setShouldManageStaffSubmit} />
      //     </div>
      //   );
      default:
        return <p>Services</p>;
    }
  };

  const onFinishSetupClick = async () => {
    await onProductsAndServicesSubmit();
    await isOnboarded();
    message.success("Clinic onboarded succesfully!");
   
    loadClinic();
    if(clinic?.firstTimeOnboard){
      if(clinic?.firstTimeOnboardStep ==="ADD_PATIENT"){
        openNewPatientDailog(true)
      }
    }
    return history.push("/clinic/profile");
  }

  return (
    <>
      <div className="onboarding-container">
        <Row justify="start" className="onboarding-title">
          <Title
            level={3}
            style={{
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            Profile & Settings
          </Title>{" "}
        </Row>

        <div>
          <StepsComponent steps={steps} current={currentStep}></StepsComponent>
        </div>

        {renderSwitch()}
      </div>
      <div className="onboarding-footer">
        {currentStep < steps.length - 1 &&
          (currentStep === 0 ? (
            <MultiStepButton
              loading={isButtonSpinning}
              align={"right"}
              type={"primary"}
              onClick={() => onNext()}
              disabled={isMultiStepButtonDisabled}
            >
              Save & Next
            </MultiStepButton>
          ) : (
            <MultiStepButton
              loading={isButtonSpinning}
              align={"right"}
              type={"primary"}
              onClick={() => onNext()}
              disabled={isMultiStepButtonDisabled}
            >
              {clinicAvailability ? "Save & Next" : "Skip"}
            </MultiStepButton>
          ))}
        {currentStep === steps.length - 1 && (
          <MultiStepButton
            onClick={onFinishSetupClick}
            align={"right"}
            type={"primary"}
          >
            Finish Setup
          </MultiStepButton>
        )}
        {/* {currentStep === steps.length - 1 && (
          <MultiStepButton
            onClick={() => {
              message.success("Clinic onboarded succesfully!");
              history.push("/clinic/profile");
              loadClinic();
            }}
            align={"right"}
          >
            Skip
          </MultiStepButton>
        )} */}
        {currentStep > 0 && (
          <MultiStepButton align={"left"} onClick={() => prev()}>
            Back
          </MultiStepButton>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps, { loadClinic,openNewPatientDailog })(Onboarding);
