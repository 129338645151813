import { Row, Col, Switch, Button, Input, Form, Divider, message } from "antd";
import { isValidURL } from "utilities/commonMethod";
import "./addForm.scss";

const AddPaymentForm = ({
  styles,
  onCancel,
  saveDataApi,
  loading,
  formHook,
  formName,
}) => {
  const urlValidator = (_, value) => {
    if (!value.trim()) {
      return Promise.reject("Please enter a URL.");
    }
    if (!isValidURL(value)) {
      return Promise.reject("Please enter a valid URL.");
    }
    return Promise.resolve();
  };

  const onFinish = async (formValues) => {
    console.log("Success:", formValues);
    try {
      const payload = {
        buttonText: formValues.buttonText,
        url: formValues.url,
        isActive: formValues.active,
        default: formValues.default,
      };
      const data = await saveDataApi(payload);
      message.success("Payment method saved successfully");
    } catch (error) {
      console.log(error);
    } finally {
      formHook.resetFields();
    }
  };

  return (
    <Row>
      <Col md={5} pull={0}>
        <div className={styles["heading-text"]}>Payment Type:</div>

        <div style={{ marginTop: "3px" }} className={styles["heading-text"]}>
          Payment Url:
        </div>

        <div style={{ marginTop: "12px" }} className={styles["heading-text"]}>
          Status:
        </div>
      </Col>
      <Col md={19}>
        <div className="add-payment-form-wrapper">
          <Form
            autoComplete="new-password"
            initialValues={{
              url: "",
              buttonText: "",
              active: true,
              default: false,
            }}
            onFinish={onFinish}
            name={formName ? formName : "addpayment-form"}
            form={formHook}
            layout="vertical"
          >
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "2fr 3fr",
                gap: "10px",
              }}
            >
              <Form.Item
                style={{ marginBotton: "0" }}
                rules={[
                  {
                    required: true,
                    message: "Button name required",
                    whitespace: true,
                  },
                ]}
                name="buttonText"
                label="Payment Button Text"
              >
                <Input placeholder="button text" />
              </Form.Item>

              <Form.Item
                rules={[{ validator: urlValidator }]}
                name="url"
                label="Payment URL"
              >
                <Input placeholder="http://www.example.com" />
              </Form.Item>
            </div>
            <div className={styles["default-btn"]}>
              <Form.Item valuePropName="checked" size="small" name="active">
                <Switch size="small" />
              </Form.Item>
              <span className={styles["btn-name"]}>Active</span>
            </div>
            <div className={styles["default-btn"]}>
              <Form.Item valuePropName="checked" size="small" name="default">
                <Switch size="small" />
              </Form.Item>
              <span className={styles["btn-name"]}>
                Default For All Products & Services
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "12px",
              }}
            >
              <Button
                style={{ width: "100px", marginRight: "10px" }}
                shape="round"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Form.Item>
                <Button
                  loading={loading}
                  htmlType="submit"
                  style={{ width: "100px" }}
                  shape="round"
                  type="primary"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <Divider />
      </Col>
    </Row>
  );
};

export default AddPaymentForm;
