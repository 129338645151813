import dayjs from "dayjs";

export const getVideoButtonState = (date) => {
  if (!date) {
    return false;
  }
  const targetDate = dayjs(date);
  const currentDate = dayjs();
  const remainingHours = targetDate.diff(currentDate, "hours");
  if (remainingHours >= 24) {
    return true;
  }
  return false;
};

export const validateWorkHoursStart = (values) => {
  for (const day of values?.weekTimings) {
    if (day.workDay) {
      for (const workHour of day?.workHours) {
        if (!workHour.start || workHour.start.length === 0) {
          return false;
        }
      }
    }
  }
  return true;
};

export const validateWorDay = (values) => {
  return values.weekTimings.some(day => day.workDay);
};

