import { Alert } from "antd";
import styles from "./alert.module.scss";
// import BulbIconComponent from "../Icon/BulbIcon";
import { ReactComponent as ToolTipIcon }  from "assets/images/ToolTipIcon.svg";

const AppAlert = ({ message, icon }) => {
  return (
    <Alert
      className={styles["alert-container"]}
      message={message}
      type="info"
      showIcon
      icon={icon ? icon : <ToolTipIcon/>}
    />
  );
};

export default AppAlert;
