import { useState, useEffect, useRef } from "react";
import { Form, Modal, Button, message, Typography, Spin } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import AddResourceForm from "../ResourceForm/ResourceForm";
import { dentistClinicApiTasks } from "models/dentistClinic";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
// import { saveResource } from "api/resource";

const { Title } = Typography;
const { confirm } = Modal;

const AddResourceModal = ({
  showAddResourceModal,
  setShowAddResourceModal,
  lab,
  clinicResources,
  setClinicResources,
  clinic,
}) => {
  // console.log("clinicResources", clinicResources);
  const [addResourceForm] = Form.useForm();
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resourceFileList, setResourceFileList] = useState([]);

  const uploadResourceFile = async (values, options = {}) => {
    setLoading(true);
    const file = resourceFileList[0];

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "application/pdf",
      },
    };
    fmData.append("file", file);

    try {
      const preSignRequestBody = {
        clinic_id: clinic.clinicId,
        file_name: file.name,
        resource_type: "file",
        title: values.title,
        file_type: "pdf",
      };

      const preSignedResponse = await dentistClinicApiTasks.post(
        "clinic/resource/add",
        preSignRequestBody
      );

      const accessUrl = preSignedResponse.body.success.accessUrl;
      const uploadUrl = preSignedResponse.body.success.uploadUrl;
      const url = preSignedResponse.body.success.url;
      const uploadResponse = await axios.put(uploadUrl, file, config);
      const successFullUpload = await dentistClinicApiTasks.post(
        "resource/upload/confirm",
        {
          clinic_id: clinic.clinicId,
          file_url: accessUrl,
          file_name: file.name,
          file_type: "pdf",
          title: values.title,
        }
      );
      const resourceId = uuidv4();

      const newAdded = {
        name: { resourceName: values.title, resourceId: resourceId },
        shareList: {
          clinic: "Me",
          resourceId: resourceId,
        },
        description: file.name,
        category: "file",
        uploaded: dayjs().format("DD-MMM-YYYY"),
        files: [
          {
            url: url,
            name: file.name,
            mimeType: "pdf",
          },
        ],
        id: { id: resourceId, name: file.name },
        videoLinks: [],
      };
      const prevResources = [...clinicResources];
      prevResources.unshift(newAdded);
      setClinicResources(prevResources);
      message.success("Resource added successfully");
    } catch (error) {
      console.log(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setLoading(false);
      setShowAddResourceModal(false);
    }
  };

  const uploadLinkResource = async (values) => {
    const linkPreSignRequestBody = {
      clinic_id: clinic.clinicId,
      file_name: values.url,
      resource_type: "url",
      file_type: "url",
      title: values.title,
    };

    try {
      setLoading(true);
      const linkPreSignedResponse = await dentistClinicApiTasks.post(
        "clinic/resource/add",
        linkPreSignRequestBody
      );
      const resourceId = uuidv4();

      const newAdded = {
        name: { resourceName: values.title, resourceId: resourceId },
        shareList: {
          clinic: "Me",
          resourceId: resourceId,
        },
        category: "url",
        uploaded: dayjs().format("DD-MMM-YYYY"),
        files: [],
        id: { id: resourceId, name: values.url },
        videoLinks: [{ url: values.url, name: values.url }],
      };
      const prevResources = [...clinicResources];
      prevResources.unshift(newAdded);
      setClinicResources(prevResources);
      message.success("Resource added successfully");
    } catch (error) {
      console.error(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setLoading(false);
      setShowAddResourceModal(false);
    }
  };
  const onResourceSubmit = async (values) => {
    if (!values.url && !resourceFileList.length) {
      return message.error("Please add atleast one resource");
    }
    if (values.url) {
      uploadLinkResource(values);
    } else {
      uploadResourceFile(values);
    }
  };

  const handleSubmit = () => {
    addResourceForm.submit();
  };

  const handleAddResourceCancel = () => {
    if (touched) {
      showConfirm();
    } else {
      setShowAddResourceModal(false);
      setResourceFileList([]);
    }
  };

  const showConfirm = () => {
    confirm({
      title: "Close add resource form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
        setShowAddResourceModal(false);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  return (
    <div>
      <Modal
        className="addResourceModal"
        title={
          <div className="titleSpan">
            <Title level={5} style={{ margin: "0px" }}>
              Add Patient Guide
            </Title>
          </div>
        }
        width={800}
        visible={showAddResourceModal}
        onCancel={handleAddResourceCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleAddResourceCancel}
            shape="round"
            style={{ width: "7rem", borderRadius: "30px" }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={handleSubmit}
            loading={loading}
            style={{ width: "7rem", borderRadius: "30px" }}
          >
            Save
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <AddResourceForm
            addResourceForm={addResourceForm}
            onResourceSubmit={onResourceSubmit}
            touched={touched}
            setTouched={setTouched}
            resourceFileList={resourceFileList}
            setResourceFileList={setResourceFileList}
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default AddResourceModal;
