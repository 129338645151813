import { useEffect } from "react";
import {
  Dropdown,
  Menu,
  Select,
  Form,
  Button,
  Input,
  Tooltip,
  message,
} from "antd";
import { removeUnderscore } from "../data";
import GenericButton from "components/shared/buttons/genericButton";
import { companyType, primaryColor } from "colors-config";
import AppButton from "components/shared/AppButton/AppButton";

const { Option } = Select;
const { TextArea } = Input;

const Teeths = ({
  teethStructure,
  toothStatuses,
  setTeethStructure,
  setToothStatuses,
  chartValue,
  disabled,
  teethChartDraftRef,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    //  console.log("toothStatuses", toothStatuses);
    const structure = [...teethStructure];
    const updatedStructure = structure.map((Item) => {
      const matchingTooth = toothStatuses.find(
        (tooth) => tooth.toothNo  == Item.name
      );
      return {
        ...Item,
        selected: matchingTooth ? true : false,
        previous: matchingTooth ? true : false,
        procedure: matchingTooth ? matchingTooth?.procedure : "",
        detail: matchingTooth ? matchingTooth?.detail : "",
        addOns: matchingTooth ? matchingTooth?.addOns: []
      };
    });
    // console.log("updatedStructure", updatedStructure);
    setTeethStructure(updatedStructure);
  }, [toothStatuses]);

  const handleSelectedTooth = (id) => {
    // console.log("selected", id);
    const filteredStatus = toothStatuses.filter((tooth) => tooth.toothNo == id);
    if (filteredStatus.length > 0) {
      message.info("Tooth is already selected add additional data");
    } else {
      form.setFieldsValue({
        toothNo: id,
      });
      const structure = [...teethStructure];
      const updatedTeths = structure.map((tooth) => {
        if (tooth.id === id) {
          return {
            ...tooth,
            selected: tooth.selected ? true : false,
            visible: !tooth.visible,
          };
        }
        return { ...tooth, visible: false };
      });
      setTeethStructure(updatedTeths);
    }
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    const filteredStatus = toothStatuses.filter(
      (tooth) => tooth.toothNo == values.toothNo
    );
    // console.log("filteredStatus", filteredStatus)
    if (filteredStatus.length > 0) {
      return message.info("Tooth is already selected add additional data");
    }

    const toothStatusObj = {
      toothNo: values.toothNo,
      detail: values.detail,
      procedure: values.procedure,
      addOns: [],
      isExtra: false,
      isAdditional: false,
      previous: true,
    };
    // console.log("toothStatusObj", toothStatusObj)
    const newStatus = [...toothStatuses, toothStatusObj];
    setToothStatuses(newStatus);
    teethChartDraftRef.current = newStatus;
    const structure = [...teethStructure];
    // console.log("structure", structure);
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === Number(values.toothNo)) {
        return {
          ...tooth,
          selected: true,
          visible: false,
          detail: values.detail,
          procedure: values.procedure,
        };
      }
      return tooth;
    });
    setTeethStructure(updatedTeths);
    // handDropDown(false)
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleDetailCancel = (id) => {
    const structure = [...teethStructure];
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === id) {
        return {
          ...tooth,
          visible: false,
        };
      }
      return tooth;
    });
    setTeethStructure(updatedTeths);
    form.resetFields();
  };

  const handleAddTooth = () => {
    const toothStatusObj = {
      procedure: "tooth_status",
      detail: "",
      toothNo: null,
      isExtra: true,
      isAdditional: false,
      addOns: [],
    };
    const newStatus = [...toothStatuses, toothStatusObj];
    setToothStatuses(newStatus);
    teethChartDraftRef.current = newStatus;
  };

  const handDropDown = (visible) => {
    if (!visible) {
      const structure = [...teethStructure];
      const updatedTeths = structure.map((tooth) => {
        return {
          ...tooth,
          visible: false,
        };
      });
      setTeethStructure(updatedTeths);
      form.resetFields();
    }
  };

  return (
    <>
      <div className="teethRow-container">
        <div className="teethRow-bgOne" />
        <div className="teethRow-one">
          {teethStructure?.map((teeth, i) => {
            return (
              <Tooltip
                placement="topLeft"
                title={
                  <div>
                    {teeth.selected ? (
                      <div>
                        <p>{removeUnderscore(teeth?.procedure)}</p>
                        <p>{teeth.detail}</p>
                        {
                          teeth.addOns.length > 0 ? (
                            <div style={{maxHeight:"90px", overflowX:"hidden", overflowY:"auto", width:"140px", marginTop:"-15px"}}>
                        <p>Extras</p>
                        {
                          teeth?.addOns.map((addOn, i) =>{
                            return(
                              <div key={i} >
                              <p>{removeUnderscore(addOn?.procedure)}</p>
                              <p>{addOn?.detail}</p>
                              </div>
                  
                            )
                          })
                        }
                        </div>
                          ) : null
                        }
                    
                    
                  
                      </div>
                    ) : (
                      <div>
                        <p>
                          {`Tooth No: ${
                            chartValue === "fdi"
                              ? teeth.showName
                              : chartValue === "universal"
                              ? teeth.universalName
                              : teeth.palmerName
                          }`}
                        </p>
                      </div>
                    )}
                  </div>
                }
                key={i}
              >
                <Dropdown
                  visible={teeth.visible}
                  onVisibleChange={(visible) => handDropDown(visible)}
                  overlay={
                    <Menu>
                      <Menu.Item key="tooth">
                        <Form
                          form={form}
                          name={teeth.name}
                          initialValues={{}}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          className="statusForm"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Form.Item
                              label=""
                              name="toothNo"
                              style={{ visibility: "hidden" }}
                            >
                              <Input disabled />
                            </Form.Item>
                            <p className="tootName" style={{backgroundColor:primaryColor}}>
                              {chartValue === "fdi"
                                ? teeth.showName
                                : chartValue === "universal"
                                ? teeth.universalName
                                : teeth.palmerName}
                            </p>
                          </div>

                          <Form.Item
                            label=""
                            name="procedure"
                            rules={[
                              {
                                required: true,
                                message: "Please select procedure!",
                              },
                            ]}
                          >
                            <Select placeholder="Select Status" size="large">
                              <Option value="tooth_status">Tooth Status</Option>
                              <Option value="missing">Missing</Option>
                              <Option value="extraction_planned">
                                Extraction Planned
                              </Option>
                              <Option value="crown">Crown</Option>
                              <Option value="veneer">Veneer</Option>
                              <Option value="Inlay_Onlay">Inlay / Onlay</Option>
                              <Option value="bridge">Bridge</Option>
                              <Option value="implant">Implant</Option>
                              <Option value="deciduous">Deciduous</Option>
                              <Option value="impacted">Impacted</Option>
                              <Option value="other">Other</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label=""
                            name="detail"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please enter detail!",
                            //   },
                            // ]}
                          >
                            <TextArea placeholder="Detail" rows={3} />
                          </Form.Item>
                          <div className="statusForm-btns">
                            <Form.Item>
                              <Button
                                type="danger"
                                onClick={() => handleDetailCancel(teeth.id)}
                                className="cancel-btn"
                              >
                                Cancel
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="save-btn"
                              >
                                Save
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <div
                    className={`${teeth.selected
                      ? "teeth-structure active-teeth"
                      : "teeth-structure"} ${companyType === 5 ? "teeth-structure-smilealign" : ""} ${companyType === 6 ? "teeth-structure-ias" : ""} ${companyType === 7 ? "teeth-structure-aqua" : ""}`}
                    onClick={(e) =>
                      disabled ? null : handleSelectedTooth(teeth.id)
                    }
                  >
                    {teeth.content}
                    <span>
                      {chartValue === "fdi"
                        ? teeth.showName
                        : chartValue === "universal"
                        ? teeth.universalName
                        : teeth.palmerName}
                    </span>
                  </div>
                </Dropdown>
              </Tooltip>
            );
          })}
          <div className="tooth-sides">
            <h2>Right</h2>
            <h2>Left</h2>
          </div>
        </div>
        <div className="teethRow-bgTwo"></div>
      </div>

      <AppButton
         className="addTooth-btn"
        type="primary"
        onClick={handleAddTooth}
        disabled={disabled}
        shape="round"
      >
        Add Tooth
      </AppButton>
    </>
  );
};

export default Teeths;
