import { Modal, Input } from "antd";
const ModifyPlan = ({
  isModalModifyPlanVisible,
  handleModifyPlanCancel,
  modifyPlanOk,
  modifyPlanPopLoading,
  modifyComment,
  setModifyComment,
  setIsModifyPlanTouched,
}) => {
  return (
    <>
      {isModalModifyPlanVisible && (
        <Modal
          title={"Request Modification"}
          confirmLoading={modifyPlanPopLoading}
          okText={"Submit"}
          cancelText="Cancel"
          visible={isModalModifyPlanVisible}
          onOk={modifyPlanOk}
          onCancel={handleModifyPlanCancel}
          okButtonProps={{
            style: { width: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
        >
          <Input.TextArea
            value={modifyComment}
            onChange={(e) => {
              setModifyComment(e.target.value);
              setIsModifyPlanTouched(true);
            }}
            placeholder="Write Something…"
            style={{
              height: 160,
              resize: "none",
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ModifyPlan;
