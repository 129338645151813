import { companyType } from "colors-config";

const SmilePrescriptionTreatmentTypes = ({ prescriptionPDFObj, treatmentOptions }) => {

  const renderTreatmentName = () => {
    const findTreatment = treatmentOptions.find(obj => obj.id == prescriptionPDFObj.smilelignTreatmentTypeId);
    if (findTreatment) return findTreatment.treatmentName;
    return '';
  }

  return (
    <div className="prescriptionPDF-card">
      <h2>Treatment Type</h2>
      <div
        className="prescriptionPDF-SmileTreat"
        style={{ paddingLeft: "20px" }}
      >
        <div>
          <div className="border-right">
            <label className="treatment-label">Treatment Type</label>
            <p className="treatment-info">
              {" "}
              {(prescriptionPDFObj.smilelignTreatmentTypeId && treatmentOptions.length)
                ? renderTreatmentName()
                : "--"}
            </p>
          </div>
        </div>
        <div>
          <div className="border-right">
            <label className="treatment-label">Retainer Arches</label>
            <p className="treatment-info">
              {" "}
              {prescriptionPDFObj.smilelignTreatmentTypeId
                ? prescriptionPDFObj.smilelignArches
                : "--"}
            </p>
          </div>
        </div>
      </div>

      {companyType === 5 ?
        <div
          className="prescriptionPDF-SmileTreat"
          style={{ paddingLeft: "20px" }}
        >
          <div>
            <div className="border-right">
              <label className="treatment-label">Phasing</label>
              <p className="treatment-info">
                {" "}
                {prescriptionPDFObj.phasing ? prescriptionPDFObj.phasing : "--"}
              </p>
            </div>
          </div>
          <div>
            <div className="border-right">
              <label className="treatment-label">Phasing Details</label>
              <p className="treatment-info">
                {prescriptionPDFObj.prescriptionPhasing
                  ? prescriptionPDFObj.prescriptionPhasing
                  : "--"}
              </p>
            </div>
          </div>
        </div> : null
      }
    </div>
  );
};

export default SmilePrescriptionTreatmentTypes;
