import { Row, Col } from "antd";
import AttachmentSessionList from "./AttachmentSessionList/AttachmentSessionList";

const AttachmentInfo = ({
  alignersLabel,
  onAlignerLabelClicked,
  upperAttachments,
  lowerAttachments,
  showInfo = true
}) => {
  return (
    <div className="attachmentInfo-container">
      <Row align="top">
        <Col lg={10}>
          <div>
            <Row>
              <p className="patient-question" style={{ marginTop: "0px" }}>
                No. of Attachment Sessions
              </p>
            </Row>
            <Row>
              <p className="patient-response">{alignersLabel.length}</p>
            </Row>
          </div>
        </Col>
        <Col lg={14}>{
          showInfo && (
            <AttachmentSessionList
            alignersLabel={alignersLabel}
            onAlignerLabelClicked={onAlignerLabelClicked}
          />
          )
        }
       
        </Col>
      </Row>
      <p
        className="patient-question"
        style={{ marginTop: "0px", textAlign: "left" }}
      >
        Attachment Details
      </p>
    </div>
  );
};

export default AttachmentInfo;
