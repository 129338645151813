import { useState, useRef, useEffect } from "react";
import {
  Upload,
  message,
  Input,
  Button,
  Row,
  Typography,
  Col,
  Progress,
} from "antd";

import { connect } from "react-redux";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import axios from "axios";
import "../../../assets/styles/components/uploadFilesComponent.scss";
import { InfoIcon } from "components/shared/InfoIcon/index";
import {
  InboxOutlined,
  LinkOutlined,
  DeleteOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
const { Dragger } = Upload;

const { Title } = Typography;

const UploadFilesComponent = ({
  shouldSubmit,
  setFileList,
  clinic,
  setIsMultiStepButtonDisabled,
  fileList,
  mode,
}) => {
  // console.log("dataa---",fileList)
  const uploadEl = useRef(null);
  const [uploadFileList, setUploadFileList] = useState(fileList);
  const [uploadFileDataObject, setUploadFileDataObject] = useState({});
  const inputEl = useRef(null);
  const [linkValue, setLinkValue] = useState();
  const inputTitle = useRef(null);
  const [title, setTitle] = useState("");
  const [uploading, setUploading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [uploadedUrl, setUploadedUrl] = useState("");

  useEffect(() => {
    if (shouldSubmit && mode !== "view") {
      setFileList(uploadFileList);
    }
  }, [shouldSubmit]);

  async function uploadFiles(options, linkData = null) {
    if (
      typeof title === "undefined" ||
      title?.toString()?.trim().length === 0 ||
      title === ""
    ) {
      return;
    }

    setIsMultiStepButtonDisabled(true);

    if (linkData) {
      const linkPreSignRequestBody = {
        clinic_id: clinic.clinicId,
        file_name: linkData.url,
        resource_type: linkData.type,
        file_type: linkData.type,
        title: title,
      };

      try {
        const linkPreSignedResponse = await dentistClinicApiTasks.post(
          "clinic/resource/add",
          linkPreSignRequestBody
        );

        setUploadFileList([...uploadFileList, linkData]);

        setUploadFileDataObject({
          ...uploadFileDataObject,
          [linkData.uid]: { name: linkData.url, fileUrl: linkData.url },
        });
        setIsMultiStepButtonDisabled(false);
      } catch (error) {
        console.error(error);
        setIsMultiStepButtonDisabled(false);
        if (error.response.data?.err?.msg) {
          message.error(error.response.data.err.msg);
        } else {
          message.error("Something went wrong. Please try again.");
        }
      }
    } else {
      const { onSuccess, onError, file, onProgress } = options;
      setUploading(true);
      const fileNameArray = file.name.split(".");
      fileNameArray[0] = fileNameArray[0] + "-" + new Date().valueOf();
      const fileName = fileNameArray.join(".");

      const fmData = new FormData();
      const config = {
        headers: {
          "content-type": file.type,
        },

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          console.log(percent);
          setPercent(percent);
          if (percent === 100) {
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("file", file);

      try {
        const preSignRequestBody = {
          clinic_id: clinic.clinicId,
          file_name: fileName,
          resource_type: "file",
          title: title,
          file_type:
            file.type.split("/")[0] === "application"
              ? "pdf"
              : file.type.split("/")[1],
        };

        const preSignedResponse = await dentistClinicApiTasks.post(
          "clinic/resource/add",
          preSignRequestBody
        );

        const accessUrl = preSignedResponse.body.success.accessUrl;
        const uploadUrl = preSignedResponse.body.success.uploadUrl;
        const url = preSignedResponse.body.success.url;
        const uploadResponse = await axios.put(uploadUrl, file, config);
        const successFullUpload = await dentistClinicApiTasks.post(
          "resource/upload/confirm",
          {
            clinic_id: clinic.clinicId,
            file_url: accessUrl,
            file_name: fileName,
            file_type:
              file.type.split("/")[0] === "application" ? "pdf" : "image",
            title: title,
          }
        );

        setUploadedUrl(url);

        setUploadFileDataObject({
          ...uploadFileDataObject,
          [file.uid]: { name: fileName, fileUrl: accessUrl },
        });
        setUploading(false);
        setPercent(0)
        onSuccess("Ok");
        setTitle("");

        setIsMultiStepButtonDisabled(false);
      } catch (error) {
        console.log(error);

        onError({ error });
        setIsMultiStepButtonDisabled(false);
        if (error.response.data?.err?.msg) {
          message.error(error.response.data.err.msg);
        } else {
          message.error("Something went wrong. Please try again.");
        }
      }
    }
  }

  const addLink = () => {
    if (
      typeof title === "undefined" ||
      title?.toString()?.trim().length === 0 ||
      title === ""
    ) {
      message.error("Please add Link title before adding any new link");
      return;
    }
    const url = linkValue;

    if (!url || url.trim().length === 0) {
      message.error("Url must be valid.");
      return;
    } else {
      const linkFile = {
        name: title + "   " + url,
        status: "done",
        type: "url",
        url,
      };
      uploadFiles({}, linkFile);
      setLinkValue("");
      setTitle("");
    }
  };

  const onFileRemove = async (file) => {
    const hide = message.loading("Deleting", 0);
    const fileId = file.uid;
    let resourceDeleteBody;
    if (uploadFileDataObject[fileId] && uploadFileDataObject[fileId].fileUrl) {
      resourceDeleteBody = {
        clinic_id: clinic.clinicId,
        file_url: uploadFileDataObject[fileId].fileUrl,
      };
    } else {
      resourceDeleteBody = {
        clinic_id: clinic.clinicId,
        file_url: file.originName,
      };
    }

    try {
      const res = await dentistClinicApiTasks.post(
        "clinic/resource/delete",
        resourceDeleteBody
      );
      const filteredList = uploadFileList.filter((file) => file.uid !== fileId);
      setUploadFileList(filteredList);
      console.log(uploadFileList, fileId);
      setTimeout(hide, 0);
      message.success("File successfully removed");
    } catch (error) {
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to delete. Please try again later");
      }
      console.log(error);
    }
  };

  const viewFile = async (file) => {
    let fileName = file.url;
    if (fileName.indexOf("/") > -1) {
      fileName = fileName.substring(fileName.indexOf("/") + 1, fileName.length);
    }
    if (file.type === "url") {
      window.open(file.url, "_blank");
    } else {
      try {
        const urlLinkRes = await dentistClinicApiTasks.post(
          "clinic/resource/get",
          { clinic_id: clinic.clinicId, file_name: file.originName }
        );
        const url = urlLinkRes.body.url;
        window.open(url, "_blank");
      } catch (error) {
        console.log(error);
        if (error.response.data?.err?.msg) {
          message.error(error.response.data.err.msg);
        } else {
          message.error("Something went wrong. Please try again.");
        }
      }
    }
  };
  const props = {
    name: "file",
    multiple: false,
    customRequest: uploadFiles,
    onChange(info) {
      if (
        (typeof title === "undefined" ||
          title?.toString()?.trim().length === 0 ||
          title === "") &&
        info?.file?.status == "uploading"
      ) {
        message.error(
          "Please add Document title before uploading any new document"
        );
      } else {
        let fileList = [];
        fileList.push(info.fileList[info.fileList.length - 1]);
        let uploadedFiles = info.fileList.filter(function (e, index) {
          return e.status !== "uploading" || index === info.fileList.length - 1;
        });
        if (
          uploadedFiles.length > 0 &&
          uploadedFiles[uploadedFiles.length - 1].name.indexOf(" ") < 0
        ) {
          uploadedFiles[uploadedFiles.length - 1].name =
            title + "  " + uploadedFiles[uploadedFiles.length - 1].name;
          uploadedFiles[uploadedFiles.length - 1].url =uploadedUrl;
          setUploadedUrl("")
        }
        setUploadFileList([...uploadedFiles]);
        setFileList([...fileList]);
        // setTitle('')
        const { status } = info.file;
        if (status !== "uploading") {
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
  };
  // console.log("fileList", fileList);
  // console.log("uploadFileList", uploadFileList);

  return (
    <div className="upload-file-container">
      <Row>
        <Title className="upload-files-title" level={5}>
          Resources/Guides
          <InfoIcon
            info={
              "Add the files/links you recommend your patients to read for help while ongoing with the dental treatment."
            }
          />
        </Title>
      </Row>
      <div className="dragger-title-container">
        <p className="dragger-title">Add Title</p>
        <Row>
          <Input
            className="add-title-input"
            size="large"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            ref={inputTitle}
            disabled={mode === "view"}
            maxLength={80}
            placeholder="Add your title here..."
            addonAfter={<></>}
          />
        </Row>
        <p className="input-length">Must be less than 80 characters</p>
      </div>
      <Row justify="space-between">
        <Col lg={14}>
          <p className="dragger-title">Upload Files</p>
          <Dragger
            showUploadList={false}
            disabled={mode === "view"}
            ref={uploadEl}
            accept=".pdf"
            onPreview={(file) => viewFile(file)}
            listType="picture"
            iconRender={(file) => {
              if (file.type === "url") return <LinkOutlined />;
            }}
            className="drag-upload-custom"
            style={{
              width: "80%",
              height: 40,
              display: "block",
              margin: " 0rem 0rem 2rem 2rem",
            }}
            fileList={uploadFileList}
            //fileList={[]}
            // onRemove={onFileRemove}
            {...props}
          >
            <div className="upload-content">
              <div style={{ display: "inline-block" }}>
                <p
                  style={{
                    display: "inline-block",
                    margin: "4px",
                  }}
                >
                  Click or drag file to this area to upload
                </p>
                <div style={{ clear: "both" }}></div>
              </div>
            </div>

            <p className="file-support">Files Supported: PDF</p>
          </Dragger>
        </Col>
        <Col lg={10}>
          <p className="url-title" style={{ fontWeight: "bold" }}>
            Add Link
          </p>
          <Input
            size="large"
            ref={inputEl}
            disabled={mode === "view"}
            style={{
              paddingRight: "30px",
              zIndex: "2",
            }}
            value={linkValue}
            onChange={(e) => setLinkValue(e.target.value)}
            placeholder="Paste link here"
            addonAfter={
              <Button
                disabled={mode === "view"}
                type="primary"
                style={{ width: "4.5rem", borderRadius: "50px" }}
                onClick={addLink}
              >
                Add
              </Button>
            }
          />
        </Col>
      </Row>
      <div className="custom-file-list-container">
        {uploadFileList.length > 0 ? (
          <div>
            {uploadFileList.map((file, index) => {
              return (
                <div className="custom-file-list" key={index}>
                  <div style={{ paddingLeft: "8px" }} className="filename">
                    {file.type === "url" ? (
                      <LinkOutlined />
                    ) : (
                      <FilePdfOutlined />
                    )}
                    <a
                      href={file.url}
                      title={file.name}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="list-file-name"
                    >
                      {file.name}
                    </a>
                  </div>
                  <div class="progress">
                    {index === uploadFileList.length - 1 ? (
                      <div>
                        {uploading && (
                          <div>
                            <Progress percent={percent} status="active" />
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <Button
                    disabled={mode === "view"}
                    icon={<DeleteOutlined />}
                    className="delete-btn"
                    onClick={() => onFileRemove(file)}
                  ></Button>
                </div>
              );
            })}
          </div>
        ) : (
          <h2>No Resources</h2>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(UploadFilesComponent);
