import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  message,
  Typography,
  Spin,
  Row,
  Form,
} from "antd";
import axios from "axios";

import {
  getClinicWhiteLabelList,
  saveClinicWhiteLabelList,
  uploadWhiteLabelFileAPI,
  confirmWhiteLabelFileAPI,
} from "api/clinic";
import { clinicIdSelector } from "services/redux/selectors/clinicSelectors";
import styles from "./whitelabel.module.scss";
import WhiteLabelForm from "./Components/WhiteLabelForm";

const { Title } = Typography;

const ClinicLabels = () => {
  const [whiteLabelData, setWhiteLabelData] = useState(null);
  const [loginImageFile, setLoginImageFile] = useState([]);
  const [splashImageFile, setSplashImageFile] = useState([]);
  const [treatmentImageFile, setTreatmentImageFile] = useState([]);

  const [whiteLabelForm] = Form.useForm();

  const clinicId = useSelector(clinicIdSelector);

  const [loading, setLoading] = useState(false);

  const [enableWhiteLabeling, setEnableWhiteLabeling] = useState(false);

  useEffect(() => {
    const fetchClinicWhiteLabel = async () => {
      try {
        setLoading(true);
        const { data } = await getClinicWhiteLabelList(clinicId);
        console.log("response", data);
        if (data) {
          setEnableWhiteLabeling(data.success);

          setWhiteLabelData(data.data.settings);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (clinicId) {
      fetchClinicWhiteLabel();
    }
  }, [clinicId]);

  const whiteLabelFileMapper = (fileObj, labId) => {
    const { name, type, imageType } = fileObj;
    const preSignRequestBody = {
      fileName: name,
      mimeType: type,
    };
    return preSignRequestBody;
  };

  const uploadService = async (preSignedResponse, file) => {
    // console.log("preSignedResponse", preSignedResponse, file);
    const config = {
      headers: {
        "content-type": file.type,
      },
    };
    // const accessUrl = preSignedResponse?.data.body.success.accessUrl;
    const uploadUrl = preSignedResponse?.data?.uploadUrl;
    const response = await axios.put(uploadUrl, file, config);
    return response;
  };

  const confirmService = async (preSignedResponse, labId, filename) => {
    const { url, fileName } = preSignedResponse?.data;
    const confirmRequestBody = {
      file_url: url,
      resource_type: "file",
      scan_type: fileName,
      case_id: 1,
      notify: false,
    };
    await confirmWhiteLabelFileAPI(confirmRequestBody);
  };

  const uploadSingleFile = async (clinicalFile, clinicId, index) => {
    const preSignedRequestBody = whiteLabelFileMapper(clinicalFile, clinicId);
    // console.log("preSignedRequestBody", preSignedRequestBody);
    const preSignedResponse = await uploadWhiteLabelFileAPI(
      preSignedRequestBody,
      clinicId
    );
    await uploadService(preSignedResponse, clinicalFile);
    await confirmService(preSignedResponse, clinicalFile.filename);

    return {
      uploadedFile: preSignedResponse?.data.fileName,
      imageType: clinicalFile.imageType,
    };
  };

  const whiteLabelFilesUploader = async (files, clinicId) => {
    const promiseArray = [];
    files.forEach((file, index) => {
      promiseArray.push(uploadSingleFile(file, clinicId, index));
    });
    const finalRes = await Promise.all(
      promiseArray.map((p) => p.catch((e) => e))
    );
    // console.log("finalRes", finalRes);
    return finalRes;
  };

  const onWhiteLabelSubmit = async (values) => {
    console.log("values", values);

    //  console.log("onWhiteLabelSubmit values ",values )
    let filesArray = [];
    let fileUrls = [];
    filesArray = [...loginImageFile, ...splashImageFile, ...treatmentImageFile];
    //  console.log("filesArray", filesArray)

    fileUrls = await whiteLabelFilesUploader(filesArray, clinicId);
    console.log("fileUrls", fileUrls);

    const transformArrayToObject = (array) => {
      return array.reduce((acc, item) => {
        acc[item.imageType] = item;
        return acc;
      }, {});
    };

    const outputObject = transformArrayToObject(fileUrls);
    console.log("outputObject", outputObject);

    const payload = {
      clinicId: clinicId,
      settings: {
        ...values,
        loginImage: outputObject.loginImage
          ? outputObject.loginImage
          : whiteLabelData.loginImage,
        splashImage: outputObject.splashImage
          ? outputObject.splashImage
          : whiteLabelData.splashImage,
        treatmentImage: outputObject.treatmentImage
          ? outputObject.treatmentImage
          : whiteLabelData.treatmentImage,
        //  fileUrls: fileUrls.length ? fileUrls : whiteLabelData.fileUrls,
      },
    };
    try {
      setLoading(true);
      const res = await saveClinicWhiteLabelList(payload);

      message.success("Clinic Whitelabel settings updated successfully");

      setWhiteLabelData({
        ...values,
        loginImage: outputObject.loginImage
          ? outputObject.loginImage
          : whiteLabelData.loginImage,
        splashImage: outputObject.splashImage
          ? outputObject.splashImage
          : whiteLabelData.splashImage,
        treatmentImage: outputObject.treatmentImage
          ? outputObject.treatmentImage
          : whiteLabelData.treatmentImage,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="whiteLabel-container">
      <div className="whiteLabel-content">
        <Spin spinning={loading}>
          <Title level={4}>Clinic WhiteLabel Settings</Title>
          {enableWhiteLabeling ? (
            <div>
              <Row>
                <Title className={styles["whiteLabel-title"]} level={5}>
                  App Settings
                </Title>
              </Row>
              <WhiteLabelForm
                whiteLabelForm={whiteLabelForm}
                whiteLabelData={whiteLabelData}
                onWhiteLabelSubmit={onWhiteLabelSubmit}
                setLoginImageFile={setLoginImageFile}
                splashImageFile={splashImageFile}
                setSplashImageFile={setSplashImageFile}
                treatmentImageFile={treatmentImageFile}
                setTreatmentImageFile={setTreatmentImageFile}
                loginImageFile={loginImageFile}
              />
            </div>
          ) : (
            <Title
              level={5}
              style={{ textAlign: "center", margin: "50px 0px" }}
            >
              Whitelabeling not enabled by your Lab
            </Title>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default ClinicLabels;
