import React from "react";
import { Steps } from "antd";

import "../../../assets/styles/components/profileNavbarComponent.scss";
import { InfoIcon } from "components/shared/InfoIcon/index";
import { primaryColor } from "colors-config";

const { Step } = Steps;

class ProfileNavbarComponent extends React.Component {
  handleStepChange = (current) => {
    this.props.setCurrentSelection(current);
  };

  render() {
    const { loading: propsLoading, currentSelection: currentStep } = this.props;

    const totalSteps = 3;
    const step = currentStep + 1;
    const underlineWidth = (step / totalSteps) * 100;

    return (
      <div className="profile-steps-container">
        <Steps
          current={currentStep}
          onChange={this.handleStepChange}
          className="profile-steps"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Step
            title="Profile"
            description={
              propsLoading && this.props.clinicDetails?.clinicName === "" ? (
                <InfoIcon info={"Please complete availability settings"} />
              ) : null
            }
          />
          <Step
            title="Availability"
            description={
              propsLoading &&
              (this.props.workingDays.length === 0 || this.props.offDays.length === 0) ? (
                <InfoIcon info={"Please complete availability settings"} />
              ) : null
            }
          />
          <Step
            title="Treatments & Appointments"
            description={
              propsLoading && this.props.isServicesandResourcesComplete ? (
                <InfoIcon info={"Please Complete Services and Resources Settings."} />
              ) : null
            }
          />
          {/* <Step
            title="Manage Staff"
            description={
              propsLoading && !this.props.canOnboardingBeCompleted ? (
                <InfoIcon
                  info={
                    "Complete profile,availability settings and Services/Products in order to enable staff management."
                  }
                />
              ) : null
            }
            disabled={!this.props.canOnboardingBeCompleted}
          /> */}
        </Steps>
        <div
          className="steps-line"
          style={{ width: `${underlineWidth}%`, background: primaryColor }}
        />
      </div>
    );
  }
}

export default ProfileNavbarComponent;

// import React from "react";
// import { Menu } from "antd";

// import "../../../assets/styles/components/profileNavbarComponent.scss";
// import { InfoIcon } from "components/shared/InfoIcon/index";

// class ProfileNavbarComponent extends React.Component {
//   state = {
//     current: "0",
//   };
//   handleClick = (e) => {
//     this.setState({ current: e.key });
//     this.props.setCurrentSelection(+e.key);
//   };
//   render() {
//     const { current } = this.state;
//     const propsLoading = this.props.loading;

//     const menuItemStyle = {
//       display: "flex",
//       alignItems: "center",
//       fontSize: "16px",
//       // margin: "-1px 50px 0",
//     };

//     return (
//       <div style={{ height: "58px" }}>
//         <Menu
//           className="profile-menubar"
//           onClick={this.handleClick}
//           selectedKeys={[current]}
//           mode="horizontal"
//           style={{
//             width: "100%",
//             heigth: "100%",
//             borderRadius: "6px",
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Menu.Item style={menuItemStyle} className="profile-menubar-items" key={0}>
//             Profile
//             {propsLoading && this.props.clinicDetails?.clinicName === "" && (
//               <InfoIcon info={"Please complete availability settings"} />
//             )}
//           </Menu.Item>
//           <Menu.Item style={menuItemStyle} className="profile-menubar-items" key={1}>
//             Availability
//             {propsLoading &&
//               (this.props.workingDays.length === 0 || this.props.offDays.length === 0) && (
//                 <InfoIcon info={"Please complete availability settings"} />
//               )}
//           </Menu.Item>
//           <Menu.Item style={menuItemStyle} className="profile-menubar-items" key={2}>
//             Services & Resources
//             {propsLoading && this.props.isServicesandResourcesComplete ? (
//               <InfoIcon info={"Please Complete Services and Resources Settings."} />
//             ) : null}
//           </Menu.Item>
//           <Menu.Item
//             style={menuItemStyle}
//             className="profile-menubar-items"
//             key={3}
//             disabled={!this.props.canOnboardingBeCompleted}
//           >
//             Manage Staff
//             {propsLoading && !this.props.canOnboardingBeCompleted && (
//               <InfoIcon
//                 info={
//                   "Complete profile,availability settings and Services/Products inorder to enable staff management."
//                 }
//               />
//             )}
//           </Menu.Item>
//           {/* <Menu.Item style={menuItemStyle} className="profile-menubar-items" key={4}>
//             Add Lab Partner
//           </Menu.Item> */}
//         </Menu>
//       </div>
//     );
//   }
// }

// export default ProfileNavbarComponent;
