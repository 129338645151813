import { useEffect } from "react";

import { Row, Col, Checkbox, TimePicker, Form } from "antd";

import "../../../assets/styles/components/pickHoursAndDaysComponent.scss";
const { RangePicker } = TimePicker;

const PickHoursAndDays = ({
  setWorkingDays,
  workingDays,
  workingHours,
  setWorkingHours,
  setBreakHours,
  breakHours,
  setCanSubmit,
  shouldSubmit,
  mode,
}) => {
  const [form] = Form.useForm();

  function handleDateCheckbox(checkedValues) {
    const workingDaysSet = new Set();
    checkedValues.forEach((checkedValue) => {
      workingDaysSet.add(checkedValue);
    });
    setWorkingDays(Array.from(workingDaysSet));
  }

  const handleWorkingHoursRange = (range) => {
    setWorkingHours(range);
  };

  const handleBreakHoursRange = (range) => {
    setBreakHours(range);
  };

  const onFinish = (values) => {
    setCanSubmit(true);
  };

  useEffect(() => {
    if (shouldSubmit) {
      form.submit();
    }
  }, [shouldSubmit]);

  return (
    <div className="pick-hours-days-container">
      <Form
        style={{
          width: "100%",
        }}
        form={form}
        name="complex-form-3"
        layout={"vertical"}
        initialValues={{
          workingDays,
          workingHours,
          breakHours,
        }}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          required={true}
          rules={[
            { required: true, message: "Selection of working days required." },
          ]}
          name="workingDays"
          style={{
            width: "95%",
            marginTop: "1rem",
            marginLeft: "2rem",
          }}
        >
          <Checkbox.Group
            style={{ float: "left" }}
            disabled={mode === "view"}
            onChange={handleDateCheckbox}
          >
            <Checkbox className="date-checkbox" value={0}>
              Sunday
            </Checkbox>
            <Checkbox className="date-checkbox" value={1}>
              Monday
            </Checkbox>
            <Checkbox className="date-checkbox" value={2}>
              Tuesday
            </Checkbox>

            <Checkbox className="date-checkbox" value={3}>
              Wednesday
            </Checkbox>
            <Checkbox className="date-checkbox" value={4}>
              Thursday
            </Checkbox>

            <Checkbox className="date-checkbox" value={5}>
              Friday
            </Checkbox>

            <Checkbox className="date-checkbox" value={6}>
              Saturday
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Row justify="space-between" style={{ width: "100%" }}>
          <Col span={11} style={{ marginLeft: "2rem" }}>
            <Form.Item
              name="workingHours"
              required={true}
              label="Working Hours"
              rules={[
                {
                  required: true,
                  message: "Selection of working hours required.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    let startDate = new Date(
                      getFieldValue("workingHours")[0]._d
                    );
                    let endDate = new Date(getFieldValue("workingHours")[1]._d);
                    if (
                      startDate.getHours() === endDate.getHours() &&
                      startDate.getMinutes() === endDate.getMinutes()
                    ) {
                      return Promise.reject(
                        "Start and End time should not be same!"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              style={{
                float: "left",
                marginTop: "1rem",
                width: "100%",
              }}
            >
              <RangePicker
                format="HH:mm"
                size="large"
                disabled={mode === "view"}
                style={{ width: "100%", backgroundColor: "#F9F9F9" }}
                onChange={handleWorkingHoursRange}
              />
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginRight: "1rem" }}>
            <Form.Item
              label="Break Hours"
              name="breakHours"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !value.length) {
                      return Promise.resolve();
                    }
                    let startDate = new Date(getFieldValue("breakHours")[0]._d);
                    let endDate = new Date(getFieldValue("breakHours")[1]._d);
                    if (
                      startDate.getHours() === endDate.getHours() &&
                      startDate.getMinutes() === endDate.getMinutes()
                    ) {
                      return Promise.reject(
                        "Start and End time should not be same!"
                      );
                    }
                    // if(startDate.getHours()>endDate.getHours()){
                    //   return Promise.reject(
                    //     "Start time cannot be greater than end time"
                    //   );
                    // }
                    return Promise.resolve();
                  },
                }),
              ]}
              style={{
                float: "left",

                width: "100%",
                marginTop: "1rem",
              }}
            >
              <RangePicker
                format="HH:mm"
                size="large"
                disabled={mode === "view"}
                style={{ width: "100%", backgroundColor: "#F9F9F9" }}
                onChange={handleBreakHoursRange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PickHoursAndDays;
