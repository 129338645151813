import { createSelector } from "reselect";

const getClinic = (state) => state.clinic;

export const clinicIdSelector = createSelector(
  getClinic,
  (clinic) => clinic.clinicId
);

export const clinicSelector = createSelector(getClinic, (clinic) => {
  return clinic;
});

export const getCountries = createSelector(
  getClinic,
  (clinic) => clinic.countries
);
export const getPatientCountryCodesList = createSelector(
  getClinic,
  (clinic) => clinic.patientCountryCodesList
);
export const getCountryCodes = createSelector(
  getClinic,
  (clinic) => clinic.countryCodes
);

export const clinicAddress = createSelector(
  getClinic,
  (clinic) => clinic.multipleAddresses
);

export const clinicDefaultAddress = createSelector(clinicAddress, (address) => {
  let defaultAddress = null;

  address.forEach((el) => {
    if (el.default) {
      defaultAddress = { ...el };
    }
  });

  if (!defaultAddress) {
    defaultAddress = address[0];
  }
  return defaultAddress;
});

export const isOnboardedSelector = createSelector(
  getClinic,
  (clinic) => clinic.isOnboarded
);


export const getClinicAvailability = createSelector(
  getClinic,
  (clinic) => clinic.clinicAvailability
);