import { Select } from "antd";

import Toolbar from "react-big-calendar-like-google";

class CustomToolbar extends Toolbar {
  componentDidMount() {
    this.props.onViewChange(this.props.selectedView);
    // console.log(view);
  }
  componentDidUpdate() {
    this.props.onViewChange(this.props.selectedView);
  }

  render() {
    return (
      <div className="rbc-toolbar" style={{ display: "inline-flex" }}>
        <span className="rbc-btn-group">
          <button
            style={{ fontFamily: "Mulish" }}
            type="button"
            onClick={() => this.navigate("TODAY")}
          >
            Today
          </button>
          <span className="rbc-toolbar-label" style={{ width: "auto" }}>
            {this.props.label}
          </span>
          <button
            className="arrowButton"
            type="button"
            onClick={() => this.navigate("PREV")}
            style={{ border: "none", marginTop: "-3px" }}
          >
            {`<`}
          </button>
          <button
            className="arrowButton"
            type="button"
            onClick={() => this.navigate("NEXT")}
            style={{ border: "none", marginTop: "-3px" }}
          >
            {`>`}
          </button>
        </span>
        <div className="selectview">
          <Select
           size="large"
            style={{ width: "8rem", float: "right" }}
            defaultValue={this.props.view}
            onChange={(e) => this.changeView(e)}
          >
            <Select.Option value="week">Weekly View</Select.Option>
            <Select.Option value="day">Daily View</Select.Option>
            <Select.Option value="month">Monthly View</Select.Option>
            <Select.Option value="agenda">Agenda View</Select.Option>
          </Select>
        </div>
      </div>
    );
  }

  changeView = (view) => {

    this.props.onViewChange(view);
  };

  navigate = (action) => {
    console.log(action);

    this.props.onNavigate(action);
  };
}

export default CustomToolbar;
