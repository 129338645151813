import { Button, Tooltip } from "antd";
import { primaryColor } from "colors-config";

const PatientFlag = ({flagText, title, icon}) => {
  return (
    <Tooltip title={title}>
    <Button shape="round" style={{ color: primaryColor }} icon={icon} className="flag-btn">
     {flagText}
    </Button>
  </Tooltip>
  )
}

export default PatientFlag