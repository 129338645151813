import { useState, useEffect } from "react";

import { Modal, Form, Input, Select } from "antd";

const { Option } = Select;

const PatientOrderModal = ({
  title,
  onConfirmation,
  showModal,
  setShow,
  clinicProducts,
  patientId,
}) => {
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clinicProductsAndServices, setClinicProductsAndServices] = useState(
    {}
  );
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [productCurrencies, setProductCurrencies] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  useEffect(() => {
    if (clinicProducts.length) {
      setClinicProductsAndServices(parseClinicProducts(clinicProducts));
      parseCurrency(clinicProducts);
    }
  }, [clinicProducts]);

  useEffect(() => {
    const keys = Object.keys(clinicProductsAndServices);

    if (keys.length) {
      console.log(clinicProductsAndServices, keys, "test");
      const productNameKey = Object.keys(clinicProductsAndServices[keys[0]])[0];
      setSelectedProductType(keys[0]);
      setSelectedProductName(productNameKey);
      form.resetFields();
    }
  }, [clinicProductsAndServices]);

  const parseClinicProducts = (products) => {
    const productsObject = {};
    products.map((p) => {
      const product = {
        ...p,
        price: p.free ? 0 : p.price,
      };
      if (product.product_type_id !== 1) {
        if (productsObject[product.product_type_id]) {
          productsObject[product.product_type_id] = {
            ...productsObject[product.product_type_id],
            [product.product_name_id]: product,
          };
        } else {
          productsObject[product.product_type_id] = {
            [product.product_name_id]: product,
          };
        }
      }
    });
   
    return productsObject;
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  const parseCurrency = (products) => {
    let currencyObj = {};
    products.map((product) => {
      currencyObj[product.product_currency_id] = {
        product_currency_id: product.product_currency_id,
        currency_name: product.currency_name,
      };
    });
    setProductCurrencies(Object.values(currencyObj));
  };

  const currencySelector = (
    <Form.Item name="productCurrency" noStyle>
      <Select size="large" style={{ width: 80 }}>
        {productCurrencies.map((currency) => {
          return (
            <Option
              key={currency.product_currency_id}
              value={currency.product_currency_id}
            >
              {currency.currency_name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const shouldFormRender = () => {
    return (
      Object.keys(clinicProductsAndServices).length > 0 &&
      selectedProductName &&
      selectedProductType
    );
  };

  const productNameSelectChanged = (val) => {
    // console.log(typeof val, "product name changed");
    setSelectedProductName(val);
    const productPrice =
      clinicProductsAndServices[selectedProductType][val].price;
    // alert(price);
    const productId =
      clinicProductsAndServices[selectedProductType][val].product_id;
    form.setFieldsValue({ productPrice, productId });
  };

  const productTypeSelectChanged = (val) => {
    // console.log(val, "product name changed");

    setSelectedProductType(val);
    setSelectedProductName(Object.keys(clinicProductsAndServices[val])[0]);
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onFinish = (val) => {
    onConfirmation(val);
  };

  let actualPrice;
  if (selectedProductType) {
    const selectedProduct = clinicProductsAndServices[selectedProductType][selectedProductName];
    actualPrice = selectedProduct.price;
    if(selectedProduct.free){
      actualPrice=0;
    }
    if(selectedProduct.discount){
      actualPrice= selectedProduct.price - selectedProduct.discount;
    }
  }



  return (
    <>
      <Modal
        okText={shouldFormRender() ? "Send Order to Patient" : "Ok"}
        width={700}
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            width: shouldFormRender() ? "10.5rem" : "7rem",
            borderRadius: "30px",
          },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {shouldFormRender() && (
          <Form
            form={form}
            style={{
              width: "100%",
            }}
            name="complex-form"
            layout={"vertical"}
            onFinish={onFinish}
            initialValues={{
              patientId: patientId,
              productType: selectedProductType,
              productName: selectedProductName,

              sendTo: "clinic",
              productId:
                clinicProductsAndServices[selectedProductType][
                  selectedProductName
                ].product_id,
              productPrice:actualPrice,
              productCurrency:
                +clinicProductsAndServices[selectedProductType][
                  selectedProductName
                ].product_currency_id,
            }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
          >
            <div>
              <Form.Item className="parent-form-item">
                <Form.Item
                  label="Patient Id"
                  name="patientId"
                  className="inline-form-left"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    marginRight: "2rem",
                    textAlign: "left",
                  }}
                >
                  <Input size="large" disabled={true} />
                </Form.Item>

                <Form.Item
                  label="Product Id"
                  name="productId"
                  className="inline-form-left"
                  style={{
                    // display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    marginRight: "0.4rem",
                    display: "none",
                    textAlign: "left",
                  }}
                >
                  <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item
                  name="productType"
                  label="Product/Service Type"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    marginRight: "0.4rem",

                    textAlign: "left",
                  }}
                >
                  <Select
                    size="large"
                    onChange={productTypeSelectChanged}
                    // defaultValue={productOrServiceTypeOptions[0].product_type}
                    style={{ width: "100%" }}
                    dropdownRender={(menu) => <div>{menu}</div>}
                  >
                    {Object.keys(clinicProductsAndServices).map(
                      (productType) => (
                        <Option key={productType} value={productType}>
                          {
                            Object.values(
                              clinicProductsAndServices[productType]
                            )[0].product_type
                          }
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Form.Item>

              <Form.Item className="parent-form-item">
                <Form.Item
                  label="Product/Service Name"
                  name="productName"
                  className="inline-form-right"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    margin: "0 2rem 0  0rem ",

                    textAlign: "left",
                  }}
                >
                  {selectedProductType && (
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      dropdownRender={(menu) => <div>{menu}</div>}
                      onChange={productNameSelectChanged}
                    >
                      {/* {clinicProducts.map((product) => (
                    <Option
                      key={product.product_name_id}
                      value={product.product_name_id}
                    >
                      {product.product_name}
                    </Option>
                  ))} */}

                      {selectedProductType &&
                        Object.keys(
                          clinicProductsAndServices[selectedProductType]
                        ).map((productName) => {
                          // console.log(
                          //   clinicProductsAndServices[selectedProductType][
                          //     productName
                          //   ].product_name,
                          //   "sddsds"
                          // );
                          return (
                            <Option key={productName} value={productName}>
                              {
                                // Object.values(clinicProductsAndServices[productType])[0]
                                //   .product_type

                                clinicProductsAndServices[selectedProductType][
                                  productName
                                ].product_name
                              }
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  label="Product Price"
                  name="productPrice"
                  disabled={true}
                  className="inline-form-left"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    marginRight: "0.4rem",

                    textAlign: "left",
                  }}
                >
                  <Input
                    size="large"
                    disabled={true}
                    addonAfter={currencySelector}
                  />
                </Form.Item>
              </Form.Item>

              {/* {mode === "view" && (
              <Form.Item
                name="clinicUrl"
                label="Clinic Url"
                style={{
                  marginRight: "0.5rem",
                  marginTop: "1rem",
                }}
              >
                <Input
                  ref={clinicUrlInputRef}
                  // disabled={true}
                  defaultValue={clinicDetails.clinicUrl}
                  addonAfter={clipboardCopyButton}
                />
              </Form.Item>
            )} */}
            </div>
          </Form>
        )}
        {!shouldFormRender() && (
          <div>
            <p>No products available!</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PatientOrderModal;
