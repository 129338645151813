import { useState, useEffect } from "react";
import moment from "moment";
import { Modal } from "antd";
import { tConvert } from "../../../utilities/commonMethod";

const DeleteConfirmationModal = ({
  title,
  content,
  onConfirmation,
  showModal,
  setShow,
  selectedClinician,
  clinicians,
  selectedAppointment,
  mode,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
    onConfirmation();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        okText="Confirm"
        onCancel={handleCancel}
        okButtonProps={{
          danger: true,

          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {mode === "onboarding" && (
          <p> Are you sure you want to remove ({content})</p>
        )}
        {mode === "cancel" && (
          <p>
            Are you sure you want to remove {content} for{" "}
            <b>
              {moment(selectedAppointment.appointment_date).format(
                "MMMM Do YYYY"
              )}
            </b>{" "}
            at{" "}
            <b>
              {moment(
                tConvert(selectedAppointment.appointment_start_time),
                "HH:mm:ss"
              ).format("hh:mm A")}
            </b>{" "}
            with{" "}
            <b>
              {
                clinicians.filter((clinician) => {
                  if (clinician.clinician_id === selectedClinician)
                    return clinician.clinician_name;
                })[0].clinician_name
              }{" "}
            </b>
          </p>
        )}
        {mode === "status" && (
          <p>
            {" "}
            <p>
              Are you sure you want to change the patient (
              {selectedAppointment.patient_first_name +
                " " +
                selectedAppointment.patient_last_name}
              ) appointment status to no show?
            </p>
          </p>
        )}
        {mode === "treatmentCancel" && (
          <p>Are you sure you want to cancel this treatment</p>
        )}
        {mode === "deleteImage" && (
          <p>Are you sure you want to delete {content} permanently.</p>
        )}
      </Modal>
    </>
  );
};

export default DeleteConfirmationModal;
