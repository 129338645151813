import { Modal, Typography } from "antd";
import { ReactComponent as UserList } from "assets/images/UserList.svg";
import UsersList from "./components/UsersList";
import ShareInput from "./components/ShareInput";
const { Title } = Typography;

const NotificationShareModal = ({
  showShareModal,
  setShowShareModal,
  shareList,
  setShareList,
  clinicId,
}) => {
  const handleShareResourceCancel = () => {
    setShowShareModal(false);
  };

  return (
    <Modal
      className="notificationShareModal"
      title={
        <div className="titleSpan">
          <Title level={5} style={{ margin: "0px" }}>
            Share with
          </Title>
        </div>
      }
      width={600}
      visible={showShareModal}
      onCancel={handleShareResourceCancel}
      footer={null}
    >
      <ShareInput
        shareList={shareList}
        setShareList={setShareList}
        clinicId={clinicId}
      />
      {shareList.length ? (
        <UsersList
          shareList={shareList}
          setShareList={setShareList}
          clinicId={clinicId}
        />
      ) : (
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <UserList />
        </div>
      )}
    </Modal>
  );
};

export default NotificationShareModal;
