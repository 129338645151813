import {
  VIDEO_CALL_MAXIMIZED,
  VIDEO_CALL_MINIMIZED,
  VIDEO_CALL_TOGGLE_AUDIO,
  VIDEO_CALL_TOGGLE_VIDEO,
  END_VIDEO_CALL,
  SET_VIDEO_CALL_STATUS, CURRENT_VIDEO_CALL_APPOINTMENT,
} from "./types";

export const minimizeVideoCall = (payload) => async (dispatch) => {
  dispatch({
    type: VIDEO_CALL_MINIMIZED,
    payload,
  });
};

export const maximizeVideoCall = () => async (dispatch) => {
  dispatch({
    type: VIDEO_CALL_MAXIMIZED,
  });
};

export const toggleAudio = (state) => async (dispatch) => {
  dispatch({
    type: VIDEO_CALL_TOGGLE_AUDIO,
    payload: state,
  });
};

export const toggleVideo = (state) => async (dispatch) => {
  dispatch({
    type: VIDEO_CALL_TOGGLE_VIDEO,
    payload: state,
  });
};

export const endCall = () => async (dispatch) => {
  dispatch({
    type: END_VIDEO_CALL,
  });
};

export const setCallStatus = (state) => async (dispatch) => {
  dispatch({
    type: SET_VIDEO_CALL_STATUS,
    payload: state,
  });
};
export const setCurrentVideoCallAppointment = (state) => async (dispatch) => {
  dispatch({
    type: CURRENT_VIDEO_CALL_APPOINTMENT,
    payload: state,
  });
};
