import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Divider,
  Form,
  Switch,
  Button,
  Select,
  Spin,
  message,
} from "antd";
import { dentistClinicApiTasks } from "models/dentistClinic";
import { updateNotificationsAPI, getNotificationsAPI } from "api/clinic";
import { ReactComponent as Users } from "assets/images/UsersIcon.svg";
import { loadClinic } from "../../../services/redux/actions/clinicActions";
import { companyType } from "colors-config";
import { connect } from "react-redux";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import NotificationShareModal from "../NotificationShareModal/NotificationShareModal";
import {
  notificationFormMapper,
  notificationToolTip,
  ORDERS_TOOLTIP,
  TREATMENT_READY_TOOLTIP,
} from "utilities/notification";
import AppButton from "components/shared/AppButton/AppButton";
const { Option } = Select;

const NotificationSettingsModal = ({
  openSettingsModal,
  onNotificationCancel,
  clinicId,
  clinic,
  loadClinic,
}) => {
  const [notificationForm] = Form.useForm();
  const [durationList, setDurationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareList, setShareList] = useState([]);
  const [switchStates, setSwitchStates] = useState({
    iprEmail: false,
    iprNotification: false,
    chatEmail: false,
    chatNotification: false,
    ordersEmail: false,
    ordersNotification: false,
    summaryEmail: false,
    prescriptionEmail: false,
    treatmentReadyEmail: false,
    treatmentReadyApp: false,
  });

  useEffect(() => {
    const fetchDurations = async () => {
      const apiArray = [
        dentistClinicApiTasks.getAll(
          "clinic/prescription/duration/get?all=true",
          {
            clinicId: clinicId,
          }
        ),
        getNotificationsAPI(clinicId),
      ];
      try {
        const response = await Promise.all(apiArray);
        // console.log("response[1]?.data?.settings[0]", response[1]?.data?.settings[0])
        if (response) {
          setDurationList(response[0]?.body?.data);
          const finalResponse = notificationFormMapper(
            response[1]?.data?.settings[0]
          );
          setSwitchStates(finalResponse);
          notificationForm.setFieldsValue(finalResponse);
          setShareList(response[1]?.data?.summaryEmails);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDurations();
  }, [clinicId, notificationForm]);

  const onFinish = async (values) => {
    const payloadObj = {
      ...values,
      iprNotification: companyType === 5 ? true : values.iprNotification,
      iprEmail: companyType === 5 ? true : values.iprEmail,
    };
    const apiPayload = { clinicId, settings: payloadObj };
    try {
      setLoading(true);
      apiPayload.clinicId = clinicId;
      const { data } = await updateNotificationsAPI(apiPayload, clinicId);

      if (data) {
        message.success("Notifications settings updated");
      }
      // await loadClinic();
      onNotificationCancel();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  const handleSwitchChange = (checked, name) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  return (
    <div>
      {showShareModal && (
        <NotificationShareModal
          showShareModal={showShareModal}
          setShowShareModal={setShowShareModal}
          shareList={shareList}
          setShareList={setShareList}
          clinicId={clinicId}
        />
      )}
      <Modal
        open={openSettingsModal}
        title="Notification Settings"
        onCancel={onNotificationCancel}
        // onOk={onNotificationsSubmit}
        width={850}
        footer={[
          <AppButton
            key="cancel"
            shape="round"
            onClick={onNotificationCancel}
            // style={{ width: "7rem" }}
          >
            Cancel
          </AppButton>,
          <AppButton
            shape="round"
            // style={{ width: "7rem" }}
            key="submit"
            type="primary"
            onClick={() => notificationForm.submit()}
          >
            Save
          </AppButton>,
        ]}
      >
        <Spin spinning={loading}>
          <div className="notifications-modal-container">
            <Row justify="end" align="middle" className="header-row">
              <Col lg={16}>
                <p style={{ textAlign: "center", marginLeft: "11rem" }}>
                  Duration
                </p>
              </Col>
              <Col lg={4}>
                <p>Receive Email</p>
              </Col>
              <Col lg={4}>
                <p>Notify on Portal</p>
              </Col>
            </Row>
            <Divider style={{ margin: "10px 0" }} />
            <Form
              name="notification-form"
              // layout={"vertical"}
              form={notificationForm}
              onFinish={onFinish}
            >
              <Row justify="start" align="middle">
                <Col lg={16}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      flexWrap: "wrap",
                    }}
                  >
                    <label className="form-label">
                      IPR/Attachment notification, send reminder
                    </label>
                    <div
                      style={{ position: "relative" }}
                      className="notification-select"
                    >
                      {/* <span className="duration-span">Duration</span> */}
                      <Form.Item name="iprNotificationDuration" label="">
                        <Select
                          size="large"
                          className="form-select"
                          placeholder="Select Duration"
                          style={{ width: 160 }}
                        >
                          {durationList.map((item) => {
                            return (
                              <Option
                                value={item.duration_id}
                                key={item.duration_id}
                              >
                                {item.prescription_duration}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <label className="form-label">before session is due</label>
                  </div>
                </Col>
                {companyType !== 5 && (
                  <Col lg={4}>
                    <div className="notification-columnTwo">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Item
                          label=""
                          name="iprEmail"
                          valuePropName="checked"
                        >
                          <Switch
                            onChange={(checked) =>
                              handleSwitchChange(checked, "iprEmail")
                            }
                          />
                        </Form.Item>
                        <span className="onLabel">
                          {switchStates.iprEmail ? "On" : "Off"}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
                {companyType !== 5 && (
                  <Col lg={4}>
                    <div className="notification-columnTwo">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Item
                          label=""
                          name="iprNotification"
                          valuePropName="checked"
                        >
                          <Switch
                            onChange={(checked) =>
                              handleSwitchChange(checked, "iprNotification")
                            }
                          />
                        </Form.Item>
                        <span className="onLabel">
                          {switchStates.iprNotification ? "On" : "Off"}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
              <Divider />

              <Row justify="end" align="middle">
                <Col lg={16}>
                  <label className="form-label">
                    Get notifications for Chat messages
                  </label>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item
                      label=""
                      name="chatEmail"
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked) =>
                          handleSwitchChange(checked, "chatEmail")
                        }
                      />
                    </Form.Item>
                    <span className="onLabel">
                      {switchStates.chatEmail ? "On" : "Off"}
                    </span>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item
                      label=""
                      name="chatNotification"
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked) =>
                          handleSwitchChange(checked, "chatNotification")
                        }
                      />
                    </Form.Item>
                    <span className="onLabel">
                      {switchStates.chatNotification ? "On" : "Off"}
                    </span>
                  </div>
                </Col>
              </Row>
              <Divider />

              <Row justify="end" align="middle">
                <Col lg={16}>
                  <label className="form-label">
                    Get notifications on Orders placed{" "}
                    <AppToolTip
                      title={ORDERS_TOOLTIP}
                      iconStyles={{ marginLeft: "10px" }}
                    />
                  </label>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item
                      label=""
                      name="ordersEmail"
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked) =>
                          handleSwitchChange(checked, "ordersEmail")
                        }
                      />
                    </Form.Item>
                    <span className="onLabel">
                      {switchStates.ordersEmail ? "On" : "Off"}
                    </span>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item
                      label=""
                      name="ordersNotification"
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked) =>
                          handleSwitchChange(checked, "ordersNotification")
                        }
                      />
                    </Form.Item>
                    <span className="onLabel">
                      {switchStates.ordersNotification ? "On" : "Off"}
                    </span>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row justify="end" align="middle">
                <Col lg={16}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <label className="form-label">
                        Clinic Summary Notification{" "}
                        <AppToolTip
                          title={notificationToolTip(companyType)}
                          iconStyles={{ marginLeft: "10px" }}
                        />
                      </label>
                      <AppButton
                        shape="round"
                        type="primary"
                        style={{
                          marginLeft: "1rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={handleShareClick}
                        icon={<Users />}
                      >
                        Share
                      </AppButton>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item name="summaryEmail" valuePropName="checked">
                      <Switch
                        onChange={(checked) =>
                          handleSwitchChange(checked, "summaryEmail")
                        }
                      />
                    </Form.Item>
                    <span className="onLabel">
                      {switchStates.summaryEmail ? "On" : "Off"}
                    </span>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    {/* <Form.Item
                      name="summaryNotification"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    <span className="onLabel">On</span> */}
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row justify="end" align="middle">
                <Col lg={16}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label className="form-label">
                      Get notification on Prescription Submission
                    </label>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item name="prescriptionEmail" valuePropName="checked">
                      <Switch
                        onChange={(checked) =>
                          handleSwitchChange(checked, "prescriptionEmail")
                        }
                      />
                    </Form.Item>
                    <span className="onLabel">
                      {switchStates.prescriptionEmail ? "On" : "Off"}
                    </span>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo"></div>
                </Col>
              </Row>

              <Divider />
              <Row justify="end" align="middle">
                <Col lg={16}>
                  <label className="form-label">
                    Get notification when Treatment Plan is Ready{" "}
                    <AppToolTip
                      title={TREATMENT_READY_TOOLTIP}
                      iconStyles={{ marginLeft: "10px" }}
                    />
                  </label>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item
                      label=""
                      name="treatmentReadyEmail"
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked) =>
                          handleSwitchChange(checked, "treatmentReadyEmail")
                        }
                      />
                    </Form.Item>
                    <span className="onLabel">
                      {switchStates.treatmentReadyEmail ? "On" : "Off"}
                    </span>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item
                      label=""
                      name="treatmentReadyApp"
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked) =>
                          handleSwitchChange(checked, "treatmentReadyApp")
                        }
                      />
                    </Form.Item>
                    <span className="onLabel">
                      {switchStates.treatmentReadyApp ? "On" : "Off"}
                    </span>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps, { loadClinic })(
  NotificationSettingsModal
);
