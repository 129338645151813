import { primaryColor } from "colors-config";
import {
    HomeOutlined,
  } from "@ant-design/icons";
export const HomeIcon = () => {
  return (
    <HomeOutlined
      style={{
        fontSize: "20px",
        marginTop: "2%",
        color: primaryColor,
      }}
    />
  );
};
