import API from "api/api";

export const getAllPaymentMethods = (clinicId) => {
  return API({ method: "GET", url: `/clinic/payments/getAll/${clinicId}` });
};

export const savePaymentMethod = (data) => {
  return API({
    method: "POST",
    url: "/clinic/payments/insert",
    data,
  });
};

export const changeActiveStatus = (clinicId, data) => {
  return API({
    method: "PUT",
    url: `/clinic/payments/activeStatus/${clinicId}`,
    data,
  });
};

export const changeDefaultStatus = (clinicId, data) => {
  return API({
    method: "PUT",
    url: `/clinic/payments/defaultStatus/${clinicId}`,
    data,
  });
};

export const updatePaymentMethod = (clinicId, data) => {
  API({
    method: "PUT",
    url: `/clinic/payments/update/${clinicId}`,
    data,
  });
};

export const deletePaymentMethod = (clinicId, data) => {
  API({
    method: "DELETE",
    url: `/clinic/payments/delete/${clinicId}`,
    data,
  });
};
