import { Select } from "antd";
import PropTypes from "prop-types";

const teethNameTypes = [
  {
    label: "FDI",
    value: "fdi",
  },
  {
    label: "Universal",
    value: "universal",
  },

  {
    label: "Palmer",
    value: "palmer",
  },
];

const TeethType = ({ teethTypeName, onTeethTypeChange }) => {
  return (
    <Select
      size="large"
      placeholder="Select option"
      className="header-select"
      onChange={onTeethTypeChange}
      value={teethTypeName}
      options={teethNameTypes}
    />
  );
};
export default TeethType;

TeethType.propTypes = {
  teethTypeName: PropTypes.string.isRequired,
  onTeethTypeChange: PropTypes.func.isRequired,
};
