import { useState, useEffect } from "react";
import { Row , Tooltip} from "antd";
import AppointmentDetailsCardComponent from "./appointmentDetailsCardComponent";
import AppointmentDetailsButton from "../../shared/buttons/appointmentDetailsButton";
import AppointmentConfirmationModal from "../../shared/modals/appointmentConfirmationModal";
import AppointmentRescheduleModal from "../../shared/modals/appointmentRescheduleModal";
import DeleteConfirmationModal from "../../shared/modals/deleteConfirmationModal";
import TitleComponent from "../../shared/titleComponent";
import { connect } from "react-redux";
import ResetAppointmentConfirmationModal from "components/shared/modals/resetAppointmentConfirmationModal";
import { getVideoButtonState } from "utilities/appointment";
import { videoCallTooltip } from "messages/messages";


const AppointmentDetailsComponent = ({
  startVideoCall,
  selectedAppointment,
  clinicians,
  confirmAppointment,
  confirmAppointmentReschedule,
  cancelAppointment,
  defaultClinicianId,
  confirmAppointmentStatus,
  updateClinician,
  videoCall,
}) => {
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [isInTimeFlag, setIsInTimeFlag] = useState(false);
  const [
    showAppointmentConfirmationModal,
    setShowAppointmentConfirmationModal,
  ] = useState(false);

  const [showRescheduleModal, setShowRescheduleModal] = useState(false);

  const [showdeleteConfirmationModal, setShowdeleteConfirmationModal] =
    useState(false);

  const [showAttendedModal, setShowAttendedModal] = useState(false);
  const [showNoShowModal, setshowNoShowModal] = useState(false);
  // const [enableAttendedButton, setEnableAttendedButton] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(null);

  const [showResetModal, setShowResetModal] = useState(false);

  useEffect(() => {
    let status;
    if (selectedAppointment.appointment_status === 1) {
      status = "PENDING";
    } else if (selectedAppointment.appointment_status === 5) {
      status = "PATIENT_RESCHEDULE";
    } else if ([2, 7].includes(selectedAppointment.appointment_status)) {
      status = "APPROVED";
    } else if ([4, 6, 8].includes(selectedAppointment.appointment_status)) {
      status = "CANCELLED";
    } else if (selectedAppointment.appointment_status === 3) {
      status = "ATTENDED";
    }
    else if ([9].includes(selectedAppointment.appointment_status)) {
      status = "NOSHOW";
    }

    setAppointmentStatus(status);
  }, [selectedAppointment]);

  const modalHeader = (
    <span>
      {`(${selectedAppointment.appointment_type}, ${selectedAppointment.appointment_name})`}
    </span>
  );

  const confirm = () => {
    selectedAppointment.clinician_id = selectedClinician;

    confirmAppointment(selectedAppointment);
  };

  const confirmReschedule = (slot, date, appointment) => {
    appointment.clinician_id = selectedClinician;

    confirmAppointmentReschedule(slot, date, appointment);
  };

  const cancelSelectedAppointment = () => {
    selectedAppointment.clinician_id = selectedClinician;
    cancelAppointment(selectedAppointment);
  };

  const confirmAttendedStatus = () => {
    const status = "attended";
    confirmAppointmentStatus(selectedAppointment, status);
  };

  const confirmNoShowStatus = () => {
    const status = "no show";
    confirmAppointmentStatus(selectedAppointment, status);
  };
  const confirmResetStatus = () => {
    const status = "booked";
    confirmAppointmentStatus(selectedAppointment, status);
  };

  const shouldDisableVideoCall = () => {
    if (videoCall.videoCallStatus === "ONGOING") {
      console.log("ONGOING");
      return true;
    }
    return isInTimeFlag;
  };

  return (
    <div>
      {showAppointmentConfirmationModal && (
        <AppointmentConfirmationModal
          title={
            <TitleComponent
              titleText="Confirm appointment"
              level={4}
            ></TitleComponent>
          }
          content={modalHeader}
          showModal={showAppointmentConfirmationModal}
          onConfirmation={confirm}
          setShow={setShowAppointmentConfirmationModal}
          selectedClinician={selectedClinician}
          clinicians={clinicians}
          selectedAppointment={selectedAppointment}
          mode={"confirm"}
        />
      )}
      {showAttendedModal && (
        <AppointmentConfirmationModal
          title={
            <TitleComponent titleText="Attended" level={4}></TitleComponent>
          }
          content={modalHeader}
          showModal={showAttendedModal}
          onConfirmation={confirmAttendedStatus}
          setShow={setShowAttendedModal}
          selectedAppointment={selectedAppointment}
          mode={"attended"}
        />
      )}
      {showRescheduleModal && (
        <AppointmentRescheduleModal
          title={
            <TitleComponent
              titleText="Reschedule appointment"
              level={4}
            ></TitleComponent>
          }
          content={modalHeader}
          showModal={showRescheduleModal}
          onConfirmation={confirmReschedule}
          setShow={setShowRescheduleModal}
          selectedAppointment={selectedAppointment}
        />
      )}

      {showdeleteConfirmationModal && (
        <DeleteConfirmationModal
          title={
            <TitleComponent
              titleText="Cancel appointment"
              level={4}
            ></TitleComponent>
          }
          content={modalHeader}
          showModal={showdeleteConfirmationModal}
          onConfirmation={cancelSelectedAppointment}
          setShow={setShowdeleteConfirmationModal}
          selectedClinician={selectedClinician}
          clinicians={clinicians}
          selectedAppointment={selectedAppointment}
          mode={"cancel"}
        />
      )}
      {showNoShowModal && (
        <DeleteConfirmationModal
          title={
            <TitleComponent titleText="No Show" level={4}></TitleComponent>
          }
          content={modalHeader}
          showModal={showNoShowModal}
          onConfirmation={confirmNoShowStatus}
          setShow={setshowNoShowModal}
          selectedAppointment={selectedAppointment}
          mode={"status"}
        />
      )}
      {showResetModal && (
        <ResetAppointmentConfirmationModal
          title={
            <TitleComponent titleText="Reset Attendance" level={4}></TitleComponent>
          }
          content={modalHeader}
          showModal={showResetModal}
          onConfirmation={confirmResetStatus}
          setShow={setShowResetModal}
          selectedAppointment={selectedAppointment}
          mode={"status"}
        />
      )}
      <AppointmentDetailsCardComponent
        selectedAppointment={selectedAppointment}
        clinicians={clinicians}
        setSelectedClinician={setSelectedClinician}
        appointmentStatus={appointmentStatus}
        defaultClinicianId={defaultClinicianId}
        selectedClinician={selectedClinician}
        updateClinician={updateClinician}
      />
      {(appointmentStatus === "APPROVED" ) && (
        <div>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            {selectedAppointment.appointment_type_id === 2 && (
              <Tooltip title={getVideoButtonState(selectedAppointment?.start) ? videoCallTooltip : ""}>
              <AppointmentDetailsButton
                // onClick={() => setShowAppointmentConfirmationModel(true)}
                onClick={() => startVideoCall(selectedAppointment)}
                type="primary"
                disabled={getVideoButtonState(selectedAppointment?.start)}
                // disabled={!selectedAppointment?.isVideoEnabled}
              >
                Start Video Call
              </AppointmentDetailsButton>
             </Tooltip>
 
            )}
          </Row>

          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              disabled={!selectedAppointment?.isAttendedEnable}
              onClick={() => setShowAttendedModal(true)}
              type="primary"
            >
              Attended
            </AppointmentDetailsButton>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton onClick={() => setshowNoShowModal(true)}>
              No Show
            </AppointmentDetailsButton>
          </Row>
        </div>
      )}
      {(appointmentStatus === "PENDING" ||
        appointmentStatus === "PATIENT_RESCHEDULE" ||
        appointmentStatus === "APPROVED") && (
        <div>
          {appointmentStatus !== "APPROVED" && (
            <Row justify="center" style={{ marginTop: "1rem" }}>
              <AppointmentDetailsButton
                onClick={() => setShowAppointmentConfirmationModal(true)}
                disabled={!selectedAppointment?.isConfirmButtonDisable}
                type="primary"
              >
                Confirm
              </AppointmentDetailsButton>
            </Row>
          )}

          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              onClick={() => setShowRescheduleModal(true)}
            >
              Reschedule
            </AppointmentDetailsButton>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              onClick={() => {
                setShowdeleteConfirmationModal(true);
              }}
            >
              Cancel Appointment
            </AppointmentDetailsButton>
          </Row>
        </div>
      )}
      {appointmentStatus === "ATTENDED" && (
        <div>
          <Row justify="center" style={{ marginTop: "1rem" }}>


          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              onClick={() => setShowResetModal(true)}
            >
              Reset attendance
            </AppointmentDetailsButton>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton onClick={() => setshowNoShowModal(true)}>
              No Show
            </AppointmentDetailsButton>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              onClick={() => setShowRescheduleModal(true)}
            >
              Reschedule
            </AppointmentDetailsButton>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              onClick={() => {
                setShowdeleteConfirmationModal(true);
              }}
            >
              Cancel Appointment
            </AppointmentDetailsButton>
          </Row>
        </div>
      )}

      {appointmentStatus === "NOSHOW" && (
        <div>

          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              onClick={() => setShowResetModal(true)}
            >
              Reset attendance
            </AppointmentDetailsButton>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              disabled={!selectedAppointment?.isAttendedEnable}
              onClick={() => setShowAttendedModal(true)}
              type="primary"
            >
              Attended
            </AppointmentDetailsButton>

          </Row>

          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              onClick={() => setShowRescheduleModal(true)}
            >
              Reschedule
            </AppointmentDetailsButton>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <AppointmentDetailsButton
              onClick={() => {
                setShowdeleteConfirmationModal(true);
              }}
            >
              Cancel Appointment
            </AppointmentDetailsButton>
          </Row>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
  videoCall: state.videoCall,
});

export default connect(mapStateToProps)(AppointmentDetailsComponent);
