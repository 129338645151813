import {
  VIDEO_CALL_MAXIMIZED,
  VIDEO_CALL_MINIMIZED,
  VIDEO_CALL_TOGGLE_AUDIO,
  VIDEO_CALL_TOGGLE_VIDEO,
  END_VIDEO_CALL,
  SET_VIDEO_CALL_STATUS,
  CURRENT_VIDEO_CALL_APPOINTMENT,
} from "../actions/types";

const initialState = {
  videoCallState: VIDEO_CALL_MAXIMIZED,
  videoCallRoom: null,
  isAudioEnabled: true,
  isVideoEnabled: true,
  videoCallStatus: null,
  currentVideoCallAppointment: null,
};

const videoCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_CALL_MAXIMIZED:
      return {
        ...state,
        videoCallState: VIDEO_CALL_MAXIMIZED,
      };
    case VIDEO_CALL_MINIMIZED:
      return {
        ...state,
        videoCallState: VIDEO_CALL_MINIMIZED,
        videoCallRoom: action.payload.room,
      };
    case VIDEO_CALL_TOGGLE_AUDIO:
      return {
        ...state,
        isAudioEnabled: action.payload,
      };
    case VIDEO_CALL_TOGGLE_VIDEO:
      return {
        ...state,
        isVideoEnabled: action.payload,
      };
    case END_VIDEO_CALL:
      return {
        ...state,
        videoCallState: VIDEO_CALL_MAXIMIZED,
        videoCallRoom: null,
        isAudioEnabled: true,
        isVideoEnabled: true,
      };
    case SET_VIDEO_CALL_STATUS:
      return {
        ...state,
        videoCallStatus: action.payload,
      };
    case CURRENT_VIDEO_CALL_APPOINTMENT:
      return {
        ...state,
        currentVideoCallAppointment: action.payload,
      };
    default:
      return state;
  }
};

export default videoCallReducer;
