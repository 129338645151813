import { useEffect, useState } from "react";
import { Spin } from "antd";

import UploadFilesComponent from "./uploadFilesComponent";

import { dentistClinicApiTasks } from "../../../models/dentistClinic";

import AppointmentTypeContainerComponent from "./appointmentTypeContainerComponent";
import ProductsAndServicesContainerComponent from "./productsAndServicesContainerComponent";
import PaymentSettings from "components/shared/modals/PaymentSettings";
import { useSelector } from "react-redux";
import { clinicIdSelector } from "services/redux/selectors/clinicSelectors";
import { getAllPaymentMethods } from "components/shared/modals/PaymentSettings/services/payment.api";
import AppEmpty from "components/shared/AppEmpty/AppEmpty";
import OffAvailability from "components/shared/OffAvailability/OffAvailability";

export default function ServicesAndResourcesComponent({
  shouldSubmit,
  setAppointments,
  setProductsAndServices,
  setFileList,
  appointmentTypes,
  setAppointmentTypes,
  productOrServiceTypes,
  setProductOrServiceTypes,
  fileList,
  removeFromDB,
  clinic,

  setIsMultiStepButtonDisabled,
  appointmentTypeOptions,
  setAppointmentTypeOptions,
  appointmentNameOptions,
  setAppointmentNameOptions,
  appointmentDurationOptions,
  setAppointmentDurationOptions,
  setAppointmentCurrencyOptions,
  appointmentCurrencyOptions,

  productOrServiceTypeOptions,
  setProductOrServiceTypeOptions,
  productOrServiceNameOptions,
  setProductOrServiceNameOptions,
  productOrServiceCurrencyOptions,
  setProductOrServiceCurrencyOptions,
  getServicesAndResources,
  mode,
  clinicAvailability,
  setClinicAvailability,
}) {
  const clinicId = useSelector(clinicIdSelector);

  const [paymentList, setPaymentList] = useState([]);
  const parseAppointmentNames = (appointmentNameObject) => {
    const appointmentObject = {};
    appointmentNameObject.map((appointment) => {
      appointmentObject[appointment[0].appointmentType] = appointment[1].appointmentNames.map(
        (appointmentName) => {
          return appointmentName;
        }
      );
    });

    Object.keys(appointmentObject).map((typeId) => {
      if (appointmentNameOptions[typeId]) {
        appointmentObject[typeId] = [
          ...appointmentNameOptions[typeId],
          ...appointmentObject[typeId],
        ];
      }
    });

    return appointmentObject;
  };

  const parseProductNames = (productNameObject) => {
    const productObject = {};
    productNameObject.map((productOrService) => {
      productObject[productOrService[0].productType] = productOrService[1].productNames.map(
        (productName) => {
          return productName;
        }
      );
    });

    Object.keys(productObject).map((typeId) => {
      if (productOrServiceNameOptions[typeId]) {
        productObject[typeId] = [...productOrServiceNameOptions[typeId], ...productObject[typeId]];
      }
    });

    return productObject;
  };

  const parseDurations = (durations) => {
    const duarationsArray = durations.map((duration) => {
      return duration;
    });
    return duarationsArray;
  };

  const parseCurrency = (currencies) => {
    const currencyArray = currencies.map((currency) => {
      return currency;
    });
    return currencyArray;
  };

  function shouldChildRender() {
    return (
      Object.keys(productOrServiceNameOptions).length > 0 &&
      productOrServiceTypeOptions.length > 0 &&
      productOrServiceCurrencyOptions.length > 0 &&
      Object.keys(appointmentNameOptions).length > 0 &&
      appointmentTypeOptions.length > 0 &&
      appointmentDurationOptions.length > 0 &&
      appointmentCurrencyOptions.length > 0
    );
  }

  useEffect(() => {
    if (clinicId) {
      (async () => {
        try {
          let res;
          if (mode !== "edit" && mode !== "view") {
            res = await dentistClinicApiTasks.getAll("clinic/getClinicListOfValues");
          } else if (mode === "edit" || mode === "view") {
            res = await dentistClinicApiTasks.getAll("clinic/getListOfValuesForEdit", {
              clinicId: clinicId,
            });
          }

          const resPayments = await getAllPaymentMethods(clinicId);
          setPaymentList(resPayments.data.data.paymentTypes.filter((el) => el.isActive));
          setAppointmentNameOptions(parseAppointmentNames(res.body.clinicAppointmentNames));
          setAppointmentTypeOptions(res.body.clinicAppointmentType);
          setAppointmentDurationOptions(parseDurations(res.body.clinicMeetingDuration));
          setAppointmentCurrencyOptions(parseCurrency(res.body.currencyNames));
          setProductOrServiceTypeOptions(res.body.clinicProductType);
          setProductOrServiceNameOptions(parseProductNames(res.body.clinicProductNames));
          setProductOrServiceCurrencyOptions(parseCurrency(res.body.currencyNames));
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [clinicId]);

  return (
    <div>
      {shouldChildRender() == false && <Spin style={{ marginTop: "2rem" }}></Spin>}
      {shouldChildRender() == true && (
        <div>
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                paddingRight: "2rem",
                position: "absolute",
                top: "25px",
                right: 0,
              }}
            >
              <PaymentSettings
                getServicesAndResources={getServicesAndResources}
                setMultiSelectPaymentList={setPaymentList}
              />
            </div>
            {clinicAvailability && (
              <AppointmentTypeContainerComponent
                paymentList={paymentList}
                setAppointments={setAppointments}
                shouldSubmit={shouldSubmit}
                appointmentTypes={appointmentTypes}
                setAppointmentTypes={setAppointmentTypes}
                appointmentTypeOptions={appointmentTypeOptions}
                setAppointmentTypeOptions={setAppointmentTypeOptions}
                appointmentNameOptions={appointmentNameOptions}
                setAppointmentNameOptions={setAppointmentNameOptions}
                appointmentDurationOptions={appointmentDurationOptions}
                setAppointmentDurationOptions={setAppointmentDurationOptions}
                appointmentCurrencyOptions={appointmentCurrencyOptions}
                setAppointmentCurrencyOptions={setAppointmentCurrencyOptions}
                removeFromDB={removeFromDB}
                mode={mode}
                getServicesAndResources={getServicesAndResources}
              ></AppointmentTypeContainerComponent>
            )}
          </div>
          <div>
            <ProductsAndServicesContainerComponent
              setPaymentList={setPaymentList}
              paymentList={paymentList}
              clinicId={clinicId}
              setProductsAndServices={setProductsAndServices}
              shouldSubmit={shouldSubmit}
              productOrServiceTypes={productOrServiceTypes}
              setProductOrServiceTypes={setProductOrServiceTypes}
              productOrServiceTypeOptions={productOrServiceTypeOptions}
              setProductOrServiceTypeOptions={setProductOrServiceTypeOptions}
              productOrServiceNameOptions={productOrServiceNameOptions}
              setProductOrServiceNameOptions={setProductOrServiceNameOptions}
              productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
              removeFromDB={removeFromDB}
              getServicesAndResources={getServicesAndResources}
              mode={mode}
            ></ProductsAndServicesContainerComponent>
          </div>
          {/* <div style={{ marginTop: "2rem" }}>
            <UploadFilesComponent
              setFileList={setFileList}
              shouldSubmit={shouldSubmit}
              setIsMultiStepButtonDisabled={setIsMultiStepButtonDisabled}
              fileList={fileList}
              mode={mode}
            ></UploadFilesComponent>
          </div> */}
        </div>
      )}
    </div>
  );
}
