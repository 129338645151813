import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ isSender, name, content, date, primaryColor }) => {
  return (
    <div className={isSender ? "senderComment" : "receiverComment"}>
      <div 
        className="senderHistory" 
        style={{ background: isSender ? primaryColor : '#f0f0f0' }} // Customize the background color
      >
        <span className="name">{name}</span>
        <p className="text">{content}</p>
        <span className="date">{date}</span>
      </div>
      <br />
    </div>
  );
};

Message.propTypes = {
  isSender: PropTypes.bool.isRequired, // ifmessage is from the sender
  name: PropTypes.string.isRequired, // The name of the sender or receiver
  content: PropTypes.string.isRequired, // content of the message
  date: PropTypes.string.isRequired, //  date of the message
  primaryColor: PropTypes.string, // color for the sender's messages
};

export default Message;
