import dayjs from "dayjs";
import { calculateProductsTotal, getProductServiceName, getProductServiceNames, getProductServiceTotalQuantity } from "utilities/treatment";

export  const createLabInvoiceTableData = (invoiceList) => {
    return invoiceList.map((dataObj, index) => {
      return {
        key: index,
        shippingFiles: dataObj?.shippingFiles || [],
        invoiceNo: dataObj.invoice_id ? dataObj.invoice_id : "Not Available",
        invoiceDate: dataObj.invoice_date
          ? dayjs(dataObj.invoice_date).format("DD/MM/YYYY")
          : "Not Available",
        patientId: dataObj.patient_id,
        patientName:
          dataObj.patient_first_name && dataObj.patient_last_name
            ? dataObj.patient_first_name + " " + dataObj.patient_last_name
            : "Not Available",
            productServiceName:  getProductServiceName(dataObj),
            productServiceNames:  getProductServiceNames(dataObj),
        paymentStatus: dataObj.payment_status
          ? dataObj.payment_status
          : "Not Available",
        amount: dataObj.free ? `${dataObj.currency_name} 0 (Free)` : dataObj.product_price
          ? dataObj.currency_name + " " + dataObj.product_price : "Not Available",
          totalPrice:  calculateProductsTotal(dataObj?.labProductList),
          quantity: getProductServiceTotalQuantity(dataObj),
         
      };
    });
  };