import dayjs from "dayjs";
export const FDI_CHART = [
  {
    name: "18",
    universalName: "1",
    palmerName: "UR8",
    showName: "18",
  },
  {
    name: "17",
    universalName: "2",
    palmerName: "UR7",
    showName: "17",
  },
  {
    name: "16",
    universalName: "3",
    palmerName: "UR6",
    showName: "16",
  },
  {
    name: "15",
    universalName: "4",
    palmerName: "UR5",
    showName: "15",
  },
  {
    name: "14",
    universalName: "5",
    palmerName: "UR4",
    showName: "14",
  },
  {
    name: "13",
    universalName: "6",
    palmerName: "UR3",
    showName: "13",
  },
  {
    name: "12",
    universalName: "7",
    palmerName: "UR2",
    showName: "12",
  },
  {
    name: "11",
    universalName: "8",
    palmerName: "UR1",
    showName: "11",
  },
  {
    name: "21",
    universalName: "9",
    palmerName: "UL1",
    showName: "21",
  },
  {
    name: "22",
    universalName: "10",
    palmerName: "UL2",
    showName: "22",
  },
  {
    name: "23",
    universalName: "11",
    palmerName: "UL3",
    showName: "23",
  },
  {
    name: "24",
    universalName: "12",
    palmerName: "UL4",
    showName: "24",
  },
  {
    name: "25",
    universalName: "13",
    palmerName: "UL5",
    showName: "25",
  },
  {
    name: "26",
    universalName: "14",
    palmerName: "UL6",
    showName: "26",
  },
  {
    name: "27",
    universalName: "15",
    palmerName: "UL7",
    showName: "27",
  },
  {
    name: "28",
    universalName: "16",
    palmerName: "UL8",
    showName: "28",
  },
  {
    name: "48",
    universalName: "17",
    palmerName: "LR8",
    showName: "48",
  },
  {
    name: "47",
    universalName: "18",
    palmerName: "LR7",
    showName: "47",
  },
  {
    name: "46",
    universalName: "36",
    palmerName: "LR6",
    showName: "46",
  },
  {
    name: "45",
    universalName: "35",
    palmerName: "LR5",
    showName: "45",
  },
  {
    name: "44",
    universalName: "34",
    palmerName: "LR4",
    showName: "44",
  },
  {
    name: "43",
    universalName: "33",
    palmerName: "LR3",
    showName: "43",
  },
  {
    name: "42",
    universalName: "32",
    palmerName: "LR2",
    showName: "42",
  },
  {
    name: "41",
    universalName: "31",
    palmerName: "LR1",
    showName: "41",
  },
  {
    name: "31",
    universalName: "30",
    palmerName: "LL1",
    showName: "31",
  },
  {
    name: "32",
    universalName: "29",
    palmerName: "LL2",
    showName: "32",
  },
  {
    name: "33",
    universalName: "28",
    palmerName: "LL3",
    showName: "33",
  },
  {
    name: "34",
    universalName: "27",
    palmerName: "LL4",
    showName: "34",
  },
  {
    name: "35",
    universalName: "26",
    palmerName: "LL5",
    showName: "35",
  },
  {
    name: "36",
    universalName: "25",
    palmerName: "LL6",
    showName: "36",
  },
  {
    name: "37",
    universalName: "24",
    palmerName: "LL7",
    showName: "37",
  },
  {
    name: "38",
    universalName: "23",
    palmerName: "LL8",
    showName: "38",
  },
];

export const showTeethName = (toothNo, structure) => {
  if (toothNo) {
    const filtered = FDI_CHART.filter((item) => item.name == toothNo);
    let name = toothNo;
    if (structure === "universal") {
      name = filtered[0].universalName;
    } else {
      name = filtered[0].palmerName;
    }
    return name;
  } else {
    return "";
  }
};

export function removeUnderscore(text) {
    if(text){
        const withoutUnderscore = text.replace(/_/g, " ");
        const capitalizedText = withoutUnderscore.replace(/\b\w/g, (match) =>
          match.toUpperCase()
        );
        return capitalizedText;
    }
    return "--"

}


export const prescriptionPDFDataMapper = (formData) =>{
    return {
        treatmentTypes: formData?.treatmentTypes?.map((el) => {
          const aligner = {
            aligner: removeUnderscore(el.aligner),
            arches: removeUnderscore(el.arch_treated),
            whitening: removeUnderscore(el.retainer_and_whitening),
            whitening_arches: removeUnderscore(el.retainer_and_whitening_type),
            comment: el.retainer_and_whitening_comment,
          };
          return aligner;
        }),
        prescriptionPhasing: formData?.prescription_phasing,
        prescription_id: formData?.prescription_id,
        id: formData?.id,
        
        teethChart: formData?.teethChart || [],
        ipr: formData?.prescription_is_ipr ? formData?.prescription_is_ipr : "--",
        overjet: removeUnderscore(formData?.overjet) || "",
        overbite: removeUnderscore(formData?.overbite) || "",
        alignment: removeUnderscore(formData?.incisal_edge_alignment),
        bioType: formData?.periodontium_biotype ?formData?.periodontium_biotype : "--",
        recession: formData?.periodontium_recession_miller ? formData?.periodontium_recession_miller :"--",
        dentalClass: removeUnderscore(formData?.angle_dental_class),
        skeletalClass: removeUnderscore(formData?.angle_skeleton_class),
        skeletalDivision:  removeUnderscore(formData?.angle_skeleton_division),
        skeletalSide:  removeUnderscore(formData?.angle_skeleton_side),
        dentalDivision:  removeUnderscore(formData?.angle_dental_division),
        dentalSide:  removeUnderscore(formData?.angle_dental_side),
        parafunctionDetails: formData?.occlusion_angle_deviation ? formData.occlusion_angle_deviation : "--" ,
        midline: removeUnderscore(formData?.prescription_is_midline_correction) || "",
        duration: formData?.prescription_aligner_duration,
        prescriptionDetails: formData?.prescription_details || "--",
        labId: formData?.prescription_lab_id,
        upperArchUrl: formData?.prescription_upper_arch_file_url || "",
        lowerArchUrl: formData?.prescription_lower_arch_file_url || "",
        biteScanUrl: formData?.prescription_bite_scans_file_url || "",
        crowding: removeUnderscore(formData?.prescription_complaint_crowding_upper) || "",
        spacing: removeUnderscore(formData?.prescription_complaint_spacing_upper) || "",
        openbite: removeUnderscore(formData?.prescription_complaint_open_bite_anterior) || "",
        crossbite: removeUnderscore(formData?.prescription_complaint_cross_bite_anterior) || "",
        others: formData?.prescription_complaint_others || "",
        attachments: removeUnderscore(formData?.prescription_attachments_upper_lower) || "",
        prescriptionVersions: formData?.prescriptionVersions,
        version: formData?.prescription_version,
        openBiteValue: removeUnderscore(formData?.openBiteValue),
        crossBiteValue: removeUnderscore(formData?.crossBiteValue),
        caseId: formData?.case_id,
        editedDate: formData?.prescription_edited_date,
        phasing:formData?.phasing,
        labProductId:formData?.labProductId,
        smilelignTreatmentTypeId:formData?.smilelignTreatmentTypeId || null,
        smilelignArches: formData?.smilelignArches || "",
        smilelignWhitening: formData?.smilelignWhitening || null,
        smilelignTreatmentTypeData: formData?.smilelignTreatmentTypeData || null,
        createdAt : formData?.createdAt
      };
}