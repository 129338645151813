import { useState, useEffect,useCallback } from "react";

import { Row, Spin, message } from "antd";
import { dentistClinicApiTasks } from "../../models/dentistClinic";
import PatientListComponent from "../../components/private/patient-journey/patientListComponent";
import { connect } from "react-redux";
import { fetchJourneyStatus } from "../../services/redux/actions/clinicActions";
import { getPatientList } from "api/clinic";
import { useLocation } from "react-router-dom";

function PatientList({ clinic, fetchJourneyStatus }) {
  const [patientsList, setPatientsList] = useState([]);
  const [isPatientTableSpinning, setIsPatientTableSpinning] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [journeyStatus, setJourneyStatus] = useState("");
  const [impressionStatus, setImpressionStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  useEffect(() => {
    if (queryParams) {
      let typeFilter;
      const typeParams=queryParams.get("type") ;
      if(typeParams=='tp-ready'){
        typeFilter="Treatment plan Ready"
      }
      else if (typeParams=='tp-production'){
        typeFilter="Aligner production Stage"
      }
      if(typeFilter){
        setJourneyStatus(typeFilter);
      }

    }
  }, []);

  useEffect(() => {
    fetchJourneyStatus();
  }, []);

  // useEffect(() => {
  //   if (searchKey && searchKey !== "") setPaginationPage(1);
  // }, [searchKey]);

  // const _getListOfValues = async () => {
  //   try {
  //     let response = await dentistClinicApiTasks.getAll(
  //       "clinic/getClinicListOfValues",
  //       {
  //         pageName: "patientlist",
  //       }
  //     );
  //     debugger;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const _getPatientList = useCallback(async () => {
    setIsPatientTableSpinning(true);
    if (searchKey) {
      setSearchLoading(true);
    }
    try {
      const params = {
        limit: paginationLimit,
        page: paginationPage,
        clinicId: clinic.clinicId,
        search: searchKey,
        startDate: fromDate,
        endDate: endDate,
        journeyStatus: journeyStatus !== "" ? `"${journeyStatus}"` : "",
        paymentStatus: paymentStatus !== "" ? `"${paymentStatus}"` : "",
      };
      if (clinic.companyType === 3) {
        params.companyType = clinic.companyType;
        params.impressionStatus =
          impressionStatus !== "" ? `"${impressionStatus}"` : "";
      }

      const list = await getPatientList(params)

      setPatientsList(list.body.data);
      setTotalRecords(list.body.matching_records);
    } catch (error) {
      console.log(error);

      message.error("Failed to load patients list");
    } finally {
      setSearchLoading(false);
      setIsPatientTableSpinning(false);
    }
  }, [
    paginationLimit,
    searchKey,
    paginationPage,
    journeyStatus,
    endDate,
    fromDate,
    impressionStatus,
    clinic.companyType,
    clinic.clinicId,
    paymentStatus
  ]);


  useEffect(() => {
    if (clinic.clinicId) {
      // console.log(searchKey, paginationPage);
      // console.log("here");
      _getPatientList();
    }
  }, [
    clinic.clinicId,
    paginationPage,
    // totalRecords,
    paginationLimit,
    journeyStatus,
    fromDate,
    endDate,
    searchKey,
    impressionStatus,
    paymentStatus,
    _getPatientList,
  ]);
  // const onSearch = (searchKey) => {
  //   // if (searchKey !== "") setPaginationPage(1);
  //   searchKey = searchKey.trim();
  //   if (searchKey.length >= 3 || searchKey === "") _getPatientList(searchKey);
  // };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationPage(pagination.current);
    setPaginationLimit(pagination.pageSize);

    if (filters && filters.journeyStatus?.length > 0) {
      setJourneyStatus(filters.journeyStatus.toString());
    } else setJourneyStatus("");

    if (filters && filters.paymentStatus?.length) {
      setPaymentStatus(filters.paymentStatus.toString());
    } else {
      setPaymentStatus("");
    }

    if (filters && filters.impressionStatus?.length) {
      setImpressionStatus(filters.impressionStatus.toString());
    } else {
      setImpressionStatus("");
    }
  };

  const handleSearch = (value) => {
    // console.log("execute");
    setSearchKey(value);
    setPaginationPage(1);
  };

  const handleDateFilter = (confirm, start = "", end = "") => {
    setFromDate(start);
    setEndDate(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };

  return (
    <div>
      <Spin spinning={isPatientTableSpinning}>
        <div style={{ width: "102%" }}>
          <Row justify="center">
            <PatientListComponent
              searchLoading={searchLoading}
              patientsList={patientsList}
              onSearch={handleSearch}
              paginationPage={paginationPage}
              paginationLimit={paginationLimit}
              onPagination={handleTableChange}
              totalRecords={totalRecords}
              handleDateFilter={(confirm, start, end) =>
                handleDateFilter(confirm, start, end)
              }
              onStatusFilter={setJourneyStatus}
              fromDate={fromDate}
              endDate={endDate}
              getPatients={_getPatientList}
            />
          </Row>
        </div>
      </Spin>
    </div>
  );
}

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps, { fetchJourneyStatus })(PatientList);
