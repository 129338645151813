import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Form, Input, Row, Col, Button, Spin, Select, Checkbox, message } from "antd";
import { Typography } from "antd";
import { connect, useSelector } from "react-redux";
import errorModal from "../../components/shared/modals/errorModal";
import SignUpConfirmation from "../../components/private/signUpConfirmation";
import axios from "axios";
import { Auth } from "aws-amplify";
import { getPatientCountryCodesList } from "services/redux/selectors/clinicSelectors";
import "../../assets/styles/routes/registration.scss";
import { RedirectLink } from "components/shared/RedirectLink/index";
import { InfoIcon } from "components/shared/InfoIcon/index";
import { AnchorTag } from "components/shared/AnchorTag/index";
import { LogInPageLogo, companyName, companyType, smilePrivacyUrl, smileTermsUrl, prefix, country as countryName } from "colors-config";
import {
  addClinicOnboardingRegisterLink,
  checkClinicEmailExists,
  getSingleOnBoardClinic,
  onboardingPartnershipPublic,
  updateClinicOnboardingProgressStatus,
} from "api/clinic";
import { passwordValidator, stringDecode } from "utilities/commonMethod";
import { ONBOARDING_PROGRESS } from "../../constants";
import { onBoardingInviteExpiredMessage, alreadyInProgressOnboardingMessage, confirmPasswordErrorMessage } from "messages/messages";
// import {
//   getPatientCountryCodesList,
// } from "../../services/redux/selectors/clinicSelectors";
const PUBLIC_API_BASE_URL = process.env.REACT_APP_PUBLIC_API_BASE_URL;

const { Title } = Typography;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 8,
      offset: 4,
    },
    lg: {
      span: 8,
      offset: 2,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 16,
      offset: 4,
    },
    lg: {
      span: 16,
      offset: 2,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
    lg: {
      span: 16,
      offset: 2,
    },
  },
};

const DELETE_USER_PUBLIC = process.env.REACT_APP_DELETE_USER_PUBLIC;

export default function Registration() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const countryCodes = useSelector(getPatientCountryCodesList);

  // Accessing individual query parameters
  const keyParam = queryParams.get("key");
  const [patientEmail, setPatientEmail] = useState("");

  // const [patientUniqueId] = useState(match.params.id);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isButtonSpinning, setIsButtonSpinning] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  // const [countryCodes, setCountryCodes] = useState([]);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useState("");
  const [isButtonEnable, setIsButtonEnable] = useState(true);
  const containerRef = useRef();
  const [countryCodePrefix, setCountryCodePrefix] = useState("");
  const patientCountriesList = useSelector(getPatientCountryCodesList);
  const [form] = Form.useForm();
  const [showClinicName, setShowClinicName] = useState(false);
  const [showClinicianName, setShowClinicianName] = useState(false);

  const handleLabClinicOnBoarding = async (email) => {


    return new Promise(async (resolve, reject) => {
      if (!email) {
        return resolve();
      }
      try {
        const { data } = await getSingleOnBoardClinic(email);
        const { onboarding_status, clinicEmail, countryCode, phone, clinicName, clinicianName } = data;
        if (onboarding_status !== ONBOARDING_PROGRESS.ONBOARDING_REQUESTED) {
          message.error(alreadyInProgressOnboardingMessage);
          history.push("/404")
          return resolve();
        }
        if (onboarding_status === ONBOARDING_PROGRESS.ONBOARDING_REQUESTED) {
          form.setFieldsValue({ email: clinicEmail, prefix: countryCode, phone: phone, clinicName: clinicName, clinicianName: clinicianName });
          setCountryCodePrefix(countryCode)
          const fieldInstance = form.getFieldInstance("email");
          if (fieldInstance) {
            fieldInstance.input.disabled = true;
          }
        }
        return resolve();
      } catch (error) {
        if (error?.response?.data.message === "clinic data not found") {
          message.error(onBoardingInviteExpiredMessage);
          history.push("/404")
        }
        return reject();
      } finally {
      }
    });
  };


  const checkQueryString = () => {
    if (!keyParam) {
      return null;
    }
    const obj = stringDecode(keyParam);
    return obj;
  };

  useEffect(() => {
    onInitialLoad();
  }, []);

  const onInitialLoad = async () => {
    try {
      setIsSpinning(true);
      // await getCountryCodes();
      await _getPolicyLinks();
      const paramsData = checkQueryString(keyParam);
      console.log("paramsData",paramsData);
      if (keyParam && paramsData && paramsData?.type === ONBOARDING_PROGRESS.ONBOARDING_REQUESTED) {
        await handleLabClinicOnBoarding(paramsData?.email);
      } else {
        console.log("prefix",prefix);
        setCountryCodePrefix(prefix);
      }
      if(keyParam && paramsData && paramsData.labId){
        form.setFieldsValue({ country: countryName });
        setShowClinicName(true);
        setShowClinicianName(true);
      }
    } catch (error) {
      console.log("error", error);
    }
    finally {
      setIsSpinning(false);
    }

  }

  const _getPolicyLinks = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/public/getGenericTC`);
        let termsUrl = smileTermsUrl;
        let privacyUrl = smilePrivacyUrl;
        if (res?.data?.body?.privacy_policy?.url && res?.data?.body?.terms_of_service?.url) {
          if (companyType !== 1) {
            termsUrl = res?.data?.body?.terms_of_service?.url;
            privacyUrl = res?.data?.body?.privacy_policy?.url;
          }
        }
        setPrivacyPolicyUrl(privacyUrl);
        setTermsOfServiceUrl(termsUrl);
        resolve();
      } catch (error) {
        console.log(error);
        reject();
      }
    })
  };

  const prefixSelector = (
    <Select size="large"  
    showSearch
    value={countryCodePrefix}
     onChange={(value) => setCountryCodePrefix(value)} 
     dropdownClassName="country-dropdown"
     className="country-select"
     dropdownStyle={{ width: "300px" }}
     style={{ width: 110 }}
     labelRender={(props) => {
       return props.label;
     }}
     options={countryCodes?.map((code, index) => {
       return {
         ...code,
         key: index,
         value: code.countryCallingCodes,
         title: `${code.emoji} ${code.countryCallingCodes}`,
         label:code.countryCallingCodes,
       };
     })}
     optionRender={(option) => {
       const { data } = option;
       return (
         <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
           <span className="emoji-font">{data.emoji}</span>
           <span>{data.countryCallingCodes}</span>
           <span>| {data.name}</span>
         </div>
       );
     }}
     filterOption={(input, option) => {
       const lowerCasedInput = input.toLowerCase();
       return (
         option.value.toLowerCase().includes(lowerCasedInput) ||
         option.name.toLowerCase().includes(lowerCasedInput)
       );
     }}
   />
    
  );

  const _register = async ({ email, username, password, phone_number, clinicName, country,clinicianName }, phoneDetails) => {
    try {
      setIsButtonSpinning(true)
      // try {
      //   const resEmail = await checkClinicEmailExists(email);
      // } catch (error) {
      //   errorModal("The email is already registered");
      //   setIsButtonSpinning(false);

      //   throw error;
      // }

      await Auth.signUp({
        username,
        password,
        attributes: {
          email, // optional
          phone_number, // optional - E.164 number convention
          // other custom attributes
        },
      });
      // setIsRegistered(true);

      setPatientEmail(email);
      
      if (keyParam) {
        const obj = stringDecode(keyParam);
        let callOnboardingPartnershipApi= false;
        if (obj.type === ONBOARDING_PROGRESS.ONBOARDING_REQUESTED) {
          await updateClinicOnboardingProgressStatus({
            email: username,
            status: ONBOARDING_PROGRESS.VERIFICATION_PENDING,
            phone:phoneDetails.phone,
            countryCode:phoneDetails.countryCode
          });
          // callOnboardingPartnershipApi=true;
        }
        if(!!obj.labId){
          await addClinicOnboardingRegisterLink({
            labId: obj.labId,
            clinicEmail: username,
            phone:phoneDetails.phone,
            countryCode:phoneDetails.countryCode,
            clinicName: clinicName,
            clinicianName:clinicianName,
            country:country
          });
          callOnboardingPartnershipApi=true;
        }
        if(callOnboardingPartnershipApi){
          const inputData={
            clinicEmail:username,
            countryCode:phoneDetails.countryCode,
            phoneNo:phoneDetails.phone
          };
          if(clinicName){
            inputData.clinicName =  clinicianName;
          }
          if(country){
            inputData.country = country;
          }
          if(clinicianName){
            inputData.clinicianName = clinicName;
          }
          await onboardingPartnershipPublic(inputData)
        }
      }
      history.push("/confirm-user?type=registered")
    } catch (error) {
      console.dir(error);
      setIsButtonSpinning(false);

      if (error.code) {
        if (error.code === "UsernameExistsException") {
          try {
            await axios.get(`${DELETE_USER_PUBLIC}`, {
              params: { email, user: "Clinic" },
            });

            _register({ email, username, password, phone_number },phoneDetails);
          } catch (err) {
            // console.log(err);
            if (error.code) {
              errorModal(error.message);
            }
          }
        } else {
          errorModal(error.message);
        }
      }

      setIsRegistered(false);
    } finally{
      setIsButtonSpinning(false);
    }
  };
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  // const getCountryCodes = async () => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const res = await axios.get(PUBLIC_API_BASE_URL + "/getCountryCodes");
  //       let countryCd = res?.data?.body?.countryCodes;
  //       if (countryCd && countryCd.length) {
  //         countryCd = getUniqueListBy(countryCd, "country_code_id");
  //       }
  //       const codes = countryCd?.sort((a, b) =>
  //         a.country_code_name < b.country_code_name
  //           ? -1
  //           : b.country_code_name < a.country_code_name
  //             ? 1
  //             : 0
  //       );
  //       setCountryCodes(codes);
  //       const prefix = codes ? companyType === 5 ? codes[2].country_code_name : codes[0].country_code_name : null;
  //       setCountryCodePrefix(prefix);
  //       resolve();
  //     } catch (error) {
  //       console.log(error);
  //       return reject(error);
  //     }
  //   });
  // };


  const onFinish = (values) => {
    const { email, password, phone, clinicName, country, clinicianName } = values;
    const phone_number = countryCodePrefix + phone;
    // setIsButtonSpinning(true);
    const cognitoRegistrationData = {
      username: email,
      password,
      email,
      phone_number,
      clinicName,
      country,
      clinicianName
    };
    _register(cognitoRegistrationData,{
      phone:phone,
      countryCode:countryCodePrefix
    });
  };
  return (
    <div ref={containerRef} className="registration-container" style={{ fontFamily: "Mulish" }}>
      <Row>
        <Col lg={12}>
          <div className="registration-image-container">
            <img className="image" src={LogInPageLogo} alt="placeholder" />
          </div>
        </Col>

        <Col lg={12} md={24} sm={24} xs={24}>
          {/* {isRegistered && <SignUpConfirmation username={patientEmail}></SignUpConfirmation>} */}
          {!isRegistered && (
            <div className="registration-form-container">
              <div className="register-form">
                {" "}
                <div className="title-container">
                  <Title
                    level={1}
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      marginBottom: "0.1rem",
                      //fontSize:"30px"
                    }}
                  >
                    Clinician Registration
                  </Title>

                  <p
                    style={{
                      fontSize: "15px",
                      color: "#89909A",
                      display: "inline",
                    }}
                  >
                    Let’s take the first step to start your journey with {companyName}
                  </p>
                </div>
                <Spin spinning={isSpinning}>
                  <Form
                    className="registration-form"
                    {...formItemLayout}
                    form={form}
                    autoComplete="off"
                    layout={"vertical"}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="email"
                      label="Email address"
                      rules={[
                        {
                          type: "email",
                          message: "Invalid email address",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Email address" autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                      name="phone"
                      label="Phone number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                        () => ({
                          validator(_, value) {
                            const convertedValue = Number(value);
                            if (!value) {
                              return Promise.resolve();
                            } else if (
                              !isNaN(convertedValue) &&
                              (value.length === 9 || value.length === 10 || value.length === 8)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Invalid phone number");
                          },
                        }),
                      ]}
                    >
                      <Input
                        size="large"
                        addonBefore={prefixSelector}
                        autoComplete="off"
                        style={{
                          width: "100%",
                        }}
                        placeholder="Phone Number"
                      />
                    </Form.Item>
                    {showClinicName ?
                      <>
                        {/* <Form.Item
                          name="clinicianName"
                          label="Clinic Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Clinic Name!",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Clinic Name" autoComplete="off" />
                        </Form.Item> */}

                        <Form.Item
                          name="country"
                          label="Country"
                          rules={[
                            {
                              required: true,
                              message: "Please select your Country",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            size="large"
                            style={{ textAlign: "left" }}
                            placeholder="Enter Country"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {patientCountriesList?.map((country, index) => (
                              <Select.Option key={index} value={country.name}>
                                {country.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                      : null
                    }
                      <Form.Item
                        name="clinicName"
                        label="Clinician Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Clinician Name!",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Clinician Name" autoComplete="off" />
                      </Form.Item>
                    
                    <Form.Item
                      name="password"
                      label={
                        <span>
                          Password&nbsp;
                          <InfoIcon
                            className="registerInfo"
                            info={
                              <div>
                                <ul style={{ padding: "5px 10px" }}>
                                  <li>At least 1 lowercase alphabetical character</li>
                                  <li>At least 1 uppercase alphabetical character</li>
                                  <li>At least 1 numeric character</li>
                                  <li>At least 1 special character</li>
                                  <li>Minimum 8 characters</li>
                                </ul>
                              </div>
                            }
                          />
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()\-_+=!@#%^&*.,><’:;|~`])\S{8,99}$/,
                          message: "Invalid password",
                        },
                        {
                          validator: passwordValidator,
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        size="large"
                        placeholder="Password"
                        autoComplete="new-password"
                      />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      label="Confirm password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(confirmPasswordErrorMessage);
                          },
                        }),
                      ]}
                    >
                      <Input.Password size="large" placeholder="Confirm Password" />
                    </Form.Item>
                    <Form.Item>
                      <div style={{ marginLeft: "-36px" }}>
                        <p>
                          <Checkbox
                            onChange={() => {
                              setIsButtonEnable(!isButtonEnable);
                            }}
                          >
                            I agree to the{" "}
                            <AnchorTag
                              url={termsOfServiceUrl}
                              target="_blank"
                              rel="noreferrer"
                              text="Terms of Service"
                            />
                            &nbsp;and&nbsp;{" "}
                            <AnchorTag
                              url={privacyPolicyUrl}
                              target="_blank"
                              rel="noreferrer"
                              text="Privacy Policy"
                            />
                          </Checkbox>
                        </p>
                      </div>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                      <div
                        className="btn-container"
                        style={{ marginTop: "-40px", marginBottom: "0" }}
                      >
                        <Button
                          disabled={isButtonEnable}
                          type="primary"
                          className="registration-button"
                          htmlType="submit"
                          loading={isButtonSpinning}
                        >
                          Create my account
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Spin>
                <Row>
                  <Col
                    xs={{
                      span: 24,
                      offset: 1,
                    }}
                    sm={{
                      span: 16,
                      offset: 4,
                    }}
                    lg={{
                      span: 16,
                      offset: 2,
                    }}
                    xl={{ span: 14, offset: 3 }}
                    xxl={{ span: 16, offset: 2 }}
                  ></Col>
                </Row>
                <Row
                  style={{
                    marginTop: "0px",
                    // marginBottom: "2rem"
                  }}
                >
                  <Col
                    xs={{
                      span: 24,
                      offset: 0,
                    }}
                    sm={{
                      span: 16,
                      offset: 4,
                    }}
                    lg={{
                      span: 16,
                      offset: 2,
                    }}
                    xl={{ span: 16, offset: 2 }}
                    xxl={{ span: 16, offset: 2 }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#1D1D1D",
                        fontSize: "17px",
                        display: "inline",

                        width: "100%",
                      }}
                    >
                      Already have an account?
                    </p>
                    <p
                      style={{
                        fontSize: "17px",
                        display: "inline",
                        marginLeft: "0.5rem",
                        fontWeight: "bolder",

                        width: "100%",
                      }}
                    >
                      <RedirectLink text={"Log in"} url={"/login"} />
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
