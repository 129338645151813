import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import TreatmentProductToolTip from "./TreatmentProductToolTip";

export const treatmentProductsTableColumn = [
  {
    title: "Lab Product/Service Name",
    key: "labProduct",
    dataIndex: "labProduct",
    width: "30%",
  },
  {
    title: "Quantity",
    key: "qty",
    dataIndex: "qty",
    width: "30%",
  },
  {
    title: "Lab Total Cost",
    key: "total",
    dataIndex: "total",
    width: "30%",
    render: (total, record) => (
      <p>
        {total}
        {record.discountGiven && (
          <AppToolTip
            title={
              <TreatmentProductToolTip
                currency={record.currency}
                price={record.price}
                netPrice={record.netPrice}
                discountPrice={record.discountPrice}
              />
            }
            iconStyles={{ marginLeft: "10px" }}
          />
        )}
      </p>
    ),
  },
];
