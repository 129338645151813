import { Modal } from "antd";

const SendAppLinkModal = ({
  title,
  content,
  isModalVisible,
  handleOk,
  handleCancel,
  loading
}) => {

  return (
    <>
      <Modal
        title={title}
        confirmLoading={loading}
        okText="Send email"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {content}
      </Modal>
    </>
  );
};

export default SendAppLinkModal;
