import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Divider,
  Checkbox,
  message,
  Col,
  Button
} from "antd";

import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import DeleteConfirmationModal from "../../shared/modals/deleteConfirmationModal";
import { InfoIcon } from "components/shared/InfoIcon/index";
import { companyType, primaryColor, appCurrencyName } from "colors-config";

const { Option } = Select;

const AppointmentTypeFormComponent = ({
  setAppointmentTypes,
  appointmentFields,
  appointmentTypes,
  appointmentTypeOptions,
  appointmentNameOptions,
  appointmentDurationOptions,
  appointmentCurrencyOptions,
  setAppointmentNameOptions,
  removeFromDB,
  mode,
  paymentList,
}) => {
  const [newAppointmentNameOption, setNewAppointmentNameOption] = useState("");
  const [selectedClinicType, setSelectedClinicType] = useState(
    appointmentFields.appointmentType
      ? JSON.parse(appointmentFields.appointmentType).clinic_appointment_type_id
      : appointmentTypeOptions[0].clinic_appointment_type_id
  );
  const [showdeleteConfirmationModal, setShowdeleteConfirmationModal] =
    useState(false);

  const [hideAppointment, setHideAppointment] = useState(false);
  const [disablePrice, setDisablePrice] = useState(appointmentFields.free ? true : false);

  const [form] = Form.useForm();

  const initialFormValues = {
    appointmentName: appointmentFields.appointmentName
      ? appointmentFields.appointmentName
      : JSON.stringify(appointmentNameOptions[selectedClinicType][0]),

    appointmentType: appointmentFields.appointmentType
      ? appointmentFields.appointmentType
      : JSON.stringify(appointmentTypeOptions[0]),
    durationOfMeeting: appointmentFields.durationOfMeeting
      ? appointmentFields.durationOfMeeting
      : JSON.stringify(appointmentDurationOptions[0]),

    price: appointmentFields.price ? appointmentFields.price : 0,
    description: appointmentFields.description
      ? appointmentFields.description
      : "--",
    appointmentCurrency: appointmentFields.appointmentCurrency
      ? appointmentFields.appointmentCurrency
      : JSON.stringify(appCurrencyName),
    paymentIds: appointmentFields?.paymentIds || [],
    free: appointmentFields?.free ? true : false,
  };

  useEffect(() => {
    form.resetFields();

    const formValues = form.getFieldsValue(true);
    const appointmentTypeId = appointmentFields.id;
    if (mode !== "view") {
      setAppointmentTypes({
        ...appointmentTypes,
        [appointmentTypeId]: { ...appointmentFields, ...formValues },
      });
    }
  }, []);

  useEffect(() => {
    if (mode === "edit" && Object.keys(appointmentTypes).length === 1) {
      const formValues = form.getFieldsValue(true);
      const appointmentTypeId = appointmentFields.id;
      setAppointmentTypes({
        ...appointmentTypes,
        [appointmentTypeId]: { ...appointmentFields, ...formValues },
      });
    }
  }, [mode]);

  const currencySelector = (
    <Form.Item name="appointmentCurrency" noStyle>
      <Select 
       showSearch
       filterOption={(input, option) =>
         option.children
           .toLowerCase()
           .indexOf(input.toLowerCase()) >= 0
       }
      size="large" disabled={mode === "view" || disablePrice} style={{ width: 80 }}>
        {appointmentCurrencyOptions.map((currency) => {
          return (
            <Option key={JSON.stringify(currency)}>
              {currency.currency_name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  function onValueChange(val) {
    // console.log("val", val)

    if (val.appointmentType) {
      // console.log("val.appointmentType", val.appointmentType)
      const appointmentSelected = JSON.parse(val.appointmentType);
      setSelectedClinicType(appointmentSelected.clinic_appointment_type_id);
      form.setFieldsValue({
        appointmentName: JSON.stringify(
          appointmentNameOptions[
            appointmentSelected.clinic_appointment_type_id
          ][0]
        ),
      });
    }
    // let appointmentTypeId;

    // if (mode === "edit") {
    //   appointmentTypeId = JSON.parse(appointmentFields.;
    // } else {
    // }
    // console.log("formValues", form.getFieldsValue())
    const appointmentTypeId = appointmentFields.id;

    setAppointmentTypes({
      ...appointmentTypes,
      [appointmentTypeId]: { ...appointmentFields, ...val },
    });
  }

  const onAppointmentNameOptionChange = (e) => {
    const newName = e.target.value;
    setNewAppointmentNameOption(newName);
  };

  const addAppointmentNameOption = () => {
    const newNameOptions = { ...appointmentNameOptions };
    const existingAppointment = newNameOptions[selectedClinicType].some(
      (service) =>
        service.clinic_appointment_name.toLowerCase() ===
        newAppointmentNameOption.toLowerCase()
    );
    if (existingAppointment) {
      return message.error("Duplicate appointment name");
    }
    if (
      newAppointmentNameOption.trim().length !== 0 &&
      newAppointmentNameOption.length <= 30
    ) {
      newNameOptions[selectedClinicType].push({
        clinic_appointment_type_name_id: "",
        clinic_appointment_name: newAppointmentNameOption,
      });
      setAppointmentNameOptions(newNameOptions);
      setNewAppointmentNameOption("");
    } else {
      if (newAppointmentNameOption.length > 30) {
        message.error("Input cannot be more than 30 characters");
      } else {
        message.error("Input must be valid");
      }
    }
  };

  const removeAppointmentType = () => {
    setShowdeleteConfirmationModal(true);
  };
  const remove = async () => {
    const deleteAppointmentId = appointmentFields.id;

    if (mode !== "edit") {
      const newAppointmentTypes = { ...appointmentTypes };

      delete newAppointmentTypes[deleteAppointmentId];
      setAppointmentTypes(newAppointmentTypes);
      message.success("Successfully removed");
    }

    if (mode === "edit") {
      // console.log(
      //   deleteAppointmentId,
      //   isNaN(deleteAppointmentId),
      //   "appoinyment type"
      // );
      if (!isNaN(deleteAppointmentId)) {
        try {
          await removeFromDB(deleteAppointmentId, "appointment");
          const newAppointmentTypes = { ...appointmentTypes };

          delete newAppointmentTypes[deleteAppointmentId];
          setAppointmentTypes(newAppointmentTypes);
          message.success("Successfully removed");
        } catch (error) {
          console.log(error);
        }
      } else {
        const newAppointmentTypes = { ...appointmentTypes };

        delete newAppointmentTypes[deleteAppointmentId];
        setAppointmentTypes(newAppointmentTypes);
        message.success("Successfully removed");
      }
    }
  };

  const onHideAppointment = (e) => {
    const shouldHide = e.target.checked;
    setHideAppointment(shouldHide);
  };


  const onDisablePrice = (e) => {
    const shouldDisable = e.target.checked;
    setDisablePrice(shouldDisable);
  };

  const createHeader = (
    // mode !== "view" ? (
    <span>
      {`${
        appointmentFields.appointmentType
          ? JSON.parse(appointmentFields.appointmentType)
              .clinic_appointment_type_name
          : appointmentTypeOptions[0].clinic_appointment_type_name
      } - ${
        appointmentFields.appointmentName
          ? JSON.parse(appointmentFields.appointmentName)
              .clinic_appointment_name
          : appointmentNameOptions[selectedClinicType][0]
              .clinic_appointment_name
      }, ${
        appointmentFields.durationOfMeeting
          ? JSON.parse(appointmentFields.durationOfMeeting)
              .clinic_meeting_duration
          : appointmentDurationOptions[0].clinic_meeting_duration
      }`}
    </span>
    // )
    // : (
    //   <span>
    //     {`${appointmentFields.appointmentType} - ${appointmentFields.appointmentName}, ${appointmentFields.durationOfMeeting}`}
    //   </span>
  );

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "6px",
        fontFamily: "Mulish",
      }}
    >
      {showdeleteConfirmationModal && (
        <DeleteConfirmationModal
          title="Remove appointment type"
          content={createHeader}
          showModal={showdeleteConfirmationModal}
          onConfirmation={remove}
          setShow={setShowdeleteConfirmationModal}
          mode={"onboarding"}
        ></DeleteConfirmationModal>
      )}
      <Row
        justify="space-between"
        style={{
          marginLeft: "2rem",
          marginTop: "1rem",
          fontWeight: "500",
          paddingBottom: "0.5rem",
          //fontFamily: "Mulish"
        }}
      >
        <Col md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {createHeader}
            <Button
              style={{
                color: "red",
                cursor: "pointer",
                display: mode === "view" ? "none" : "",
                background: "none",
                border: "none",
                boxShadow: "none",
                padding: "4px"
              }}
              disabled={Object.keys(appointmentTypes).length === 1}
              onClick={removeAppointmentType}
            >
              <DeleteOutlined
                style={{
                  marginLeft: "0.5rem",
                  fontWeight: 500
                }}
              />
              Remove
            </Button>
            <span style={{ marginLeft: "1rem" }}>
              <Checkbox onChange={onHideAppointment}>
                {hideAppointment ? "Unhide" : "Hide"}
              </Checkbox>
            </span>
          </div>
        </Col>
      </Row>
      {!hideAppointment && (
        <Row>
          <Form
            style={{
              width: "95%",
              marginLeft: "2rem",
              paddingBottom: "0.5rem",
              marginTop: "-12px",
            }}
            form={form}
            onValuesChange={onValueChange}
            name="complex-form"
            layout={"vertical"}
            initialValues={initialFormValues}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
          >
            <Form.Item className="parent-form-item">
              <Form.Item
                label="Appointment Type"
                name="appointmentType"
                className="inline-form-left"
                rules={[
                  { required: true, message: "Appointment type is required" },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  marginRight: "0.4rem",

                  textAlign: "left",
                }}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="custom dropdown render"
                  disabled={mode === "view"}
                  dropdownRender={(menu) => <div>{menu}</div>}
                >
                  {appointmentTypeOptions.map((item) => (
                    <Option
                      key={JSON.stringify(item)}
                      value={JSON.stringify(item)}
                      //
                    >
                      {item.clinic_appointment_type_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Appointment Name"
                name="appointmentName"
                rules={[
                  { required: true, message: "Appointment name is required" },
                ]}
                className="inline-form-right"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  margin: "0 0.5rem 0  2rem ",

                  textAlign: "left",
                }}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  disabled={mode === "view"}
                  placeholder="custom dropdown render"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          size="large"
                          style={{ flex: "auto" }}
                          value={newAppointmentNameOption}
                          onChange={onAppointmentNameOptionChange}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                            color:primaryColor
                          }}
                          onClick={addAppointmentNameOption}
                        >
                          <PlusOutlined /> Add new Appointment Name
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {appointmentNameOptions[selectedClinicType].map((item) => (
                    <Option key={JSON.stringify(item)}>
                      {item.clinic_appointment_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>

            <Form.Item className="parent-form-item">
              <Form.Item
                label="Duration of meeting"
                name="durationOfMeeting"
                className="inline-form-left"
                rules={[
                  {
                    required: true,
                    message: "Duration of meeting is required",
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  marginRight: "0.4rem",

                  textAlign: "left",
                }}
              >
                <Select
                  size="large"
                  disabled={mode === "view"}
                  style={{ width: "100%" }}
                  placeholder="custom dropdown render"
                  dropdownRender={(menu) => <div>{menu}</div>}
                >
                  {appointmentDurationOptions.map((item) => (
                    <Option key={JSON.stringify(item)}>
                      {item.clinic_meeting_duration}{" "}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <div
                className="inline-form-right"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  margin: "0 0.5rem 0  2rem ",
                  textAlign: "left",
                  position:"relative"
                }}
              >
              <div style={{position:"absolute", left:"50px", top:"-4px"}}>
              <Form.Item
                label=""
                name="free"
                valuePropName="checked"
              >
              <Checkbox disabled={mode === "view"} onChange={onDisablePrice}>
                <label>Free</label>
              </Checkbox>
              </Form.Item>
              </div>
                <Form.Item
                  label="Price"
                  name="price"
                  rules={[
                    { required: true, message: "Price is required" },
                    () => ({
                      validator(_, value) {
                        const convertedValue = Number(value);
                        if (
                          !value.length ||
                          (!isNaN(convertedValue) && value.trim().length !== 0)
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject("Invalid price");
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    disabled={mode === "view" || disablePrice}
                    placeholder="Price"
                    addonAfter={currencySelector}
                  />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Description&nbsp;
                  <InfoIcon
                    info={
                      "Description/additional details about the appointment"
                    }
                  />
                </span>
              }
              name="description"
              style={{
                marginRight: "0.5rem",
                marginTop: "2rem",
              }}
              rules={[
                {
                  required: true,
                  message: "Please add a description!",
                },
                () => ({
                  validator(_, value) {
                    if (value.trim().length !== 0 || !value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Description should be valid!");
                  },
                }),
              ]}
            >
              <Input
                size="large"
                disabled={mode === "view"}
                placeholder="Description"
              />
            </Form.Item>
            {/* <Row>
              <Col md="12"> */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                marginTop: "2rem",
                gap: "0 2rem",
              }}
            >
              <Form.Item
                rules={[{ required: true }]}
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
                label="Payment Types"
                name="paymentIds"
              >
                <Select
                  disabled={mode === "view" || disablePrice}
                  size="large"
                  style={{ width: "100%" }}
                  mode="multiple"
                >
                  {paymentList?.map((el) => {
                    return <Option value={el.id}>{el.buttonText}</Option>;
                  })}
                </Select>
              </Form.Item>
            </div>

            {/* </Col>
            </Row> */}
          </Form>
        </Row>
      )}
    </div>
  );
};

export default AppointmentTypeFormComponent;
