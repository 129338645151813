import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";

export const DownloadIcon = ({ onClick, marginTop ,marginLeft }) => {
  return (
    <DownloadOutlined
      style={{
        color: primaryColor,
        marginTop:marginTop ? marginTop: "0.2rem",
        marginLeft: marginLeft ? marginLeft : "0.2rem" 
      }}
      onClick={onClick}
    />
  );
};
