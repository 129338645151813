export const scansArray = [
    {
      name: "upperArch",
      label: "Upper Arch",
      id: 1,
    },
    {
      name: "lowerArch",
      label: "Lower Arch",
      id: 2,
    },
    {
      name: "biteScan1",
      label: "Bite Scan 1",
      id: 3,
    },
    {
      name: "biteScan2",
      label: "Bite Scan 2",
      id: 4,
    },
  ];