import { Row, Col } from "antd";
const PatientImages = ({preliminaryImages}) => {
  return (
    <div className="prescriptionPDF-card">
    <h2>Patient preliminary photos</h2>
    {
      preliminaryImages.length ? <div className="patient-images-container">
     {
      preliminaryImages.map((obj,i) =>{ 
        return(
          <div key={i} className="image-col">
            <img src={obj.url} alt="" className="patient-image"/>
          </div>
        )
      })
     }
    </div> : <div className="no-photos-container">
    No photos provided
    </div>
    }

  </div>
  )
}

export default PatientImages