import { Modal, Spin } from "antd";
import TextEditor from "components/shared/TextEditor/TextEditor";
import "../../../assets/styles/modals/NotesModal.scss";

const NotesModal = ({
  showNotesModal,
  toggleNotesModal,
  isNotesLoading,
  noteText,
  setNoteText,
}) => {
  return (
    <Modal
      className="notesModal"
      title="Add Notes"
      visible={showNotesModal}
      footer={null}
      width={400}
      onCancel={toggleNotesModal}
    >
      <Spin spinning={isNotesLoading}>
        <div className="note-editor">
        <TextEditor editoInput={noteText} setEditorInput={setNoteText} image={false} />
        </div>
      </Spin>
    </Modal>
  );
};

export default NotesModal;
