import { useEffect } from "react";
import AppointmentTypeFormComponent from "./appointmentTypeFormComponent";
import { v4 as uuidv4 } from "uuid";
import { Button, Row, Col, Typography, ConfigProvider } from "antd";

import "../../../assets/styles/components/appointmentTypeContainerComponent.scss";
import { InfoIcon } from "components/shared/InfoIcon/index";
import { primaryColor } from "colors-config";
const appointmentDetailsStructure = {
  appointmentType: "",
  appointmentName: "",
  durationOfMeeting: "",
  description: "",
  appointmentCurrency: "",
  id: null,
};

const { Title } = Typography;

const AppointmentTypeContainerComponent = ({
  appointmentTypes,
  setAppointmentTypes,
  appointmentTypeOptions,
  setAppointmentTypeOptions,
  appointmentNameOptions,
  setAppointmentNameOptions,
  appointmentDurationOptions,
  setAppointmentDurationOptions,
  appointmentCurrencyOptions,
  setAppointmentCurrencyOptions,
  removeFromDB,
  getServicesAndResources,
  mode,
  paymentList,
}) => {
  const createAppointmentType = () => {
    const id = uuidv4();
    const defaultPaymentIds = [];

    paymentList.forEach((el) => {
      if (el.default) {
        defaultPaymentIds.push(el.id);
      }
    });
    const newAppointmentType = {
      ...appointmentDetailsStructure,
      id,
      paymentIds: defaultPaymentIds,
    };
    setAppointmentTypes({
      ...appointmentTypes,
      [id]: newAppointmentType,
    });
  };
  useEffect(() => {
    if (!Object.keys(appointmentTypes).length) {
      createAppointmentType();
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "white",
        // marginTop: "1rem",
        borderRadius: "6px",
        width: "100%",
        //fontFamily: "Mulish"
      }}
    >
      <div>
        <Row justify="space-between">
          <Col md={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Title className="appointment-type-title" level={5}>
                Appointments Type
                <InfoIcon
                  info={"Add the types of Appointment that the patients can schedule with you."}
                />
              </Title>
            </div>
          </Col>
        </Row>
      </div>
      {Object.keys(appointmentTypes).length ? (
        <div>
          {Object.keys(appointmentTypes).map((appointmentType) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: primaryColor,
                    borderRadius: 4,
                  },
                }}
              >
                <AppointmentTypeFormComponent
                  paymentList={paymentList}
                  appointmentFields={appointmentTypes[appointmentType]}
                  setAppointmentTypes={setAppointmentTypes}
                  appointmentTypes={appointmentTypes}
                  appointmentTypeOptions={appointmentTypeOptions}
                  appointmentNameOptions={appointmentNameOptions}
                  appointmentDurationOptions={appointmentDurationOptions}
                  appointmentCurrencyOptions={appointmentCurrencyOptions}
                  setAppointmentTypeOptions={setAppointmentTypeOptions}
                  setAppointmentNameOptions={setAppointmentNameOptions}
                  setAppointmentDurationOptions={setAppointmentDurationOptions}
                  setAppointmentCurrencyOptions={setAppointmentCurrencyOptions}
                  removeFromDB={removeFromDB}
                  getServicesAndResources={getServicesAndResources}
                  mode={mode}
                />
              </ConfigProvider>
            );
          })}
        </div>
      ) : null}
      <div style={{marginTop:"15px"}}>
        <Row justify="space-between">
          <Col></Col>
          <Col
            style={{
              marginRight: "2rem",
              marginBottom: "0.95rem",
            }}
          >
            <Button
              type="primary"
              size="middle"
              shape="round"
              style={{
                width: "10rem",
                display: mode === "view" ? "none" : "",
              }}
              onClick={createAppointmentType}
            >
              Add appointment
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AppointmentTypeContainerComponent;
