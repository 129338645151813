import React from "react";
import { Upload } from "antd";
import { DownloadOutlined, StarOutlined } from "@ant-design/icons";
import { downloadS3File } from "api/patient";

const FileList = ({ iprFile }) => {
  // console.log("iprFile",iprFile)
  const openFileInNewTab = (file) => {

    if (file) {
      window.open(file);
    }
  };

  const downloadFile = (file) => {
    if (file?.hash && file?.meta?.extension) {
      const fileName = file.hash + "." + file.meta.extension;
      if (fileName) {
        window.open(downloadS3File(fileName));
      }
    }
  }

  const props = {
    defaultFileList: [],
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: <DownloadOutlined />,
      showRemoveIcon: true,
      removeIcon: (
        <DownloadOutlined onClick={() => downloadFile(iprFile)} />
      ),
    },
  };

  return (
    <div className="ipr-file-container">
      {iprFile ? (
        <Upload
          {...props}
          name="avatar"
          listType="picture-card"
          className="ipr-upload"
          fileList={[iprFile]}
          onPreview={(file) => openFileInNewTab(file.url)}
        >
          <div style={{ textAlign: "center" }}>
            <p className="ant-upload-text">Upload</p>
          </div>
        </Upload>
      ) : (
        <div style={{ textAlign: "left" }}>--</div>
      )}
    </div>
  );
};

export default FileList;
