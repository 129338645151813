import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { primaryColor } from "colors-config";

export const InfoIcon = ({ info, className}) => {
  return (
    <Tooltip title={info} overlayClassName={className}>
      <InfoCircleOutlined style={{ color: `${primaryColor}`, marginLeft: "0.5rem" }} />
    </Tooltip>
  );
};
