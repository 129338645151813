import api from "./api";

export const getPrescriptionDraftAPI = (clinicId, patientId) => {
  return api({
    method: "GET",
    url: `/clinic/getPrescriptionDraft/${clinicId}/${patientId}`,
  });
};

export const savePrescriptionDraftAPI = (clinicId, patientId, draft) => {
  console.log("patintId",patientId);
  return api({
    method: "POST",
    url: "/clinic/savePrescriptionDraft",
    data: {
      clinicId,
      patientId,
      draft,
    },
  });
};

export const deletePrescriptionDraftAPI = (clinicId, patientId) => {
  return api({
    method: "DELETE",
    url: `/clinic/deletePrescriptionDraft/${clinicId}/${patientId}`,
  });
};

export const savePrescriptionAPI = (data) => {
  return api({
    method: "POST",
    url: "/clinic/prescription/add",
    data,
  });
};

export const getPrescriptionAPI = (patientId, caseId) => {
  return api({
    method: "GET",
    url: `/clinic/prescription/patient/get?patientId=${patientId}&caseId=${caseId}`,
  });
};

export const getPrescriptionByVersion = (prescriptionId, version) => {
  return api({
    method: "GET",
    url: `/clinic/prescription/getByvesion/${prescriptionId}/${version}`,
  });
};

export const updateClinicTeethChartStructure = (clinicId, data) => {
  return api({
    method: "POST",
    url: `/clinic/update/teeth-structure/${clinicId}`,
    data:data
  });
};


export const getPreliminaryImagesApi = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patient/resource/getOnboardingImages`,
    params: params,
  });
};
