import moment from "moment";
export const getClinicWorkingDays = (workindDaysArray) => {
  const workingDays = {};
  if (workindDaysArray && workindDaysArray.length) {
    workindDaysArray.forEach(({ workday_number }) => {
      workingDays[workday_number] = workday_number;
    });
  }

  return workingDays;
};
const getWeekNumber = (name) => {
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const getIndex=weekDays.findIndex(obj=>obj==name);
  return getIndex;
}

export const getClinicNewWorkingDays = (workindDaysArray) => {
  const workingDays = {};
  if (workindDaysArray && workindDaysArray.length) {
    workindDaysArray.forEach((data) => {
      if(data.workDay){
        const weekNumber = getWeekNumber(data.name);
        workingDays[weekNumber] = weekNumber;
      }
     
    });
  }

  return workingDays;
};




export const getHolidays = (holidays) => {
  const days = {};

  if (holidays && holidays.length) {
    holidays.forEach((el) => {
      const val = moment(el).startOf("day").valueOf();
      days[val] = val;
    });
  }
  return days;
};

export const mapTimeSlotData = (timeSlots) => {
  const sortedTimeSlots = timeSlots.sort((a, b) => {
    const timeA = a.appointmentStartTime.split(":");
    const timeB = b.appointmentStartTime.split(":");
    for (let i = 0; i < timeA.length; i++) {
      if (parseInt(timeA[i]) < parseInt(timeB[i])) {
        return -1;
      } else if (parseInt(timeA[i]) > parseInt(timeB[i])) {
        return 1;
      }
    }

    return 0;
  });

  const timeFormat = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":");
    const date = new Date();

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    const formattedTime = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedTime;
  };

  const mappedSlots = sortedTimeSlots.map((el) => ({
    ...el,
    label: timeFormat(el.appointmentStartTime),
    value: el.appointmentSlotId,
  }));
  // console.log(mappedSlots);
  return mappedSlots;
};



