import { Radio, Row, Typography, Spin } from "antd";
import moment from "moment";
import "../../../assets/styles/components/slotsComponent.scss";

const { Title } = Typography;

export default function SlotsComponent({
  slots,
  date,
  onSlotSelectChange,

  isSpinning,
}) {
  const timeZoneConvert = (time) => {
    try {
      time = moment(`06/14/2020 ${time} UTC`).format('hh:mm A');
      return time;
    } catch (ex) {
        // If time format incorrect
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1); // Remove full string match value
        time[3] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join('');
    }
  };

  const createSlotButtons = (slots) => {
    return slots.map((slot) => {
      const time = timeZoneConvert(slot.appointmentStartTime)

      return (
        <Radio className="slot-buttons" value={slot}>
          {time}
        </Radio>
        // <div></div>
      );
    });
  };

  const onSlotChanged = (slot) => {
    onSlotSelectChange(slot);
  };

  return (
    <div className="slot-outer-container">
      <div className="slots-container">
        <div className="slots-title-container">
          <Row justify="start">
            <Title className="slots-title" level={5}>
              Available slots
            </Title>
            {date && (
              <p
                style={{
                  marginTop: "1.5rem",
                  color: "#77849E",
                }}
              >
                {date}
              </p>
            )}
          </Row>
        </div>
        {!slots.length && isSpinning && (
          <Spin style={{ margin: "2rem" }}></Spin>
        )}
        {!slots.length && !isSpinning && (
          <p
            style={{
              marginTop: "2rem",
              marginBottom: "2rem",
              fontSize: "16px",
            }}
          >
            No slots available!
          </p>
        )}
        {slots.length > 0 && (
          <Spin spinning={isSpinning}>
            <Row>
              <Radio.Group
                size="medium"
                buttonStyle="solid"
                onChange={(e) => {
                  onSlotChanged(e.target.value);
                }}
              >
                <div className="radio-buttons-container">
                  {createSlotButtons(slots)}
                </div>
              </Radio.Group>
            </Row>
          </Spin>
        )}
      </div>
    </div>
  );
}
