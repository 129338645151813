import { useEffect, useState } from "react";
import { Modal, message, Button, } from "antd";

import TextEditor from "components/shared/TextEditor/TextEditor";
import {
  getClinicLabs,
  getLabResources,
  insertRefinementData,
  updatePatientClinicTreatmentDetails,
  uploadMedia,
} from "api/clinic";
import { treatmentDetailsPlaceholder } from "messages/messages";


const TreatmentDetailsModal = ({
  treatment,
  treatmentDetailModalOpen,
  setTreatmentDetailModalOpen,
  setTreatment,
  patientInfo
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // const clinic = useSelector((state) => state.clinic);

  const [inputText, setInputText] = useState("");



  const handleOk = async() => {

    

    setConfirmLoading(true)
    //

    try {
      const insertData = {
        commentDetails:inputText
      };

      await updatePatientClinicTreatmentDetails(treatment.lab_treatment_id,insertData);
      const updatedObject = { ...treatment, treatment_details: inputText };
      setTreatment(updatedObject);
      message.success(`Treatment Details has been updated successfully`);
    } catch (error) {
      console.log("error", error.response);
      if (error?.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setConfirmLoading(false);
      setTreatmentDetailModalOpen(false);
    }
  };
  const handleCancel = () => {
    setTreatmentDetailModalOpen(false);
  };

const renderFooter=()=>{
  return (
    [
      <Button
        key="cancel"
        shape="round"
        style={{ width: "7rem" }}
        onClick={handleCancel}
      >
        Cancel
      </Button>,
      <Button
        shape="round"
        style={{ width: "7rem" }}
        key="submit"
        type="primary"
        loading={confirmLoading}
        onClick={handleOk}
      >
        Save
      </Button>,
    ]
  )
}

  return (
    <>
      <Modal
        open={treatmentDetailModalOpen}
        width={750}
        title={`Treatment Details Share with ${patientInfo?.patient_first_name || ""}`}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={editMode ? renderFooter() : false}
        okText="Submit"
      >
        <div
          className="treatment-comment-container"
        >
          {!editMode ?
            <>
              <Button
                shape="round"
                type="primary"
                className="edit-btn"
                onClick={() => {
                  setInputText(treatment.treatment_details)
                  setEditMode(true);
                }}
              >
                Edit
              </Button>
              <div
                className="treatment-comment-editor-text"
                dangerouslySetInnerHTML={{
                  __html: treatment.treatment_details,
                }}
              />
            </>
            : <>
              <TextEditor
                editoInput={inputText}
                setEditorInput={setInputText}
                hideToolbar={true}
                placeholder={treatmentDetailsPlaceholder}
                image={false}
              />
            </>
          }
        </div>
      </Modal>
    </>
  );
};

export default TreatmentDetailsModal;
