import  { useState, useEffect } from "react";
import { Table, Radio, Dropdown, Menu, Button, message, Spin, Row,Typography } from "antd";
import { manageStaffApiTasks } from "../../../models/mangeStaff";
import { connect } from "react-redux";
import MenuIcon from "../../../assets/images/menu_icon.svg";
import EditIcon from "../../../assets/images/edit_icons.svg";
import DeleteIcon from "../../../assets/images/delete_icon.svg";
import AddClinician from "../../../components/shared/modals/AddClinician";
import DeleteModal from "../../shared/modals/DeleteModal";
import "../../../assets/styles/components/manageStaff.scss";
const { Column } = Table;
const { Title } = Typography;

const ManageStaff = (props) => {
  const [modalPlaceholder, setModalPlaceholder] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getLabs();
  }, []);

  const menu = (obj) => (
    <Menu disabled={props.mode === "view"} style={{ width: "8vw" }}>
      <Menu.Item onClick={() => openEditModal(obj)}>
        <div className="menuItem">
          <img src={EditIcon} alt="Edit" />
          <p>Edit</p>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => openDeleteModal(obj)}>
        <div className="menuItem">
          <img src={DeleteIcon} alt="Edit" />
          <p>Delete</p>
        </div>
      </Menu.Item>
    </Menu>
  );

  function getLabs() {
    setIsLoading(true);
    manageStaffApiTasks
      .getAll("clinic/clinician/getByClinic?clinicId=" + props.clinicId)
      .then((res) => {
        const arr = res.body.data.map((data) => ({
          ...data,
          clinician_contact:
            data.clinician_country_code_name && data.clinician_contact
              ? data.clinician_country_code_name + data.clinician_contact
              : "Not added yet",
        }));
        setData(arr);
        setIsLoading(false);
        if (res.body.data.length > 0) {
          props.shoudSubmit(true);
        } else {
          props.shoudSubmit(false);
        }
      })
      .catch((error) => console.error(error));
  }

  function makeDefault(id, rec, i) {
    message.loading("Please Wait", 2);
    manageStaffApiTasks
      .post("clinic/defualtclinician/set", {
        clinic_id: props.clinicId,
        clinician_id: rec.clinician_id,
      })
      .then((res) => {
        message.success(res.body.success);
        getLabs();
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data?.err?.msg) {
          message.error(error.response.data.err.msg);
        } else {
          message.error("Something went wrong. Please try again.");
        }
      });
  }

  function openAddModal() {
    setModalPlaceholder(
      <AddClinician
        mode="add"
        clinicId={props.clinicId}
        handleAfterClose={() => setModalPlaceholder(null)}
        success={getLabs}
      />
    );
  }

  function openEditModal(obj) {
      if(obj.clinician_contact === "Not added yet"){
          obj = {...obj,clinician_contact:""}
      }
      setModalPlaceholder(
      <AddClinician
        mode="edit"
        data={obj}
        clinicId={props.clinicId}
        handleAfterClose={() => setModalPlaceholder(null)}
        success={getLabs}
      />
    );
  }

  function openDeleteModal(obj) {
    setModalPlaceholder(
      <DeleteModal
        deleteFor="clinician"
        data={{
          clinicId: props.clinicId,
          clinician_id: obj.clinician_id,
          userName: obj.clinician_name,
        }}
        handleAfterClose={() => setModalPlaceholder(null)}
        success={getLabs}
      />
    );
  }

  return (
    <div id="container" style= {{fontFamily: "Mulish", width: "100% "}}>
      <Row justify="space-between">
        {/* <p >Clinician Lists</p> */}
        <Title style={{ fontFamily:"'Poppins', sans-serif",marginTop:"5px",   fontWeight: "bold" }} level={5}>
          Clinician Lists
        </Title>

        <Button
          disabled={props.mode === "view"}
          type="primary"
          id="button"
          style={{ width: "10rem" }}
          onClick={() => openAddModal()}
        >
          Add New Clinician
        </Button>
      </Row>
      <Spin spinning={isLoading}>
        {data.length === 0 ? (
          <p className="noClinicians">
            Added Clinicians list will be displayed here
          </p>
        ) : (
          <Table dataSource={data} pagination={false} id="table" rowKey="key">
            <Column
              title="Clinician Name"
              dataIndex="clinician_name"
              key="clinician_name"
            />
            <Column
              title="Registartion No."
              dataIndex="clinician_regno"
              key="clinician_regno"
              render={(val, record, index) => (
              <p>{val ? val :"Not added yet"}</p>
              )}
            />
            <Column
              title="Email"
              dataIndex="clinician_email"
              key="clinician_email"
              render={(val, record, index) => (
              <p>{val ? val :"Not added yet"}</p>
              )}
            />
            <Column
              title="Phone No."
              dataIndex={["clinician_contact"]}
              key="clinician_contact"
            />
            <Column
              title="Set as default"
              dataIndex="default"
              key="default"
              render={(val, record, index) => (
                <Radio
                  disabled={props.mode === "view"}
                  checked={record.clinician_id == record.default_clinician_id}
                  onClick={
                    record.clinician_id != record.default_clinician_id
                      ? () => makeDefault(val, record, index)
                      : null
                  }
                />
              )}
            />
            <Column
              render={(val) => (
                <>
                  <Dropdown
                    disabled={props.mode === "view"}
                    placement="bottomRight"
                    overlay={menu(val)}
                    trigger={["click"]}
                  >
                    <div id="menuIcon">
                      <img src={MenuIcon} alt="Actions" />
                    </div>
                  </Dropdown>
                </>
              )}
            />
          </Table>
        )}
      </Spin>
      {modalPlaceholder}
    </div>
  );
};
const mapStateToProps = (state) => ({
  userEmail: state.clinic.clinicEmail,
  clinicId: state.clinic.clinicId,
});

export default connect(mapStateToProps)(ManageStaff);
