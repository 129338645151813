import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

const baseUrl = process.env.REACT_APP_BASE_URL;

dayjs.extend(utc)
dayjs.extend(timezone)

export const getTimeSlotApi = ({
  clinicId,
  date,
  appointmentTypeId,
  appointmentNameId,
  clinicianId,
  time,
}) =>
  {
    const timezone = dayjs.tz.guess();
    return axios.get(
      `${baseUrl}/clinic/getTimeSlot?clinicId=${clinicId}&appointmentDate=${date}&time=${time}&appointmentNameId=${appointmentNameId}&appointmentTypeId=${appointmentTypeId}&clinicianId=${clinicianId}&timezone=${timezone}`
    )
  };

export const getClinicScheduleApi = (clinicId) =>
  axios.get(`${baseUrl}/clinic/getcalendar?clinicId=${clinicId}`);

export const confirmAppointmentApi = (payload) =>
  axios.post(`${baseUrl}/patient/makeAppointmentClinic`, payload);

export const getClinicAppointmentTypesApi = (clinicId) =>
  axios.get(`${baseUrl}/patient/fetchIp?clinicId=${clinicId}`);

export const getClinicUsersApi = (clinicId) =>
  axios.get(`${baseUrl}/clinic/clinician/getByClinic?clinicId=${clinicId}`);

export const getBookedAppointment = (patient_id) =>
  axios.get(baseUrl + `/clinic/appointment/patient?patientId=${patient_id}`);

export const getServicesAndResources = (clinicId) =>
  axios.get(baseUrl + `/clinic/getServicesAndResources?clinicId=${clinicId}`);

export const searchPatientsApi = (searchValue, clinicId) =>
  axios.get(
    baseUrl +
      `/clinic/patients/search`,
      {
        params: {
          search: searchValue,
          clinicId:clinicId
        }
      }
  );

export const updateAppointment = (payload) =>
  axios.post(
    baseUrl + "/appointment/cancelConfirmRescheduleClinicAppointment",
    payload
  );
