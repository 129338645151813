import React from "react";
import { VideoCameraOutlined } from "@ant-design/icons";

import "../../../assets/styles/components/calendarEventsComponent.scss";


class EventComponent extends React.Component {
  componentDidMount() {
    if (this.props.isWeekly) {
      const eventContainerArray = document.querySelectorAll(".rbc-event");
      eventContainerArray.forEach(eventContainer => {
        eventContainer.classList.add("weekly")
      })
    }

  }
  render() {
    //console.log("this",this.props)
    return (
      <div className={this.props.test}>
        <p className="event-patient-name">{this.props.title}</p>
        <p className="event-appointment-type">
          {" "}
          {this.props.event?.resource?.appointment_name}

        </p>

        <div className="event-appointment-icon">
          {this.props.event?.resource?.appointment_type_id === 2 ? (
            <VideoCameraOutlined className="event-icon" />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default EventComponent;
