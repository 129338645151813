import { primaryColor } from "colors-config";
export const AnchorTag = ({
  text,
  onUrlClicked,
  url,
  target,
  rel,
  className,
}) => {
  return (
    <a
      {...(className && { className: className })}
      {...(url && { href: url })}
      {...(target && { target: target })}
      {...(rel && { rel: rel })}
      style={{ color: primaryColor }}
      onClick={onUrlClicked}
    >
      {text}
    </a>
  );
};
