export const APPOINTMENT_STATUS_CODE = {
    patientCancel: 4,
    awaitingConfirmation: 1,
    clinicRescheduled: 7,
    booked: 2,
    attended: 3,
    patientRescheduled: 5,
    clinicCancelled: 8,
    NoShow: 9,
    inValid: 6,
};