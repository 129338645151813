import api from "./api";

export const getClinicDetails = (clinicEmail) => {
  return api({
    method: "GET",
    url: "/clinic/autoPopulateClinicDetailsOnFirstLogin",
    // url: "/clinic/autoPopulateClinicDetailsOnFirstLogin",
    params: {
      clinicEmail: clinicEmail,
    },
  });
};

export const getClinicLabs = (params) => {
  return api({
    method: "GET",
    url: "/clinic/patientlist/patient/orders/placeOrder/getPartneredLabs",
    params,
  }).then((res) => res.data);
};

export const getLabResources = (params) => {
  return api({
    method: "GET",
    url: "/clinic/labPartner/getResource",
    params,
  }).then((res) => res.data);
};

export const uploadMedia = (data, config) => {
  return api({
    method: "POST",
    url: "/clinic/media/add",
    data,
    ...config,
  });
};

export const insertRefinementData = (data) => {
  return api({
    method: "POST",
    url: "/clinic/refinement/insert",
    data,
  });
};

export const addClinic = (data) => {
  return api({
    method: "POST",
    url: "/clinic/add",
    data,
  });
}

export const getRefinementData = (patientId) => {
  return api({
    method: "GET",
    url: "/clinic/refinement/getDetails/" + patientId,
  });
};
export const addClinicAppointmentProducts = (data) => {
  return api({
    method: "POST",
    url: "/clinic/AddAppointment",
    data: data,
  });
};

export const getClinicResources = (params) => {
  return api({
    method: "GET",
    url: "/clinic/getServicesAndResources",
    params: params,
  }).then((res) => res.data);
};

export const getClinicProductPrice = (params) => {
  return api({
    method: "GET",
    url: "/clinic/treatment/product/price",
    params: params,
  }).then((res) => res.data);
};

export const getAllPendingAppointments = (params) => {
  return api({
    method: "GET",
    url: "/clinic/appointment/get/pending",
    params: params,
  }).then((res) => res.data);
};

export const getAllApproveAppointments = (params) => {
  return api({
    method: "GET",
    url: "/clinic/appointment/get/approved",
    params: params,
  }).then((res) => res.data);
};

export const getAllCancelledAppointments = (params) => {
  return api({
    method: "GET",
    url: "/clinic/appointment/get/cancelled",
    params: params,
  }).then((res) => res.data);
};

export const reschedulePatientAppointment = (data) => {
  return api({
    method: "POST",
    url: "/appointment/cancelConfirmRescheduleAppointment",
    data: data,
  }).then((res) => res.data);
};

export const completeIPRSession = (data) => {
  return api({
    method: "POST",
    url: "/clinic/ipr-session/complete",
    data: data,
  });
};

export const getPatientList = (params) => {
  return api({
    method: "GET",
    url: "/clinic/patient/getlist",
    params: params,
  }).then((res) => res.data);
};

export const getArchivePatientList = (params) => {
  return api({
    method: "GET",
    url: "/clinic/archivePatientList",
    params: params,
  }).then((res) => res.data);
};

export const getNotificationsAPI = (clinicId) => {
  return api({
    method: "GET",
    url: `/clinic/notifications/get/${clinicId}`
  });
};

export const deleteSummaryEmailsAPI = (data) => {
  return api({
    method: "DELETE",
    url: `/clinic/notifications/summaryEmails/delete`,
    data: data,
  });
};

export const updateSummaryEmailsAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/notifications/summaryEmails/add`,
    data: data,
  });
};


export const updateNotificationsAPI = (data, clinicId) => {
  return api({
    method: "POST",
    // url: `/clinic/notifications/update`,
    url: `/clinic/notifications/update`,
    data: data,
  });
};

export const checkClinicEmailExists = (email) => {
  return api({
    method: "POST",
    url: `/public/checkClinicEmailExists`,
    params: { email },
  }).then((res) => res.data);
};

export const getAllLabOrders = (params) => {
  return api({
    method: "GET",
    url: `/clinic/billing/labOrders`,
    params: params,
  }).then((res) => res.data);
};

export const getClinicLabPartner = (clinicId) => {
  return api({
    method: "GET",
    url: `/clinic/getPartnerLabsByClinic?clinicID=${clinicId}`,
  });
};

export const getClinicPatientOrdersList = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/patient/orders/get`,
    params: params,
  }).then((res) => res.data);
};

export const getClinicLabOrdersList = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/lab/orders/get`,
    params: params,
  }).then((res) => res.data);
};


export const getTreatmentTypes = (labId) => {
  return api({
    method: "GET",
    url: `/clinic/prescription/smilelign/treatmentTypes/${labId}`,
  });
};

export const patientClinicUpdateStatus = (data) => {
  return api({
    method: "PUT",
    url: `/clinic/patientlist/treatmentSetup/updateStatus`,
    data,
  }).then((res) => res.data);
};

export const updatePatientClinicTreatmentDetails = (labTreatmentId, data) => {
  return api({
    method: "POST",
    url: `/clinic/treatment-detail/comment/${labTreatmentId}`,
    data,
  });
};


export const updateClinicOnboardingProgressStatus = (data) => {
  return api({
    method: "POST",
    url: `/lab/clinic-on-boarding/update/progress-status`,
    data,
  }).then(res => {

  }).catch(err => {

  })
};

export const addClinicOnboardingRegisterLink = (data) => {
  return api({
    method: "POST",
    url: `/lab/clinic-on-boarding/add-clinic-register-link`,
    data,
  });
};

export const getSingleOnBoardClinic = (email) => {
  return api({
    method: "GET",
    url: `/lab/clinic-on-boarding/get-onboarding-clinic`,
    params: { email },
  }).then((res) => res.data);
};

export const getClinicNotification = (params) => {
  return api({
    method: "GET",
    url: `/notification/get`,
    params: params,
  }).then((res) => res.data);
};

export const getClinicFirstLogin = (params, headers) => {
  return api({
    method: "GET",
    url: `/clinic/checkClinicFirstLogin`,
    params: params,
    headers
  });
};
export const onboardingPartnershipPublic = (data) => {
  return api({
    method: "POST",
    url: `/clinic/onboardingPartnershipPublic`,
    data
  });
};
export const addClinicAvailability = (data) => {
  return api({
    method: "POST",
    url: `/clinic/addAvailability`,
    data
  });
};
export const getProfileAndAvailability = (params) => {
  return api({
    method: "GET",
    url: `/clinic/getProfileAndAvailability`,
    params
  }).then((res) => res.data);
};


export const clinicianDelete = (data) => {
  return api({
    method: "POST",
    url: `/clinic/clinician/delete`,
    data
  });
};

export const clinicEditAvailability = (data) => {
  return api({
    method: "PUT",
    url: `/clinic/editAvailability`,
    data
  });
};

export const getClinicWhiteLabelList = (clinicId) => {
  return api({
    method: "GET",
    url: `lab/clinic/get/clinic/${clinicId}`,
  })
};

export const saveClinicWhiteLabelList = (data) => {
  return api({
    method: "POST",
    url: `lab/clinic/settings/save/clinic`,
    data,
  }).then((obj) => obj.data);
};

export const uploadWhiteLabelFileAPI = (data, labId) => {
  return api({
    method: "POST",
    url: `/lab/clinic/resource/add/${labId}`,
    data,
  }).then((obj) => obj.data);;
};


export const confirmWhiteLabelFileAPI = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/upload/confirm",
    data,
  });
};

export const updatePatientApprovalAPI = (treatmentId) => {
  return api({
    method: "POST",
    url: `/clinic/updatePatientApproval/${treatmentId}`,
  });
};


export const checkCognitoUserAPI = (email) => {
  return api({
    method: "GET",
    url: `/clinic/auth/check-cognito-user`,
    params: {
      email
    }
  })
};

export const checkClinicIntercomTourStatus = (clinicId) => {
  return api({
    method: "GET",
    url: `/clinic/intercom/getClinicIntercomStatus/${clinicId}`,
  })
};

export const updateClinicIntercomStatus = (clinicId, data) => {
  return api({
    method: "POST",
    url: `/clinic/intercom/updateClinicIntercomStatus/${clinicId}`,
    data
  })
};

export const getClinicLabels = (clinicId) => {
  return api({
    method: "GET",
    url: `/clinic/getClinicLabels`,
    params: { clinic_id: clinicId }
  })
};
export const uploadMediaApi = (data, config) => {
  return api({
    method: "POST",
    url: "/media/upload",
    data,
    ...config
  });
};


export const deleteConsentFormApi = (clinicId) => {
  return api({
    method: "DELETE",
    url: `/clinic/deleteConsentPdf/${clinicId}`
  });
};

export const getCountriesApi = () => {
  return api({
    method: "GET",
    url: `/clinic/getCountries`
  });
};

export const deleteDeviceTokenApi = () => {
  return api({
    method: "DELETE",
    url: `/deviceToken/delete`
  });
};
export const updateClinicIntercomJourneyStatus = (clinicId, data) => {
  return api({
    method: "POST",
    url: `/clinic/intercom/updateClinicIntercomJourney/${clinicId}`,
    data
  })
};

export const finishClinicIntercomJourneyStatus = (clinicId) => {
  return api({
    method: "POST",
    url: `/clinic/intercom/finishClinicIntercomJourney/${clinicId}`,
  })
};

export const increaseAddPatientCountApi = (clinicId) => {
  return api({
      method: "POST",
      url: `/clinic/intercom/increaseAddPatientCount/${clinicId}`,
  })
};