import { ReactComponent as Invalid } from "assets/images/Invalid.svg";
import { Row, Col, Button } from "antd";

const ErrorPage = () => {
  return (
    <div className="errorContainer">
      <Row align="middle">
        <Col lg={16}>
          <Invalid />
        </Col>
        <Col lg={8}>
          <h1>Invalid URL</h1>
          <p>The requested URL is invalid</p>
          <a href="/">
            <Button type="primary" shape="round" className="app-button-class">
              Back to Home
            </Button>
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorPage;
