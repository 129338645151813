import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Skeleton, Form } from "antd";
import PaymentMethod from "./Components/DefaultPaymentMethod/DefaultPaymentMethod";
import AddPayment from "./Components/AddPayment/AddPayment";
import AddPaymentForm from "./Components/AddPayment/AddPaymentForm";
import {
  getAllPaymentMethods,
  updatePaymentMethod,
  deletePaymentMethod,
} from "./services/payment.api";
import { paymentTitles } from "./constants";
import styles from "./paymentSettings.module.scss";

const PaymentSettingsContent = forwardRef(({ clinicId }, ref) => {
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [editForm] = Form.useForm();

  useImperativeHandle(ref, () => {
    return {
      paymentList,
    };
  });

  useEffect(() => {
    if (clinicId) {
      setLoading(true);
      getAllPaymentMethods(clinicId)
        .then((res) => {
          setPaymentList(
            res?.data?.data?.paymentTypes.map((el) => {
              if (el.type === paymentTitles.OTHERS) {
                return el;
              }
              return { ...el, edit: false };
            })
          );
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clinicId]);

  const updateList = ({ paymentId, checked, activeBtn }) => {
    setPaymentList((prev) =>
      prev.map((el) => {
        const keyUpdated = activeBtn ? "isActive" : "default";

        if (el.id === paymentId) {
          return {
            ...el,
            [keyUpdated]: checked,
          };
        }
        return el;
      })
    );
  };

  const onUpdatePayment = async (paymentId, data) => {
    try {
      setSaveBtnLoading(true);
      await updatePaymentMethod(clinicId, { ...data, paymentId });

      updatePaymentListState(paymentId, { ...data, edit: false });
    } catch (err) {
      console.log(err);
    } finally {
      setSaveBtnLoading(false);
    }
  };
  const onCancel = (id) => {
    updatePaymentListState(id, { edit: false });
  };

  const updatePaymentListState = (id, data, other) => {
    setPaymentList((prev) =>
      prev.map((el) => {
        if (el.id == id) {
          return {
            ...el,
            ...data,
          };
        }
        let element = { ...el };
        if (other) {
          element = { ...el, ...other };
        }
        return element;
      })
    );
  };
  const onEditClick = (payment) => {
    const { url, buttonText, isActive, default: defaultStatus, id } = payment;
    editForm.setFieldsValue({
      url: url,
      buttonText,
      active: isActive,
      default: defaultStatus,
    });
    updatePaymentListState(id, { edit: true }, { edit: false });
  };

  const onDeleteClick = async (id) => {
    try {
      await deletePaymentMethod(clinicId, { paymentId: id });
      setPaymentList((prev) => prev.filter((el) => el.id !== id));
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  return (
    <Skeleton active loading={loading}>
      {paymentList?.map((payment) => {
        if (payment.edit) {
          return (
            <AddPaymentForm
              styles={styles}
              onCancel={() => onCancel(payment.id)}
              saveDataApi={(data) => onUpdatePayment(payment.id, data)}
              loading={saveBtnLoading}
              payment={payment}
              formHook={editForm}
              formName={"edit-payment-form"}
            />
          );
        }

        return (
          <PaymentMethod
            onEditClick={onEditClick}
            payment={payment}
            updateList={updateList}
            onDeleteClick={onDeleteClick}
          />
        );
      })}
      <AddPayment clinicId={clinicId} setPaymentList={setPaymentList} />
    </Skeleton>
  );
});

export default PaymentSettingsContent;
