import { Modal } from "antd";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { primaryColor } from "colors-config";

const { confirm } = Modal;

export const showConfirmationModal = (onOkCallback, title, info, cancelText,okText ) => {
  confirm({
    title: title,
    cancelText: cancelText ? cancelText :"Cancel",
    okText: okText ? okText : "Ok",
    icon: <ExclamationCircleFilled />,
    content: info,
    okButtonProps: {
      className: "okButtonClass",
      style: {
        background: primaryColor,
        borderColor: primaryColor,
        color: "#fff",
      },
    },
    cancelButtonProps: {
      className: "okButtonClass",
      style: {},
    },
    maskClosable: true,
    onOk: onOkCallback,
    onCancel() {
      // console.log('Cancel');
    },
  });
};
