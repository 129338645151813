import { handleResponse, handleError } from "./response";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'http://test-api.smilegeniusapp.com/api/v1/clinic'
const getAll = async (resource, config, axiosInstance, isAuthRequired) => {
  return axiosInstance
    .get(`${BASE_URL}/${resource}`, config)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const getSingle = async (resource, config, axiosInstance, isAuthRequired) => {
  return axiosInstance
    .get(`${BASE_URL}/${resource}`, config)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const post = async (resource, model, axiosInstance, isAuthRequired, config) => {
  return axiosInstance
    .post(`${BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const put = async (resource, model, axiosInstance, isAuthRequired, config) => {
  return axiosInstance
    .put(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const patch = async (resource, model, axiosInstance, isAuthRequired) => {
  return axiosInstance
    .patch(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const remove = async (resource, data, axiosInstance, isAuthRequired) => {
  return axiosInstance
    .delete(`${BASE_URL}/${resource}`, { data })
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
};
