import { useEffect, useState } from "react";
import { Row, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import NotificationsComponent from "../../components/private/notificationsComponent";
import { dentistClinicApiTasks } from "../../models/dentistClinic";
import { changeNotificationState } from "../../services/redux/actions/clinicActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { primaryColor } from "colors-config";
import { getClinicNotification } from "api/clinic";

const Notifications = ({ clinic, changeNotificationState, location }) => {
  const [isNotificationsSpinning, setIsNotificationsSpinning] = useState(true);
  const [filterVal, setFilterVal] = useState("ALL");
  const [totalNotifications, setTotalNotifications] = useState(0);
  const queryParams = new URLSearchParams(location.search);

  const [filterListChecked, setFilterListChecked] = useState([]);

  const [pageState, setPageState] = useState({
    prevPage: 0,
    nextPage: 1,
    notificationsArray: [],
  });
  const history = useHistory();
  const PAGE_LIMIT = 20;

  useEffect(() => {
    if (queryParams) {
      const typeParams=queryParams.get("type") ;
      if(typeParams=='comment'){
        setFilterListChecked(["comment"]);
      }
    }
  }, []);

  const getNotifications = async (pageNo = 1, reset = false) => {
    const secondaryFilter = !reset ? filterListChecked.join(",") : "";
    if (pageNo !== 0) {
      try {
        setIsNotificationsSpinning(true);
        let getNotifications = "";
        if (filterVal === "ALL") {
          getNotifications = await getClinicNotification({
            user_id: clinic.clinicId,
            limit: PAGE_LIMIT,
            page: pageNo,
            key: filterVal,
            secondaryFilter,
          })
          
          // await dentistClinicApiTasks.getAll(
          //   "notification/get",
          //   {
          //     user_id: clinic.clinicId,
          //     limit: PAGE_LIMIT,
          //     page: pageNo,
          //     secondaryFilter,
          //   }
          // );
          setTotalNotifications(getNotifications?.body?.unreadDataCount);
        } else {
          getNotifications = await getClinicNotification({
            user_id: clinic.clinicId,
            limit: PAGE_LIMIT,
            page: pageNo,
            key: filterVal,
            secondaryFilter,
          })
          
          // await dentistClinicApiTasks.getAll(
          //   "notification/get",
          //   {
          //     user_id: clinic.clinicId,
          //     limit: PAGE_LIMIT,
          //     page: pageNo,
          //     key: filterVal,
          //     secondaryFilter,
          //   }
          // );
         
          setTotalNotifications(getNotifications?.body?.unreadDataCount);
        }

        changeNotificationState(false);
        let tempNotification = [];
        if (pageNo === 1) {
          tempNotification = getNotifications?.body?.userData;
        } else {
          tempNotification = [
            ...pageState.notificationsArray,
            ...getNotifications?.body?.userData,
          ];
        }
        pageState.notificationsArray = tempNotification;
        setIsNotificationsSpinning(false);
        pageState.nextPage = getNotifications?.body?.nextPage;
        if (filterVal === "LAB") {
          tempNotification = tempNotification?.filter(
            (i) => i?.notification_from === "Lab"
          );
        } else if (filterVal === "PATIENT") {
          tempNotification = tempNotification?.filter(
            (i) => i?.notification_from === "Patient"
          );
        } else {
          // eslint-disable-next-line no-self-assign
          tempNotification = tempNotification;
        }
        setPageState({
          ...pageState,
          notificationsArray: tempNotification,
          nextPage: getNotifications?.body?.nextPage,
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsNotificationsSpinning(false);
      }
    }
  };
  useEffect(() => {
    if (clinic.clinicId !== null) {
      getNotifications(1).then(() => {});
    }
  }, [clinic.clinicId, filterVal]);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Back"
      />

      <Spin style={{ width: "100%" }} spinning={isNotificationsSpinning}>
        <InfiniteScroll
          dataLength={pageState?.notificationsArray?.length} //This is important field to render the next data
          next={() => getNotifications(pageState.nextPage)}
          loader={
            pageState?.notificationsArray?.length > 0 && <h4>Loading...</h4>
          }
          hasMore={pageState.nextPage !== 0}
          endMessage={
            <p style={{ textAlign: "center", color: primaryColor }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Row>
            <NotificationsComponent
              notificationsArray={pageState.notificationsArray}
              setFilterVal={setFilterVal}
              filterVal={filterVal}
              getNotifications={getNotifications}
              totalNotifications={totalNotifications}
              clinicId={clinic.clinicId}
              filterListChecked={filterListChecked}
              setFilterListChecked={setFilterListChecked}
            />
          </Row>
        </InfiniteScroll>
      </Spin>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clinic: state.clinic,
});
export default connect(mapStateToProps, { changeNotificationState })(
  Notifications
);
