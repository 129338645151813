import { Radio } from "antd";
import SlotsSkeleton from "./SlotsSkeleton";
const Slots = ({ timeSlots, selectedSlot, setSelectedSlot, slotLoading }) => {
  return (
    <div>
      {slotLoading ? (
        <SlotsSkeleton />
      ) : (
        <Radio.Group
          value={selectedSlot}
          onChange={(e) => setSelectedSlot(e.target.value)}
          buttonStyle="solid"
          options={timeSlots}
          optionType="button"
        />
      )}
    </div>
  );
};

export default Slots;
