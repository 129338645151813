import { primaryColor } from "colors-config";
import { VideoCameraOutlined } from "@ant-design/icons";
export const CameraIcon = () => {
  return (
    <VideoCameraOutlined
      style={{
        fontSize: "20px",
        marginTop: "2%",
        color: primaryColor,
      }}
    />
  );
};
