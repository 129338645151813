import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import AddPaymentForm from "./AddPaymentForm";
import { savePaymentMethod } from "../../services/payment.api";
import styles from "../../paymentSettings.module.scss";
import AppButton from "components/shared/AppButton/AppButton";

const AddPayment = ({ clinicId, setPaymentList }) => {
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const [AddForm] = Form.useForm();

  const onCancel = () => {
    setAdd(false);
  };

  const onSaveApi = (data) => {
    setLoading(true);
    savePaymentMethod({ ...data, clinicId })
      .then((res) => {
        setPaymentList((prev) => [...prev, res.data.data]);
        onCancel();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onAddClick = () => {
    setAdd((prev) => !prev);
  };

  return (
    <div>
      {add && (
        <AddPaymentForm
          loading={loading}
          saveDataApi={onSaveApi}
          onCancel={onCancel}
          styles={styles}
          formHook={AddForm}
        />
      )}
      {!add && (
        <div className={styles["add-payment-btn-container"]}>
          <AppButton
            onClick={onAddClick}
            shape="round"
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Payment Method
          </AppButton>
        </div>
      )}
    </div>
  );
};

export default AddPayment;
