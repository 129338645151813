import { useState, useEffect } from "react";
// import cardData from "./patient-journey/index.json";
import "../../assets/styles/components/notificationsComponent.scss";
import {
  Radio,
  Row,
  Menu,
  Dropdown,
  message,
  Card,
  Empty,
  Modal,
  Tooltip,
  Popover,
  Popconfirm,
  Button,
  Checkbox,
  List,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import NotificationSettingsModal from "./NotificationSettingsModal/NotificationSettingsModal";
// import { dateFnsLocalizer } from "react-big-calendar";
import moment from "moment";
// import { NotificationTwoTone } from "@ant-design/icons";
import { dentistClinicApiTasks } from "../../models/dentistClinic";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addReadCount, changeCommentBoxState, updateReadCount,updateCommentPatientTabState } from "../../services/redux/actions/clinicActions";
import { ShowTotalNumber } from "components/shared/ShowTotalNumber/index";
import { ReactComponent as SettingIcon } from "assets/images/Setting.svg";
import { primaryColor } from "colors-config";
import { secondaryFilterFilters } from "./constants/notifications";
import { NOTIFICATION_CAUSES } from "utilities/constants";

const NotificationsComponent = ({
  notificationsArray,
  clinic,
  setFilterVal,
  getNotifications,
  totalNotifications,
  filterVal,
  clinicId,
  setFilterListChecked,
  filterListChecked,
}) => {
  const history = useHistory();

  const [readNotifications, setReadNotifications] = useState([]);
  const [notifications, setNotifications] = useState(notificationsArray || []);

  const [openMarkAllButton, setOpenMarkAllButton] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  let [deleteFileOpen, setDeleteFileOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const handleFileOk = () => {
    updateAllReadStatus();
    setDeleteFileOpen(false);
  };

  const handleFileCancel = () => {
    setDeleteFileOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setNotifications(notificationsArray);
  }, [notificationsArray]);

  const markRead = (
    index,
    createdDate,
    id,
    cause,
    title,
    actionFlag,
    appointment_id
  ) => {
    var arr = [...readNotifications];
    if (!arr.includes(index)) {
      arr.push(index);
      setReadNotifications(arr);
    }

    updateReadStatus(createdDate, 1);
    dispatch(updateReadCount())
    if (cause === NOTIFICATION_CAUSES.PATIENT_JOINED_VIDEO_CALL) {
      history.push(
        `/clinic/scheduling/approved?appointment_id=${appointment_id}`
      );
    }
    if (cause === "appointment") {
      if (title.includes("Requested")) {
        history.push(
          `/clinic/scheduling/pending?appointment_id=${appointment_id}`
        );
      }
      if (title.includes("Request")) {
        history.push(
          `/clinic/scheduling/pending?appointment_id=${appointment_id}`
        );
      } else if (title.includes("Appointment Reminder")) {
        history.push(
          `/clinic/scheduling/approved?appointment_id=${appointment_id}`
        );
      } else if (title.includes("Appointment Cancelled")) {
        history.push(
          `/clinic/scheduling/cancelled?appointment_id=${appointment_id}`
        );
      } else {
        history.push(
          `/clinic/scheduling/pending?appointment_id=${appointment_id}`
        );
      }
    } else if (cause === "treatment_setup") {
      history.push(`/clinic/patient/${id}`);
    } else if (cause === "smile-assessment-form") {
      history.push(`/clinic/patient/${id}`);
    } else if (cause === "ipr-session-reminder") {
      history.push(`/clinic/patient/${id}`);
    } else if (cause === "patient-created") {
      history.push(`/clinic/patient/${id}`);
    }
    // else if (cause === "order" && (actionFlag=='ALIGNER_SHIPPED_BY_LAB' || actionFlag=='ALIGNER_PRODUCTION_STARTED_BY_LAB')) {
    //   history.push(`/clinic/patient/${id}/orders?redirect=lab`);
    // }
    else if (cause === "order") {
      history.push(`/clinic/patient/${id}/orders?redirect=lab`);
    } else if (cause === "progress_images") {
      history.push(`/clinic/patient/${id}`);
    } else if (cause === "impression_images") {
      history.push(`/clinic/patient/${id}?impression=1`);
    } else if (cause === "comment") {
      const notifcationChatData=notifications[index];
      dispatch(changeCommentBoxState(true));
      dispatch(updateCommentPatientTabState(notifcationChatData.lab_id ? false : true))
      
      history.push({
        pathname: `/clinic/patient/${id}/`,
        state: { senderId: notifications[index].sender_id },
      });
    } else if (cause === "patient_order") {
      history.push(`/clinic/patient/${id}/orders`);
    } else if (cause === "patient_payment") {
      history.push(`/clinic/patient/${id}/orders`);
    } else {
      console.log("cause undefined");
    }
  };
  const updateAllReadStatus = async () => {
    try {
      await dentistClinicApiTasks.post("notifications/updateAllReadStatus", {
        user_id: clinic.clinicId,
      });
      getNotifications();
    } catch (error) {
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }

  };
  const updateReadStatus = async (date, val) => {
    try {
      await dentistClinicApiTasks.post("notifications/updateReadStatus", {
        user_id: clinic.clinicId,
        notification_created_date: date,
        status: val,
      });
      getNotifications();
    } catch (error) {
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  };
  const handleMenuClick = (event, createdDate) => {
    const name = event?.domEvent?.target?.textContent;
    if (name === "Mark As UnRead") {
      dispatch(addReadCount())
      updateReadStatus(createdDate, 0);
      
    } else if (name === "Mark As Read") {
      updateReadStatus(createdDate, 1);
      dispatch(updateReadCount())
    } else if (name == "Mark All As Read") {
      setDeleteFileOpen(true);
    }
  };

  const showPopconfirm = () => {
    setOpenMarkAllButton(true);
  };
  const handleOk = () => {
    // updateAllReadStatus();
    // setDeleteFileOpen(false);
    updateAllReadStatus();
    getNotifications();
    setOpenMarkAllButton(false);
    dispatch(updateReadCount(0));
  };

  const handleCancel = () => {
    setOpenMarkAllButton(false);
  };

  const onNotificationCancel = () => {
    setOpenSettingsModal(false);
  };

  const showNotificationModal = () => {
    setOpenSettingsModal(true);
  };

  const onFilterIconClick = () => {
    console.log("click");
  };

  const closeFilter = () => {
    setOpenFilter(false);
  };

  const onFilterReset = () => {
    getNotifications("", false);
    setFilterListChecked([]);
    // setSecondaryFilter([]);
    closeFilter();
  };

  const onFilterOk = () => {
    getNotifications();
    // setSecondaryFilter(filterListChecked);
    closeFilter();
  };

  const filterListContent = () => {
    return (
      <div>
        <Row>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={filterListChecked}
            onChange={(checkedValues) => {
              setFilterListChecked(checkedValues);
            }}
          >
            <List
              style={{ height: "250px", width: "270px", overflowY: "auto" }}
              bordered
              size="small"
              dataSource={secondaryFilterFilters}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </List.Item>
              )}
            />
          </Checkbox.Group>
        </Row>
        <Row className="clinic-filter-actions">
          <Button
            type="link"
            onClick={onFilterReset}
            style={{ marginTop: "3px" }}
          >
            Reset
          </Button>
          <Button
            style={{
              marginLeft: "auto",
              padding: "0px 7px",
              height: "24px",
              fontSize: "14px",
              borderRadius: "3px",
              marginTop: "5px",
            }}
            type="primary"
            onClick={onFilterOk}
          >
            OK
          </Button>
        </Row>
      </div>
    );
  };

  return (
    <div style={{ width: "100%", marginLeft: "150px" }}>
      {openSettingsModal && (
        <NotificationSettingsModal
          openSettingsModal={openSettingsModal}
          // setOpenSettingsModal={setOpenSettingsModal}
          onNotificationCancel={onNotificationCancel}
          clinicId={clinicId}
        />
      )}

      <Row className="notification-header">
        <div className="notification-wrapper">
          <ShowTotalNumber
            total={totalNotifications}
            className={"notificationListLength"}
          />
          <PageHeader
            className="site-page-header"
            title="Notifications"
            style={{ marginLeft: "0px" }}
          />
          <div>
            <Popover
              overlayClassName="clinic-list-popover"
              align="bottom"
              content={filterListContent}
              visible={openFilter}
              onVisibleChange={(val) => setOpenFilter(val)}
              trigger="click"
            >
              <FilterOutlined
                // onClick={onFilterIconClick}
                style={{
                  fontSize: "16px",
                  marginRight: "10px",
                }}
              />
            </Popover>
            <Popconfirm
              title="Are you sure you want to Mark all as read?"
              // open={openMarkAllButton}
              onConfirm={handleOk}
              onCancel={handleCancel}
              okButtonProps={{
                style: {
                  width: "5rem",
                  borderRadius: "30px",
                  color: "#fff",
                  height: "30px",
                  padding: "4px 15px",
                  marginTop: "10px",
                },
              }}
              cancelButtonProps={{
                style: {
                  width: "5rem",
                  borderRadius: "30px",
                  height: "30px",
                  padding: "4px 15px",
                  marginTop: "10px",
                },
              }}
            >
              <Button
                style={{
                  marginLeft: "-5px",
                  fontSize: "14px",
                  color: primaryColor,
                }}
                type="link"
                size="large"
                onClick={showPopconfirm}
              >
                Mark All As Read
              </Button>
            </Popconfirm>
          </div>
          {/* {totalNotifications > 0 && (
            <p className="notification-counter">{totalNotifications}</p>
          )} */}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Radio.Group
            // defaultValue={"ALL"}
            value={filterVal}
            buttonStyle="solid"
            style={{ height: "100%" }}
            onChange={(e) => {
              setFilterVal(e.target.value);
            }}
          >
            <Radio.Button className="status-radio-buttons" value="ALL">
              All
            </Radio.Button>
            <Radio.Button className="status-radio-buttons" value="Patient">
              Patient
            </Radio.Button>
            <Radio.Button className="status-radio-buttons" value="Lab">
              Lab
            </Radio.Button>
          </Radio.Group>
          <Tooltip
            placement="topLeft"
            title="Notification & Email Settings"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <div onClick={showNotificationModal} style={{ cursor: "pointer" }}>
              <SettingIcon />
            </div>
          </Tooltip>
        </div>
      </Row>
      <div className="notifications-container">
        {notifications.length > 0 ? (
          notifications.map((notification, index) => {
            return (
              <Card className="notifications-card">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={(e) =>
                          handleMenuClick(
                            e,
                            notification.notification_created_date
                          )
                        }
                        style={{
                          paddingTop: "0.5rem",
                          marginTop: "0.5rem",
                          width: "10rem",
                        }}
                        mode="horizontal"
                      >
                        <Menu.Item
                          key={1}
                          name="asd"
                          value={notification.notification_created_date}
                          style={{ textAlign: "right" }}
                        >
                          {readNotifications.includes(index) ||
                          notification.isViewed
                            ? "Mark As UnRead"
                            : "Mark As Read"}
                        </Menu.Item>
                        {/* <Menu.Item
                          key={2}
                          value={notification.notification_created_date}
                          style={{ textAlign: "right" }}
                        >
                          Mark All As Read
                        </Menu.Item> */}
                      </Menu>
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <div
                      className={
                        readNotifications.includes(index) ||
                        notification.isViewed
                          ? "notifications-status-read"
                          : "notifications-status"
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Dropdown>

                  <div
                    className="button-notifications"
                    onClick={() => {
                      markRead(
                        index,
                        notification.notification_created_date,
                        notification.patient_id,
                        notification.cause,
                        notification?.title,
                        notification?.actionFlag,
                        notification?.appointment_id
                      );
                    }}
                  >
                    <div
                      className={
                        readNotifications.includes(index) ||
                        notification.isViewed
                          ? "notifications-text-read"
                          : "notifications-text"
                      }
                    >
                      <p
                        style={{
                          fontWeight:
                            notification?.cause == "ipr-session-reminder"
                              ? "bold"
                              : "",
                        }}
                      >
                        {notification.notification_message}
                      </p>
                    </div>
              
                  </div>
                  <div
                      className={
                        readNotifications.includes(index)
                          ? "notifications-timestamp-read"
                          : "notifications-timestamp"
                      }
                    >
                      <p style={{ color: "#77888B" }}>
                        {" "}
                        {moment
                          .duration(
                            Date.now() - notification.notification_created_date
                          )
                          .humanize()}
                      </p>
                    </div>
                </div>
              </Card>
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 60,
            }}
            description={<span>No new notifications</span>}
          ></Empty>
        )}
      </div>
      <Modal
        visible={deleteFileOpen}
        title={"Read Notification"}
        onOk={handleFileOk}
        onCancel={handleFileCancel}
      >
        <p>Are you sure you want to Mark all as read?</p>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clinic: state.clinic,
});
export default connect(mapStateToProps)(NotificationsComponent);
