import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Row, Col, Button } from "antd";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";

const Invoice = ({ record, onViewClick, onDownloadAllClick }) => {
  return (
    <Row style={{ marginTop: "1rem" }}>
      <Col>
        {record?.shippingFiles?.length ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="View">
              <Button
                onClick={() => onViewClick(record)}
                className="invoice-viewBtn"
                icon={
                  <EyeOutlined
                    style={{ fontSize: "17px", cursor: "pointer" }}
                  />
                }
              ></Button>
            </Tooltip>
            <Tooltip title="Download">
              <Button
                onClick={() => onDownloadAllClick(record?.shippingFiles)}
                className="invoice-viewBtn"
                icon={
                  <DownloadOutlined
                    style={{ fontSize: "17px", cursor: "pointer" }}
                  />
                }
              ></Button>
            </Tooltip>
          </div>
        ) : (
          "--"
        )}
      </Col>
    </Row>
  );
};

Invoice.propTypes = {
  record: PropTypes.shape({
    shippingFiles: PropTypes.arrayOf(PropTypes.object), 
  }), 
  onViewClick: PropTypes.func.isRequired,
  onDownloadAllClick: PropTypes.func.isRequired,
};

export default Invoice;
