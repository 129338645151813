import { Modal, Select, Form ,message} from "antd";
import { useEffect, useState } from "react";
import AppTag from "components/shared/Tag/Tag";
import styles from "./patient.module.scss";
import { startRevisionTreatmentApi } from "api/patient";
import { TAG_STATUS } from "constants";

const StartRefinementModal = ({
  viewStartRefinementModal,
  setViewStartRefinementModal,
  treatment,
  alignerList,
  labOrders,
  labOrder,
  getData
}) => {
  //   console.log("treatment", treatment);
  const [currentAlignerState, setCurrentAignerState] = useState({});
  const [currentAligners, setCurrentAligners] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const currentAlignerId = alignerList.body.current_aligner;

    const currentAlignerData = alignerList.body.data.find(
      (el) => el.aligner_id === currentAlignerId
    );
    if (currentAlignerData) {
      let filteredAligners = [];
      setCurrentAignerState(currentAlignerData);
      const finalAligners = alignerList.body.data.filter(obj=>!obj.type);
      
      finalAligners.forEach((aligner) => {
        if (aligner.aligner_count >= currentAlignerData.aligner_count) {
          filteredAligners.push({
            ...aligner,
            label:aligner.stage_name,
            value: aligner.aligner_count,
          });
        }
      });

      setCurrentAligners(filteredAligners);
    }
  }, [alignerList]);
  const handleCancel = () => {
    setViewStartRefinementModal(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const onStageChange = (option) => {
    console.log(option);
  };

  const onFinish = async (values) => {
   
    const { refinementAppliedStage } = values;
    const findAppliedStage = currentAligners.find(obj => obj.aligner_count == refinementAppliedStage);
    if (findAppliedStage) {
      // console.log("values", findAppliedStage);
      const findLabOrder = labOrders.find(
        (obj) => obj.lab_treatment_id == treatment.lab_treatment_id
      );
      let patientOrderId = labOrder.patient_order_id;
  
      if (findLabOrder) {
        patientOrderId = findLabOrder.patient_order_id;
      }
      const apiReq={
        order_id: patientOrderId,
        treatmentId: treatment.lab_treatment_id,
        upperAligner:findAppliedStage.upperAligner,
        lowerAligner:findAppliedStage.lowerAligner,
      }
      try {
        setConfirmLoading(true)
        await startRevisionTreatmentApi(apiReq);
        message.success("Patient Refinement has been started.");
        getData();
        handleCancel();
      } catch (error) {
        console.log("error occured");
        message.success("Error occured during starting Refinement");
      }
      finally{
        setConfirmLoading(false)
      }
     
    }
    // handleCancel();
  }

  return (
    <Modal
      visible={viewStartRefinementModal}
      closeIcon={null}
      width={750}
      title="Start Refinement"
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Start"
      okButtonProps={{
        style: { borderRadius: "30px" },
      }}
      cancelButtonProps={{ style: { borderRadius: "30px" } }}
    >
      <div>
        <div>
          <label>New Stages of Refinement</label>
          <div className={styles["aligner-container"]}>
            <AppTag text={`${treatment.num_of_upper_aligners} Upper`} />
            <AppTag text={`${treatment.num_of_lower_aligners} Lower`} />
          </div>
        </div>

        <div>
          <label>Current Stage</label>
          <div style={{ width: "100px" }}>
            <AppTag
            color={TAG_STATUS.DEFAULT}
            text={currentAlignerState.stage_name}
            />
          </div>
        </div>
        <Form
          onFinish={onFinish}
          name="refinement-applied-form"
          layout={"vertical"}
          style={{
            marginTop:"10px"
          }}
          form={form}
        >
          <Form.Item
            label="Start Refinement after stage"
            name="refinementAppliedStage"
            rules={[{ required: true, message: "Start Refinement stage is required" }]}
          >
              <Select
                onChange={onStageChange}
                placeholder="Choose"
                style={{ width: "200px" }}
                options={currentAligners}
              />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default StartRefinementModal;
