import { Button } from "antd";

const GenericButton = ({
  content,

  type,
  children,
  customStyle,

  ...props
}) => {
  return (
    <Button
      {...props}
      type={type}
      style={{
        borderRadius: "20px",
        ...customStyle,

        width: "9rem",
        height: "2.5rem",
        lineHeight:"10px"
      }}
    >
      {children}
    </Button>
  );
};

export default GenericButton;
