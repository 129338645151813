import { useState, useEffect, useRef } from "react";
import { Form, Row, Typography, Switch, message, Button, Col, Input, Card } from "antd";
import SelectDaysOffComponent from "./selectDaysOffComponent";
import PickHoursAndDays from "./pickHoursAndDays";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as CalendarOff } from "assets/images/CalendarOff.svg";
import "../../../assets/styles/components/availabilitySettingsComponent.scss";
import moment from "moment";
import { InfoIcon } from "components/shared/InfoIcon/index";
import WeeklyAvailability from "../WeeklyAvailability/WeeklyAvailability";
import dayjs from "dayjs";
import { validateWorkHoursStart, validateWorDay } from "utilities/appointment";
import AppEmpty from "components/shared/AppEmpty/AppEmpty";
import InfoBulbIcon from "components/shared/Icon/InfoBulbIcon";
import ConfirmationModal from "components/shared/modals/confirmationModal";

const { Title } = Typography;
// const { RangePicker } = DatePicker;

export default function AvailabilitySettingsComponent({
  setOffDays,
  offDays,
  workingDaysRange,
  setWorkingDaysRange,
  workingDays,
  setWorkingDays,
  workingHours,
  setWorkingHours,
  mode,
  setBreakHours,
  breakHours,
  shouldSubmit,
  setShouldSubmit,
  onSubmit,
  clinicDetails,
  setClinicDetails,
  userWeeklyAvailability,
  timezone,
  resetClinicAvailabilityForm,
  prevUserAvailabilityForm,
  setPrevUserAvailabilityForm,
  clinicAvailability,
  setClinicAvailability,
  type,
  next,
  setMode
}) {
  const clinicUrlInputRef = useRef(null);
  const [form] = Form.useForm();
  const [availabilityForm] = Form.useForm();

  const [canOffDaysSubmit, setCanOffDaysSubmit] = useState(false);
  const [canPickHoursAndDaysSubmit, setCanPickHoursAndDaysSubmit] =
    useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false)

  const onConfirmationOk = ()=> {
    console.log('onConfirmationOk')
    onChange(false)
  }


  const onFinish = (values) => {
    if (
      workingHours?.length === 2 &&
      // breakHours?.length === 2 &&
      workingDays !== 0
    ) {
      availabilityForm.submit();
      // onSubmit();
    }
  };

  const availabilitySubmit = (values) => {
    const workDayCheck = validateWorDay(values)
    if (!workDayCheck && clinicAvailability) {
      return message.error(
        "To enable this section, please schedule appointments for at least one day."
      );
    }
    const isValid = validateWorkHoursStart(values);
    if (clinicAvailability && !isValid) {
      return message.error(
        "Work hours must be specified for each working day."
      );
    }
    const workingHours = values.weekTimings.map(
      ({ name, workDay, workHours, breakHours }) => {
        const extractedHours = workHours.reduce((acc, { start }) => {
          if (start !== null) {
            const formattedStart = start.map((date) =>
              dayjs(date).format("HH:mm")
            );
            acc.push(formattedStart);
          } else {
            acc.push([]);
          }
          return acc;
        }, []);
        const formattedBreakHours = breakHours
          ? breakHours.map((date) => dayjs(date).format("HH:mm"))
          : [];
        return {
          name,
          workDay,
          workHours: extractedHours,
          breakHours: formattedBreakHours,
        };
      }
    );
    const finalValues = {
      timeZone: values.timeZone,
      workingHours: workingHours,
    };
    setPrevUserAvailabilityForm({
      timeZone: values.timeZone,
      workingHours: workingHours,
    });
    onSubmit(finalValues);
  };

  useEffect(() => {
    if (shouldSubmit) {
      new Promise((res, rej) => {
        res(form.submit());
      }).then(() => {
        setShouldSubmit(false);
      });
    }
  }, [shouldSubmit]);
  useEffect(() => {
    //setting date
    setWorkingDaysRange([
      moment().format("YYYY-MM-DD"),
      moment("2099-01-01T00:00:00.000Z").format("YYYY-MM-DD"),
    ]);
  }, []);

  // useEffect(()=>{
  //   console.log("clinicAvailability",clinicAvailability);
  //   if(type !== 'onboarding' && !clinicAvailability){
  //     setConfirmationModal(true)
  //   }
  // },[clinicAvailability])

  const onChange = (checked) => {
    setClinicAvailability(checked);
    setClinicDetails({
      ...clinicDetails,
      clinicAvailability: checked,
    });
  };

  const onChangeConfirm = (checked) => {
   
    if(type !== 'onboarding' && !checked){
      setConfirmationModal(true)
    }
    else{
      onChange(checked)
    }
  };


  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(clinicDetails.clinicUrl);
    e.target.focus();
    message.success("URL copied to clipboard");
  };


  const clipboardCopyButton = (
    <Button
      type="primary"
      style={{ borderRadius: "20px" }}
      onClick={copyToClipboard}
      disabled={false}
    >
      Copy
    </Button>
  );

  return (
    <div>
      <div
        className="availability-settings-container"
        style={{ fontFamily: "Mulish", width: "100%" }}
      >
        {!clinicAvailability ? <div className="appointment-off-container">
          <div className="appointment-backdrop" />
          <p><InfoBulbIcon />  Turn availability ON if you want to use this portal to take appointments.</p>
          <div className="appointment-content">
            <CalendarOff />
            <Button shape="round" type="primary" onClick={() => {
              if(type !== 'onboarding'){
                setMode("edit")
              }
              setClinicAvailability(true)
              
            }}>Turn on for appointments</Button>
            {
              type === "onboarding" && (
                <h1 onClick={next}>Skip &gt; &gt;</h1>
              )
            }
          </div>

        </div> : <div>
          <Form
            form={form}
            preserve={false}
            name="complex-form"
            layout={"vertical"}
            onFinish={onFinish}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 25 }}
          ></Form>

        
            <Card> <div>
              <Title className="off-days-title" level={5} >
                Availability{" "} <InfoIcon info={"Disable Appointment on mobile app"} />
                <Switch
                  disabled={mode === "view"}
                  style={{
                    marginLeft: "10px",
                  }}
                  checked={clinicAvailability}
                  onChange={onChangeConfirm}
                />
                <span style={{
                  marginLeft: "10px",
                }}>
                  {clinicAvailability ? "On" : "Off"}
                </span>
              </Title>
            </div></Card>

          <WeeklyAvailability
            onFinish={availabilitySubmit}
            availabilityForm={availabilityForm}
            mode={mode}
            userWeeklyAvailability={userWeeklyAvailability}
            timezone={timezone}
            resetClinicAvailabilityForm={resetClinicAvailabilityForm}
            prevUserAvailabilityForm={prevUserAvailabilityForm}
            clinicAvailability={clinicAvailability}
          />

            <Card bodyStyle={{ paddingBottom: 0}}>
              <div className="off-days-title-container ">
                <Row>
                  <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <Title className="off-days-title" level={5}>
                      Days Off <InfoIcon info={"Holidays/Days Off"} />
                    </Title>
                  </div>
                </Row>
              </div>
              <Row>
                <SelectDaysOffComponent
                  mode={mode}
                  setOffDays={setOffDays}
                  offDays={offDays}
                  shouldSubmit={shouldSubmit}
                  setCanSubmit={setCanOffDaysSubmit}
                  workingDaysRange={workingDaysRange}
                  clinicAvailability={clinicAvailability}
                ></SelectDaysOffComponent>
              </Row>
            </Card>
            {mode === "view" && (<Row className="availability-clinicUrls">
              <Col lg={24}>

                <>
                <div style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}>
                    <Title level={5}>
                    Patient Appointment URL <InfoIcon info={"This link lets your new patients book appointments directly into your calendar. You can use this link anywhere on the web like on your website, social media pages and emails."} />
                    </Title>
                  </div>
                  <Input
                    size={"large"}
                    ref={clinicUrlInputRef}
                    defaultValue={clinicDetails.clinicUrl}
                    addonAfter={clipboardCopyButton}
                    disabled
                  />
                </>


              </Col>
            </Row>)}
        </div>


        }
        {confirmationModal &&
        <ConfirmationModal
          title="Are you sure?"
          content={"By turning availability OFF, all existing and future appointments will be cancelled."}
          showModal={confirmationModal}
          setShow={setConfirmationModal}
          onConfirmation={onConfirmationOk}
          onCancel={()=> setClinicAvailability(true)}
          okText="Confirm"
        ></ConfirmationModal>
      }

      </div>
    </div>
  );
}
