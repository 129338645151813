import { Row, Col } from "antd";
import { DownloadOutlined , EyeOutlined} from "@ant-design/icons";


export function downloadButton() {
    return (
      <Row style={{ marginTop: "1rem",  }}>
        <Col
          style={{
            marginTop: "0.05rem",
            marginBottom: "11%",
            marginLeft: "30%",
            cursor:"pointer",
          }}
        >
        <span><EyeOutlined style={{ fontSize: "17px", marginRight:"20px" }}/></span>  
          <DownloadOutlined style={{ fontSize: "17px" }} />
        </Col>
      </Row>
    );
  };