import { useState } from "react";
import { message, FloatButton } from "antd";
import NotesModal from "../../shared/modals/NotesModal";
import CommentModal from "../../shared/modals/CommentModal";
import { addPatientNote, getPatientNote } from "api/patient";
import CustomNotesIcon from "components/shared/Icon/NotesIcon";

const FloatingButtons = ({
  clinicId,
  patientId,
  partneredLabs,
  patientName,
  showCommentBox,
  disableCommentBox,
  senderId
}) => {
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(
    typeof showCommentBox !== "undefined" ? showCommentBox : false
  );
  const [noteText, setNoteText] = useState("");
  const [noteTextBE, setNoteTextBE] = useState("");
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  // useEffect(() => {

  // }, [notes]);

  const getAllNotes = async () => {
    setIsNotesLoading(true);
    try {
      const reqBody = {
        patientId: patientId,
        clinicId: clinicId,
      };
      const response = await getPatientNote(reqBody)
      
      // dentistClinicApiTasks.getAll(
      //   "clinic/patientlist/note/get",
      //   reqBody
      // );
      let noteData = "";
      if (response.body.data.length > 0) {
        response.body.data.map((note) => {
          noteData = noteData.concat(noteData, note.note_description);
        });
        setNoteText(noteData);
        setNoteTextBE(noteData);
      } else setNoteText("");
      setIsNotesLoading(false);
    } catch (error) {
      setIsNotesLoading(false);
      console.log(error);
    }
  };
  const toggleNotesModal = () => {
    // console.log(notes);
    // debugger;
    if (showNotesModal && noteText !== "" && noteTextBE !== noteText) {
      addNotes();
      setNoteText("");
    } else if (!showNotesModal) getAllNotes();
    setShowNotesModal(!showNotesModal);
  };
  const toggleCommentModal = () => {
    // if (showNotesModal && noteText !== "") {
    //   addNotes();
    //   setNoteText("");
    // } else if (!showNotesModal) getAllNotes();
    if (showCommentsModal) {
      setUnreadCount(0);
      disableCommentBox();
    }

    setShowCommentsModal(!showCommentsModal);
  };
  const addNotes = async () => {
    try {
      const reqBody = {
        clinic_id: clinicId,
        patient_id: patientId,
        note_description: noteText,
      };

      
      const response = await addPatientNote(reqBody);
      
      // dentistClinicApiTasks.post(
      //   "clinic/patientlist/note/add",
      //   reqBody
      // );
      message.success(response.body.success);
    } catch (error) {
      message.error(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  };
  return (
    <div className="floatingButtons">
      <NotesModal
        showNotesModal={showNotesModal}
        toggleNotesModal={toggleNotesModal}
        isNotesLoading={isNotesLoading}
        noteText={noteText}
        setNoteText={setNoteText}
      />
      {/* {partneredLabs?.length && clinicId && patientId && (
        <CommentModal
          showCommentsModal={showCommentsModal}
          toggleCommentModal={toggleCommentModal}
          partneredLabs={partneredLabs}
          clinicId={clinicId}
          patientName={patientName}
          patientId={patientId}
          commentSenderId={senderId}
          setUnreadCount={setUnreadCount}
        />
      )} */}

      <FloatButton
        shape="circle"
         icon={<CustomNotesIcon/>}
        onClick={toggleNotesModal}
        size="large"
      />

      {/* <FloatButton
        badge={{ count: unreadCount }}
        style={{ right: 40 }}
        type="primary"
        shape="circle"
        icon={showCommentsModal ? <CloseOutlined /> : <MessageOutlined />}
        onClick={toggleCommentModal}
      /> */}
    </div>
  );
};

export default FloatingButtons;
