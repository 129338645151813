import { useState } from "react";
import { Modal, Button, message } from "antd";
import { manageStaffApiTasks } from "../../../models/mangeStaff";
import "../../../assets/styles/modals/DeleteClinician.scss";
import { primaryColor } from "colors-config";
import { clinicianDelete } from "api/clinic";
// import axios from "axios";

const DeleteModal = ({
  data: { clinicId, clinician_id, userName, lab_id },
  deleteFor,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  function deleteClinician() {
    setIsLoading(true);
    // manageStaffApiTasks
    //   .post("clinic/clinician/delete", {
    //     clinician_id: clinician_id,
    //     clinic_id: clinicId,
    //   })
      clinicianDelete( {
        clinician_id: clinician_id,
        clinic_id: clinicId,
      })
      .then((res) => {
        message.success("Clinician deleted successfully");
        // message.success(res.body.msg);
        props.success();
        setIsVisible(false);
      })
      .catch((error) => {
        console.log(error);

        message.error("Something went wrong. Please try again");
      })
      .finally(() => {
        setIsLoading(false);
        onCancel();
      });
  }

  function deleteLabPartner() {
    setIsLoading(true);
    manageStaffApiTasks
      .remove("clinic/deleteLabPartner", {
        clinic_id: clinicId,
        lab_id: lab_id,
      })
      .then((res) => {
        message.success(res.body.msg);
        props.success();
        setIsVisible(false);
      })
      .catch((error) => {
        console.error(error);
        message.error("Something went wrong. Please try again");
      })
      .finally(() => {
        setIsLoading(false);
        onCancel();
      });
  }
  const onCancel = () => {
    setIsVisible(false);
  };

  const deleteClinicianHandler = (type) => {
    type === "clinician" ? deleteClinician() : deleteLabPartner();
  };

  return (
    <Modal
      title="Delete Partner Lab"
      visible={isVisible}
      okText="Confirm"
      {...props}
      onCancel={() => setIsVisible(false)}
      afterClose={props.handleAfterClose}
      footer={[
        <Button
          className="deleteModalButton deleteCancelBtn"
          style={{
            borderColor:primaryColor,
            color:primaryColor
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          className="deleteModalButton deleteConfirmBtn"
          loading={isLoading}
          type="primary"
          onClick={() => deleteClinicianHandler(deleteFor)}
        >
          Confirm
        </Button>,
      ]}
    >
      <p>
        Are you sure want delete {deleteFor}{" "}
        <span style={{ fontWeight: "bolder" }}>{userName}</span> from{" "}
        {deleteFor} list
      </p>
    </Modal>
  );
};

export default DeleteModal;
