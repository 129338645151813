import { Modal, Button } from "antd";
import Header from "./components/Header";

import "./doctorModal.scss";
import ScanApp from "./components/ScanApp";

const DoctorAppModel = ({ isModalVisible, handleOk, handleCancel}) => {
  return (
    <Modal
      title={<Header />}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      okButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      width={1020}
      className="doctors-app-container"
      closeIcon={null}
    >
      <ScanApp/>
      <div className="skip-footer">
      <Button shape="round" type="primary" onClick={handleCancel} className="skip-footerBtn" size="large">Skip</Button>
      </div>

    
    </Modal>
  );
};

export default DoctorAppModel;
