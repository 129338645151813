import React, { useState, useEffect } from "react";
import { playStoreUrl, iosStoreUrl } from "../../colors-config";

export default function ResetPasswordRedirect({ location, match }) {
  useEffect(() => {
    console.log("hererererre");
    // const deepLinkUrl = `${process.env.REACT_APP_DEEPLINK_URL}://?email=${patientEmail}&code=${patientCode}`;
    const deepLinkUrl = `com.app.smilegeniusdental`;
    const fallbackUrl = `https://clinic.smilegeniusapp.com`;
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let url = "";
    if (isMobile) {
      //  window.location = deepLinkUrl;
      setTimeout(() => {
        const isGoogle = /Android/i.test(navigator.userAgent)
        window.location = isGoogle ? playStoreUrl : iosStoreUrl;
      }, 2000);
    } else {
      window.location = fallbackUrl;
    }
  }, []);

  return (
    <div>
      Redirecting.....
      {/* {url} */}
    </div>
  );
}
