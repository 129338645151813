import { Row, Col, Divider } from "antd";

const Footer = () => {
  return (
    <div>
      <div
        style={{ padding: "1rem" , marginTop:"0.5rem"}}
        className="aligner-component treatmentPDF-footer"
      >
        <Row gutter={[12,12]}>
          <Col span={12} lg={12}>
            <div>
              <p className="patient-response">IPR Instructions Section</p>
              <p className="patient-question">
                1. Reduce interproximal enamel between the teeth show
              </p>
              <p className="patient-question">
                2. The number beside the section is the respective aligner stage
              </p>
              <p className="patient-question">
                3. IPR should be performed before the patient wears that stage
              </p>
              <p className="patient-question">
                4.  For example, if aligner 2 is indicated, then IPR is performed before
                wearing aligner 2.
              </p>
            </div>
          </Col>
          <Col span={12} lg={12}>
            <div>
              <p className="patient-response">Treatment instructions</p>
              <p className="patient-question">
                1. Aligners must be worn 22 hours/day.
              </p>
              <p className="patient-question">2. Perform IPR as prescribed</p>
              <p className="patient-question">
                3. Use template(s) to bond attachments before stage 1 aligners
              </p>
              <p className="patient-question">
                4. Send new scans/photos if a refinement/retainer is required
                after treatment
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
