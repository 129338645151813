import ChangePasswordComponent from "../../components/private/changePasswordComponent";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { message } from "antd";


const ChangePassword = () => {
  const history = useHistory();

  const changePassword = (oldPassword, newPassword) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        history.push("/clinic/profile");
        message.success("Password successfully changed");
     
      })
      .catch((err) => message.error(err.message=="Incorrect username or password." ? "Incorrect password, please try again.":err.message));
    };
  return (
    <div style={{ marginTop: "5rem", marginLeft: "3rem" }}>
      <ChangePasswordComponent changePassword={changePassword} />
    </div>
  );
};

export default ChangePassword;
