import React from "react";
import PropTypes from "prop-types";
import AppTag from "components/shared/Tag/Tag";

const PaymentStatus = ({ paymentStatus }) => {
  return (
    <AppTag text={paymentStatus} customStyle={{ width: "140px" }} />
  );
};

PaymentStatus.propTypes = {
  paymentStatus: PropTypes.string.isRequired, 
};

export default PaymentStatus;
