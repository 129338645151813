import { Modal } from "antd";
import { primaryColor } from "colors-config";

const errorModal = (content, onOk) => {
  Modal.error({ title: "Oops! Error", content, onOk,  okButtonProps: {
    style: {
      background: primaryColor,  // Custom background color
      borderColor: primaryColor,  // Custom border color
      color: '#fff',  // Custom text color
    },
  }, });
};

export default errorModal;



