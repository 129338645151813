export const GLOBAL ={
    VIEW:"view",
    STORAGE:"storage",
    STRIPEONBOARD:"stripeOnboarded",
    COPY:"copy",
    URL_COPIED:"Url copied to clipboard",
    TRY_AGAIN:"Please try again",
    CONNECT_STRIPE:'Connect to Stripe',
    NOT_FOUND:"Not Found",
    DETAILS:'Details',
    ACC_SETTING:'Account Settings'
}

export const API_CONST ={
    LIST_VALUE:"clinic/getClinicListOfValues",
    COUNTRY:"clinic/getCountries",
    STRIPE_ONBOARD:"clinic/stripe/onboarding",
    GET_ZIP_CODE:"getZipcode",
    CLINIC_ADD:"clinic/add"
}

export const FORM_LABELS={
CLINIC_NAME:"Clinic Name",
ADMIN_NAME:"Admin Name",
PHONE_NUMBER:"Phone Number",
EMAIL_ADDRESS:"Email Address",
ADDRESS:'Address',
CITY:'City',
COUNTY_CODE:"County/Province/State",
POST_CODE:"Post/Zip/EIR Code",
COUNTRY:'Country',
BUSS_REG:'Business Registration No.',
BANK_ACC:'bankAccountNo',

}
export const FORM_NAMES ={
    clinicName: 'clinicName',
    adminName: 'adminName',
    phone: 'clinicPhoneNumber',
    email: 'clinicEmail',
    businessRegistrationNo: 'businessRegistrationNo',
    bankAccountNo: 'bankAccountNo',
    address: 'address',
    city: 'city',
    county: 'county',
    postalCode: 'postalCode',
    country: 'country',
    prefix: 'clinicCountryCode',
}

export const ERRORS ={
CLINIC_ERR :"Clinic name is required",
CLINIC_MSG :"Clinic name cannot be more than 100 characters",
ADMIN_ERR : "Admin name is required",
ADMIN_MSG: "name cannot be more than 50 characters",
PHONE_MSG: "Please input your phone number!",
PHONE_INVALID:"Invalid phone number",
PHONE_MAX:"Number must be a maximum of 10 digits",
EMAIL_INVALID: "Invalid email address",
EMAIL_INPUT:"Please input your E-mail!",
ADDRESS_MSG:"Please input your Address!",
ADDRESS_INVALID:"Address should be valid!",
}


