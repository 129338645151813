import { Typography } from "antd";

const { Title } = Typography;

const TitleComponent = ({ titleText,level }) => {
  return (
    <div>
      <Title
        level={level?level:3}
        style={{
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        {titleText}
      </Title>
    </div>
  );
};

export default TitleComponent;
