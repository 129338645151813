import { Row, Col } from "antd";
import { ReactComponent as Phones } from "assets/images/Phones.svg";
import { ReactComponent as ScanArrow } from "assets/images/ScanArrow.svg";
import { ScanImageUrl } from "colors-config";
const ScanApp = () => {
  return (
    <div>
      <Row gutter={[16, 16]} justify="space-between" align="top">
        <Col lg={12}>
          <div className="features-list">
            <h2 className="title">Real-Time Notifications</h2>
            <p className="description">
              Stay updated with instant notifications, even when you're away.
            </p>
            <h2 className="title">Seamless Communication</h2>
            <p className="description">
              Connect with patients and labs through built-in messaging.
            </p>
            <h2 className="title">Quick Patient Addition</h2>
            <p className="description">
              Easily add new patients directly from your mobile phone.
            </p>
            <h2 className="title">Progress Images</h2>
            <p className="description">
              View patient progress images on the go, no need to access your
              computer.
            </p>
          </div>
          <Row gutter={[16, 16]} className="qr-row" align="bottom">
            <Col lg={6}>
            <div className="scan-arrow">
            <ScanArrow/>
            <p className="description">
              Scan me
            </p>
            </div>
       
            </Col>
            <Col lg={18}>
            <div className="qr-scan">
            <img src={ScanImageUrl} alt=""/>
            </div>

            </Col>
          </Row>
        </Col>
        <Col lg={12}>
        <Phones style={{width:"100%", height:"400px"}}/>
        </Col>
      </Row>
    </div>
  );
};

export default ScanApp;
