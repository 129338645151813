import { companyType } from "colors-config";

export const showPhasingDetails = (phasing = "", show = true) => {
  if (companyType !== 5) {
    return "--";
  }
  const value = phasing?.trim() || "--";
  return (
    <div style={{ textAlign: "left" }}>
     {show && <p style={{ marginBottom: 0 }} className="patient-question">Phasing</p>} 
      <p style={{ marginBottom: 0, marginTop:"5px" }}>{value}</p>
    </div>
  );
};


