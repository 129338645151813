import { useEffect, useState } from "react";
import Video from "twilio-video";
import { dentistClinicApiTasks } from "../../models/dentistClinic";
import Participant from "./participant";
import { message, Button } from "antd";
import Icon, { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ReactComponent as VideoOn } from "../../assets/images/video_on.svg";
import { ReactComponent as VideoOff } from "../../assets/images/video_off.svg";
import { ReactComponent as EndCall } from "../../assets/images/end_call.svg";
import { ReactComponent as VideoMiniSvg } from "../../assets/images/video_minimize.svg";
import { connect } from "react-redux";

import Draggable from "react-draggable";
import "../../assets/styles/components/videoCallComponent.scss";

import {ReactComponent as VideoMaxiMizeSvg }from "../../assets/images/video_maximize.svg";

import { VIDEO_CALL_MINIMIZED } from "../../services/redux/actions/types";
import {
  minimizeVideoCall,
  toggleAudio,
  toggleVideo,
  endCall,
  setCallStatus,
  setCurrentVideoCallAppointment,
  maximizeVideoCall,
} from "../../services/redux/actions/videoCallActions";



const VideoCallComponent = (props) => {
  const [token, setToken] = useState(null);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState(null);

  const history = useHistory();
  useEffect(() => {
    setAppointmentDetails(props.videoCall.currentVideoCallAppointment);
  }, [props.videoCall.currentVideoCallAppointment]);

  useEffect(() => {
    const getRoomToken = () => {
      dentistClinicApiTasks
        .getAll(
          "twilio/get?identity=SmileGenius&appointmentId=" +
            props.videoCall.currentVideoCallAppointment.appointment_id
        )
        .then((res) => {
          setToken(res.body.token);
          props.setCallStatus("ONGOING");
        })
        .catch((error) => message.error("Token Fetch Failed"));
    };
    if (props.videoCall.currentVideoCallAppointment) {
      getRoomToken();
    }
  }, [props.videoCall.currentVideoCallAppointment]);

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      isLocal={false}
    />
  ));

  const participantConnected = (participant) => {
    setParticipants((prevParticipants) => [...prevParticipants, participant]);
  };

  function disconnectFromRoom() {
    if (room !== null) {
      room.disconnect();
      props.setCallStatus("ENDED");
      props.setCurrentVideoCallAppointment(null);
      setRoom(null);
      setParticipants([]);
      props.endCall();
      history.push(`/clinic/scheduling/approved`);
    }
  }

  useEffect(() => {
    if (token) {
      Video.connect(token, {
        name: props.videoCall.currentVideoCallAppointment.appointment_id,
      })
        .then((room) => {
          setRoom(room);
        })
        .catch((err) => {
          console.log("error",err);
          message.error("Not able to start Call")
        });
    }
  }, [token]);

  useEffect(() => {
    if (room) {
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", disconnectFromRoom);
      room.participants.forEach(participantConnected);
      disableAudio();
      disableVideo();
      transferWindow();
    }
  }, [room]);

  function enableVideo() {
    if (room !== null) {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
        props.toggleVideo(true);
      });
    }
  }
  function disableVideo() {
    if (room !== null) {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
        props.toggleVideo(false);
      });
    }
  }
  function enableAudio() {
    if (room !== null) {
      room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
        props.toggleAudio(true);
      });
    }
  }
  function disableAudio() {
    if (room !== null) {
      room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
        props.toggleAudio(false);
      });
    }
  }

  function transferWindow() {
    if (props.videoCall.videoCallState === VIDEO_CALL_MINIMIZED) {
      history.push("/videoChatRoom", { refFrom: "videoMinWindow" });
      props.maximizeVideoCall();
    } else {
      props.minimizeVideoCall({
        room: room,
      });
      appointmentDetails !== null &&
        history.replace(`/clinic/patient/${appointmentDetails?.patient_id}`);
    }
  }

  const VideoOnSvg = () => <VideoOn />;
  const VideoOffSvg = () => <VideoOff />;
  const EndCallSvg = () => <EndCall />;

  const videoCallScreen = () => {
    return (
      <div
        className={
          props.videoCall.videoCallState === VIDEO_CALL_MINIMIZED
            ? "dragContainer"
            : "roomContainer"
        }
      >
        <Button
          className="minimize"
          style={{
            backgroundColor: "#00000036",
            border: "none",
            position: "absolute",
            left: 20,
            top: 20,
            zIndex: 2,
          }}
          type="primary"
          shape="circle"
          size="large"
          icon={
            props.videoCall.videoCallState === VIDEO_CALL_MINIMIZED ? (
              <VideoMaxiMizeSvg />
            ) : (
              <VideoMiniSvg />
            )
          }
          onClick={transferWindow}
        />
        <div className="remote-participant">
          {remoteParticipants.length > 0 ? (
            remoteParticipants
          ) : (
            <div className="participant center">
              <p style={{ color: "#fff" }}>Waiting for Patient to join</p>
            </div>
          )}
          <div
            className={
              props.videoCall.videoCallState === VIDEO_CALL_MINIMIZED
                ? "minCallControls"
                : "bottomButtons"
            }
          >


            
            <Button
              style={{
                backgroundColor: "#00000036",
                border: "none",
              }}
              type="primary"
              shape="circle"
              size="large"
              onClick={
                props.videoCall.isAudioEnabled ? disableAudio : enableAudio
              }
              icon={
                props.videoCall.isAudioEnabled ? (
                  <AudioOutlined />
                ) : (
                  <AudioMutedOutlined />
                )
              }
            />
            <Button
              style={{
                backgroundColor: "#00000036",
                border: "none",
              }}
              type="primary"
              shape="circle"
              onClick={
                props.videoCall.isVideoEnabled ? disableVideo : enableVideo
              }
              size="large"
              icon={
                props.videoCall.isVideoEnabled ? (
                  <Icon component={VideoOnSvg} />
                ) : (
                  <Icon component={VideoOffSvg} />
                )
              }
            />
            <Button
              style={{ backgroundColor: "#FE5050", border: "none" }}
              type="primary"
              shape="circle"
              size="large"
              onClick={disconnectFromRoom}
              icon={<Icon component={EndCallSvg} />}
            />
          </div>
        </div>
        <div
          className="local-participant"
          style={{
            display:
              props.videoCall.videoCallState === VIDEO_CALL_MINIMIZED
                ? "none"
                : "block",
          }}
        >
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              isLocal={true}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  return (
    <Draggable bounds={"parent"}>
      <div
        className={
          props.videoCall.videoCallState === VIDEO_CALL_MINIMIZED
            ? "minContainer"
            : "maxContainer"
        }
        style={{
          display: props.videoCall.currentVideoCallAppointment
            ? "block"
            : "none",
        }}
      >
        {videoCallScreen()}
      </div>
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  videoCall: state.videoCall,
});

export default connect(mapStateToProps, {
  minimizeVideoCall,
  toggleAudio,
  toggleVideo,
  endCall,
  setCallStatus,
  setCurrentVideoCallAppointment,
  maximizeVideoCall,
})(VideoCallComponent);
