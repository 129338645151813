import { Checkbox } from "antd";
import PropTypes from "prop-types";
import { getAlignerNumberBackground } from "utilities/IPR";

const IprSessionList = ({ alignersLabel, onAlignerLabelClicked }) => {
  function getOrdinal(number) {
    let num = number
    if (typeof number !== 'number') {
     num = +number
    }
  
    const lastDigit = number % 10;
    const secondLastDigit = Math.floor((number % 100) / 10);
  
    if (lastDigit === 1 && secondLastDigit !== 1) {
      return `${num}st`;
    } else if (lastDigit === 2 && secondLastDigit !== 1) {
      return `${num}nd`;
    } else if (lastDigit === 3 && secondLastDigit !== 1) {
      return `${num}rd`;
    } else {
      return `${num}th`;
    }
  }

  return (
    <div className={alignersLabel.length > 10 ? "ipr-checks-scroll" :""}>
  <div style={{marginBottom:"15px", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-end"}}>
      {alignersLabel.map((obj, index) => {
        
        return (
          <div key={index} className="ipr-check-row">
            <Checkbox checked={obj.checked} onClick={()=>onAlignerLabelClicked(index)}></Checkbox>
            <p className="patient-question" style={{ margin: "0 8px 0px 0px" }}>
            {getOrdinal(index + 1)} IPR Session (Before Aligner {obj.aligner_count})
            </p>
            <div 
             style={{
              background: `${getAlignerNumberBackground(
                obj.aligner_count
              )} `
            }}
            className={`ipr-circle`} />
          </div>
        );
      })}
    </div>
    </div>
  
  );
};

export default IprSessionList;

IprSessionList.propTypes = {
  alignersLabel: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool.isRequired,
      aligner_count: PropTypes.number.isRequired,
    })
  ).isRequired,
};
