import { Tooltip } from "antd";
import { primaryColor } from "colors-config";
import { InfoCircleOutlined } from "@ant-design/icons";

const AppToolTip = ({ title, iconStyles, children }) => {
  return (
    <Tooltip title={title} arrowPointAtCenter arrow={{ pointAtCenter: true }}>
      <InfoCircleOutlined style={{ color: primaryColor, ...iconStyles }} />
      {children}
    </Tooltip>
  );
};

export default AppToolTip;
