import { useState } from "react";
import { Button, message, Upload } from "antd";

import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import "./uploadFile.scss";

const { Dragger } = Upload;

const UploadFile = ({
  fileList,
  multiple = true,
  maxCount = 5,
  uploadFiles,
  beforeUpload,
  onChange,
  children,
  className,
  style,
  accept,
}) => {
  // const [uploading, setUploading] = useState(false);
  const props = {
    className: className ? className : "custom-file-dragger",
    style,
    name: "file",
    multiple: multiple,
    maxCount: maxCount,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    customRequest: uploadFiles,
    onChange: onChange,
    beforeUpload: beforeUpload,
   
  };

  if (accept) {
    props.accept = accept;
  }

  if (fileList) {
    props.fileList = fileList;
  }

  return (
    <div>
      <Dragger {...props}>
        {children ? (
          children
        ) : (
          <>
            {" "}
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              <strong>Click</strong> or <strong>drag file</strong> to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
          </>
        )}
      </Dragger>
    </div>
  );
};

export default UploadFile;
