import { useEffect, useState } from "react";
import {
  PlusOutlined,
  InboxOutlined,
  LinkOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Row, Col, Modal, Button, message, Upload, Image, Spin } from "antd";

import { useSelector } from "react-redux";
import documentImage from "../../../assets/images/document.png";
import _ from "lodash";
import {
  insuranceGetAllMediaAPI,
  insuranceMediaAddAPI,
  insuranceMediaConfirmAPI,
  insuranceMediaUploadToS3API,
} from "api/insurance";
import { groupByDate } from "utilities/commonMethod";
const { Dragger } = Upload;

const InsuranceModal = ({
  insuranceModal,
  onInsuranceModalOpen,
  onInsuranceModalOk,
  onInsuranceModalClose,
  patientInfo,
}) => {
  const clinic = useSelector((res) => res.clinic);
  const [modalLoading, setModalLoading] = useState(false);
  const [uploadDocumentsCheck, setUploadDocumentsCheck] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    getAllPatientInsuranceMedia();
  }, []);

  const getAllPatientInsuranceMedia = async () => {
    try {
      setModalLoading(true);
      const insuranceMediaResponse = await insuranceGetAllMediaAPI(
        patientInfo.patient_id
      );

      const insuranceList = insuranceMediaResponse.data;
      setDocumentList(_.values(groupByDate(insuranceList)));
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    } finally {
      setModalLoading(false);
    }
  };

  const handleUpload = async () => {
    if (!fileList.length) {
      message.error("Please upload your insurance document to confirm");
      return;
    }
    try {
      setUploading(true);
      await Promise.all(
        fileList.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          const mediaAddData = {
            clinicId: clinic.clinicId,
            patientId: patientInfo.patient_id,
            resourceType: "file",
            contentType: file.type,
            fileName: file.name,
          };
          const preSignedResponse = await insuranceMediaAddAPI(mediaAddData);

          const uploadUrl = preSignedResponse.data.uploadUrl;
          await insuranceMediaUploadToS3API(uploadUrl, file.type, file);

          mediaAddData.hash = preSignedResponse.data.hash;
          mediaAddData.meta = preSignedResponse.data.meta;
          mediaAddData.uploadedBy = "clinic";
          await insuranceMediaConfirmAPI(mediaAddData);
        })
      );
      message.success("Insurance Documents has been uploaded");
      setUploadDocumentsCheck(false);
      setFileList([]);
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    } finally {
      setUploading(false);
      getAllPatientInsuranceMedia();
    }
  };

  const props = {
    name: "file",
    multiple: true,
    maxCount: 1,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 2) {
        message.error("You can only add 5 documents.");
        return false;
      }
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <Modal
      className="insurance-modal"
      wrapClassName="insurance-modal-wrap"
      closable={false}
      width={600}
      title={
        <Row>
          <Col span={uploadDocumentsCheck ? 16 : 8}>
            {uploadDocumentsCheck
              ? "Upload Insurance Documents"
              : "Insurance Documents"}
          </Col>

          {!uploadDocumentsCheck && (
            <Col className="insurance-header-upload" span={8} offset={8}>
              <Button
                onClick={() => {
                  setUploadDocumentsCheck(true);
                }}
                icon={<PlusOutlined />}
              >
                Upload Documents
              </Button>
            </Col>
          )}
        </Row>
      }
      visible={insuranceModal}
      onOk={onInsuranceModalOk}
      onCancel={onInsuranceModalClose}
      footer={null}
    >
      <Spin spinning={modalLoading}>
        {!uploadDocumentsCheck && (
          <>
            {!documentList.length && <div>No Documents uploaded yet.</div>}
            <Image.PreviewGroup>
              {documentList.map((date) => {
                const filterClinic = date.filter(
                  (obj) => obj.uploadedBy == "clinic"
                );
                const filterPatient = date.filter(
                  (obj) => obj.uploadedBy == "patient"
                );
                return (
                  <>
                    {filterPatient.length ? (
                      <div>
                        <p className="insurance-list-label">
                          Patient uploaded on{" "}
                          {moment(date[0].createdAt).format("DD-MMMM-YYYY")}{" "}
                        </p>
                        <div>
                          {filterPatient.map((document) => {
                            if (document?.meta?.mimeType?.includes("image")) {
                              return (
                                <Image
                                  className="insurance-images"
                                  key={document.id}
                                  src={document.url}
                                />
                              );
                            }
                            return (
                              <>
                                <a href={document.url} target="_blank">
                                  <Image
                                    preview={false}
                                    className="insurance-images"
                                    key={document.id}
                                    src={
                                      require("../../../assets/images/document.png")
                                        .default
                                    }
                                  />
                                </a>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {filterClinic.length ? (
                      <div>
                        <p className="insurance-list-label">
                          Clinic uploaded on{" "}
                          {moment(date[0].createdAt).format("DD-MMMM-YYYY")}{" "}
                        </p>
                        <div className="custom-file-list-container">
                          {filterClinic.map((document) => {
                            console.log("document, document", document);
                            if (document?.meta?.mimeType?.includes("image")) {
                              return (
                                <Image
                                  className="insurance-images"
                                  key={document.id}
                                  src={document.url}
                                />
                              );
                            }
                            return (
                              <>
                                <div className="custom-file-list">
                                  <a
                                    key={document.id}
                                    href={document.url}
                                    title={document.name}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="list-file-name"
                                  >
                                    <div className="image-mask">
                                      <div class="ant-image-mask-info">
                                        <span
                                          role="img"
                                          aria-label="eye"
                                          class="anticon anticon-eye"
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="eye"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
                                          </svg>
                                        </span>{" "}
                                        Preview
                                      </div>
                                    </div>
                                    <div
                                      style={{ paddingLeft: "8px" }}
                                      className="filename"
                                    >
                                      <FilePdfOutlined />
                                    </div>
                                    {document.name}
                                  </a>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </Image.PreviewGroup>
          </>
        )}

        {uploadDocumentsCheck && (
          <>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>

            <Row style={{ justifyContent: "center", marginTop: 20 }}>
              <Button type="primary" onClick={handleUpload} loading={uploading}>
                Confirm Upload
              </Button>
            </Row>
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default InsuranceModal;
