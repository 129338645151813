export const GET_ITEMS = "GET_ITEMS";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const ITEMS_LOADING = "ITEMS_LOADING";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_TOKEN = "SET_TOKEN";
export const CLINIC_LOADED = "CLINIC_LOADED";
export const CLEAR_CLINIC = "CLEAR_CLINIC";
export const VIDEO_CALL_MINIMIZED = "VIDEO_CALL_MINIMIZED";
export const VIDEO_CALL_MAXIMIZED = "VIDEO_CALL_MAXIMIZED";
export const VIDEO_CALL_TOGGLE_AUDIO = "VIDEO_CALL_TOGGLE_AUDIO";
export const VIDEO_CALL_TOGGLE_VIDEO = "VIDEO_CALL_TOGGLE_VIDEO";
export const END_VIDEO_CALL = "END_VIDEO_CALL";
export const SET_VIDEO_CALL_STATUS = "SET_VIDEO_CALL_STATUS";
export const GET_JOURNEY_STATUS = "GET_JOURNEY_STATUS";
export const NOTIFICATION_STATE_CHANGE = "NOTIFICATION_STATE_CHANGE";
export const UPDATE_COMMENT_BOX_STATE = "UPDATE_COMMENT_BOX_STATE";
export const OPEN_COMMENT_BOX_PATIENT_STATE = "OPEN_COMMENT_BOX_PATIENT_STATE";
export const CURRENT_VIDEO_CALL_APPOINTMENT = "CURRENT_VIDEO_CALL_APPOINTMENT";
export const APPOINTMENT_CREATED = "APPOINTMENT_CREATED";
export const SAVE_COUNTRY_CODES="SAVE_COUNTRY_CODES";
export const CLINIC_NOTIFICATION_LOADED="CLINIC_NOTIFICATION_LOADED";
export const CLINIC_NOTIFICATION_READ="CLINIC_NOTIFICATION_READ";
export const PATIENT_COUNTRY_CODES="PATIENT_COUNTRY_CODES";
export const CLINIC_AVAILABILITY="CLINIC_AVAILABILITY";
export const CLINICIAN_NAME_UPDATE="CLINICIAN_NAME_UPDATE";


export const OPEN_NEW_PATIENT = "OPEN_NEW_PATIENT";
export const CLINIC_ONBOARDING_PATIENT_STATUS_UPDATE = "CLINIC_ONBOARDING_PATIENT_STATUS_UPDATE";
export const CLINIC_ONBOARDING_PATIENT_STATUS_FINISH = "CLINIC_ONBOARDING_PATIENT_STATUS_FINISH";

export const INTERCOM_OPEN_SEND_INVITE = "INTERCOM_OPEN_SEND_INVITE";
export const INTERCOM_SHOW_AFTER_PATIENT_SENT_ORDER_TOUR = "INTERCOM_SHOW_AFTER_PATIENT_SENT_ORDER_TOUR";
