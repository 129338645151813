import { ReactComponent as EditIcon } from "assets/images/edit_svg_icon.svg";
import styles from "./editButton.module.scss";

const EditButton = ({ onEditClick, text }) => {
  return (
    <div className={styles["edit-btn-container"]} onClick={onEditClick}>
      <EditIcon fill={"#77888B"} />
      {/* <span className={styles["name"]}> {text ? text : "Edit"}</span> */}
    </div>
  );
};

export default EditButton;
