import React from "react";
import { Button } from "antd";
import styles from "./button.module.scss";

const AppButton = ({
  type = "default",
  size = "large",
  shape = "round",
  loading = false,
  disabled = false,
  children,
  icon,
  iconPosition,
  className,
  onClick,
  block,
  htmlType,
  style,
  ...props
}) => {
  return (
    <Button
      type={type}
      size={size}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      shape={shape}
      iconPosition={iconPosition}
      className={`${styles["appBtn"]} ${className}`}
      {...props}
      block={block}
      icon={icon}
      htmlType={htmlType || "button"}
      style={style}
    >
      {children}
    </Button>
  );
};

export default AppButton;
