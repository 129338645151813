import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Modal, notification, Row, Col, Select, Spin, DatePicker, Divider } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import {
  getCountryCodes,
  getPatientCountryCodesList,
} from "../../../services/redux/selectors/clinicSelectors";
import { companyType, prefix, country } from "colors-config";
import { FORM_LABELS } from "helper/constants/globalConstant";
import { getClinicLabels } from "api/clinic";
import { updateClinicFirstTimeStatusUpdate } from "services/redux/actions/clinicActions";

const AddOrEditPatient = ({ title, content, onConfirmation, IsEdit, showModal, setShow }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadValue, setLoadValue] = useState({
    country: companyType === 5 || companyType === 6 ? "United Kingdom" : "",
  });

  const [isSpin, setIsSpin] = useState(false);
  const [clinicLabels, setClinicLabels] = useState([])
  const [confimLoader, setConfirmLoader] = useState(false);
  const [form] = Form.useForm();
  const clinic = useSelector((state) => state.clinic);

  const countryCodes = useSelector(getCountryCodes);
  const patientCountriesList = useSelector(getPatientCountryCodesList);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  useEffect(() => {
    form.resetFields();
  }, [countryCodes]);

  useEffect(async() => {
    const data = await getClinicLabels(clinic?.clinicId);
    setClinicLabels(data?.data?.body?.response)
  }, []);

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  // const getCountryCodes = async () => {
  //   setIsSpin(true);
  //   try {
  //     const res = await axios.get(
  //       process.env.REACT_APP_PUBLIC_API_BASE_URL + "/getCountryCodes"
  //     );

  //     let countryCd = res?.data?.body?.countryCodes;
  //     if (countryCd && countryCd.length) {
  //       countryCd = getUniqueListBy(countryCd, "country_code_id");
  //     }

  //     setCountryCodes(
  //       countryCd?.sort((a, b) =>
  //         a.country_code_name < b.country_code_name
  //           ? -1
  //           : b.country_code_name < a.country_code_name
  //           ? 1
  //           : 0
  //       )
  //     );
  //     setIsSpin(false);
  //   } catch (error) {
  //     setIsSpin(false);

  //     console.log(error);
  //   }
  // };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  useEffect(() => {
    if (patientCountriesList?.length) {
      form.setFieldValue("prefix", prefix);
      form.setFieldValue("country", country);
    }
  }, [patientCountriesList]);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        dropdownClassName="country-dropdown"
        className="country-select"
        size="large"
        dropdownStyle={{ width: "300px" }}
        style={{ width: 110 }}
        defaultValue={prefix}
        labelRender={(props) => {
          return props.label;
        }}
        options={patientCountriesList?.map((code, index) => {
          return {
            ...code,
            key: index,
            value: code.countryCallingCodes,
            title: `${code.emoji} ${code.countryCallingCodes}`,
            label: code.countryCallingCodes,
          };
        })}
        optionRender={(option) => {
          const { data } = option;
          return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
              <span className="emoji-font">{data.emoji}</span>
              <span>{data.countryCallingCodes}</span>
              <span>| {data.name}</span>
            </div>
          );
        }}
        showSearch
        filterOption={(input, option) => {
          const lowerCasedInput = input.toLowerCase();
          return (
            option.value.toLowerCase().includes(lowerCasedInput) ||
            option.name.toLowerCase().includes(lowerCasedInput)
          );
        }}
      />
    </Form.Item>
  );

  useEffect(() => {
    if (IsEdit) {
      setLoadValue({
        firstName: content?.patient_first_name,
        Email: content?.patient_email,
        lastName: content?.patient_last_name,
        phone: content?.patient_phone_number,
        prefix: content?.patient_country_code_name,
        Address: content?.patient_address,
        city: content?.patient_city,
        county: content?.patient_county,
        Zipcode: content?.patient_postal_code,
        country: content?.patient_country,
        dob: content?.patient_date_of_birth ? dayjs(content?.patient_date_of_birth) : null,
        clinicName: content?.addressId
      });
    }

    return () => form.resetFields();
  }, [content, IsEdit]);
  // function getAge(dateString) {
  //   var today = new Date();
  //   var birthDate = new Date(dateString);
  //   var age = today.getFullYear() - birthDate.getFullYear();
  //   var m = today.getMonth() - birthDate.getMonth();
  //   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
  //     age--;
  //   }
  //   return age;
  // }
  function checkUndefined(val) {
    return val ? val : "";
  }
  const handleOk = async () => {
    setConfirmLoader(true);
    form
      .validateFields()
      .then(async (value) => {
        const apiString = IsEdit ? "/patient/editPatient" : "/patient/createPatient";
        const payload = {
          patientId: content?.patient_id,
          firstName: value?.firstName,
          lastName: value?.lastName,
          email: value?.Email,
          isUnderAge: false,
          phoneNumber: value?.phone,
          clinicId: clinic?.clinicId,
          countryCode:value?.phone?  value.prefix:null,
          Address: checkUndefined(value?.Address),
          county: checkUndefined(value?.county),
          Zipcode: checkUndefined(value?.Zipcode),
          city: checkUndefined(value?.city),
          country: checkUndefined(value?.country),
          dob: value?.dob ? moment(value.dob).toISOString() : null,
          addressId: value.clinicName
        };
        if (clinic?.companyType) {
          payload.companyType = clinic.companyType;
        }
        if (IsEdit) {
          const res = await axios.put(process.env.REACT_APP_BASE_URL + apiString, payload);

          if (res?.data?.body?.msg) {
            notification.success({ message: res?.data?.body?.msg });
            // form.resetFields();
            handleCancel();

            onConfirmation();
          }
          else{
            notification.error({ message: res?.data?.err?.msg || "Some error occured" });
          }
        } else {
          delete payload.patientId;
          await axios.post(process.env.REACT_APP_BASE_URL + apiString, payload).then((res) => {
            if (res?.data?.err?.msg === "The user email already exists.") {
              notification.error({
                message: "The user email already exists.",
              });
              return false;
            }
            if (res?.data?.body?.msg) {
              notification.success({
                message: res?.data?.body?.msg,
                description: <a>View Profile</a>,
                className: "patient-created",
                onClick: () => {
                  history.push("/clinic/patient/" + res?.data?.body?.patientId);
                  window.location.reload();
                },
              });
              handleCancel();
              if(clinic?.firstTimeOnboardStep ==="ADD_PATIENT"){
                history.push("/clinic/patient/" + res?.data?.body?.patientId+"/prescription");
                setTimeout(() => {
                  window.Intercom("startTour", process.env.REACT_APP_INTERCOM_TOUR_ADD_PRESCRIPTION);
                }, 3000);
                dispatch(updateClinicFirstTimeStatusUpdate("ADD_PRESCRIPTION"));
                return;
                // window.location.reload();
              }
              // form.resetFields();
            }
          })
          .catch(err=>{
            const errorMessage = err?.response?.data?.err?.msg ? err?.response?.data?.err?.msg : err.message;
            notification.error({
              message: errorMessage,
            });
            return false;
          })
        }
        setConfirmLoader(false);
      })
      .catch((error) => {
        setConfirmLoader(false);
        console.error(error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };
  const disabledDate = (current) => {
    // Disable dates from today and onwards
    return current && current >= dayjs().endOf("day");
  };

  // const validateZipCode = (_, value) => {
  //   // Check if the value contains only numbers
  //   const isNumber = /^[0-9]+$/.test(value);

  //   if (isNumber) {
  //     return Promise.resolve();
  //   }

  //   return Promise.reject("Zip code should only contain numbers");
  // };

  return (
    <>
      <Modal
        title={title}
        width={700}
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={confimLoader}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {isSpin ? (
          <Spin spinning={isSpin} />
        ) : (
          <Form form={form} layout="vertical" name="form_in_modal" initialValues={loadValue}>
            <Row>
              <Col>
              <Form.Item className="labelClass" name="clinicName" rules={[ {required: true , message: "Clinic Name is required",} ]} label="Clinic Name">
                    <Select
                      showSearch
                      size="large"
                      style={{ width: "300px" }}
                      placeholder="Enter Clinic Name"
                      options={clinicLabels.map((clinic,index)=>{
                        return {
                          key: index,
                          value: clinic.id,
                          label: clinic.label,
                        };
                      })}
                      
                      filterOption={(input, option) => {
                        const lowerCasedInput = input.toLowerCase();
                        return option.label.toLowerCase().includes(lowerCasedInput);
                      }}
                    />
                  </Form.Item>
              </Col>
            </Row>
            <Divider style={{ marginTop: 0}} />
            <Row>
              <Col span={24}>
                <div className="sectionInputType">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "First Name is required",
                      },
                    ]}
                  >
                    <Input size="large" className="inputTypeClass" placeholder="Enter first name" />
                  </Form.Item>
                  <Form.Item
                    className="inputTypeClass"
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Last Name is required",
                      },
                    ]}
                  >
                    <Input size="large" className="inputTypeClass" placeholder="Enter last name" />
                  </Form.Item>
                </div>
                <div className="sectionInputType">
                  <Form.Item
                    className="inputTypeClass"
                    name="Email"
                    label="Email"
                    rules={[
                      {
                        type: "email",
                        message: "Invalid email address",
                      },
                      // {
                      //   required: true,
                      //   message: "Please input your E-mail!",
                      // },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Email address"
                      className="inputTypeClass"
                      disabled={IsEdit}
                    />
                  </Form.Item>
                  {/* {IsEdit ? (
                    <Form.Item
                      className="inputTypeClass"
                      name="phone"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                        () => ({
                          validator(_, value) {
                            const convertedValue = Number(value);
                            if (!value) {
                              return Promise.resolve();
                            } else if (
                              !isNaN(convertedValue) &&
                              (value.length > 10 || value.length > 9)
                            ) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              "Input must be a valid number"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        size="large"
                        className="inputTypeClass"
                        placeholder="Phone Number"
                      />
                    </Form.Item>
                  ) : ( */}
                  <Form.Item
                    className="inputTypeClass"
                    name="phone"
                    label="Phone Number"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please input your phone number!",
                      // },
                      () => ({
                        validator(_, value) {
                          const convertedValue = Number(value);
                          if (!value) {
                            return Promise.resolve();
                          } else if (
                            !isNaN(convertedValue) &&
                            (value.length === 10 || value.length === 9)
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject("Input must be a valid number");
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      addonBefore={prefixSelector}
                      className="inputTypeClass"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                  {/* )} */}
                </div>
              </Col>
            </Row>
            <Row className="divider">
              <Col span={24}>
                <div className="sectionInputType">
                  <Form.Item className="labelClass" name="dob" label="Date of Birth">
                    <DatePicker className="inputTypeClass dob-input" disabledDate={disabledDate} />
                  </Form.Item>
                  <Form.Item className="labelClass" name="Address" label="Address">
                    <Input size="large" className="inputTypeClass" />
                  </Form.Item>
                </div>
                <div className="sectionInputType">
                  <Form.Item className="labelClass" name="city" label="City">
                    <Input size="large" className="inputTypeClass" />
                  </Form.Item>
                  <Form.Item className="labelClass" name="county" label="County/Province/State">
                    <Input size="large" className="inputTypeClass" />
                  </Form.Item>
                </div>
                <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "space-between",
                  //   flexDirection: "row",
                  //   width:"100%"
                  // }}
                  className="sectionInputType"
                >
                  <Form.Item className="labelClass" name="country" label="Country">
                    <Select
                      showSearch
                      size="large"
                      style={{ width: "300px" }}
                      placeholder="Enter Country"
                      defaultValue={country}
                      options={patientCountriesList.map((country, i) => {
                        return {
                          ...country,
                          value: country.name,
                          title: country.name,
                          label: (
                            <>
                              <span className="emoji-font">{country.emoji}</span>
                              {` ${country.name}`}
                            </>
                          ),
                          key: i,
                        };
                      })}
                      labelRender={(props) => {
                        return props.title;
                      }}
                      filterOption={(input, option) => {
                        const lowerCasedInput = input.toLowerCase();
                        return option.name.toLowerCase().includes(lowerCasedInput);
                      }}
                    />
                  </Form.Item>

                  <Form.Item className="labelClass" name="Zipcode" label={FORM_LABELS.POST_CODE}>
                    <Input size="large" className="inputTypeClass" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AddOrEditPatient;
