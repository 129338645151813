import { TREATMENT_ORDER_KEY, APPLIANCES_ORDER_KEY } from "./constants";

export const placeOrderItems = (handleOnClick) => {
    return [
      {
        key: TREATMENT_ORDER_KEY,
        label: "Treatment",
        onClick: () => handleOnClick(TREATMENT_ORDER_KEY),
      },
      {
        key: APPLIANCES_ORDER_KEY,
        label: "Appliances",
        onClick: () => handleOnClick(APPLIANCES_ORDER_KEY),
      },
    ];

  };


