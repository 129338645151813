import LabPartners from "../../components/private/onboarding-profile/labPartners";

const ClinicLabPartners = () => {
  return (
    <div>
      <LabPartners mode={"partnerHandling"}></LabPartners>
    </div>
  );
};

export default ClinicLabPartners;
