import { Col, Row, Calendar, Select } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";

moment.updateLocale("en", {
  weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
});

export default function CalendarComponent({
  clinicWorkingSchedule,
  setSelectedDate,
  onDateChange
}) {
  const months = [];

  const dateChanged = (value) => {
    const date = dayjs(value).format("YYYY-MM-DD");
    setSelectedDate(date);
  };

  const disableDates = (currentDate) => {
    const today = dayjs().startOf("day");
    const current = dayjs(currentDate).startOf("day");
    const currentDay = current.day();
    const currentValue = current.valueOf();

   
    if (!clinicWorkingSchedule) {
      return currentValue < today.valueOf();
    }
    const findWorkDayKey = clinicWorkingSchedule.newWorkingDays[currentDay];
    return (
      currentValue < today.valueOf() ||
      currentDay!=findWorkDayKey ||
      (Object.keys(clinicWorkingSchedule.holidays).length &&
        clinicWorkingSchedule.holidays[currentValue])
    );
  };


  const calendarHeader = ({ value, type, onChange, onTypeChange }) => {

    const calendarArrowClick = (type) => {
      let newValue = value.clone();
      if(type=="add"){
        newValue = newValue.add(1, 'month')
      }
      else{
        newValue = newValue.subtract(1, 'month')
      }
      onChange(newValue);
     
    };
    return (
      <div style={{ padding: "14px 8px" }}>
        <Row gutter={8} align="middle" justify="space-between">
          <Col>
            <LeftOutlined
              onClick={() => {
                calendarArrowClick("subtract");
              }}
            />
          </Col>
          <Col>
            <p style={{ fontFamily: "'Poppins', sans-serif", margin: "0" }}>

            <span style={{textTransform:"uppercase"}}>{value.format("MMM")}</span> {value.year()}

            </p>
          </Col>

          <Col>
            <RightOutlined
              onClick={() => {
                calendarArrowClick("add");
              }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="calendar-wrapper">
      <Calendar
        fullscreen={false}
        disabledDate={disableDates}
        style={{ border: "1px solid #d3d3d3" }}
        headerRender={calendarHeader}
        onSelect={ dateChanged}
      />
    </div>
  );
}
