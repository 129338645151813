import { Tooltip } from "antd";
import { companyType, primaryColor } from "colors-config";
import { InfoCircleOutlined } from "@ant-design/icons";

export const accountSettingFormValuesNormalizer = (formValue, addressIds) => {
  const formObject = { ...formValue };
  let multipleAddress = [];
  let addressDefault = null;
  addressIds.forEach(({ addressId }) => {
    const obj = { id: addressId };
    for (const key in formObject) {
      if (key.includes("" + addressId)) {
        const splittedValue = key.split("-");

        obj[splittedValue[0]] = formValue[key];

        delete formObject[key];
      }
    }
    if (obj.default) {
      addressDefault = {
        ...obj,
      };
    }

    multipleAddress.push(obj);
  });

  if (!addressDefault) {
    multipleAddress = multipleAddress.map((el, index) => {
      if (index === 0) {
        return {
          ...el,
          default: true,
        };
      }
      return el;
    });

    addressDefault = multipleAddress[0];
  }

  return {
    ...formObject,
    multipleAddresses: multipleAddress,
    ...addressDefault,
    phone: formValue.phone.number,
    prefix: formValue.phone.prefix,
  };
};



export const SupportToolTip = ({ name, title,label }) => {
  return (
    <div className="support-tag">
   
    <span style={{color:"#798c96"}}> {label ? label : ''}</span>
    <Tooltip title={title} arrowPointAtCenter arrow={{ pointAtCenter: true }}>
      <InfoCircleOutlined style={{ color: primaryColor,marginLeft:"10px" }} />
    </Tooltip>
    </div>
  );
};

export const getOrderBtnBackground = () =>{
  let color = primaryColor;
  if (companyType === 7) {
    color= "#E92A2A"
  }
  return color
}
 

