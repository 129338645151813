import {  Skeleton, Space } from "antd";
const SlotsSkeleton = () => {
    return (
        <div className="slots-skeleton-group">
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
            
        </div>
    );
};

export default SlotsSkeleton;
