import { Form, Input, Select, Row, Col, message } from "antd";
import { memo } from "react";
import { dentistClinicApiTasks } from "../../../../models/dentistClinic";
import { companyType } from "colors-config";
const { Option } = Select;
const { TextArea } = Input;
const Duration = ({
  durationList,
  labList,
  setTermsAndConditionsURL,
  setDataAcquisitionURL,
  onFormChange,
  disabled,
}) => {
  const updateURL = async (lab) => {
    const hide = message.loading("Updating forms", 0);
    try {
      const url = await dentistClinicApiTasks.getAll(
        "clinic/labPartner/getResource",
        {
          labId: lab,
        }
      );
      // formDataObject.selectLab = lab;
      setTermsAndConditionsURL(url.body.terms_and_conditions.url);
      setDataAcquisitionURL(url.body.data_acquisition_form.url);
      // formDataObject.dataAcquisitionURL = url.body.data_acquisition_form.url;
      // formDataObject.termsAndConditionsURL = url.body.terms_and_conditions.url;
      setTimeout(hide, 0);
    } catch (error) {
      setTimeout(hide, 0);
      console.error(error);
      message.error("Failed to update Terms and Conditions. Please try again!");
    }
  };

  return (
    <div>
      <h1 className="form-heading">5. Duration & Prescription Details</h1>
      <Row className="spacing-row">
        <Col lg={12}>
          <Form.Item
            name="duration"
            label="Duration Per Aligner"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              size="large"
              className="form-select"
              placeholder="Select Duration"
              onChange={(value) => onFormChange("duration", value)}
              disabled={companyType == 5 ? true : disabled}
            >
              {durationList?.body?.data?.map((item) => {
                return (
                  <Option
                    value={item.prescription_duration}
                    key={item.prescription_duration}
                  >
                    {item.prescription_duration}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12}></Col>
      </Row>
      <Row className="spacing-row">
        <Col lg={24}>
          <Form.Item name="prescriptionDetails" label="Prescription Details">
            <TextArea
              rows={4}
              onChange={(e) =>
                onFormChange("prescriptionDetails", e.target.value)
              }
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="spacing-row">
        <Col lg={12}>
          <Form.Item
            name="labId"
            label="Lab Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              size="large"
              className="form-select"
              placeholder="Select Lab"
              onSelect={updateURL}
              onChange={(val) => onFormChange("labId", val)}
              disabled={disabled}
            >
              {labList?.body?.data.map((item) => {
                return (
                  <Option value={item.lab_id} key={item.lab_id}>
                    {item.lab_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12}></Col>
      </Row>
    </div>
  );
};

export default memo(Duration);
