import api from "./api";

export const getPatientOrders = (
  paginationLimit,
  paginationPage,
  clinicId,
  searchKey,
  fromDate,
  endDate
) => {
  return api({
    method: "GET",
    url: "/clinic/billing/patientOrders",
    params: {
      limit: paginationLimit,
      page: paginationPage,
      clinicId: clinicId,
      search: searchKey,
      startDate: fromDate,
      endDate: endDate,
    },
  });
};
