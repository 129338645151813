import { useState } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, InboxOutlined } from "@ant-design/icons";
import "../../../assets/styles/components/patient-journey/uploadImage.scss";
import { connect } from "react-redux";
import axios from "axios";
import { patientResourceImageConfirmApi, uploadScanFileAPI } from "api/patient";



const UploadImage = ({
  patientId,
  viewType,
  caseId,
  selectedCaseId,
  selectedUploadStageData,
  getProgressImages
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState();
  const beforeUpload=(file) =>{
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, JPEG and PNG files!");
    }
    const isLt150M = file.size / 1024 / 1024 < 150;
    if (!isLt150M) {
      setLoading(false);
      message.error("Image must smaller than 150MB!");
      return false
      
    }
    return isJpgOrPng && isLt150M;
  }

  async function uploadFiles(options) {
    const { onSuccess, onError, file, onProgress } = options;
    setLoading(true);
    const fileNameArray = file.name.split(".");
    const randomPart = Math.random().toString(36).substring(2, 8);
    fileNameArray[0] = viewType + "_" + new Date().valueOf() + "_" + randomPart;

    const fileName = fileNameArray.join(".");
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": file.type,
      },

      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);

        if (percent === 100) {
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    try {

      const preSignRequestBody = {
        patient_id: patientId,
        file_name: fileName,
        resource_type: "file",
        file_type:
          file.type.split("/")[0] === "image" && file.type.split("/")[1],
      };

      const preSignedResponse = await uploadScanFileAPI(preSignRequestBody);

      const accessUrl = preSignedResponse?.data?.body?.success?.accessUrl;
      const uploadUrl = preSignedResponse?.data?.body?.success?.uploadUrl;
      await axios.put(uploadUrl, file, config);
      const requestAlignerBody = {
        patient_id: patientId,
        file_url: accessUrl,
        caseId,
        notify: false
      }
      if (selectedUploadStageData?.patient_image_uploaded_stage_id == 100) {
        requestAlignerBody.patient_upload_stage = "preliminary";
        requestAlignerBody.aligner_id = 100;
      }
      else if (selectedUploadStageData?.patient_image_uploaded_stage_id >= 1 && selectedUploadStageData?.patient_image_uploaded_stage_id <= 99) {
        requestAlignerBody.patient_upload_stage = "aligner";
        requestAlignerBody.aligner_id = selectedUploadStageData?.patient_image_uploaded_stage_id;
        requestAlignerBody.lab_treatment_id = selectedUploadStageData?.labTreatmentId
      }
      await patientResourceImageConfirmApi(requestAlignerBody)
      getProgressImages();
      
      onSuccess("Ok");
    } catch (error) {
      console.log(error);
      onError({ error });
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }finally{
      setLoading(false);
    }
  }
  const props = {
    name: "file",
    multiple: false,

    customRequest: uploadFiles,

    onChange:(info)=> {
      const { status } = info.file;
      if (status !== "done") {
        setLoading(true);
        setUploadMessage("Uploading");
      }
      if (status === "done") {
        setLoading(false);
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploadMessage("");
      } else if (status === "error") {
        setLoading(false);
        setUploadMessage("");
        message.error(`${info.file.name} file upload failed.`);
      }
      if(!status){
        setLoading(false);
        setUploadMessage("");
      }
    },
  };

  const handleChange = (info) => {
    if (info.file.status !== "done") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
    }
  };
  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <InboxOutlined style={{ fontSize: "1rem" }} />
      )}
      <div style={{ marginTop: 8, width: 120 }}>
        {uploadMessage
          ? uploadMessage
          : "Click or drag file to this area to upload"}
      </div>
    </div>
  );
  return (
    <div className="uploadImage">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
        onChange={handleChange}
        disabled={selectedCaseId === caseId ? false : true}
        {...props}
      >
        {uploadButton}
      </Upload>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clinic: state.clinic,
});
export default connect(mapStateToProps)(UploadImage);
