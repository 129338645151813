import { Row, Divider } from "antd";
import "../../../assets/styles/components/patient-journey/patientPrescriptionFormComponent.scss";
import UploadScanComponent from "./uploadScanComponent";
import UploadClinicalFiles from "./UploadClinicalFiles";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import {
  SCAN_COLOR,
} from "utilities/prescription";
dayjs.extend(minMax);


const ViewScanFilesComponent = ({
  clinicalFiles,
  scanUrls,
  patientId,
  treatmentOriginId,
  activeCaseId,
  getImg,
  setClinicalFilesArray,
  clinicalFilesArray,
  setScanUrls,
  setDeleteScanUrls,
  isMaxVersion
}) => {

  const [lastUploadedScan, setLastUploadedScan] = useState();
  const [lastUploadedClinicalScan, setLastUploadedClinicalScan] = useState();



  useEffect(() => {
    if (scanUrls && scanUrls.length) {
      const getAllUploadedDates = scanUrls.map((scanUrl) => {
        return dayjs(scanUrl.upload_date);
      });
      if (getAllUploadedDates?.length) {
        const largestDate = dayjs.max(getAllUploadedDates);
        setLastUploadedScan(largestDate.format("DD-MM-YYYY"));
      }
    }
  }, [scanUrls]);

  useEffect(() => {
    if (clinicalFiles && clinicalFiles.length) {
      const getAllUploadedClinicalDates = clinicalFiles.map((scanUrl) => {
        return dayjs(scanUrl.upload_date);
      });
      if (getAllUploadedClinicalDates?.length) {
        const largestDate = dayjs.max(getAllUploadedClinicalDates);
        setLastUploadedClinicalScan(largestDate.format("DD-MM-YYYY"));
      }
    }
  }, [clinicalFiles]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="scan-form-container">
          <div className="scanFiles-col">
            <div className="scanFiles-header">
              <Row className="scan-form-heading">Scan Files</Row>
              {lastUploadedScan ? (
                <div style={{ marginRight: "auto", paddingLeft: "10px" }}>
                  <span style={{ color: SCAN_COLOR }}>Last Updated at:</span> {lastUploadedScan}
                </div>
              ) : null}
            </div>
            <Divider />
            <UploadScanComponent
              scanUrls={scanUrls}
              patientId={patientId}
              treatmentOriginId={treatmentOriginId}
              activeCaseId={activeCaseId}
              getImg={getImg}
              setClinicalFilesArray={setClinicalFilesArray}
              clinicalFilesArray={clinicalFilesArray}
              setScanUrls={setScanUrls}
              setDeleteScanUrls={setDeleteScanUrls}
              isMaxVersion={isMaxVersion}
            />
          </div>
          <div className="clinicFiles-col">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "14px 0px 1px 20px",
              }}
            >
              <Row className="scan-form-heading clinicalfiles" style={{ padding: "0" }}>
                {`X-RAY | Clinical Files ${
                  clinicalFiles?.length ? "(" + clinicalFiles.length + ")" : ""
                }`}
              </Row>
              {lastUploadedClinicalScan ? (
                <div style={{ paddingRight: "20px" }}>
                  <span style={{ color: "#000", fontWeight: "bold" }}>Last Updated at:</span>{" "}
                  <span style={{ color: SCAN_COLOR }}>{lastUploadedClinicalScan}</span>
                </div>
              ) : null}
            </div>
            <Divider />
            <div style={{ padding: "27px 10px 20px 10px", position: "relative" }}>
              <UploadClinicalFiles
                clinicalFiles={clinicalFiles}
                patientId={patientId}
                treatmentOriginId={treatmentOriginId}
                activeCaseId={activeCaseId}
                getImg={getImg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewScanFilesComponent;
