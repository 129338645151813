import React from "react";
import PropTypes from "prop-types";
import { FileOutlined } from "@ant-design/icons";

const FileCell = ({ invoiceNo }) => {
  return (
    <div>
      <div style={{ color: "#223032" }}>
        <FileOutlined style={{ marginRight: "5px", color: "#77888B" }} />
        {invoiceNo}
      </div>
    </div>
  );
};

FileCell.propTypes = {
  invoiceNo: PropTypes.string.isRequired,
};

export default FileCell;
