export const json = {
  formText: `Generally, outstanding orthodontic results can be attained through informed and compliant patients. This consent form outlines the routine information given to those considering Orthodontic treatment. Although the benefits of orthodontic treatment includes healthy teeth and a beautiful smile, you should clearly understand that, similar to all medical and dental procedures, there are potential risks and limitations. Although most individuals are suitable for treatment, these risks and limitations should be well considered beforehand. It’s best to speak with an orthodontist or registered dental professional to determine your best treatment options. Please do not hesitate to ask questions during treatment.\nThe Patient’s Responsibility:
    1. Before treatment, visit an Orthodontist or dental professional for a comprehensive dental exam and to receive precise treatment information.
    2. Follow brushing & oral hygiene instructions to avoid complications with your teeth and gums. Attend all appointments on the indicated date(s) and time. This includes attending your routine exams and cleanings every 3 or 6 months.
    3. Follow food restrictions to avoid damage to your teeth and orthodontic appliances (braces, aligners, retainers).
    4. Total and complete patient compliance is mandatory.\nAligners:
    1. A series of aligners (orthodontic appliances) gently move your teeth to a desired position stage by stage.
    2. Physical or digital (scan) impressions of your teeth, images, x-rays and 3D tooth positioning technology are used to create your 3D treatment plan and manufacture your aligners.
    3. Once received, begin wearing your aligners as soon as possible otherwise they may not fit over time due to natural tooth movement. Failure to comply may result in additional diagnostic procedures or treatment at an extra cost.
    4. Wear your aligners for 22 hours per day, 2 weeks each unless otherwise instructed by your dentist.
    5. You must remove your aligners to eat, otherwise they may become damaged.
    6. Aligners may not be completely clear and may present with spaces between the tips of the teeth and aligners, as well as with bubbles due to saliva.
    7. Aligners work by applying pressure to your teeth which may result in discomfort, and in some cases, mild to advanced pain.
    8. You may experience a temporary increase in salivation or dryness of mouth particularly due to certain medications.
    9. Treatment types which only move the front teeth do not address bite issues in the molar region.
    10. You must contact us immediately if you experience any issues with your treatment or aligners. Failure to do so, or skipping aligners may result in treatment delays or personal injury.
    11. Aligners may be manufactured by a 3rd-party under a separate agreement. I understand my dentist will instruct the manufacturer based on my case specifications and information provided.
    12. As the aligners (as a medical device) are uniquely manufactured to the specifications of the patient, there are no refunds available unless the appliance is faulty or the correct service has not been rendered.
    13. Do not modify your aligners unless instructed to do so. This may damage your aligners and negatively affect your treatment.
    14. You may purchase replacement appliances (ex. Aligners or Retainers) which have been lost or damaged due to patient neglect or non-compliance.
    Oral Hygiene:
    1. Decay, permanent markings (calcification), and/or gum disease may occur without thorough tooth brushing and flossing. Immaculate oral hygiene is required.
    2. Sugary foods and snacking should be reduced significantly. Tooth sensitivity/damage:
    1. Sensitivity may occur.
    2. A dead (non-vital) tooth may occur.
    3. Teeth negatively affected by deep fillings or trauma (a blow) may die during or without orthodontic treatment.
    4. Undetected dead (non-vital) teeth may flare up during orthodontic tooth movement. A root canal (endodontic treatment) may be needed to correct this.
    5. Teeth may come out of the gum (surpa-erupt) without, at least, partial aligner coverage.
    Root resorption:
    1. The root ends of the teeth may be shortened due to treatment, known as root resorption. Shortened roots generally have no impact in healthy oral environments.
    2. Potential gum disease in later life however may negatively impact the affected tooth’s longevity due to root resorption.
    3. Aside from orthodontic treatment, root resorption may occur from cuts, trauma, impaction, endocrine disorders and/or spontaneously.
    Gum & Bone tissues:
    1. Gum tissue levels surrounding your teeth are always dependent on the amount of underlying bone supporting your teeth.
    2. Often, when aligning crowded teeth, there may be a lack of supporting bone and therefore, gum tissue. As a result, gum tissue and bone support may be inadequate, requiring periodontal treatment.
    3. Missing tissue in the interproximal spaces may result in “Black triangles” as a result of aligning crowded teeth.
    4. In some cases bone and gum health can become aggravated or impaired.
    5. Sharp edges on aligners may mildly cut the gums causing sores or discomfort. Gently filing sharp edges with an Emory board or nail file may correct this.
    Treatment time:
    1. The estimated treatment duration may be delayed.
    2. Non-compliance, damaged/missing appliances and missed appointments may increase the treatment duration and negatively impact the predicted results.
    TMJ:
    1. Although rare, TMJ (Temporomandibular joint) issues may occur or worsen during orthodontic treatment.
    2. In many cases, tooth alignment and bite correction may improve relative TMJ pain.
    3. The severity and frequency of TMJ pain and dysfunction appears to be due mainly in part by Tension along with many other causes.
    Limitations:
    1. Implants or tooth roots fused to the surrounding bone (Hypercementosis/Analysis) will not move with clear aligners.
    2. Speech may be temporarily affected.
    Medications:
    1. Chronic use of anti-inflammatories, Analgesics (pain meds like Aspirin and Ibuprofen), estrogen or calcitonin may slow or inhibit tooth movement
    2. Bisphosphonate use will inhibit tooth movement.
    3. Heavy fluoride use may limit tooth movement.
    4. Corticosteroids may decrease bone formation, decreasing the stability of tooth movement and treatment in general.
    5. Immunosuppressants and anticonvulsants may complicate treatment by causing gum inflammation making oral hygiene difficult.
    6. Anticancer drugs (busulfan/cyclophosphamide) may reduce bone growth, complicating tooth movement.
    7. Alcohol abuse may cause severe root resorption.
    8. Overall medication consumption and conditions may negatively impact your treatment.
    Very unusual occurrences:
    1. Chipped teeth, dislodged restorations, swallowed appliances, choking on appliances and allergies to nickel, latex or polyurethane are possible, but rarely occur.
    2. Aligners may rarely cause soft tissue irritation and sores of the mouth (cheeks, gums, lips and tongue).
    3. Dislodged restorations and ill-fitting dentures may need re-cementation or replacement at the expense of the patient.
    Termination of Treatment:
    1. Please note that treatment may be terminated for non-compliance, missing appointments, not wearing appliances, overdue payments, excessive appliance damage, relocations, personal conflicts or any other reason the doctor feels necessary.
    2. If termination is required, the patient will be given enough time to locate another dentist to continue treatment and will no longer receive aligners (or braces will be removed).
    Expectations:
    1. All patients can expect minor to drastic improvements to their smile, however, absolute perfection is usually impossible due to tooth shapes and sizes, muscle balance, non-compliance and hereditary reasons.
    2. Additional treatment may be required to finalize your tooth alignment. There may be additional charges for this service.
    3. It should be well understood that this is a predictive service and your results are NOT guaranteed.
    Relapse:
    1. Natural phenomena tend to cause your teeth to return to their original position after treatment. This is known as Relapse.
    2. Very severe issues are more prone to relapse, with the lower front teeth and rotated teeth being the most susceptible.
    3. Retainers are used to minimize or prevent relapse. Retention is a life-time commitment where compliance is vital.
    4. Full alignment is our main goal and in many cases we will over-correct issues to counteract relapse tendencies.
    5. When retention is discontinued, relapse may still occur.
    Home impression Kit
    1. All Home impression Kit sales are final and a refund may not be issued unless the product is defective, damaged, or undelivered.
    2. Rarely, impression kit materials may cause allergy. If so, contact your dental professional immediately. In serious cases, seek medical attention immediately.
    Final consent:
    1. I consent to the taking of photographs, study models and x-rays before, during and after orthodontic treatment to assist in the planning and progress treatment objectives. If the case proves to be of special scientific interest, the dentist reserves the right to present the records in scientific papers or demonstrations to the profession. I acknowledge I am engaging in orthodontic therapy entirely at my own risk. I certify that I have read or had read to me the contents of this form and do realize the risks and limitations involved, and consent to orthodontic treatment.`,
};
export const INFO = {
  formText: `This consent form outlines the routine information given to those considering Orthodontic treatment. Similar to all medical and dental procedures, there are potential risks and limitations.`,
};
export const PATIENT_RESPONSIBILITY = {
  formText: `1. Before treatment, visit an Orthodontist or dental professional for a comprehensive dental exam and to receive precise treatment information.
2. Follow brushing & oral hygiene instructions to avoid complications with your teeth and gums. Attend all appointments on the indicated date(s) and time. This includes attending your routine exams and cleanings every 3 or 6 months.
3. Follow food restrictions to avoid damage to your teeth and orthodontic appliances (braces, aligners, retainers).,
4. Total and complete patient compliance is mandatory.`,
};
export const ALIGNER_TERMS = {
  formText: `1. Total and complete patient compliance is mandatory.,
2. Physical or digital (scan) impressions of your teeth, images, x-rays and 3D tooth positioning technology are used to create your 3D treatment plan and manufacture your aligners.,
3. Once received, begin wearing your aligners as soon as possible otherwise they may not fit over time due to natural tooth movement. Failure to comply may result in additional diagnostic procedures or treatment at an extra cost.,
4. Wear your aligners for 22 hours per day, 2 weeks each unless otherwise instructed by your dentist.,
5. You must remove your aligners to eat, otherwise they may become damaged.,
6. Aligners may not be completely clear and may present with spaces between the tips of the teeth and aligners, as well as with bubbles due to saliva.,
7. Aligners work by applying pressure to your teeth which may result in discomfort, and in some cases, mild to advanced pain.,
8. You may experience a temporary increase in salivation or dryness of mouth particularly due to certain medications. ,
9. Treatment types which only move the front teeth do not address bite issues in the molar region.,
10. You must contact us immediately if you experience any issues with your treatment or aligners. Failure to do so, or skipping aligners may result in treatment delays or personal injury.,
11. Aligners may be manufactured by a 3rd-party under a separate agreement. I understand my dentist will instruct the manufacturer based on my case specifications and information provided.,
12. As the aligners (as a medical device) are uniquely manufactured to the specifications of the patient, there are no refunds available unless the appliance is faulty or the correct service has not been rendered.,
13. Do not modify your aligners unless instructed to do so. This may damage your aligners  and negatively affect your treatment.,
14. You may purchase replacement appliances (ex. Aligners or Retainers) which have been lost or damaged due to patient neglect or non-compliance.`,
};
export const ORAL_HYGIENE = {
  formText: `1. Decay, permanent markings (decalcification), and/or gum disease may occur without thorough tooth brushing and flossing. Immaculate oral hygiene is required.
 2. Sugary foods and snacking should be reduced significantly.`,
};

export const TOOTH_SENSITIVITY_DAMAGE = {
  formText: `1. Sensitivity may occur.
2.  A dead (non-vital) tooth may occur. Teeth negatively affected by deep fillings or trauma (a blow) may die during or without orthodontic treatment.
3. Once received, begin wearing your aligners as soon as possible otherwise they may not fit over time due to natural tooth movement. Failure to comply may result in additional diagnostic procedures or treatment at an extra cost.,
4. Undetected dead (non-vital) teeth may flare up during orthodontic tooth movement. A root canal (endodontic treatment) may be needed to correct this, Teeth may come out of the gum (surpa-erupt) without, at least, partial aligner coverage.,`,
};

export const ROOT_RESORPTION = {
  formText: `1. The root ends of the teeth may be shortened due to treatment, known as root resorption. Shortened roots generally have no impact in healthy oral environments.
2. Potential gum disease in later life however may negatively impact the affected tooth’s longevity due to root resorption.
3. Aside from orthodontic treatment, root resorption may occur from cuts, trauma, impaction, endocrine disorders and/or spontaneously.`,
};

export const GUM_TISSUE = {
  formText: `1. Gum tissue levels surrounding your teeth are always dependent on the amount of underlying bone supporting your teeth.
2. Often, when aligning crowded teeth, there may be a lack of supporting bone and therefore, gum tissue. As a result, gum tissue and bone support may be inadequate, requiring periodontal treatment.
3. Missing tissue in the interproximal spaces may result in “Black triangles” as a result of aligning crowded teeth.
4. In some cases bone and gum health can become aggravated or impaired.
5. Sharp edges on aligners may mildly cut the gums causing sores or discomfort. Gently filing sharp edges with an emery board or nail file may correct this.
`,
};

export const TREATMENT_TIME = {
  formText: `1. The estimated treatment duration may be delayed.
2. Non-compliance, damaged/missing appliances and missed appointments may increase the treatment duration and negatively impact the predicted results.
`,
};

export const TMJ = {
  formText: `1. Although rare, TMJ (Temporomandibular joint) issues may occur or worsen during orthodontic treatment.
2. In many cases, tooth alignment and bite correction may improve relative TMJ pain.
3. The severity and frequency of TMJ pain and dysfunction appears to be due mainly in part by Tension along with many other causes.
`,
};

export const LIMITATION = {
  formText: `1. Implants or tooth roots fused to the surrounding bone (Hypercementosis/Ankylosis) will not move with clear aligners.
2. Speech may be temporarily affected.
`,
};

export const MEDICATION = {
  formText: `1. Chronic use of anti-inflammatories, Analgesics (pain meds like Aspirin and Ibuprofen), estrogens, calcitonin, bisphosphonates and  heavy fluoride use may slow or inhibit tooth movement
2. Corticosteroids and Anticancer drugs (busulfan/cyclophosphamide) may decrease bone formation, decreasing the stability of tooth movement and treatment in general.
3. Immunosuppressants and anticonvulsants may cause gum inflammation making oral hygiene difficult.
4. Alcohol abuse may cause severe root resorption.
5. Overall medication consumption and conditions may negatively impact your treatment.
`,
};

export const OCCURENCE = {
  formText: `1. Chipped teeth, dislodged restorations, swallowed appliances, choking on appliances and allergies to nickel, latex or polyurethane are possible, but rarely occur.
2. Aligners may rarely cause soft tissue irritation and sores of the mouth (cheeks, gums, lips and tongue).
3. Dislodged restorations and ill-fitting dentures may need re-cementation or replacement at the expense of the patient. 
`,
};

export const TERMINATION_OF_TREATMENT = {
  formText: `1. Please note that treatment may be terminated for non-compliance, missing appointments, not wearing appliances, overdue payments, excessive appliance damage, relocations, personal conflicts or any other reason the doctor feels necessary.
2. If termination is required, the patient will be given enough time to locate another dentist to continue treatment and will no longer receive aligners (or braces will be removed).
`,
};

export const EXPECTATIONS = {
  formText: `1. All patients can expect minor to drastic improvements to their smile, however, absolute perfection is usually impossible due to tooth shapes and sizes, muscle balance, non-compliance and hereditary reasons.
2. Additional treatment, known as Refinements, may be required to finalize your tooth alignment. 1 refinement is included with treatment.
3. Additional refinements cost may be incurred. Speak with your clinic for details.
4. It should be well understood that this is a predictive service and your results are NOT guaranteed.
`,
};

export const RELAPSE = {
  formText: `1. Natural phenomena tend to cause your teeth to return to their original position after treatment (Relapse). Retainers are worn for 3-6 months after treatment for 22/day then at night indefinitely (or as prescribed by your dentist). When retention is discontinued, relapse may occur. 
`,
};

export const FINAL_CONSENT = {
  formText: `1. I consent to the taking of photographs, study models and x-rays before, during and after orthodontic treatment to assist in the planning and progress treatment objectives. If the case proves to be of special scientific interest, the dentist reserves the right to present the records in scientific papers or demonstrations to the profession. I acknowledge I am engaging in orthodontic therapy entirely at my own risk.  I certify that I have read or had read to me the contents of this form and do realize the risks and limitations involved, and consent to orthodontic treatment. 

`,
};
