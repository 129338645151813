import api from "./api";

const getLabProductList = (labId) => {
    return api({
        method: "GET",
        url: `/lab/product/list/${labId}`,
    })
};

const getLabResourcesList = (clinicId,labId) => {
    return api({
        method: "GET",
        url: `/lab/resources/clinic/view/${clinicId}/${labId}`,
    })
};

export const archivePatient = (data) => {
  return api({
    method: "POST",
    url: "/lab/archivePatient",
    data: data,
  });
};

 const searchLabResources = (clinicId,labId,params) => {
    return api({
      method: "GET",
      url: `/lab/resources/clinic/search/${clinicId}/${labId}`,
      params
    });
  };

  const getLabOrderList = () => {
    return api({
      method: "GET",
      url: `/lab/lab/order/getStatus`,
    });
  };






export {
    getLabProductList,
    getLabResourcesList,
    searchLabResources,
    getLabOrderList,
};
