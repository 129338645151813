import ClinicIntegrationList from "../../components/private/integrations";

const ClinicIntegrations = () => {
  return (
    <div>
      <ClinicIntegrationList/>
    </div>
  );
};

export default ClinicIntegrations;
