import { Modal, Empty } from "antd";
const ModificationRequestModal = ({ modifyComment, setModificationModalOpen,modificationModalOpen }) => {
  return (
    <div>
      <Modal
        title="Modification Request"
        footer={null}
        onCancel={() => setModificationModalOpen(false)}
        visible={modificationModalOpen}
      >
        {modifyComment ? (
          modifyComment
        ) : (
          <Empty description={"No modification comments"} />
        )}
      </Modal>
    </div>
  );
};

export default ModificationRequestModal;
