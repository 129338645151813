export const teethNameType = [
  {
    label: "FDI",
    value: "fdi",
  },
  {
    label: "Universal",
    value: "universal",
  },

  {
    label: "Palmer",
    value: "palmer",
  },
];

export const jawType = {
  UPPER: "upper",
  LOWER: "lower",
};




