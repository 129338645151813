import { jawType } from "components/IPRDetail/constants";
import { uniqByKeepLast } from "utilities/commonMethod";
import { ALIGNER_COLOR_CONFIGURATION } from "./constants";
export const dataMapper = (iprData) => {
  let upper = [];
  const lower = [];
  iprData.forEach((el) => {
    const mappedIprObject = {
      ...el,
      name: `aligner-${el.aligner_count}`,
      session: el.aligner_count,
      uniqueId: el.id
    };
    if (el.jawType === jawType.UPPER) {
      upper.push(mappedIprObject);
    } else {
      lower.push(mappedIprObject);
    }
  });

  // const lowerArrows = Array.from({ length: 15 }).fill(false);

  // lower.forEach((el) => {
  //   el.alignerValues.forEach((v, index) => {
  //     if (v.value) {
  //     }
  //   });
  // });;
  // const upperFinalCopy ={}
  // const upperFinal =  fillSessionGaps(upper);
  // const lowerFinal =  fillSessionGaps(lower);
  // console.log("upperFinal", upperFinal)

  // for (const key in upperFinal) {
  //   upperFinalCopy[key] = upperFinal[key].reverse()
  // }
  const upperASC = upper.sort((a, b) => {
    return a.session - b.session;
  });
  return {
    lower : lower,
    upper: upper,
  };
};

const fillSessionGaps = (sessions) => {
  const sessionCopy = JSON.parse(JSON.stringify(sessions));
  const columns = {};

  for (let i = 0; i < 15; i++) {
    columns[i] = [];
  }

  const sessionASC = sessionCopy.sort((a, b) => {
    return a.session - b.session;
  });

  //   const sessionDesc = sessions.sort((a, b) => b.session - a.session);
  sessionASC.forEach((el) => {
    el.alignerValues.forEach((val, index) => {
      if (val.value) {
        columns[index].push({ value: val.value, session: el.session });
      }
    });
  });

return columns
};


export const checkTextEditorContent = (content) => {
  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = content;
  const innerText = tempContainer.innerText;
  return innerText ? true : false
}

export function generateAlignerLabels(alignerLabel, noOfUpper, noOfLower) {
  return alignerLabel.map((obj, index) => {
      obj.checked = true;
      obj.session = index + 1;
      if ((obj.aligner_count <= noOfUpper) && (obj.aligner_count <= noOfLower)) {
          obj.sessionListName = `U${obj.aligner_count}L${obj.aligner_count}`;
      } else if (obj.aligner_count > noOfLower) {
          obj.sessionListName = `U${obj.aligner_count}`;
      } else if (noOfLower > noOfUpper) {
          obj.sessionListName = `L${obj.aligner_count}`;
      }
      return obj;
  });
}

export const iprJawMappers = (data, treatment) => {
  let apiIprData = data;
  if (treatment.revision_plan_version && Number(treatment.refinement_applied) === 0) {
    apiIprData = apiIprData.filter(
      (obj) => obj.refinement && treatment.lab_treatment_id == obj.labTreatmentId
    );
  } else {
    apiIprData = apiIprData.filter((obj) => !obj.refinement);
  }
  const { lower, upper } = dataMapper(apiIprData);
  const upperJaw = upper.reverse();

  const lowerJaw = lower;

  const iprData = apiIprData;
  iprData.sort((a, b) => {
    return a.aligner_count - b.aligner_count;
  });
  let uniqueAlignerLabel = uniqByKeepLast(iprData, (obj) => obj.aligner_count);

  const alignerLabel = generateAlignerLabels(uniqueAlignerLabel, treatment.num_of_upper_aligners, treatment.num_of_lower_aligners);

  return {
    upperJaw,
    lowerJaw,
    alignerLabel,
  };
};

export const removeTrailingEmptyTagsFromString = (htmlString) => {
  // Parse the HTML string to a document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Function to check if an element is empty
  const isEmptyElement = (element) => {
    return (
      element.innerHTML.trim() === '' ||
      [...element.childNodes].every(
        (child) => child.nodeName === 'BR' || (child.nodeType === Node.TEXT_NODE && !child.nodeValue.trim())
      )
    );
  };

  // Function to remove trailing empty elements
  const removeTrailingEmptyTags = (parent) => {
    const children = [...parent.children];
    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      if (isEmptyElement(child)) {
        parent.removeChild(child);
      } else {
        break;
      }
    }
  };

  // Remove trailing empty tags from the body
  removeTrailingEmptyTags(doc.body);

  // Serialize the document back to a string
  return doc.body.innerHTML;
};

export function getOrdinal(number) {
  let num = number
  if (typeof number !== 'number') {
   num = +number
  }

  const lastDigit = number % 10;
  const secondLastDigit = Math.floor((number % 100) / 10);

  if (lastDigit === 1 && secondLastDigit !== 1) {
    return `${num}st`;
  } else if (lastDigit === 2 && secondLastDigit !== 1) {
    return `${num}nd`;
  } else if (lastDigit === 3 && secondLastDigit !== 1) {
    return `${num}rd`;
  } else {
    return `${num}th`;
  }
}



export function getAlignerClassName(aligner, upperAligner, alignerType, upperIndex, alignersArray) {
  let className = `aligner-box aligner-box${alignerType}`;

  if (alignerType === "upper") {
      if (aligner.value && upperIndex === alignersArray.length - 1) {
          className += ` aligner-0-upper`;
      }
  } else {
      if (aligner.value) {
          className += ` aligner-${upperIndex}-${alignerType}`;
      }
  }

  return className.trim();
}


export function getAlignerBorder(aligner, upperAligner) {
  return ALIGNER_COLOR_CONFIGURATION[upperAligner.aligner_count - 1]
}

export function getAlignerBackground(aligner, upperAligner) {
  let bg = "#fff";
 if (aligner.value) {
   bg = ALIGNER_COLOR_CONFIGURATION[upperAligner.aligner_count - 1]
 }
 return bg
}


export function getAlignerNumberBackground(aligner) {
  let bg = "#fff";
 if (aligner) {
   bg = ALIGNER_COLOR_CONFIGURATION[aligner - 1]
 }
 return bg
}
