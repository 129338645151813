import { Button } from "antd";

const AppointmentDetailsButton = ({
  type,

  children,
  ...props
}) => {
  return (
    <Button
      {...props}
      type={type}
      style={{
        borderRadius: "75px",

        // color: type !== "primary" ? "#00c6e9" : "white",
        width: "100%",
        height: "2.9rem",
        // borderColor: "#00c6e9",
        // background: type !== "primary" ? "white" : "#00c6e9",
      }}
    >
      {children}
    </Button>
  );
};

export default AppointmentDetailsButton;
