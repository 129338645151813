import { Row, Col } from "antd";
import AttachmentSessionList from "./AttachmentSessionList/AttachmentSessionList";
import { getAlignerNumberBackground } from "utilities/IPR";

const AttachmentInfo = ({
  alignersLabel,
  onAlignerLabelClicked,
  upperAttachments,
  lowerAttachments,
}) => {
  return (
    <div className="attachmentInfo-container">
      <p
        className="patient-question"
        style={{ marginTop: "0px", textAlign: "left" }}
      >
        Attachment Details
      </p>
      {upperAttachments.map((attachment, i) => {
        return (
          <div key={i}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row
                      gutter={[16, 16]}
                      align="middle"
                    >
                      <Col lg={4} span={4}>
                        <p
                           style={{
                            background: `${getAlignerNumberBackground(
                              attachment.aligner_count
                            )} `
                          }}
                          className={`toothName`}
                        >
                          {attachmentValue.teeth}
                        </p>
                      </Col>
                      <Col lg={8} span={8}>
                        <p className="attachment-response">
                          {attachmentValue.attachmentType}
                        </p>
                      </Col>
                      <Col lg={12} span={12}>
                        <p className="attachment-response">
                        {attachmentValue.detail ? attachmentValue?.detail : "--"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
      {lowerAttachments.map((attachment, i) => {
        return (
          <div key={i}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row
                      gutter={[16, 16]}
                      align="middle"
                   
                    >
                      <Col lg={4}  span={4}>
                      <p
                           style={{
                            background: `${getAlignerNumberBackground(
                              attachment.aligner_count
                            )} `
                          }}
                          className={`toothName`}
                        >
                          {attachmentValue.teeth}
                        </p>
                      </Col>
                      <Col lg={8} span={8}>
                        <p className="attachment-response">
                          {attachmentValue.attachmentType}
                        </p>
                      </Col>
                      <Col lg={12} span={12}>
                        <p className="attachment-response">
                        {attachmentValue.detail ? attachmentValue?.detail : "--"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentInfo;
