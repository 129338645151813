import { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import { Modal, Input, Form, message } from "antd";
import { useHistory } from "react-router-dom";
import { checkCognitoUserAPI } from "api/clinic";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 8,
      offset: 2,
    },
    lg: {
      span: 8,
      offset: 0,
    },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
  },
  wrapperCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 24,
      offset: 0,
    },
    lg: {
      span: 24,
      offset: 0,
    },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
  },
};

const PasswordHandleModal = ({
  title,
  showModal,
  setShow,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const [form] = Form.useForm();
  const history = useHistory();
  

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);



  const onFinish = async (values) => {
    try {
      setLoading(true);
      const email = values.email;
      await checkCognitoUserAPI(email);
      await Auth.forgotPassword(email);
      setIsModalVisible(false);
      setShow(false);
      history.push({
        pathname: "/forgot-password",
        state: { message: email },
      });
    } catch (error) {
      console.log("error", error);
      message.error(error?.response?.data?.message ? error?.response?.data?.message : error.message);
    }
    finally {
      setLoading(false);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={() => form.submit()}
        confirmLoading={loading}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        <p>Dont worry happens to the best of us.</p>

        <Form
          className="login-form"
          {...formItemLayout}
          layout={"vertical"}
          name="register"
          scrollToFirstError
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Invalid email address",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
            label="Your email id"
          >
            <Input size='large' ref={inputRef} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PasswordHandleModal;
