import { useState, useEffect } from "react";
import { Typography, Row, Spin, message, Button, Form } from "antd";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import { useParams, useRouteMatch } from "react-router-dom";

import AccountSettingsFormComponent from "../../components/private/onboarding-profile/accountSettingsFormComponent";
import AvailabilitySettingsComponent from "../../components/private/onboarding-profile/availabilitySettingsComponent";
import ServicesAndResourcesComponent from "../../components/private/onboarding-profile/servicesAndResourcesComponent";
import ManageStaff from "../../components/private/onboarding-profile/manageStaff";
import LabPartners from "../../components/private/onboarding-profile/labPartners";
import ProfileNavbarComponent from "../../components/private/onboarding-profile/profileNavbarComponent";

import { dentistClinicApiTasks } from "../../models/dentistClinic";

import "../../assets/styles/routes/onboarding.scss";
import errorModal from "../../components/shared/modals/errorModal";
import GenericButton from "../../components/shared/buttons/genericButton";
import {
  addClinic,
  addClinicAppointmentProducts,
  addClinicAvailability,
  clinicEditAvailability,
  getClinicResources,
  getProfileAndAvailability,
} from "api/clinic";
import ClinicianForm from "components/private/onboarding-profile/ClinicianForm/ClinicianForm";
import AppButton from "components/shared/AppButton/AppButton";
const { Title } = Typography;
let clinicInfo = {
  clinicName: "",
  adminName: "",
  phone: "",
  email: "",
  businessRegistrationNo: "",
  bankAccountNo: "",
  address: "",
  city: "",
  county: "",
  postalCode: "",
  country: "",
  prefix: "",
};

function ProfileAndSettings({ clinic }) {
  const { step } = useParams();

  const [currentSelection, setCurrentSelection] = useState(0);
  const [appointmentTypes, setAppointmentTypes] = useState({});
  const [appointmentfromDb, setAppointmrntfromDB] = useState([]);
  const [productOrServiceTypes, setProductOrServiceTypes] = useState({});
  const [mode, setMode] = useState("view");
  const [form] = Form.useForm();
  const [clinicDetails, setClinicDetails] = useState(clinicInfo);
  const [isServicesandResourcesComplete, setIsServicesandResourcesComplete] = useState(false);
  const [clinicDetailsForCache, setClinicDetailsForCache] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [shouldAvailabilitySubmit, setShouldAvailabilitySubmit] = useState(false);
  const [canAvailabilitySubmit, setCanAvailabilitySubmit] = useState(false);
  const [locationSearchResults, setLocationSearchResults] = useState([]);

  const [shouldProductsAndServicesSubmit, setShouldProductsAndServicesSubmit] = useState(false);
  const [shouldManageStaffSubmit, setShouldManageStaffSubmit] = useState(false);

  const [clinicOffDays, setClinicOffDays] = useState([]);
  const [clinicWorkingDaysRange, setClinicWorkingDaysRange] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [clinicWorkingDays, setClinicWorkingDays] = useState([]);
  const [clinicWorkingHours, setClinicWorkingHours] = useState([]);
  const [clinicBreakHours, setClinicBreakHours] = useState([]);

  const [fileListView, setFileListView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isMultiStepButtonDisabled, setIsMultiStepButtonDisabled] = useState(false);

  const [isButtonSpinning, setIsButtonSpinning] = useState(false);

  const [appointmentTypeOptions, setAppointmentTypeOptions] = useState([]);
  const [appointmentNameOptions, setAppointmentNameOptions] = useState([]);

  const [appointmentDurationOptions, setAppointmentDurationOptions] = useState([]);

  const [appointmentCurrencyOptions, setAppointmentCurrencyOptions] = useState([]);

  const [productOrServiceTypeOptions, setProductOrServiceTypeOptions] = useState([]);
  const [productOrServiceNameOptions, setProductOrServiceNameOptions] = useState([]);

  const [productOrServiceCurrencyOptions, setProductOrServiceCurrencyOptions] = useState([]);

  const [canBeOnboarded, setCanBeOnboarded] = useState(true);
  const [userWeeklyAvailability, setUserWeeklyAvailability] = useState([]);
  const [timezone, setTimezone] = useState("");
  const [resetClinicAvailabilityForm, setResetClinicAvailabilityForm] = useState(false);
  const [prevUserAvailabilityForm, setPrevUserAvailabilityForm] = useState(null);
  const [prevClinicOffDays, setPrevClinicOffDays] = useState([]);
  const [clinicAvailability, setClinicAvailability] = useState(false);
  const [clinicStaff, setClinicStaff] = useState([]);
  const [cliniciansForm] = Form.useForm();

  // const [clinicUrl,setClinicUrl]

  useEffect(() => {
    let stepIndex = 0;

    if (step === "services") {
      stepIndex = 2;
    } else if (step === "availability") {
      stepIndex = 1;
    }

    setCurrentSelection(stepIndex);
  }, [step]);

  useEffect(() => {
    canOnboardingBeCompleted();
  }, [clinic, clinicWorkingDays, appointmentTypes]);

  const parseWorkingDaysRangeForView = (workingDaysRange) => {
    return [moment(workingDaysRange.clinic_start_date), moment(workingDaysRange.clinic_end_date)];
  };

  const parseHolidaysForView = (holidays) => {
    return holidays.map((holiday) => {
      return moment(holiday.holiday).format("YYYY-MM-DD");
    });
  };

  const parseWorkDaysForView = (workDays) => {
    return workDays.map((workDay) => {
      return workDay.workday_number;
    });
  };

  const parseBreakAndWorkingHoursForView = (clinicTiming, type) => {
    const dummyDate = "2017-03-13";
    if (type === "working") {
      return [
        dayjs(dummyDate + " " + clinicTiming.clinic_start_time),
        dayjs(dummyDate + " " + clinicTiming.clinic_end_time),
      ];
    } else if (type === "break") {
      if (
        clinicTiming.clinic_break_start_time === "00:00:00" &&
        clinicTiming.clinic_break_end_time === "00:00:00"
      ) {
        return [];
      }
      return [
        moment(dummyDate + " " + clinicTiming.clinic_break_start_time),
        moment(dummyDate + " " + clinicTiming.clinic_break_end_time),
      ];
    }
  };

  const removeAppointmentProductFromDB = async (id, type) => {
    try {
      const model = {
        clinic_id: clinic.clinicId,
        appointment_id: type === "appointment" ? id : "",
        product_id: type === "product" ? id : "",
      };
      const res = await dentistClinicApiTasks.remove("clinic/deleteAppointmentProduct", model);
    } catch (error) {
      //debugger
      console.log(error.response.data.err.msg);
      if (error.response.data.err?.msg) {
        message.error(error.response.data.err?.msg);
      } else {
        message.error("Something went wrong. Please try again!");
        //debugger
      }

      throw error;
    }
  };

  const parseAppointmentsForView = (appointmentTypes) => {
    let appointmentTyepObject = {};
    appointmentTypes.map((appointmentType) => {
      appointmentTyepObject[appointmentType.appointment_id] = {
        appointmentType: JSON.stringify({
          clinic_appointment_type_id: appointmentType.appointment_type_id,
          clinic_appointment_type_name: appointmentType.appointment_type,
        }),
        appointmentName: JSON.stringify({
          clinic_appointment_type_name_id: appointmentType.appointment_name_id,
          clinic_appointment_name: appointmentType.appointment_name,
        }),

        // durationOfMeeting: appointmentType.clinic_meeting_duration,
        durationOfMeeting: JSON.stringify({
          clinic_meeting_duration_id: appointmentType.slot_duration_id,
          clinic_meeting_duration: appointmentType.clinic_meeting_duration,
        }),

        description: appointmentType.description,
        price: appointmentType.price,
        // appointmentCurrency: appointmentType.currency_name,
        appointmentCurrency: JSON.stringify({
          currency_id: appointmentType.slot_currency_id,
          currency_name: appointmentType.currency_name,
        }),
        id: appointmentType.appointment_id,
        paymentIds: appointmentType.paymentIds,
        free: appointmentType.free,
      };
    });
    return appointmentTyepObject;
  };

  const calculateTotalPrice = (price, discount) => {
    var numVal1 = Number(price);
    var numVal2 = Number(discount ? discount : 0);

    const totalValue = numVal1 * ((100 - numVal2) / 100);
    return Math.ceil(totalValue);
  };

  const parseServicesForView = (services) => {
    let servicesObj = {};
    services.map((service) => {
      servicesObj[service.product_id] = {
        // productOrServiceType: service.product_type,
        productOrServiceType: JSON.stringify({
          product_type_id: service.product_type_id,
          product_type: service.product_type,
        }),

        // productOrServiceName: service.product_name,
        productOrServiceName: JSON.stringify({
          product_name_id: service.product_name_id,
          product_name: service.product_name,
        }),

        description: service.description,
        price: service.price,
        total: calculateTotalPrice(service.price, service.discountAmount),
        // discount:service.discount?service.discount:0,
        discount:
          service.discount && service.price
            ? calculatePercentage(service.price, service.discount)
            : 0,
        discountAmount: service.discount ? service.discount : 0,
        // currency: service.currency_name,
        currency: JSON.stringify({
          currency_id: service.product_currency_id,
          currency_name: service.currency_name,
        }),
        paymentIds: service.paymentIds,
        id: service.product_id,
        free: service.free ? true : false,
        hidePatientPrice: service.hidePatientPrice ? true : false,
      };
    });
    return servicesObj;
  };
  const calculatePercentage = (price, value) => {
    const total = price - value;
    const diffPercentage = (total / price) * 100;
    const discount = Number(100 - diffPercentage).toFixed(1);
    return discount;
  };

  useEffect(() => {
    if (clinic?.clinicEmail) {
      setClinicStaff(clinic?.clinicians);
    }
  }, [clinic]);

  const parseFiles = (files) => {
    return files.map((file) => {
      return {
        name: file.resource_title !== null ? file.title : file.file_name,
        status: "done",
        type: file.file_type,
        uid: uuidv4(),
        url: file.resource_url,
        originName: file.file_name,
      };
    });
  };

  const onProfileFormSubmit = async (newClinicDetails) => {
    // const currentValues = form.getFieldsValue();
    // const clinicianValues = currentValues?.clinicians

    // setClinicDetails({ ...clinicDetails, ...newClinicDetails });
    setIsButtonSpinning(true);

    const handleAddress = (addressDetail) => {
      try {
        const address = JSON.parse(addressDetail).description;
        return address;
      } catch (error) {
        return addressDetail;
      }
    };

    const model = {
      address: handleAddress(newClinicDetails.address),
      details: "Details",
      name: newClinicDetails.clinicName,
      admin: newClinicDetails.adminName,
      regno: newClinicDetails.businessRegistrationNo,
      email: newClinicDetails.email,
      bankAccNo: newClinicDetails.bankAccountNo,
      city: newClinicDetails.city,
      county: newClinicDetails.county,
      zipcode: newClinicDetails.zipCode,
      countryCode: newClinicDetails.prefix,
      phoneNumber: newClinicDetails.phone,
      regno: newClinicDetails.businessRegistrationNo,
      country: newClinicDetails.country,
      multipleAddresses: newClinicDetails.multipleAddresses,
      supportEmail: newClinicDetails.supportEmail,
      supportPhone: newClinicDetails.supportPhone,
      supportSamePhone: newClinicDetails.supportSamePhone,
      supportSameEmail: newClinicDetails.supportSameEmail,
      // clinicians: clinicianValues
    };

    try {
      // const res = await dentistClinicApiTasks.post("clinic/add", model);
      // await addClinic(model);

      setMode("view");
      if (!clinic.isOnboarded) {
        fetchClinicDetails();
      }
      message.success("Profile settings updated");
    } catch (error) {
      errorModal(error?.response?.data?.err);

      console.log(error);
    } finally {
      setIsButtonSpinning(false);
      setShouldSubmit(false);
    }
  };

  const createWorkingDays = () => {
    return clinicWorkingDays.map((clinicWorkingDay) => {
      return [clinic.clinicId, clinicWorkingDay];
    });
  };

  const createHolidays = () => {
    return clinicOffDays.map((clinicOffDay) => {
      return [clinic.clinicId, clinicOffDay];
    });
  };

  useEffect(() => {
    if (clinic.clinicId) {
      fetchClinicDetails();
      _getServicesAndResources();
    }
  }, [clinic.clinicId]);

  const resetClinicAvaibility = () => {
    if (clinicDetailsForCache !== null && typeof clinicDetailsForCache?.body !== "undefined") {
      const holidays = clinicDetailsForCache?.body?.holidays;
      const workingDays = clinicDetailsForCache?.body?.workdays;
      const workingDaysRange = clinicDetailsForCache?.body?.working_period[0];
      const breakHours = clinicDetailsForCache?.body?.break_hours[0];
      const workingHours = clinicDetailsForCache?.body?.working_hours[0];
      setClinicWorkingDaysRange(parseWorkingDaysRangeForView(workingDaysRange));
      setClinicOffDays(parseHolidaysForView(holidays));
      setClinicWorkingDays(parseWorkDaysForView(workingDays));
      setClinicBreakHours(parseBreakAndWorkingHoursForView(breakHours, "break"));
      setClinicWorkingHours(parseBreakAndWorkingHoursForView(workingHours, "working"));
    }
    setResetClinicAvailabilityForm(true);
    setClinicOffDays(prevClinicOffDays);
  };

  const fetchClinicDetails = async () => {
    try {
      setIsLoading(true);
      const res = await getProfileAndAvailability({ clinicId: clinic.clinicId });
      setUserWeeklyAvailability(res?.body?.workingDays?.workingDays);
      setPrevUserAvailabilityForm(res?.body?.workingDays?.workingDays);
      setTimezone(res?.body?.profile?.timezone);
      setClinicDetailsForCache(res);
      setClinicStaff(res?.body?.clinicians);
      const servicesRes = await getClinicResources({
        clinicId: clinic.clinicId,
      });

      const profile = res.body.profile;
      let multipleAddresses = [...profile.multipleAddresses];
      const checkDefaultAddress = profile.multipleAddresses.some((el) => el.default);

      if (!checkDefaultAddress) {
        multipleAddresses = multipleAddresses.map((el, index) => {
          if (index === 0) {
            return {
              ...el,
              default: 1,
            };
          }
          return el;
        });
      }

      clinicInfo = {
        clinicName: profile.clinic_name,
        adminName: profile.clinic_admin,
        phone: profile.clinic_contact,
        email: profile.clinic_email,
        businessRegistrationNo: profile.clinic_regno,
        bankAccountNo: profile.clinic_bank_acc_no,
        address: profile.clinic_address,
        city: profile.clinic_city,
        county: profile.clinic_county,
        zipCode: profile.clinic_zipcode,
        country: profile.clinic_country,
        prefix: profile.country_code_name,
        clinicUrl: servicesRes.body.clinic_url,
        multipleAddresses: multipleAddresses,
        clinicAvailability: profile.clinic_availability ? true : false,
        supportEmail: profile.supportEmail,
        supportNumber: profile.supportNumber,
        supportPrefix: profile.supportPrefix,
        supportSamePhone: profile.supportSamePhone,
        supportSameEmail: profile.supportSameEmail,
        clinicians: res?.body?.clinicians,
        consentDetails:res?.body?.consentDetails
      };

      setClinicDetails(clinicInfo);

      const holidays = res.body.holidays;
      const workingDays = res.body.workdays;
      const workingDaysRange = res.body.working_period[0];
      const breakHours = res.body.break_hours[0];
      const workingHours = res.body.working_hours[0];

      setClinicWorkingDaysRange(parseWorkingDaysRangeForView(workingDaysRange));
      setClinicOffDays(parseHolidaysForView(holidays));
      setPrevClinicOffDays(parseHolidaysForView(holidays));
      setClinicWorkingDays(parseWorkDaysForView(workingDays));
      setClinicBreakHours(parseBreakAndWorkingHoursForView(breakHours, "break"));
      setClinicWorkingHours(parseBreakAndWorkingHoursForView(workingHours, "working"));

      const appointmentTypes = servicesRes.body.clinic_appointments;
      const services = servicesRes.body.clinic_products;
      const resources = servicesRes.body.clinic_resources;
      setAppointmentTypes(parseAppointmentsForView(appointmentTypes));
      setProductOrServiceTypes(parseServicesForView(services));
      setFileListView(parseFiles(resources));
      setClinicAvailability(res?.body?.profile?.clinic_availability ? true : false);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const _getServicesAndResources = async () => {
    try {
      // const servicesRes = await dentistClinicApiTasks.getAll(
      //   "clinic/getServicesAndResources",
      //   {
      //     clinicId: clinic.clinicId,
      //   }
      // );
      const servicesRes = await getClinicResources({
        clinicId: clinic.clinicId,
      });
      setAppointmrntfromDB(servicesRes.body.clinic_appointments);
      let appointmentTypes = servicesRes.body.clinic_appointments;
      if (!Array.isArray(appointmentTypes)) {
        appointmentTypes = [appointmentTypes];
      }
      const services = servicesRes.body.clinic_products;
      const resources = servicesRes.body.clinic_resources;
      const isAppointmentIncomplete = appointmentTypes?.some(
        (key) => appointmentTypes[key]?.description === ""
      );
      const isProductIncomplete = services?.some(
        (key) => productOrServiceTypes[key]?.description === ""
      );
      setIsServicesandResourcesComplete(!(isAppointmentIncomplete && isProductIncomplete));
      setAppointmentTypes(parseAppointmentsForView(appointmentTypes));
      setProductOrServiceTypes(parseServicesForView(services));
      setFileListView(parseFiles(resources));
    } catch (error) {
      console.log(error);
    }
  };

  const onAvailabilitySubmit = async (values) => {
    //  console.log("hereeeeeeeeeeeeeee valuessssssssssssss", values)
    setPrevClinicOffDays(clinicOffDays);
    setResetClinicAvailabilityForm(false);
    setIsButtonSpinning(true);
    let postClinicAvailability = {
      startDate: moment("2022-01-01T00:00:00.000Z").format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment("2099-01-01T00:00:00.000Z").format("YYYY-MM-DD HH:mm:ss"),
      clinic_id: clinic.clinicId,
      // start_time: clinicWorkingHours[0].format("HH:mm:ss"),
      // end_time: clinicWorkingHours[1].format("HH:mm:ss"),
      clinicAvailability: clinicAvailability,
      // workingDays: values,
      // timezone: values.timeZone
    };
    if (values) {
      postClinicAvailability.workingDays = values;
      if (values?.timeZone) {
        postClinicAvailability.timezone = values.timeZone;
      }
    }

    if (clinicBreakHours && clinicBreakHours.length) {
      postClinicAvailability = {
        ...postClinicAvailability,
        break_start_time: clinicBreakHours[0].format("HH:mm:ss"),
        break_end_time: clinicBreakHours[1].format("HH:mm:ss"),
        workingDays: values,
        timezone: values.timeZone,
      };
    }

    try {
      await clinicEditAvailability(postClinicAvailability);

      const holidays = createHolidays();
      const postHolidays = { dates_array: holidays };

      await dentistClinicApiTasks.post(
        "clinic/AddHoliday?clinic_id=" + clinic.clinicId,
        postHolidays
      );

      const workingDays = createWorkingDays();
      const postWorkingDays = { workdays: workingDays };
      await dentistClinicApiTasks.post("clinic/AddWorkday", postWorkingDays);

      message.success("Availability settings updated");
      if (!clinic.isOnboarded) {
        fetchClinicDetails();
      }
      setMode("view");

      setIsButtonSpinning(false);
    } catch (error) {
      errorModal(error.response.data.err);

      console.log(error);
    } finally {
      setIsButtonSpinning(false);
    }
  };

  function removeDuplicate(arr1, arry2) {
    return arr1.filter(
      ({ appointmentNameId, appointmentTypeId }) =>
        !arry2.some(
          ({ appointment_name_id: name, appointment_type_id: type }) =>
            name === appointmentNameId && appointmentTypeId === type
        )
    );
  }
  // console.log(removeDuplicate([1,2,3],[1,3,4]),'here'.repeat(250))
  const onProductsAndServicesSubmit = async () => {
    setIsButtonSpinning(true);

    try {
      const appointments = Object.values(appointmentTypes).map((appointment) => {
        return {
          clinicId: clinic.clinicId,
          appointmentType: JSON.parse(appointment.appointmentType).clinic_appointment_type_name,
          appointmentName: JSON.parse(appointment.appointmentName).clinic_appointment_name,
          appointmentDuration: JSON.parse(appointment.durationOfMeeting).clinic_meeting_duration,
          appointmentDescription: appointment.description,
          appointmentPrice: appointment.price,
          appointmentTypeId: JSON.parse(appointment.appointmentType).clinic_appointment_type_id,
          appointmentNameId: JSON.parse(appointment.appointmentName)
            .clinic_appointment_type_name_id,
          appointmentDurationId: JSON.parse(appointment.durationOfMeeting)
            .clinic_meeting_duration_id,
          appointmentCurrencyId: JSON.parse(appointment.appointmentCurrency).currency_id,
          paymentIds: appointment?.paymentIds || [],
          free: appointment?.free ? true : false,
        };
      });

      const productsAndServices = Object.values(productOrServiceTypes).map(
        (productOrService, index) => {
          return {
            clinicId: clinic.clinicId,
            productType: JSON.parse(productOrService.productOrServiceType).product_type,
            productTypeId: JSON.parse(productOrService.productOrServiceType).product_type_id,
            productName: JSON.parse(productOrService.productOrServiceName).product_name,
            productNameId: JSON.parse(productOrService.productOrServiceName).product_name_id,
            productDescription: productOrService.description,
            productPrice: productOrService.price,
            productCurrencyId: JSON.parse(productOrService.currency).currency_id,
            paymentIds: productOrService?.paymentIds || [],
            discount: productOrService?.discountAmount || 0,
            free: productOrService?.free ? true : false,
            hidePatientPrice: productOrService?.hidePatientPrice ? true : false,
          };
        }
      );

      const hasDuplicates = (values) =>
        new Set(
          values.map(({ appointmentNameId, appointmentTypeId }) =>
            JSON.stringify([appointmentNameId, appointmentTypeId])
          )
        ).size < values.length;
      if (hasDuplicates(appointments)) {
        message.error("Appointment type is already exist!");
        setIsButtonSpinning(false);
        return false;
      }
      const addAppointmentsAndProducts = {
        prodDetails: productsAndServices,
        // appointmentDetails: removeDuplicate(appointments,appointmentfromDb),
        appointmentDetails: appointments,
      };
      if (
        !appointments.every(
          (i) =>
            i.appointmentDescription && (i.appointmentPrice || i.free || i.appointmentPrice === 0)
        )
      ) {
        message.error("All fields are required");
        setIsButtonSpinning(false);
        return false;
      }

      if (
        !productsAndServices.every(
          (i) => i.productDescription && (i.productPrice || i.free || i.productPrice === 0)
        )
      ) {
        message.error("All fields are required");
        setIsButtonSpinning(false);
        return false;
      }
      // const res = await dentistClinicApiTasks.post(
      //   "clinic/AddAppointment",
      //   addAppointmentsAndProducts
      // );
      const res = await addClinicAppointmentProducts(addAppointmentsAndProducts);
      setIsButtonSpinning(false);
      if (res?.data?.body?.err?.msg) {
        message.error(res?.data?.body?.err?.msg);
      } else {
        _getServicesAndResources();
        message.success("Clinic settings have been updated");
        setMode("view");
        if (!clinic.isOnboarded) {
          fetchClinicDetails();
        }
      }
    } catch (error) {
      if (error.response.data?.err?.msg) {
        errorModal(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }

      setIsButtonSpinning(false);
    }
  };

  const canOnboardingBeCompleted = () => {
    setCanBeOnboarded(
      (clinicDetails.clinicName &&
        (!clinicDetails.clinicAvailability || userWeeklyAvailability) &&
        // clinicWorkingDays.length &&
        Object.values(appointmentTypes).length) ||
        clinic.isOnboarded
    );
  };

  const submitAvailabilityOff = async () => {
    const postClinicAvailability = {
      email: clinic.clinicEmail,
      clinicId: clinic.clinicId,
      clinicAvailability,
    };

    try {
      setIsButtonSpinning(true);
      await addClinicAvailability(postClinicAvailability);
    } catch (error) {
      console.log("submitAvailabilityOff", error);
    } finally {
      setIsButtonSpinning(false);
      setMode("view");
    }
  };

  const editProfile = () => {
    if (currentSelection === 0) {
      setShouldSubmit(true);
    } else if (currentSelection === 1) {
      if (clinicAvailability) {
        setShouldAvailabilitySubmit(true);
      } else {
        submitAvailabilityOff();
      }
    } else if (currentSelection === 2) {
      onProductsAndServicesSubmit();
    } else if (currentSelection > 2) {
      setMode("view");
      form.resetFields();
      resetClinicAvaibility();
    }
  };

  const renderSwitch = () => {
    switch (currentSelection) {
      case 0:
        return (
          <div>
            <AccountSettingsFormComponent
              formRef={form}
              onSubmit={onProfileFormSubmit}
              clinicDetails={clinicDetails}
              shouldSubmit={shouldSubmit}
              setShouldSubmit={setShouldSubmit}
              canSubmit={canAvailabilitySubmit}
              locationSearchResults={locationSearchResults}
              setLocationSearchResults={setLocationSearchResults}
              mode={mode}
              forPage="onboardedClinicScreenFlag"
              clinicStaff={clinicStaff}
              setClinicStaff={setClinicStaff}
              fetchClinicDetails={fetchClinicDetails}
            ></AccountSettingsFormComponent>

            {/* <ClinicianForm clinicStaff={clinicStaff} cliniciansForm={cliniciansForm}/> */}
          </div>
        );
      case 1:
        return (
          <div>
            <AvailabilitySettingsComponent
              resetClinicAvailabilityForm={resetClinicAvailabilityForm}
              prevUserAvailabilityForm={prevUserAvailabilityForm}
              setPrevUserAvailabilityForm={setPrevUserAvailabilityForm}
              userWeeklyAvailability={userWeeklyAvailability}
              timezone={timezone}
              formRef={form}
              setOffDays={setClinicOffDays}
              mode={mode}
              setMode={setMode}
              offDays={clinicOffDays}
              workingDaysRange={clinicWorkingDaysRange}
              setWorkingDaysRange={setClinicWorkingDaysRange}
              workingDays={clinicWorkingDays}
              setWorkingDays={setClinicWorkingDays}
              workingHours={clinicWorkingHours}
              setWorkingHours={setClinicWorkingHours}
              breakHours={clinicBreakHours}
              setBreakHours={setClinicBreakHours}
              shouldSubmit={shouldAvailabilitySubmit}
              setShouldSubmit={setShouldAvailabilitySubmit}
              onSubmit={onAvailabilitySubmit}
              clinicDetails={clinicDetails}
              setClinicDetails={setClinicDetails}
              clinicAvailability={clinicAvailability}
              setClinicAvailability={setClinicAvailability}
            ></AvailabilitySettingsComponent>
          </div>
        );

      case 2:
        return (
          <ServicesAndResourcesComponent
            formRef={form}
            onSubmit={onProductsAndServicesSubmit}
            shouldSubmit={shouldProductsAndServicesSubmit}
            setFileList={setFileListView}
            appointmentTypes={appointmentTypes}
            setAppointmentTypes={setAppointmentTypes}
            productOrServiceTypes={productOrServiceTypes}
            setProductOrServiceTypes={setProductOrServiceTypes}
            setIsMultiStepButtonDisabled={setIsMultiStepButtonDisabled}
            fileList={fileListView}
            appointmentTypeOptions={appointmentTypeOptions}
            setAppointmentTypeOptions={setAppointmentTypeOptions}
            appointmentNameOptions={appointmentNameOptions}
            setAppointmentNameOptions={setAppointmentNameOptions}
            appointmentDurationOptions={appointmentDurationOptions}
            setAppointmentDurationOptions={setAppointmentDurationOptions}
            appointmentCurrencyOptions={appointmentCurrencyOptions}
            setAppointmentCurrencyOptions={setAppointmentCurrencyOptions}
            productOrServiceTypeOptions={productOrServiceTypeOptions}
            setProductOrServiceTypeOptions={setProductOrServiceTypeOptions}
            productOrServiceNameOptions={productOrServiceNameOptions}
            setProductOrServiceNameOptions={setProductOrServiceNameOptions}
            productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
            removeFromDB={removeAppointmentProductFromDB}
            clinic={clinic}
            setProductOrServiceCurrencyOptions={setProductOrServiceCurrencyOptions}
            getServicesAndResources={_getServicesAndResources}
            mode={mode}
            clinicAvailability={clinicAvailability}
          ></ServicesAndResourcesComponent>
        );
      // case 3:
      //   return (
      //     <div>
      //       <ManageStaff
      //         formRef={form}
      //         shoudSubmit={setShouldManageStaffSubmit}
      //         mode={mode}
      //       />
      //     </div>
      //   );
      // case 4:
      //   return (
      //     <div>
      //       <LabPartners mode={mode} />
      //     </div>
      //   );
      default:
        return <p>Services</p>;
    }
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="onboarding-container">
          <Row justify="space-between" style={{ width: "100%" }} className="onboarding-title">
            <Title
              level={3}
              style={{
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Profile & Settings
            </Title>
            {mode === "view" && (
              <AppButton
                type="primary"
                shape="round"
                style={{ width: "100px" }}
                onClick={() => setMode("edit")}
              >
                Edit
              </AppButton>
            )}
          </Row>

          <div>
            <ProfileNavbarComponent
              currentSelection={currentSelection}
              loading={isLoading}
              setCurrentSelection={setCurrentSelection}
              canOnboardingBeCompleted={canBeOnboarded}
              clinicDetails={clinicDetails}
              offDays={clinicOffDays}
              workingDaysRange={clinicWorkingDaysRange}
              workingDays={clinicWorkingDays}
              workingHours={clinicWorkingHours}
              breakHours={clinicBreakHours}
              appointmentTypes={appointmentTypes}
              productOrServiceTypes={productOrServiceTypes}
              isServicesandResourcesComplete={isServicesandResourcesComplete}
            ></ProfileNavbarComponent>
          </div>
          {renderSwitch()}
        </div>
        <div className={mode === "edit" ? "onboarding-footer" : ""}>
          {mode === "edit" && (
            <Row justify="end" style={{ marginTop: "1rem", width: "100%" }}>
              <AppButton
                onClick={() => {
                  setMode("view");
                  form.resetFields();
                  resetClinicAvaibility();
                }}
              >
                Cancel
              </AppButton>
              {currentSelection <= 2 && (
                // <Spin spinning={isButtonSpinning}>
                //   {" "}
                <AppButton
                  loading={isButtonSpinning}
                  type={"primary"}
                  style={{ marginLeft: "1rem" }}
                  onClick={editProfile}
                >
                  Save
                </AppButton>
                // </Spin>
              )}
            </Row>
          )}
        </div>
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(ProfileAndSettings);
