import Icon from "@ant-design/icons";
import { primaryColor } from "colors-config";
import styles from "./customIcon.module.scss";

const CustomIcon = ({ style, iconComponent, ...props }) => {
  return (
    <Icon
      className={styles.icon}
      style={{ color: primaryColor, ...style }}
      {...props}
      component={iconComponent}
    />
  );
};

export default CustomIcon;
