import { primaryColor } from "colors-config";
import { Link } from "react-router-dom";
export const RedirectLink = ({text,url}) => {
  return (
    <Link
      style={{
        color: primaryColor,
        fontFamily: "Mulish",
      }}
      to={url}
    >
     {text}
    </Link>
  );
};
