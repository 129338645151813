import { Row, Col } from "antd";

const PrescriptionAlignment = ({prescriptionPDFObj}) => {
  return (
    <div className="prescriptionPDF-card">
      <h2>Alignment</h2>
      <div className="prescriptionPDF-align">
        <div>
          <div className="border-right">
            <label className="treatment-label">Incisal edge alignment</label>
            <p className="treatment-info">{prescriptionPDFObj.alignment}</p>
          </div>
        </div>
        <div>
          <div className="border-right">
            <label className="treatment-label">Crowding</label>
            <p className="treatment-info">{prescriptionPDFObj.crowding}</p>
          </div>
        </div>

        <div>
          <div>
            <label className="treatment-label">Spacing</label>
            <p className="treatment-info">{prescriptionPDFObj.spacing}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrescriptionAlignment