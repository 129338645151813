import { Switch } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const SwitchButton = ({
  styles,
  name,
  onSwitchChange,
  type,
  loading,
  value,
  disabled
}) => {
  return (
    <>
      <Switch
        checked={value}
        loading={loading}
        onChange={(e) => onSwitchChange(e, type)}
        size="small"
        disabled={disabled}
      />
      <span className={styles["btn-name"]}>{name}</span>
      {value ? <CheckCircleFilled style={{ color: "#26BE00" }} /> : ""}
    </>
  );
};

export default SwitchButton;
