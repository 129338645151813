import { Tag } from "antd";
import { TAG_STATUS } from "../../../constants";
import styles from "./tag.module.scss";

const defaultColors = {
  background: "#DBDBDB",
  color: "#000000",
};

const successColors = {
  background: "#DFEFEA",
  color: "#187E2C",
};

const errorColors ={
  background: "rgba(254, 80, 80, 0.18)",
  color: "red"
}

const AppTag = ({ text, color, customStyle={} }) => {
  let style = {};
  if (color === TAG_STATUS.DEFAULT) {
    style = defaultColors;
  } else if (color === TAG_STATUS.SUCCESS) {
    style = successColors;
  }
   else if (color === TAG_STATUS.ERROR) {
    style = errorColors;
  }

  style = { ...style, ...customStyle }

  return (
    <Tag color={color} style={style} className={styles["tag-class"]}>
      {text}
    </Tag>
  );
};

export default AppTag;
