import ApiCore from "../services/api/utilities/core";
import axios from "axios";

export const manageStaffAxiosInstance = axios.create();

const configurationObject = {
  getAll: true,
  getSingle: true,
  getClinic: true,
  post: true,
  put: false,
  patch: true,
  remove: true,
  isAuthRequired: true,
};

export const manageStaffApiTasks = new ApiCore(
  { ...configurationObject },
  manageStaffAxiosInstance
);
