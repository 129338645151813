import React from "react";

import { Tag, Tooltip, DatePicker, Input, Typography, Form } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';


import "../../../assets/styles/components/selectDaysOffComponent.scss";
import { tagBackgroundColor } from "colors-config";
dayjs.extend(isBetween)
class SelectDaysOffComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.offDays,

      inputValue: "",
    };
    this.formRef = React.createRef();
  }

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);

    this.setState({ tags });
    this.props.setOffDays(tags);
  };

  onFinish = (values) => {
    this.props.setCanSubmit(true);
  };

  componentDidUpdate(prevProps) {
    // console.log(this.props, "props days of");
  

    if (this.props.shouldSubmit) {
      this.formRef.current.resetFields();
      if (this.formRef.current.getFieldValue().off.length) {
        this.props.setCanSubmit(true);
      }

      this.formRef.current.validateFields();
    }
    if (prevProps.offDays !== this.props.offDays) {
      this.setState({ tags: this.props.offDays });
    }
  }

  disableDates = (currentDate) => {
    // const cDate= moment(currentDate);
    if (this.props.workingDaysRange) {
      const from = this.props.workingDaysRange[0];
      const to = this.props.workingDaysRange[1];
      return !currentDate.isBetween(from, dayjs(to).add(1, "days"));
    }
  };

  handleInputConfirm = (momentDate) => {
    const date = momentDate.format("YYYY-MM-DD");
    this.setState({ inputValue: date });

    const selectedDate = date;

    let { tags } = this.state;
    if (selectedDate && !tags.includes(selectedDate)) {
      tags = [...tags, selectedDate];
    }

    this.setState({
      tags,

      inputValue: "",
    });
    this.props.setOffDays(tags);
  };

  saveInputRef = (input) => {
    this.input = input;
  };



  render() {
    const {
      tags,

      inputValue,
    } = this.state;
    return (
      <div className="days-off-container">
        <Form
          style={{
            width: "95%",
          }}
          name="complex-form-2"
          ref={this.formRef}
          layout={"vertical"}
          onFinish={this.onFinish}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item name="off" initialValue={tags} style={{ display: "none" }}>
            <Input size="large"></Input>
          </Form.Item>

          <Form.Item
            label="Dates"
            name="offDays"
            style={{
              width: "100%",
              marginTop: "1rem",
              marginLeft: "2rem",
            }}
          >
            <div className="days-off-input-container">
              
                {tags.map((tag, index) => {
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      style={{ float: "left", margin: "0.5rem", backgroundColor:"transparent", color:"rgba(0, 0, 0, 0.88)", border:"1px solid #d9d9d9" }}
                      closable={this.props.mode !== "view" && this.props.clinicAvailability}
                      onClose={() => this.handleClose(tag)}
                    >
                      {isLongTag ? `${tag}` : tag}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}
              
              <DatePicker
               size="large"
                disabled={this.props.mode === "view" || !this.props.clinicAvailability}
                style={{ marginLeft: "0rem", width: "100%" }}
                disabledDate={this.disableDates}
                bordered={false}
                ref={this.saveInputRef}
                onChange={this.handleInputConfirm}
                value={inputValue !== "" ? dayjs(inputValue) : ""}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export default SelectDaysOffComponent;
