import React from "react";
import PropTypes from "prop-types";

const PatientName = ({ record }) => {
  return (
    <div className="patient-name-tableData">
      <p>{record.patientName}</p>
      <p>{record.patientId}</p>
    </div>
  );
};

PatientName.propTypes = {
  record: PropTypes.shape({
    patientName: PropTypes.string.isRequired, 
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};

export default PatientName;
