import { useState, useEffect } from "react";

import { Modal } from "antd";

const ConfirmationModal = ({
  title,
  content,
  onConfirmation,
  showModal,
  setShow,
  onCancel,
  okText
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
    onConfirmation();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
    if(onCancel){
      onCancel();
    }
  };

  return (
    <>
      <Modal
        title={title}
        okText={okText ? okText : "Confirm"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {content}
      </Modal>
    </>
  );
};

export default ConfirmationModal;
