import { useState } from "react";
import { Form, Input, Row, Col, Button, Popconfirm } from "antd";
import ResourceUpload from "../ResourceUpload/ResourceUpload";
// import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";
// import { DeleteOutlined } from "@ant-design/icons";
// import { primaryColor } from "colors-config";
// const { TextArea } = Input;

const AddResourceForm = ({
  addResourceForm,
  onResourceSubmit,
  setTouched,
  resourceFileList,
  setResourceFileList,
}) => {
  const onChange = (info) => {
    // setIprFile(info.file);
  };

  const beforeUpload = (file) => {
    return false;
  };
  return (
    <div>
      <Form
        form={addResourceForm}
        layout="vertical"
        name="addResourceForm"
        className="addResource-form"
        onFinish={onResourceSubmit}
        onFieldsChange={(changedValue, allFields) => {
          setTouched(true);
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please add a resource title",
            },
          ]}
        >
          <Input placeholder="Enter title" size="large"/>
        </Form.Item>

        <label>Upload Documents and Images</label>
        <ResourceUpload
          multiple={true}
          beforeUpload={beforeUpload}
          onChange={onChange}
          resourceFileList={resourceFileList}
          setResourceFileList={setResourceFileList}
        />
        <Form.Item
          name="url"
          label="Add Link"
          style={{
            textAlign: "left",
            marginTop:"8px"
          }}
          rules={[
            {
              type: "url",
              message:
                "This field must be a valid url (start with https://) and end with a domain.",
            },
          ]}
        >
          <Input placeholder="https://www.example.com" size="large"></Input>
        </Form.Item>
        {/* <Form.List name="videoLinks">
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, fieldKey, ...restField }, index) => {
                const formValues = addResourceForm.getFieldsValue();
                console.log("formValues", formValues)
                const linkValue = Object.keys(formValues).length ? formValues?.videoLinks[index]?.resourceUrl :  null; 
                return (
                  <div key={key} style={{ position: "relative" }}>
                    <Row gutter={[8, 8]} align="middle">
                      <Col lg={23}>
                        <Form.Item
                          {...restField}
                          fieldKey={[fieldKey, "resourceUrl"]}
                          name={[name, "resourceUrl"]}
                          label="Add Link"
                          style={{
                            textAlign: "left",
                          }}
                          rules={[
                            {
                              type: "url",
                              message:
                                "This field must be a valid url (start with https://) and end with a domain.",
                            },
                          ]}
                        >
                          <Input placeholder="https://www.example.com"></Input>
                        </Form.Item>
                      </Col>
                      <Col lg={1}>
                        {fields.length > 1 && linkValue && (
                          <Popconfirm
                            okButtonProps={{
                              style: {
                                width: "7rem",
                                background: primaryColor,
                                borderColor: primaryColor,
                                borderRadius: "30px",
                                color: "#fff",
                                height: "32px",
                                padding: "4px 15px",
                                marginTop: "10px",
                              },
                            }}
                            cancelButtonProps={{
                              style: {
                                width: "7rem",
                                borderRadius: "30px",
                                height: "32px",
                                padding: "4px 15px",
                                marginTop: "10px",
                              },
                            }}
                            title="Delete Link"
                            description="Are you sure to delete this video link?"
                            onConfirm={() => {
                              remove(name);
                            }}
                            onCancel={(e) => console.log("cancel")}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              className="delete-btn"
                              icon={<DeleteOutlined />}
                            ></Button>
                          </Popconfirm>
                        )}
                        {fields.length > 1 && !linkValue && (
                          <Button
                            className="delete-btn"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              remove(name);
                            }}
                          ></Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <Button
                icon={<AddIcon style={{ fill: primaryColor }} />}
                className="addOn-btn"
                onClick={() => {
                  add();
                }}
              >
                Add Another Link
              </Button>
            </>
          )}
        </Form.List> */}
      </Form>
    </div>
  );
};

export default AddResourceForm;
