import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc)
dayjs.extend(timezone)
export const saveAppointmentPayload = ({
  timeSlots,
  dropDown,
  selectedDate,
  clinicAppointmentServices,
  selectedPatient,
  selectedSlot,
  clinicId,
  iprSelected,
}) => {
  const slot = timeSlots.find((el) => el.appointmentSlotId === selectedSlot);

  let apiData = {};

  const appointmentDetailData = clinicAppointmentServices.find(
    (obj) =>
      obj.appointment_name.toLowerCase() ==
        slot.appointmentName.toLowerCase() &&
      obj.appointment_type.toLowerCase() == slot.appointmentType.toLowerCase()
  );
  const timezone = dayjs.tz.guess();  
  apiData = {
    email: selectedPatient.patient_email,
    clinicId: clinicId,
    appointmentDate: selectedDate,
    appointmentType: appointmentDetailData.appointment_type,
    appointmentName: appointmentDetailData.appointment_name,
    appointmentStartTime: slot.appointmentStartTime,
    appointmentEndTime: slot.appointmentEndTime,
    appointmentSlotId: slot.appointmentSlotId,
    appointmentTypeId: appointmentDetailData.appointment_type_id,
    appointmentNameId: appointmentDetailData.appointment_name_id,
    cost: appointmentDetailData.price,
    currencyId: appointmentDetailData.slot_currency_id,
    currencyName: appointmentDetailData.currency_name,
    paymentType: "offline",
    clinician_id: dropDown.clinicianId,
    notify:false,
    timezone:timezone,
    iprSelected,
    addressId:dropDown.clinicId
  };

  return apiData;
};
